import { HOST_URL, LOCAL_STORAGE_LIVE_REPORT, LOCAL_STORAGE_TOKEN_KEY } from "../../consts";
import axios from "axios";
import { getLiveReportsSuccess } from "./actions/getLiveReportsSuccess";
import { unauthorizedError } from "../signin/unauthorizedErrorAction";
import { getRobotVoltagesSuccess } from "./actions/getRobotVoltagesSuccessAction";

export default class ApiHandler {
    constructor(props) {
        this.authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    }

    get = async (url) => {
        const response = await axios.get(url, { headers: { Authorization: this.authToken } });
        return response.data;
    }

    post = async (url, data) => {
        const response = await axios.post(url, data, { headers: { Authorization: this.authToken } });
        return response.data;
    }

    getLiveReports = async (projectId, processId, saveData = true) => {
        // if (saveData) {
        //     const local = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_REPORT) || '{}');
        //     local[projectId] = {
        //         processId,
        //         time: new Date()
        //     };
        //     localStorage.setItem(LOCAL_STORAGE_LIVE_REPORT, JSON.stringify(local));
        // }
        return this.get(`${HOST_URL}/api/projects/${projectId}/getlivereports?processId=${processId}`);
    }

    refreshRobotBatteries = async (projectId, robotId, vehicleId) => {
        let value = '';
        let category = 'project';
        if (robotId) {
            category = 'robot';
            value = robotId;
        }
        else if (vehicleId) {
            category = 'vehicle';
            value = vehicleId;
        }

        return this.get(`${HOST_URL}/api/projects/refresh/robotreports?category=${category}&projectId=${projectId}&value=${value}`);
    }

    getRobotBatteries = async (projectId, from, to) => this.get(`${HOST_URL}/api/projects/${projectId}/robotvoltages?from=${from.getTime()}&to=${to.getTime()}`);

    sendCommand = async ({ projectId, event, category, value, cmd, rpi }) => {
        const body = {
            event,
            category,
            value,
            cmd,
            rpi
        }
        const url = `${HOST_URL}/api/commands/projects/${projectId}`;
        return this.post(url, body);
    }


    getAvgVoltage = (projectId, from, to) => {
        if (!from) {
            from = new Date();
            from.setMonth(from.getMonth() - 1);
        }

        if (!to)
            to = new Date();


        return this.get(`${HOST_URL}/api/projects/${projectId}/averagevoltage?from=${from.getTime()}&to=${to.getTime()}`);
    }


    getCleanedArea = (projectId, from, to) => {
        if (!from) {
            from = new Date();
            from.setMonth(from.getMonth() - 1);
        }

        if (!to)
            to = new Date();


        return this.get(`${HOST_URL}/api/projects/${projectId}/cleaningreports?from=${from.getTime()}&to=${to.getTime()}`);
    }

}