import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getRobotSettingsSuccess} from "./getRobotSettingsSuccessAction";
import {getRobotSettingsError} from "./getRobotSettingsErrorAction";
import {getRobotSettingsStarted} from "./getRobotSettingsStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {uploadRobotSettingsError} from "./uploadRobotSettingsErrorAction";

export function uploadRobotSettingsCsv(fromData, projectId) {

    return function (dispatch) {
        let url = HOST_URL + '/api/robots/more/settings/import';

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotSettingsStarted());

        return axios.post(url, fromData, {headers: {Authorization: authToken}}).then(async (response) => {
            if (response.data.success) {
                let url = HOST_URL+'/api/robots/more/settings?projectId=' + projectId;
                const r = await axios.get(url,{headers: {Authorization: authToken}});
                if (r.data.success)
                    dispatch(getRobotSettingsSuccess(r.data.data));
                else
                    dispatch(uploadRobotSettingsError(new Error("can't get robot settings")));
            }
            else {
                dispatch(getRobotSettingsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotSettingsError(error));
        });
    };
}