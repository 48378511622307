import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editRobotSettingsSuccess} from "./editRobotSettingsSuccessAction";
import {editRobotSettingsError} from "./editRobotSettingsErrorAction";
import {editRobotSettingsStarted} from "./editRobotSettingsStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function editRobotSettings(robotId, settings) {

    return function (dispatch) {
        let url = HOST_URL+`/api/robots/${robotId}/settings`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editRobotSettingsStarted());

        return axios.patch(url, settings,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(editRobotSettingsSuccess(response.data.robot));
            }
            else {
                dispatch(editRobotSettingsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editRobotSettingsError(error));
        });
    };
}