import axios from "axios";

import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_LIVE_REPORT } from "../../../consts";

import { getLiveReportsSuccess } from "./getLiveReportsSuccess";
import { unauthorizedError } from "../../signin/unauthorizedErrorAction";

export function getLiveReports(projectId, processId, saveData = true) {

    return function (dispatch) {

        if (saveData) {
            const local = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_REPORT) || '{}');
            local[projectId] = {
                processId,
                time: new Date()
            };
            localStorage.setItem(LOCAL_STORAGE_LIVE_REPORT, JSON.stringify(local));
        }

        let url = HOST_URL + `/api/projects/${projectId}/getlivereports?processId=${processId}`
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        return axios.get(url, { headers: { Authorization: authToken } }).then((response) => {
            if (response.data.success) {
                dispatch(getLiveReportsSuccess(response.data.reports));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            // dispatch(getRobotLogsError(error));
        });
    };
}