export const EDIT_ROBOT_SETTINGS_STARTED = 'EDIT_ROBOT_SETTINGS_STARTED';
export const EDIT_ROBOT_SETTINGS_ERROR = 'EDIT_ROBOT_SETTINGS_ERROR';
export const EDIT_ROBOT_SETTINGS_SUCCESS = 'EDIT_ROBOT_SETTINGS_SUCCESS';
export const VIEW_ROBOT_SETTINGS = 'VIEW_ROBOT_SETTINGS';

const INITIAL_STATE = {
    isLoading: false
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case VIEW_ROBOT_SETTINGS:
            return {
                ...state,
                robotSettingsSaved: undefined
            }
        case EDIT_ROBOT_SETTINGS_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case EDIT_ROBOT_SETTINGS_SUCCESS:
            return  {
                ...state,
                isLoading: false,
                robotSettings: action.payload.robotSettings,
                robotSettingsSaved: true
            }
        case EDIT_ROBOT_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        default:
            return state;
    }
}