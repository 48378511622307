import {EDIT_AVAILABILITY_SUCCESS} from "./editAvailabilityReducer";

export const GET_AVAILABILITY_STARTED = 'GET_AVAILABILITY_STARTED';
export const GET_AVAILABILITY_ERROR = 'GET_AVAILABILITY_ERROR';
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_CSV_ERROR = 'GET_AVAILABILITY_CSV_ERROR';
export const GET_AVAILABILITY_CSV_SUCCESS = 'GET_AVAILABILITY_CSV_SUCCESS';

const INITIAL_STATE = {
    reports: [],
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_AVAILABILITY_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_AVAILABILITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reports: action.payload.reports
            };

        case GET_AVAILABILITY_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_AVAILABILITY_CSV_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_AVAILABILITY_CSV_SUCCESS:
            return {
                ...state,
                csv: action.payload.csv,
                isLoading: false
            };
        case EDIT_AVAILABILITY_SUCCESS:
            const {reports} = state;
            const index = reports.findIndex(r=>r._id ===action.payload.availabilityReport._id);
            if (index !==-1)
                reports[index] = action.payload.availabilityReport;

            return {
                ...state,
                reports
            }
        default:
            return state;
    }
    // //console.log("action.type",action.type);
}