import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getMessagesSuccess} from "./getMessagesSuccessAction";
import {getMessagesError} from "./getMessagesErrorAction";
import {getMessagesStarted} from "./getMessagesStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getMessages(projectId, search, expiryDate) {

    return function (dispatch) {
        let url = `${HOST_URL}/api/messages?projectId=${projectId}`;
        if (search)
            url += '&search='+search;

        if (expiryDate)
            url += '&expiryDate='+expiryDate;
        
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getMessagesStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // dispatch(getMessagesSuccess(response.data.data));
                dispatch(getMessagesSuccess(response.data));

            }
            else {
                dispatch(getMessagesError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getMessagesError(error));
        });
    };
}