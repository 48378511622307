import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getVehicleLogsStarted} from "./getVehicleLogsStartedAction";
import {getVehicleLogsError} from "./getVehicleLogsErrorAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {getVehicleDetailsSuccess} from "./getVehicleDetailsSuccessAction";

export function getVehicleDetails(vehicleId, lastTimestamp, errorsOnly) {

    return function (dispatch) {
        let url = HOST_URL+`/api/vehicles/${vehicleId}?lastTimestamp=${lastTimestamp}&errorsOnly=${errorsOnly}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        dispatch(getVehicleLogsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // console.log("response.data",response.data)
                dispatch(getVehicleDetailsSuccess(response.data.data));
            }
            else {
                dispatch(getVehicleLogsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getVehicleLogsError(error));
        });
    };
}