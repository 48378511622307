export const CREATE_PROJECT_STARTED = 'CREATE_PROJECT_STARTED';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const ADD_ATTACHMENT_STARTED = 'ADD_ATTACHMENT_STARTED';
export const ADD_ATTACHMENT_ERROR = 'ADD_ATTACHMENT_ERROR';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';


const INITIAL_STATE = {
    isLoading: false
}


export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CREATE_PROJECT_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                project: action.payload.project
            }
        case CREATE_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        case ADD_ATTACHMENT_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case ADD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // project: action.payload.project
            }
        case ADD_ATTACHMENT_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}