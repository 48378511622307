import React, { Component, PropTypes, useEffect, useState } from 'react';
import './errors.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Search from "../search/search";
import { camelCaseToText, LOCAL_STORAGE_USER_KEY, LOCAL_STORAGE_PROJECT } from "../../consts";
import checkIcon from "./images/check-icon.svg";
import attachmentIcon from "./images/attachment.png";
import addIcon from "./images/add-icon.svg";
import repeaterIcon from "../menu/wifi-icon.png";
import exclamationIcon from "./images/exclamation-icon.svg";
import EditError from "../editError/editError";
import Loader from "../loader/loader";
import DateCustomInput from "../dateCustomInput/dateCustomInput"
import DatePicker from "react-datepicker";
import downloadIcon from "../availability/download-icon.svg";
import Modal from "../modal/modal";
import { getDateStr, TimeZone } from "../utils";
import Dropdown from "react-dropdown";
import ApiHandler from '../api';
import getAdditionalData from './utils';
import InfiniteScroll from 'react-infinite-scroll-component';

const ErrorsComponent = ({ }) => {

    let now = new Date();
    let twoWeeks = new Date();
    twoWeeks.setDate(twoWeeks.getDate() - 14);

    const [project, setProject] = useState({});
    const [user, setUser] = useState({});
    const [search, setSearch] = useState('');
    const [showError, setShowError] = useState(false);
    const [selectedErrors, setSelectedErrors] = useState([]);
    const [startDate, setStartDate] = useState(twoWeeks);
    const [endDate, setEndDate] = useState(now);
    const [errors, setErrors] = useState([]);
    const [page, setPage] = useState(0);
    const [showCloseMultipleErrors, setShowCloseMultipleErrors] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [sort, setSort] = useState('date');
    const [timeZone, setTimeZone] = useState();
    const [errorCodeFilter, setErrorCodeFilter] = useState('important');
    const [showDeleteError, setShowDeleteError] = useState(false);
    const [showEditError, setShowEditError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [description, setDescription] = useState('');
    const [errorCodes, setErrorCodes] = useState([]);
    const [totalRedErrors, setTotalRedErrors] = useState(0);
    const [totalYellowErrors, setTotalYellowErrors] = useState(0);
    const [totalErrors, setTotalErrors] = useState(0);
    const [numOfPages, setNumOfPages] = useState(0);

    const apiHandler = new ApiHandler();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);
        setTimeZone(new TimeZone(project));
    }, []);

    useEffect(() => {
        if (!project || !project._id)
            return;

        handleErrors();
    }, [errorCodeFilter, sort, startDate, endDate, search, project])

    const onErrorTypeFilterSelected = async option => {
        setErrorCodeFilter(option.value);
    }

    const handleErrors = async () => {
        setPage(0);
        setIsLoading(true);
        try {
            const result = await apiHandler.getErrors({
                projectId: project._id,
                from: startDate,
                to: endDate,
                errorCode: errorCodeFilter,
                sort,
                search,
                page: 0
            });
            const  { errors, errorCodes, totalRedErrors, totalYellowErrors, totalErrors, numOfPages } = result;
            setErrors(errors);
            setTotalErrors(totalErrors);
            setNumOfPages(numOfPages)
            setErrorCodes(errorCodes);
            setTotalRedErrors(totalRedErrors);
            setTotalYellowErrors(totalYellowErrors);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            alert("Can't load errors");
            console.log(err);
        }
    }

    const getNextPage = async () => {
        setIsLoadingPage(true);
        setPage(page + 1);
        try {
            const { errors: newErrors } = await apiHandler.getErrors({
                projectId: project._id,
                from: startDate,
                to: endDate,
                errorCode: errorCodeFilter,
                sort,
                search,
                page: page + 1
            });

            setErrors([...errors, ...newErrors]);
            setIsLoadingPage(false);
        } catch (e) {
            setIsLoadingPage(false);
            alert("can't load more errors");
            console.log(e);
        }
    }

    const onSortSelected = async option => {
        setSort(option.value);
    }

    const downloadXlsxFile = async () => {

        const response = await apiHandler.getErrorsXlsx({projectId: project._id, search, errorCode: errorCodeFilter, from: startDate, to: endDate, sort});        
        saveXlsxFile(response);
    }

    const saveXlsxFile = (response) => {
        console.log("blob", response)
        const xlsxURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = xlsxURL;
        const startDateStr = getDateStr(startDate);
        const endDateStr = getDateStr(endDate);

        const downloadFileName = endDateStr === startDateStr ? `${project.projectName} ${startDateStr}.xlsx` : `${project.projectName} ${startDateStr}-${endDateStr}.xlsx`;

        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
    }


    const errorSelected = (error) => {
        if (user.role === "projectOwner")
            return;

        // debugger;
        if (error.isSelected) {
            const selected = errors.filter(e => e.isSelected);
            setSelectedErrors(selected.length ? selected : [error]);
            setShowEditError(true);
        }
        else {
            setSelectedErrors([error]);
            setShowEditError(true);
        }

        // props.actions.viewError();

    }

    const getSeverityIcon = (error) => error.status.includes('fixed') ? checkIcon : exclamationIcon;

    const getSeverityClass = (error) => {
        if (error.status.includes('fixed'))
            return 'severity-fixed';

        if (error.severity === 'red')
            return 'severity-red';

        if (error.severity === 'yellow')
            return 'severity-orange';

        if (error.severity === 'grey')
            return 'severity-grey';

        // default
        return 'severity-orange'
    }


    const getRowText = (error) => {
        if (!error.row)
            return;


        return error.row.number
    }

    const getErrorStatus = (error) => {

        if (!error.status.startsWith('fixed'))
            return error.status;

        let status = error.status;
        if (error.user)
            status += ` (${error.user.fullName}, ${timeZone.getLocalTime(error.fixDate, true)})`;

        return camelCaseToText(status);
    }

    const dateChanged = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    const createNewError = () => {
        // props.actions.viewError();

        setSelectedErrors([Object.assign({}, {
            robotName: "",
            vehicleName: "",
            row: null,
            projectId: project?._id,
            timestamp: null,
            errorCode: "MANUAL_ERROR",
            description: "",
            severity: "yellow",
            remarks: "",
            status: "pending",
            replacements: "",
            reporter: "manual",
        })]);
        setShowEditError(true);
    }


    const closeDeleteErrorModal = () => {
        setShowDeleteError(false);
        setShowEditError(true);
    }

    const deleteError = async () => {
        setShowDeleteError(false);
        setShowEditError(false);
        await apiHandler.deleteError(selectedErrors.map(e => e._id));

    }

    const checkboxClicked = (e, index) => {
        e.stopPropagation();

        errors[index].isSelected = e.target.checked;

        let allChecked;
        const selected = errors.filter(error => error.isSelected).length;
        if (selected === errors.length) {
            allChecked = true;
        } else {
            allChecked = false;
        }
        setErrors([...errors]);
        setAllChecked(allChecked);
    }


    const allSelected = (e) => {

        const selected = errors.filter(error => error.isSelected).length;
        let allChecked;
        if (selected === errors.length) {
            errors.map(error => error.isSelected = false);
            allChecked = false;
        } else {
            errors.map(error => error.isSelected = true);
            allChecked = true;
        }
        setErrors([...errors]);
        setAllChecked(allChecked);
    }

    const closeMultipleErrors = async () => {

        const ids = errors.filter(error => error.isSelected).map(error => error._id);
        try {
            await closeMultipleErrors(ids, project._id);
            errors.forEach(error => {
                if (error.isSelected) {
                    error.status = 'fixed';
                    error.fixDate = new Date();
                }
            });
            setErrors([...errors]);
            setShowCloseMultipleErrors(false);
        } catch (e) {
            console.log(e);
            alert("Can't close errors");
        }

    }

    const attachmentClicked = (e, attachment) => {
        e.stopPropagation();
        window.open(attachment.url, "_blank");
    }

    const getDescription = (error) => {

        let str;
        if (error.description)
            str = error.description;
        else
            str = error.errorCode.substring(0, 1) + error.errorCode.substring(1).toLowerCase().replaceAll('_', ' ');

        if (str.includes('Ups'))
            str = str.replace('Ups', 'UPS')

        if (str.includes('Ble'))
            str = str.replace('Ble', 'BLE')

        // if (error.errorCodeValue) {
        //     return <><div>{str}</div><div className='error-code'>{error.errorCode}, ${error.errorCodeValue}</div></>
        // }
        // else {
        //     return <><div>{str}</div><div className='error-code'>{error.errorCode}</div></>
        // }
        return str;
    }



    const onDeleteError = () => {
        setShowDeleteError(true);
        setShowEditError(false);
    }

    const getHeaderTH = (paramName, text, sort, onSortByColumn, className) => {
        return <th className={className}>{text}</th>;
    }

    const onErrorSaved = () => {
        
        setShowEditError(false);
        handleErrors();
    }

    const pageLoader = () => {
        if (!isLoadingPage)
            return;

        return <div className='page-loader'>
            Loading more errors...
        </div>
    }

    return (
        <div className="page">
            {isLoading ? <Loader /> : null}

            {showEditError ?
                <EditError
                    closeEditError={() => setShowEditError(false)}
                    onErrorSaved={onErrorSaved}
                    errors={selectedErrors}
                    deleteError={onDeleteError}
                /> : null}

            {showDeleteError ? <Modal title={`Delete ${selectedErrors.length} error${selectedErrors.length > 1 ? 's' : ''}?`} onClose={closeDeleteErrorModal}>
                <div className="buttons-wrapper">
                    <div className="button cancel" onClick={closeDeleteErrorModal}>Cancel</div>
                    <div className="button" onClick={deleteError}>Delete forever</div>
                </div>

            </Modal> : null}

            {showCloseMultipleErrors ?
                <Modal
                    title={`Close ${errors.filter(error => error.isSelected).length} errors?`}
                    onClose={() => setShowCloseMultipleErrors(false)}
                >

                    <div className="buttons-wrapper">
                        <div className="input-line">
                            <div className="input-description">System Description</div>
                            <input value={description} placeholder="" type="text"
                                onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="button cancel" onClick={() => setShowCloseMultipleErrors(false)}>Cancel</div>
                        <div className="button" onClick={closeMultipleErrors}>Close errors</div>
                    </div>

                </Modal> : null}

            <div className="menu-wrapper">
                <Menu />
            </div>
            <div className="content">
                <PlantHeader title={"Errors"} secondaryText={project.projectName} />
                <div className="errors">
                    <div className="top">
                        <Search onSearch={setSearch} placeholder="search by robot number" />

                        <DatePicker
                            selected={startDate}
                            onChange={dateChanged}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            shouldCloseOnSelect={false}
                            customInput={<DateCustomInput
                                logsStartDate={startDate}
                                logsEndDate={endDate} />}
                        />

                        <div className="error-filter error-codes">
                            <Dropdown
                                placeholder="IMPORTANT ERRORS"
                                options={[{
                                    label: "IMPORTANT ERRORS",
                                    value: 'important'
                                }, {
                                    label: "ALL ERRORS",
                                    value: ''
                                }].concat(errorCodes.map(item => ({
                                    value: item,
                                    label: camelCaseToText(item.name)
                                })))}
                                onChange={onErrorTypeFilterSelected}
                                // value={errorCodeFilter}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>

                        <div className="error-filter">
                            <Dropdown
                                placeholder="Sort"
                                options={[{
                                    label: "Sort by date",
                                    value: 'date'
                                }, {
                                    label: "Sort by robot",
                                    value: 'robot'
                                }]}
                                onChange={onSortSelected}
                                // value={errorCodeFilter}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>

                        <div className="download left" onClick={downloadXlsxFile}><img src={downloadIcon} />DOWNLOAD
                            REPORT
                        </div>
                        {user.role !== "projectOwner" ?
                            <div className="download" onClick={createNewError}><img src={addIcon} />CREATE ERROR
                            </div> : null}
                    </div>
                    <div className="total">
                        <span className="severity severity-red">A</span> {totalRedErrors}
                        <span className="severity severity-orange">C</span> {totalYellowErrors}
                    </div>

                    <InfiniteScroll
                        dataLength={errors.length}
                        next={getNextPage}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                        inverse={false} //
                        hasMore={page < numOfPages}
                        loader={pageLoader()}
                    // scrollableTarget="scrollableDiv"
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <th className='checkbox'>
                                        <input type="checkbox" checked={allChecked} onClick={allSelected} />
                                    </th>
                                    {getHeaderTH('', 'Severity')}
                                    {getHeaderTH('timestamp', 'Date')}
                                    {project.arrayType === 'solar-tracking' ? getHeaderTH('vehicle.name', 'Vehicle') : null}
                                    <th>Robot/<br />Repeater</th>
                                    {getHeaderTH('errorCode', 'Error Code')}
                                    {getHeaderTH('description', 'Description')}
                                    {getHeaderTH('status', 'Status')}
                                    {getHeaderTH('errorResolutionId.numerator', 'Resolved Numerator')}
                                    {getHeaderTH('row.number', 'Row')}
                                    {getHeaderTH('more data', 'Additional data')}
                                    {getHeaderTH('mainDescription', 'Error Type')}
                                    {getHeaderTH('replacements', 'Replacements')}
                                    {getHeaderTH('description', 'Remarks')}
                                    <th><img src={attachmentIcon} /></th>
                                </tr>
                                {errors.map((error, index) => {
                                    return <tr key={index} onClick={() => errorSelected(error)}>
                                        <td className="checkbox">
                                            <input type="checkbox" checked={error.isSelected}
                                                onClick={(e) => checkboxClicked(e, index)} />
                                        </td>
                                        <td className="severity">
                                            <div className={getSeverityClass(error)}>
                                                {error.status.includes('fixed') ?
                                                    <img src={getSeverityIcon(error)} /> :
                                                    <span
                                                        className="severity-letter">{error.severity === 'yellow' ? 'C' : 'A'}</span>
                                                }
                                            </div>

                                        </td>
                                        <td className="date">
                                            <span className='day'>{timeZone.getLocalTime(error.timestamp, true)}</span><br />
                                            <span className='hour'>{timeZone.getLocalTime(error.timestamp, false, false, true)}</span>
                                        </td>
                                        {project.arrayType === 'solar-tracking' ?
                                            <td>{error.vehicle ? error.vehicle.name : null}</td> : null}
                                        <td>
                                            {error.robot ?
                                                <>{error.robot.name}</> :
                                                error.repeaterId ?
                                                    <><img className="icon" src={repeaterIcon} /> {error.repeaterId.repeaterNumber}</>
                                                    : null}
                                        </td>
                                        <td className="error-code">
                                            {error.errorCode}
                                            {error.isRepeated && <div className='repeated-error'>Repeated</div>}
                                        </td>
                                        <td className="description">{getDescription(error)}</td>
                                        <td>{getErrorStatus(error)}</td>
                                        <td>{error.errorResolutionId?.numerator}</td>
                                        <td>{getRowText(error)}</td>
                                        <td className='additional-data'>{getAdditionalData(error)}</td>
                                        <td>{error.mainDescription}</td>
                                        <td className='replacements'>{error.replacements}</td>
                                        <td className="remarks">{error.remarks}</td>
                                        <td className="image">{error.attachments && error.attachments.map(
                                            attachment => <img
                                                src={attachment.url}
                                                onClick={(e) => attachmentClicked(e, attachment)}
                                            />)}
                                        </td>

                                    </tr>

                                })}

                            </tbody>
                        </table>
                    </InfiniteScroll>

                </div>
            </div>
        </div>
    )



}

export default ErrorsComponent;