import {ROBOT_SELECTED} from "../../../reducers/indexReducer";
import {LOCAL_STORAGE_ROBOT} from "../../../consts";

export const robotSelected = (robot) => {
    robot.id = robot.robotId;
    localStorage.setItem(LOCAL_STORAGE_ROBOT, JSON.stringify(robot));

    return {
        type: ROBOT_SELECTED,
        payload: {robot}
    }
}