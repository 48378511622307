import { Component } from "react";
import { getFormattedDate } from "../../consts";
import React from "react";

class DateCustomInput extends Component {
    constructor(props) {
        super(props);
        this.handleText(this.props.logsStartDate, this.props.logsEndDate);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.logsStartDate)
            this.handleText(nextProps.logsStartDate, nextProps.logsEndDate);
    }

    handleText = (logsStartDate, logsEndDate) => {
        const t1 = getFormattedDate({ date: this.props.logsStartDate, dateOnly: true });        
        const t2 = getFormattedDate({date:this.props.logsEndDate, dateOnly:true});
        this.text = t1 === t2 || !this.props.logsEndDate ? t1 : t1 + ' - ' + t2;
    }

    render() {
        return <button className="date-custom-input" onClick={this.props.onClick}>
            {this.text}
        </button>
    }

};

export default DateCustomInput;