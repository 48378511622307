import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './robotSettings.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Weather from "../weatherFooter/weather";
import Search from "../search/search";
import Checkbox from "../checkbox/checkbox";
import {projectSelected} from "../projects/projectSelectedAction";
import {getRobotSettings} from "./getRobotSettingsAction";
import download from './download-icon.svg';
import upload from './upload-icon.svg';
import plus from './plus.svg';
import {uploadRobotSettings} from "./uploadRobotSettingsAction";
import Loader from "../loader/loader";
import {getRobotSettingsSuccess} from "./getRobotSettingsSuccessAction";
import TableComponent from "../tableComponent/tableComponent";
import {downloadRobotSettingsCsv} from "./downloadRobotSettingsCsvAction";
import {uploadRobotSettingsCsv} from "./uploadRobotSettingsCsvAction";
import EditRobotSettings from "../editRobotSettings/editRobotSettings";
import {editRobotSettings} from "../editError/actions/editRowAction";
import {viewRobotSettings} from "../editRobotSettings/viewRobotSettingsAction";
import {withNavigation} from '../withNavigate';

class RobotSettingsComponent extends TableComponent {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }


        this.state = {
            showAddRow: false,
            sortParam: 'rowNumber',
            sortDirection: 1
        };
    }

    downloadCsv = () => {

        this.props.actions.downloadRobotSettingsCsv(this.props.project._id, this.props.project.projectName);
    }

    componentDidMount() {


        this.props.actions.getRobotSettings(this.props.project._id);
    }

    searchClicked = (search) => {
        this.props.actions.getRobotSettings(this.props.project._id, search);
    }

    excludeCleaningChanged = (robotSetting, isExcluded) => {
        let params = {...robotSetting};
        params.excludeCleaning = isExcluded;
        this.props.actions.editRobotSettings(params, robotSetting._id);
    }

    // onFileChange = (event)=>{
    //     this.props.actions.uploadRobotSettings(this.props.project._id, event.target.files[0]);
    // }

    fileUploadClick = () => {
        this.fileUpload.click();
    }

    closeEditRobotSettings = () => {
        this.setState({showEditRobotSettings: false});
    }


    editRobotSettings = (selectedRobot) => {
        this.props.actions.viewRobotSettings();
        this.setState({showEditRobotSettings: true, selectedRobot});
    }

    getData = () => this.props.robotSettings;

    publishData = (data) => this.props.actions.getRobotSettingsSuccess(data);

    onFileChange = event => {

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );

        formData.append(
            "projectId",
            this.props.project._id
        );

        this.fileUpload.value = "";

        this.props.actions.uploadRobotSettingsCsv(formData, this.props.project._id);
    };

    getBooleanValue = (val)=>val ==='' || val ===undefined?'':
        val ===true || val ==='yes'? 'Yes': val ===false || val ==='no'? 'No': val;

    render() {
        return (
            <div className="page">
                {this.props.isLoading ? <Loader/> : null}

                {this.state.showEditRobotSettings ? <EditRobotSettings closeEditRobotSettings={this.closeEditRobotSettings} robot={this.state.selectedRobot}/> : null}

                <div className="menu-wrapper">
                    <Menu/>
                </div>
                <div className="content">
                    <PlantHeader title={"Robot Settings"} secondaryText={this.props.project.projectName}/>
                    <div className="robotSettings">
                        <div className="top">
                            <Search onSearch={this.searchClicked} placeholder="search by robot number"/>
                            <div className="buttons">
                                <div className="button">
                                    <img src={download}/>
                                    <span className="text" onClick={this.downloadCsv}>EXPORT</span>
                                </div>

                                <div className="button" onClick={this.fileUploadClick}>
                                    <img src={upload}/>
                                    <span className="text">UPLOAD CSV</span>
                                    <input ref={(ref) => this.fileUpload = ref} type="file"
                                           accept=".csv"
                                           onChange={this.onFileChange}/>
                                </div>


                            </div>
                        </div>
                        <table>
                            <tr>
                                {this.getHeaderTH('robot', 'Robot')}
                                {this.getHeaderTH('rowNumber', 'Row Number')}
                                {this.getHeaderTH('length', 'Row Length [m]')}
                                {this.getHeaderTH('serialNumber', 'Serial Number')}
                                {this.getHeaderTH('pcbNumber', 'PCB Version')}
                                {this.getHeaderTH('swVersion', 'Software Version')}
                                {this.getHeaderTH('flippers', 'Flippers',undefined,undefined,'boolean')}
                                {this.getHeaderTH('wheelExtension', 'Wheel Extension',undefined,undefined,'boolean')}
                                {this.getHeaderTH('fourByFour', '4 X 4',undefined,undefined,'boolean')}
                                {this.getHeaderTH('robotVersion', 'Robot version')}    
                                {this.getHeaderTH('numberOfPanels', 'Panel size')}                                                        
                                {this.getHeaderTH('bridges', 'Bridges')}
                                {this.getHeaderTH('integralPanel', 'Integral Panel')}                            
                                {this.getHeaderTH('OCM', 'OCM')}
                                {this.getHeaderTH('dockingStation', 'Docking Station')}
                                {this.getHeaderTH('batterySize', 'Battery Size')}
                                {this.getHeaderTH('freeText', 'Free Text')}
                            </tr>
                            <tbody>
                            {this.props.robotSettings.map((robotSetting, index) => {
                                return <tr key={index} onClick={() => this.editRobotSettings(robotSetting)}>
                                    <td>{robotSetting.robotName}{robotSetting.rpi?` (${robotSetting.rpi})`:''}</td>
                                    <td>{robotSetting.moreSettings.rowNumber}</td>
                                    <td>{robotSetting.moreSettings.rowLength}</td>
                                    <td>{robotSetting.moreSettings.serialNumber}</td>
                                    <td>{robotSetting.moreSettings.pcbNumber}</td>
                                    <td>{robotSetting.moreSettings.swVersion}</td>
                                    <td className='boolean'>{this.getBooleanValue(robotSetting.moreSettings.flippers)}</td>
                                    <td className='boolean'>{this.getBooleanValue(robotSetting.moreSettings.wheelExtension)}</td>
                                    <td className='boolean'>{this.getBooleanValue(robotSetting.moreSettings.fourByFour)}</td>                                 
                                    <td>{robotSetting.moreSettings.robotVersion}</td>                                    
                                    <td>{robotSetting.moreSettings.numberOfPanels}</td>                                    
                                    <td>{this.getBooleanValue(robotSetting.moreSettings.bridges)}</td>
                                    <td>{this.getBooleanValue(robotSetting.moreSettings.integralPanel)}</td>                                    
                                    <td>{this.getBooleanValue(robotSetting.moreSettings.OCM)}</td>
                                    <td>{this.getBooleanValue(robotSetting.moreSettings.dockingStation)}</td>
                                    <td>{robotSetting.moreSettings.batterySize}</td>
                                    <td>{robotSetting.moreSettings.freeText}</td>
                                </tr>
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.robotSettingsReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            uploadRobotSettingsCsv,
            downloadRobotSettingsCsv,
            getRobotSettings,
            editRobotSettings,
            projectSelected,
            uploadRobotSettings,
            getRobotSettingsSuccess,
            viewRobotSettings
        }, dispatch),
    };
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(RobotSettingsComponent));