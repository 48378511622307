import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { getFormattedDate } from "../../consts";
import getAdditionalData from "../errors/utils";

const RepeatedErrorsComponent = ({ project, date }) => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [flatData, setFlatData] = useState([]);
    const [dates, setDates] = useState([]);
    const apiHandler = new ApiHandler();
    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            try {
                const reports = await apiHandler.getRobotDailyReports({ projectId: project._id, date, reportType: 'repeatedErrors' });

                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        getReports();

    }, [project, date]);

    useEffect(() => {
        const flatData = [];
        const dates = [];
        reports.forEach((report) => {
            report.repeatedErrors.forEach((error) => {
                const repeatedErrors = error.errorData.map(errorItem => ({
                    ...errorItem,
                    additionalData: getAdditionalData({ ...errorItem, errorCode: error.errorCode })
                }));

                dates.push(...repeatedErrors.map(error => error.date));

                repeatedErrors.sort((a, b) => {
                    if (a.date < b.date) {
                        return -1;
                    }
                    if (a.date > b.date) {
                        return 1;
                    }
                    return 0;
                });

                flatData.push({
                    robotNumber: report.robotId.robotNumber,
                    errorCode: error.errorCode,
                    repeatedErrors
                });
            });
        });
        flatData.sort((a, b) => {
            if (a.robotNumber !== b.robotNumber) {
                return a.robotNumber - b.robotNumber;
            }
            if (a.errorCode < b.errorCode) {
                return -1;
            }
            if (a.errorCode > b.errorCode) {
                return 1;
            }
            return 0;
        });

        for (const flatDataItem of flatData) {
            const {robotNumber} = flatDataItem;
            const items = flatData.filter(item => item.robotNumber === robotNumber);
            flatDataItem.index = items.indexOf(flatDataItem);
            flatDataItem.indexLength = items.length;            
        }

        dates.sort((a, b) => new Date(a) - new Date(b));
        setFlatData(flatData);
        const dd = dates.map(date => getFormattedDate({ date, dateOnly: true }));
        setDates([...new Set(dd)]);


    }, [reports]);

    const getRepeatedErrorByDate = (flatDataItem, date) => {
        return flatDataItem.repeatedErrors.find(error => getFormattedDate({ date: error.date, dateOnly: true }) === date);
    }

    return <>
        <table className="repeated-errors">
            <tbody>
                <tr>
                    <th className="robot-number">Robot</th>
                    <th className="error-code">Error Code</th>
                    {dates.map((date, index) => <th key={index} className="additional-data">{date}</th>)}
                    {/* <th className="additional-data">Error details</th>                     */}
                </tr>

                {flatData.map((flatDataItem) => {
                    return <tr key={`${flatDataItem.robotNumber}_${flatDataItem.errorCode}`} className={flatDataItem.index === 0?'first-line':''}>
                        {flatDataItem.index === 0 && <td className="robot-number" rowSpan={flatDataItem.indexLength}>{flatDataItem.robotNumber}</td>}
                        <td className="error-code">{flatDataItem.errorCode}</td>
                        {dates.map((date, index) => <td className='additional-data'>
                            {getRepeatedErrorByDate(flatDataItem, date) &&
                                <span className="date-and-details-x">
                                    {
                                        getRepeatedErrorByDate(flatDataItem, date).additionalData.length ? getRepeatedErrorByDate(flatDataItem, date).additionalData :
                                            'X'
                                    }
                                </span>
                            }
                        </td>
                        )}
                    </tr>
                })}
            </tbody>
        </table>
    </>
}

export default RepeatedErrorsComponent;