import {GET_ERRORS_SUCCESS} from "./errorsReducer";
import {GET_MESSAGES_SUCCESS} from "./messagesReducer";
import {EDIT_MESSAGE_SUCCESS} from "./editMessageReducer";

export const GET_PROJECT_SETTINGS_SUCCESS = 'GET_PROJECT_SETTINGS_SUCCESS';
export const GET_AVG_VOLTAGE_SUCCESS = 'GET_AVG_VOLTAGE_SUCCESS';
export const GET_CLEANED_AREA_SUCCESS = 'GET_CLEANED_AREA_SUCCESS';
export const GET_ROBOT_VOLTAGES_SUCCESS = 'GET_ROBOT_VOLTAGES_SUCCESS';
export const REFRESH_ROBOT_BATTERIES_SUCCESS = 'REFRESH_ROBOT_BATTERIES_SUCCESS';
export const REFRESH_ROBOT_BATTERIES_STARTED = 'REFRESH_ROBOT_BATTERIES_STARTED';
export const GET_LIVE_REPORTS_SUCCESS = 'GET_LIVE_REPORTS_SUCCESS';
export const GET_CLEANING_DATA_SUCCESS = 'GET_CLEANING_DATA_SUCCESS';

const INITIAL_STATE = {
    messages: [],
    errors:[],
    avgVoltageReports: [],
    liveReports: [],
    isLoading: false,
    pendingCommand: false,
    currentState: 'cleaning',
    cleaningData: {}
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_CLEANING_DATA_SUCCESS:
            return {
                ...state,
                cleaningData: action.payload.cleaningData
            };
        case GET_ROBOT_VOLTAGES_SUCCESS:
            return {
                ...state,
                robotVoltages: action.payload.robotVoltages.sort((a,b)=>a.name>b.name)
            };
        case GET_LIVE_REPORTS_SUCCESS:
            return {
                ...state,
                liveReports:  action.payload.liveReports
            };
        case GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload.messages.slice(0,10)
            };
        case EDIT_MESSAGE_SUCCESS:
            const {messages} = state;
            const message = action.payload?action.payload.message:null;

            const item = messages.find(e=>e._id ===message._id);
            if (item) {
                const index = messages.indexOf(item);
                messages[index] = message;
            }
            else {
                messages.splice(0, 0, message);
            }
            return {
                ...state,
                messages: [...messages]
            }
        case GET_ERRORS_SUCCESS:
            return {
                ...state,
                errors: action.payload.errors.slice(0,10)
            };
        case GET_PROJECT_SETTINGS_SUCCESS:
            return {
                ...state,
                project: action.payload.project
            };
        case GET_AVG_VOLTAGE_SUCCESS:
            return {
                ...state,
                avgVoltageReports: action.payload.avgVoltageReports
            }

        case GET_CLEANED_AREA_SUCCESS:
            return {
                ...state,
                cleanedAreaItems: action.payload.cleanedAreaItems
            }
        case REFRESH_ROBOT_BATTERIES_SUCCESS:
            return {
                ...state,
                processId: action.payload.processId
            }
        default:
            return state;
    }
}