// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
:root {
  --almost-black: #0d191f;
  --turquoise: #05b2aa;
  --rusty-orange: #e36510;
  --very-light-blue: #e7e8e9;
  --gun-metal: #555e62;
  --light-grey-blue: #b9bcbe;
  --cherry-red: #f6102a;
  --white: #ffffff;
  --blue-grey: #898b8d; }

.weather {
  padding: 15px 0;
  margin: 0 2.27%;
  display: inline-block;
  border-top: solid 1px var(--very-light-blue); }
  .weather .item {
    margin-right: 50px;
    display: inline-block; }
  .weather .local-time {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0 21px 0 15px;
    border-radius: 21px;
    border: 1px solid #d8d8d8; }
  .weather img {
    display: inline-block;
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 6px; }
  .weather .texts {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    margin-top: 3px; }
    .weather .texts .name {
      font-size: 12px;
      letter-spacing: -0.19px;
      color: var(--blue-grey);
      margin-bottom: 3px; }
    .weather .texts .value {
      font-size: 15px;
      letter-spacing: -0.23px;
      color: #414141; }
`, "",{"version":3,"sources":["webpack://./src/components/weatherFooter/weather.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEhB;EACE,uBAAe;EACf,oBAAY;EACZ,uBAAe;EACf,0BAAkB;EAClB,oBAAY;EACZ,0BAAkB;EAClB,qBAAa;EACb,gBAAQ;EACR,oBAAY,EAAA;;AAId;EACE,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,4CAA4C,EAAA;EAJ9C;IAOI,kBAAkB;IAClB,qBAAqB,EAAA;EARzB;IAYI,0CAAuC;IACvC,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB,EAAA;EAf7B;IAmBI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB,EAAA;EAvBrB;IA2BI,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,eAAe,EAAA;IA9BnB;MAiCM,eAAe;MACf,uBAAuB;MACvB,uBAAuB;MACvB,kBAAkB,EAAA;IApCxB;MAwCM,eAAe;MACf,uBAAuB;MACvB,cAAc,EAAA","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);\n\n:root {\n  --almost-black: #0d191f;\n  --turquoise: #05b2aa;\n  --rusty-orange: #e36510;\n  --very-light-blue: #e7e8e9;\n  --gun-metal: #555e62;\n  --light-grey-blue: #b9bcbe;\n  --cherry-red: #f6102a;\n  --white: #ffffff;\n  --blue-grey: #898b8d;\n}\n\n\n.weather {\n  padding: 15px 0;\n  margin: 0 2.27%;\n  display: inline-block;\n  border-top: solid 1px var(--very-light-blue);\n\n  .item {\n    margin-right: 50px;\n    display: inline-block;\n  }\n\n  .local-time {\n    background-color: rgba(255,255,255,0.5);\n    padding: 0 21px 0 15px;\n    border-radius: 21px;\n    border: 1px solid #d8d8d8;\n  }\n\n  img {\n    display: inline-block;\n    width: 40px;\n    height: 40px;\n    object-fit: contain;\n    margin-right: 6px;\n  }\n\n  .texts {\n    display: inline-block;\n    height: 100%;\n    vertical-align: top;\n    margin-top: 3px;\n\n    .name {\n      font-size: 12px;\n      letter-spacing: -0.19px;\n      color: var(--blue-grey);\n      margin-bottom: 3px;\n    }\n\n    .value {\n      font-size: 15px;\n      letter-spacing: -0.23px;\n      color: #414141;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
