import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './weather.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";

import weatherHumidityIcon from "./weather-humidity-icon.svg";
import weatherRainIcon from "./weather-rain-icon.svg";
import weatherTempIcon from "./weather-temp-icon.svg";
import weatherWindIcon from "./weather-wind-icon.svg";
import { bindActionCreators } from "redux";
import { getCurrentWeather } from "./getCurrentWeatherAction";
import { getWeatherHistory } from "./getWeatherHistoryAction";
import { Bar } from "react-chartjs-2";

import Loader from "../loader/loader";
import DateCustomInput from "../dateCustomInput/dateCustomInput"
import { withNavigation } from '../withNavigate';
import { TimeZone } from "../utils";

// const plugins = [{
//     afterDraw: (chartInstance, easing) => {
//         const ctx = chartInstance.chart.ctx;
//         // ctx.fillText("This text drawn by a plugin", 100, 100);
//     }
// }];


class WeatherComponent extends Component {
    constructor(props) {
        super(props);
        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }


        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        this.state = {
            startDate,
            endDate: new Date(),
            tab: 0
        };
    }


    componentDidMount() {

        const projectId = this.props.project._id;
        this.props.actions.getWeatherHistory(projectId, this.state.startDate.getTime(), this.state.endDate.getTime());
        this.props.actions.getCurrentWeather(projectId);

    }

    dateChanged = dates => {
        const [start, end] = dates;
        this.setState({ startDate: start, endDate: end });
        this.props.actions.getWeatherHistory(this.props.project._id, start ? start.getTime() : '', end ? end.getTime() : '');
    };

    getWindDirection = (angle) => {
        let directions = [
            'N',
            'NE',
            'E',
            'SE',
            'S',
            'SW',
            'W',
            'NW'
        ];

        let index = Math.round((angle % 360) / 45);
        return directions[index];
    }

    getGraphTitle = () => {
        switch (this.state.tab) {
            case 0:
                return 'DAILY TEMPERATURE';
            case 1:
                return 'DAILY HUMIDITY';
            case 2:
                return 'DAILY RAIN';
            case 3:
                return 'DAILY WIND SPEED AND DIRECTION';
        }
    }




    getData = () => {
        const getDataLabel = () => {
            switch (this.state.tab) {

                case 0:
                    return 'temperature';
                case 1:
                    return 'humidity';
                case 2:
                    return 'rain';
                case 3:
                    return 'windSpeed';
            }
        }
        const labels = this.props.weatherHistory.map((w, index) => new TimeZone(this.props.project).getLocalTime(w.timestamp));
        const data = this.props.weatherHistory.map((w, index) => {
            switch (this.state.tab) {
                case 0:
                    return w.temperature;
                case 1:
                    return w.humidity;
                case 2:
                    return w.rain;
                case 3:
                    return w.windSpeed
            }
        });

        const datasets = [{
            type: "line",
            borderColor: '#e36510',
            pointRadius: 2,
            borderWidth: 5,
            label: getDataLabel(),
            backgroundColor: '#e36510',
            data,
            yAxisID: 'y-axis-1'
        }];

        if (this.state.tab === 3) {
            datasets.push({
                type: "bar",
                barPercentage: 0.4,
                label: 'Wind direction',
                backgroundColor: '#bbbfc0',
                hoverBackgroundColor: '#555e62',
                data: this.props.weatherHistory.map(w => w.windDirection),
                yAxisID: 'y-axis-2'
            });
        }

        return {
            labels,
            datasets
        };
    }

    getOptions = () => {

        const options = {
            maintainAspectRatio: false,
            legend: {
                display: false,
                position: 'top',
            },
            responsive: true,
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                x: {
                    display: true,
                    gridLines: {
                        display: true
                    },
                    // labels: {
                    //     show: true
                    // }
                },

                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: true
                    },
                    labels: {
                        show: true
                    }
                }

            }
        };

        if (this.state.tab === 3) {
            options.scales.y = {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    display: true
                },
                labels: {
                    show: true
                }
            }
        }
        return options;
    }

    selectTab = (tab) => {
        this.setState({ tab });
    }

    getHumidityItemClass = () => {
        let classes = ['item'];

        if (this.props.currentWeather.humidity > this.props.project.humidityCleaningThreshold)
            classes.push('over-threshold');

        if (this.state.tab === 1)
            classes.push('selected');

        return classes.join(" ");
    }

    getRainItemClass = () => {
        let classes = ['item'];

        if (this.props.currentWeather.rain > this.props.project.rainCleaningThreshold)
            classes.push('over-threshold');

        if (this.state.tab === 2)
            classes.push('selected');

        return classes.join(" ");
    }

    getWindItemClass = () => {
        let classes = ['item'];

        if (this.props.currentWeather.windSpeed > this.props.project.windCleaningThreshold)
            classes.push('over-threshold');

        if (this.state.tab === 3)
            classes.push('selected');

        return classes.join(" ");
    }


    render() {
        return (
            <div className="page">
                {this.props.isLoading ? <Loader /> : null}
                <div className="menu-wrapper">
                    <Menu />
                </div>
                <div className="content">
                    <PlantHeader title={"Weather"}
                        secondaryText={this.props.project.projectName} />

                    <div className="weather-tab">
                        {this.props.currentWeather ? <div className="header">
                            <div className={this.state.tab === 0 ? 'item selected' : 'item'} onClick={() => this.selectTab(0)}>
                                <img src={weatherTempIcon} />
                                <div className="texts">
                                    <div className="text">Temperature</div>
                                    {this.props.currentWeather ? <div className="value">{this.props.currentWeather.temperature}°C</div> : null}
                                </div>
                                <div className="threshold">OVER THRESHOLD</div>

                            </div>

                            <div className={this.getHumidityItemClass()} onClick={() => this.selectTab(1)}>
                                <img src={weatherHumidityIcon} />
                                <div className="texts">
                                    <div className="text">Humidity</div>
                                    <div className="value">{this.props.currentWeather.humidity}%</div>
                                </div>
                                <div className="threshold">OVER THRESHOLD</div>
                            </div>

                            <div className={this.getRainItemClass()} onClick={() => this.selectTab(2)}>
                                <img src={weatherRainIcon} />
                                <div className="texts">
                                    <div className="text">Rain today</div>
                                    <div className="value">{this.props.currentWeather.rain}mm</div>
                                </div>
                                <div className="threshold">OVER THRESHOLD</div>
                            </div>

                            <div className={this.getWindItemClass()} onClick={() => this.selectTab(3)}>

                                <img src={weatherWindIcon} />
                                <div className="texts">
                                    <div className="text">Wind</div>
                                    <div className="value">{this.props.currentWeather.windSpeed} Km/h, {this.getWindDirection(this.props.currentWeather.windDirection)}</div>
                                </div>
                                <div className="threshold">OVER THRESHOLD</div>
                            </div>
                        </div> : null}
                    </div>

                    <div className="weather-history">
                        <div className="title-wrapper">

                            <div className="title">{this.getGraphTitle()}</div>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.dateChanged}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                selectsRange
                                // onSelect={this.onDateSelected}
                                shouldCloseOnSelect={false}
                                customInput={<DateCustomInput logsStartDate={this.state.startDate} logsEndDate={this.state.endDate} />}
                            />
                        </div>
                        <div className="wrapper">
                            <Bar
                                data={this.getData()}
                                options={this.getOptions()}
                                // plugins={plugins}
                                height={400}
                            // width={this.state.graphWidth}
                            />
                        </div>



                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //

    return {
        ...state.weatherReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ getCurrentWeather, getWeatherHistory }, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(WeatherComponent));