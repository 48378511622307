import {CREATE_ERROR_SUCCESS, EDIT_ERROR_SUCCESS, EDIT_MULTIPLE_ERRORS_SUCCESS} from "./editErrorReducer";

export const GET_ERRORS_STARTED = 'GET_ERRORS_STARTED';
export const GET_ERRORS_ERROR = 'GET_ERRORS_ERROR';
export const GET_ERRORS_SUCCESS = 'GET_ERRORS_SUCCESS';
export const GET_ERRORS_CSV_ERROR = 'GET_ERRORS_CSV_ERROR';
export const GET_ERRORS_CSV_SUCCESS = 'GET_ERRORS_CSV_SUCCESS';
export const DELETE_ERROR_ERROR = 'DELETE_ERROR_ERROR';
export const DELETE_ERROR_SUCCESS = 'DELETE_ERROR_SUCCESS';
export const ALERT_SHOWN = 'ALERT_SHOWN';

const INITIAL_STATE = {
    errors: [],
    isLoading: false,
    errorCodes: []
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    const error = action.payload?action.payload.error:null;
    const {errors} = state;
    // debugger;
    switch (action.type) {
        case GET_ERRORS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_ERRORS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };

        case GET_ERRORS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case EDIT_MULTIPLE_ERRORS_SUCCESS:
            for (const error of action.payload.errors) {
                const item = errors.find(e => e._id ===error._id);
                if (item) {
                    const index = errors.indexOf(item);
                    errors[index] = {...error, repeaterId: errors[index].repeaterId, robotId: errors[index].robotId};
                } else {
                    errors.splice(0, 0, error);
                }
            }
            return {
                ...state,
                errors: [...errors]
            }

            break;
        case EDIT_ERROR_SUCCESS:

            const item = errors.find(e=>e._id ===error._id);
            if (item) {
                const index = errors.indexOf(item);
                errors[index] = {...error, repeaterId: errors[index].repeaterId, robotId: errors[index].robotId};                
            }
            else {
                errors.splice(0, 0, error);
            }
            return {
                ...state,
                errors: [...errors]
            }
        case CREATE_ERROR_SUCCESS:

            return {
                ...state,
                errors: [...action.payload.errors,...errors]
            }
        case GET_ERRORS_CSV_SUCCESS:
            
            return {
                ...state,
                blob: action.payload.blob,
                isLoading: false
            }
        case DELETE_ERROR_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case DELETE_ERROR_SUCCESS:
            for (const errorId of action.payload.errorIds) {
                errors.splice(errors.findIndex(e => e._id ===errorId), 1);
            }
            return {
                ...state,
                errors: [...errors]
            }
        case GET_ERRORS_CSV_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}