import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import ApiHandler from "../api";

const apiHandler = new ApiHandler();

const RobotSettings = ({vehicle, project, user, onSettingsChanged}) => {
    const [editRobotSettings, setEditRobotSettings] = useState(false);

    const saveRobotSettings = async () => {
        try {
            await apiHandler.updateVehicleSettings(vehicle._id, project._id, vehicle.settings);
            setEditRobotSettings(false);
        } catch (err) {
            alert("Can't update vehicle settings");
        }
    }


    return (<table>
        <tbody>
        <tr>
            <td colSpan="2">
                <div className="title">
                    <span className="text">ROBOT SETTINGS</span>
                    {user.role !=="projectOwner" ?
                        <span className="separator">|</span> : null}
                    {user.role !=="projectOwner" ? <span>
                                        {editRobotSettings ?
                                            <span className="custom"
                                                  onClick={saveRobotSettings}>SAVE</span>
                                            :
                                            <span className="custom"
                                                  onClick={() => setEditRobotSettings(true)}>EDIT</span>
                                        }

                                    </span> : null}
                </div>
                <div className="separator"></div>
            </td>
        </tr>
        <tr>
            <td>Drive Speed [m/minute]</td>
            <td><input type="tel" value={vehicle.settings.driveSpeed || ''}
                       onChange={(e) => onSettingsChanged('driveSpeed', e.target.value)}
                       disabled={!editRobotSettings}/></td>
        </tr>
        <tr>
            <td>Forward Rotor Speed [RPM]</td>
            <td><input type="tel" value={vehicle.settings.forwardRotorSpeed || ''}
                       onChange={(e) => onSettingsChanged('forwardRotorSpeed', e.target.value)}
                       disabled={!editRobotSettings}/></td>
        </tr>
        <tr>
            <td>Backwards Rotor Speed [RPM]</td>
            <td><input type="tel" value={vehicle.settings.backwardRotorSpeed || ''}
                       onChange={(e) => onSettingsChanged('backwardRotorSpeed', e.target.value)}
                       disabled={!editRobotSettings}/></td>
        </tr>
        <tr>
            <td>Min Voltage [Volt]</td>
            <td><input type="tel" value={vehicle.settings.minRobotBattery || ''}
                       onChange={(e) => onSettingsChanged('minRobotBattery', e.target.value)}
                       disabled={!editRobotSettings}/></td>
        </tr>

        </tbody>
    </table>)
}

export default RobotSettings;