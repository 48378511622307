import Loader from "../../loader/loader";
import Menu from "../../menu/menu";
import PlantHeader from "../../plantHeader/plantHeader";
import React, {useEffect, useState} from "react";
import "./dashboard.scss";
import {
    LOCAL_STORAGE_PROJECT,
    LOCAL_STORAGE_USER_KEY,
} from "../../../consts";

import CleaningControlVehicleNew from "../cleaningControl/cleaningControlVehicleNew";
import ErrorTable from "../../dashboard/errors-table";
import ApiHandler from "../../api";

const apiHandler = new ApiHandler();

const VehicleDashboard = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();
    const [user, setUser] = useState();
    const [currentStatus, setCurrentStatus] = useState('parking');
    const [vehicleList, setVehicleList] = useState([]);
    const [numOfRobots, setNumOfRobots] = useState(0);


    const handleDashboardApi = async (project) => {
        try {
            const data = await apiHandler.getDashboard(project._id);
            const numOfRobots = Math.max(...data.map(vl => vl.robotList.length));
            setNumOfRobots(numOfRobots);

            for (const vehicle of data) {
                for (let i = vehicle.robotList.length; i < numOfRobots; i++) {
                    vehicle.robotList.push({});
                }
            }
            setVehicleList(data);
            /*

             */
        } catch (err) {
            alert("Can't get project data");
        }
    }


    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);

        handleDashboardApi(project)
    }, []);



    const showEditError = () => {

    }

    const getCellClass = (status)=>{
        if (status.includes('drive'))
            return 'cleaning';
        else if (status.includes('error'))
            return 'error';
        else if (status.includes('parking'))
            return 'parking';
    }

    return (
        <div className="page">
            {isLoading ? <Loader/> : null}
            <div className="menu-wrapper">
                <Menu/>
            </div>
            <div className="content">
                <PlantHeader project={project}/>

                <div className="vehicles">
                    <div className="left">
                        {project ? <div className="control-panel">
                            <div className="title">CONTROL PANEL</div>
                            <div className="status">
                                {/*{project.connectionStatus ==="offline" ?*/}
                                {/*    <span className="offline"><span className="circle"></span>offline</span>*/}
                                {/*    :*/}
                                {/*    <span className="online"><span className="circle"></span>online</span>*/}
                                {/*}*/}
                                <span className="online"><span className="circle"></span>online</span>
                            </div>

                            <CleaningControlVehicleNew
                                project={project}
                                user={user}
                                currentStatus={currentStatus}
                                offline={false}
                            />

                            <div className="separator"></div>
                        </div> : null}

                        <div className="vehicle-table">
                            <table className="vehicle-grid">
                                <thead>
                                <th className="vehicle-number">Vehicle</th>
                                <th className="vehicle-voltage">Current Status</th>
                                {numOfRobots >= 1 && <th className="robot">Robot 1</th>}
                                {numOfRobots >= 2 && <th className="robot">Robot 2</th>}
                                {numOfRobots >= 3 && <th className="robot">Robot 3</th>}
                                {numOfRobots >= 4 && <th className="robot">Robot 4</th>}
                                {numOfRobots >= 5 && <th className="robot">Robot 5</th>}
                                <th className="yesterday">Yesterday Activity</th>
                                </thead>
                                <tbody>

                                {vehicleList.map(item => <tr className="vehicle-item">
                                    <td className="full">{item.vehicle.vehicleNumber}</td>
                                    <td>
                                        <div className="top">
                                            {item.lastReport ?
                                                <div
                                                    className="vehicle-voltage">{item.lastReport.voltage}V</div> : null}
                                        </div>
                                        <div className="bottom">
                                            {item.lastReport ?
                                                <div className="vehicle-status">{item.lastReport.status}</div> : null}
                                        </div>
                                    </td>

                                    {item.robotList.map((robotItem, index) =>
                                        <td className={`robot${index + 1}`}>
                                            <div className="top">

                                                {robotItem.reports ? <div className="robot-voltage">
                                                    <div className="v1">
                                                        <div className="time">8:00 AM</div>
                                                        <div className="voltage">{robotItem.reports[0].voltage}V</div>
                                                    </div>
                                                    <div className="v2">
                                                        {robotItem.reports[1] ?<>
                                                            <div className="time">12:00 PM</div>
                                                            <div className="voltage">{robotItem.reports[1].voltage}V</div>
                                                        </>: null}
                                                    </div>
                                                </div> : <div className="no-data">No data</div>}
                                            </div>
                                            {robotItem.robot ? <div
                                                className={`robot${index + 1} bottom ${getCellClass(robotItem.robot.currentStatus)}`}>{robotItem.robot.currentStatus}</div> : null}
                                        </td>
                                    )}

                                    <td className='yesterday-activity'>
                                        <div className="top">
                                            <div className="tables">{item.yesterdayRows} tables cleaned</div>
                                        </div>
                                        <div className="bottom">
                                            <div className="hours">{item.yesterdayCleaningTime} hours worked</div>
                                        </div>
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>

                    </div>


                    {user ? <ErrorTable
                        project={project}
                        user={user}
                        showEditError={showEditError}/> : null}
                </div>
            </div>
        </div>
    )
}

export default VehicleDashboard;