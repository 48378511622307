import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getRobotDetailsSuccess} from "./getRobotDetailsSuccessAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getRobotDetails(robotId) {

    return function (dispatch) {
        let url = HOST_URL+`/api/robots/${robotId}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        // dispatch(getRobotLogsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getRobotDetailsSuccess(response.data.data));
            }
            // else {
            //     dispatch(getRobotLogsError(new Error(response.data.message)));
            // }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            // dispatch(getRobotLogsError(error));
        });
    };
}