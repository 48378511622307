import React, {Component, PropTypes} from 'react';

import sortIcon from "../robotSettings/sort.png";
import sortUpIcon from "../robotSettings/sort-up.png";
import sortDownIcon from "../robotSettings/sort-down.png";

export default class TableComponent extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {sortParam:''}
    // }
    getData = ()=>{
        throw new Error('Please implement the methods getData');
    }

    publishData = (data)=>{
        throw new Error('Please implement the methods publishData');
    }

    sort = (sortParam)=>{
        const decide = (a,b)=>{


            const [param1, param2] = sortParam.indexOf(".")? sortParam.split("."):[sortParam, null];

            if (!param2) {
                if (!isNaN(a[sortParam]) && !isNaN(b[sortParam])) {
                    a[sortParam] = Number(a[sortParam]);
                    b[sortParam] = Number(b[sortParam]);
                }
                if (a[sortParam] && !b[sortParam])
                    return 1;

                if (!a[sortParam] && b[sortParam])
                    return -1;

                if (!a[sortParam] && !b[sortParam])
                    return 0;

                if (a[sortParam] > b[sortParam])
                    return 1;

                if (b[sortParam] > a[sortParam])
                    return -1;

                return 0;
            }


            let r=10;
            if (a[param1] && !b[param1])
                r=1;

            else if (b[param1] && !a[param1])
                r = -1;

            else if (!b[param1] && !a[param1])
                r = 0;

            else {
                if (!isNaN(a[param1][param2]) && !isNaN(b[param1][param2])) {
                    a[param1][param2] = Number(a[param1][param2]);
                    b[param1][param2] = Number(b[param1][param2]);
                }
                if (a[param1][param2] > b[param1][param2])
                    r = 1;

                else if (b[param1][param2] > a[param1][param2])
                    r = -1;

                else if (b[param1][param2] ===a[param1][param2])
                    r = 0;
            }
            let valA = a[param1]?a[param1][param2]:null;
            let valB = b[param1]?b[param1][param2]:null;
            if (r > 0)
                console.log(`${valA} > ${valB}`)
            else if (r < 0)
                console.log(`${valB} > ${valA}`)
            else if (r ===0)
                console.log(`${valB} ===${valA}`)
            else {
                console.log(`${valB} ??? ${valA}`)
                console.log(a[param1])
                console.log(b[param1])
            }


            return r;
        }
        let sortDirection = 1;
        if (this.state.sortParam ===sortParam)
            sortDirection = -1 * this.state.sortDirection;

        const data = this.getData();

        data.sort((a,b)=>{
            if (sortDirection > 0)
                return decide(a,b);
            else
                return -decide(a,b);
        });

        console.log("sortParam",sortParam,"sortDirection",sortDirection);
        console.log(data.map(d=>d[sortParam]));
        this.publishData(data);
        this.setState({sortParam, sortDirection})
    }

    getSortIconClass = (sortParam)=>{
        if (this.state.sortParam !==sortParam)
            return 'gone';

        console.log("sortDirection",this.state.sortDirection)

        return this.state.sortDirection ==1 ? 'sort-up': 'sort-down'
    }

    getIcon = (sort)=>{
        if (sort ===0)
            return sortIcon;
        if (sort ===1)
            return sortUpIcon;
        if (sort ===-1)
            return sortDownIcon;
    }

    getHeaderTH = (paramName, text, sort, onSortByColumn, className)=>{
        return <th className={className}>{text}</th>;

        if (!onSortByColumn)
            return <th>{text}</th>

        return <th onClick={()=>onSortByColumn(paramName)}>{text}<img className="sort-icon" src={this.getIcon(sort)} /></th>
    }
}


