import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './editError.scss';
import Modal from "../modal/modal";
import Attachments from "../attachments/attachments";
import { editError } from "./actions/editErrorAction";
import deleteIcon from "../editUser/delete-icon.svg";
import DatePicker from "react-datepicker";
import { createError } from "./actions/createErrorAction";
import { deleteError } from "../errors/actions/deleteErrorAction";
import deleteUserIcon from "../editUser/delete-user-icon.svg";
import { getRobots } from "./actions/getRobotsAction";
import Attachment from "../attachment/attachment";
import { TimeZone } from "../utils";
import { editMultipleErrors } from "./actions/editMultipleErrorsAction";
import { viewError } from './actions/viewErrorAction';


const PCB_LAST_ACTIVITY = [
    'Charging',
    'Cleaning',
    'Transiting',
    'Stuck on obstacle',
    'Try to run back'
];

const PCB_FINDINGS = [
    'Charging LED not active',
    'On/off LED not active',
    'No charging',
    'Burned chip',
    'Other'
]

const ROBOT_LOCATIONS = [
    'Middle of the table',
    'Docking station',
    'End of table'
]
const SEVERITY = [
    'grey',
    'yellow',
    'red'
]

const DESCRIPTIONS = [
    "Stuck –Battery low",
    "Stuck – Table issue",
    "Stuck - Bridge issue",
    "Charging – Charging strip issue",
    "Charging – Battery issue",
    "Charging – Switch issue",
    "Charging – PCB’s issue",
    "Charging – Other",
    "Communication – Antenna issue",
    "Communication Setting issue",
    "Communication PCB’s issue",
    "Communication Other",
    "Customer – Obstacle / misalignment",
    "Customer – Grass issue",
    "Customer – Vandalism",
    "Customer – Other",
    "Mechanical – Wheel screw loose",
    "Mechanical – Axel issue",
    "Mechanical – Bearing noise",
    "Mechanical – Magnet issue",
    "Mechanical - H wheel issue",
    "Mechanical - chain issue",
    "Mechanical - docking issue",
    "Mechanical - wing issue",
    "Mechanical - actuator",
    "Mechanical - other",
    "General - No Issue found",
    "Other"
];


const REPLACEMENTS = [
    "Battery",
    "Rotor motor holder",
    "Drive motor holder",
    "Rotor motor",
    "Drive motor",
    "Led light",
    "On / off switch",
    "Magnet sensor",
    "Actuator",
    "Actuator cable",
    "Japan bearing",
    "Mid rotor bering",
    "End wheels bearings",
    "Vertical Wheel",
    "Horizontal wheel",
    "Charging hook robot",
    "Charging hooks docking",
    "Solar panel",
    "PCB",
    "Antenna",
    "Chain",
    "Cog-wheel",
    "Coupling",
    "Rotor’s Pin",
    "Towels",
    "Bridges",
    "Magnet",
    "Docking",
    "System Reset",
    "Communication problem",
]

class EditErrorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pcb: {},
            ...this.props.errors[0],
            replacements: this.props.errors[0].replacements && this.props.errors[0].replacements.length ? this.props.errors[0].replacements.split(",") : [],
            date: new Date(),
            projectRobotError: 'robot',
            robots: '',
            files: [],
            index: 0,
        };

        console.log("this.props.errors[0]", this.props.errors[0])

        this.isNewError = this.props.errors[0]._id === undefined && this.props.errors.length === 1;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errorSaved) {
            
            if (!this.props.errorSaved) {                
                this.props.actions.viewError();
                if (this.props.onErrorSaved)
                    this.props.onErrorSaved();
                else
                    this.props.closeEditError();

                this.setState({ files: [] });
                return;
            }
        }

        if (nextProps.errors && (!this.props.errors[0] || (this.props.errors[0] !== nextProps.errors[0]))) {
            alert(`Something went wrong`)
        }
    }

    componentDidMount() {
        this.props.actions.getRobots(this.props.project._id);
    }

    onChange = (value, param) => {
        let state = {};

        state[param] = value;

        this.setState(state);
    }

    onRobotChange = (value) => {
        // regex to remove spaces, non digits except for comma and dash
        value = value.replace(/[^0-9,-]/g, '');
        this.setState({ robots: value });
        // parse list of numbers from range of number seperated by comma or dash
        let list = value.split(/,|-/);
    }

    onSubmit = () => {

        let params = { ...this.state };
        params.replacements = params.replacements.join(",");
        params.projectId = this.props.project._id;


        const mandatory = {}

        if (['fixedRemotely', 'fixedOnSite', 'fixed'].includes(params.status)) {
            mandatory.mainDescription = 'Error Type';
            mandatory.remarks = 'Remarks';
        }

        if (this.isNewError) {
            params.timestamp = this.state.date.getTime();
            if (this.state.row)
                params.rowNumber = this.state.row;

            if (this.state.projectRobotError === 'robot') {
                if (!this.state.robots)
                    return alert(`At least one robot must be specified`);
            } else {
                delete params.robots;
            }

            console.log(params)

            for (let key in mandatory) {
                if (!params[key])
                    return alert(`Please fill in the ${mandatory[key]} field`);
            }

            this.props.actions.createError(params, this.state.files);
        } else if (this.props.errors.length === 1) {
            for (let key in mandatory) {
                if (!params[key])
                    return alert(`Please fill in the ${mandatory[key]} field`);
            }

            this.props.actions.editError(params, this.props.errors[0]._id, this.props.project._id, this.state.files);
        } else {
            for (let key in mandatory) {
                if (!params[key])
                    return alert(`Please fill in the ${mandatory[key]} field`);
            }
            params.projectId = this.props.project._id;
            params.errorIds = this.props.errors.map(e => e._id);
            this.props.actions.editMultipleErrors(params);
        }
    }


    onAddReplacement = (item) => {
        const { replacements } = this.state;
        if (replacements.includes(item.value))
            return;

        replacements.push(item.value);
        this.setState({ replacements });
    }

    removeReplacement = (replacement) => {
        const { replacements } = this.state;
        let i = replacements.find(r => r === replacement);
        replacements.splice(i, 1);
        this.setState({ replacements });
    }

    getTitle = () => {
        if (this.props.errors.length > 1)
            return `Edit ${this.props.errors.length} Errors`;

        if (this.isNewError)
            return 'New Error';

        if (this.state.vehicleId) {
            let str = `Vehicle ${this.state.vehicleId.vehicleNumber}`;
            if (this.state.robot)
                str += ` | Robot ${this.state.robot.name}`;
            return str;
        }

        if (this.state.robot) {
            
            let str = `Robot ${this.state.robot.name}:`;
            if (this.state.locationOnRow)
                str += ` (${Math.round(this.state.locationOnRow / 1000)}m)`;

            str += ` ${this.state.errorCode}`

            return str;
        }
        else {
            return 'Project error';
        }
    }

    showDeleteError = () => {
        return (this.props.user.role === "admin" || this.props.user.role === "countryAdmin") &&
            this.props.deleteError && this.props.errors.length
    }

    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeEditError}>
                {this.state.selectedAttachment?<Attachment closeAttachment={this.closeAttachment} src={this.state.selectedAttachment.path} />:null}
                {!this.isNewError ? <div className="input-line">
                    <div className="input-description">Status</div>
                    {this.state.status === 'fixedBySystem' ?
                        <input value={'Fixed by Sytem'} disabled placeholder="" type="string" />
                        :
                        <Dropdown
                            options={[{ label: "Pending", value: 'pending' }, { label: "Fixed Remotely", value: 'fixedRemotely' }, { label: "Fixed On Site", value: 'fixedOnSite' }]}
                            onChange={item => this.setState({ status: item.value })}
                            value={this.state.status}
                            className='dropdown'
                            controlClassName='control'
                        />
                    }
                </div> : null}

                {this.isNewError ? <div className="new-error-lines">
                    <div className="input-line radio">
                        <span className="radio-item">
                            <input checked={this.state.projectRobotError === 'robot'} value="robot" type="radio"
                                name="projectRobotError"
                                onChange={(e) => this.onChange('robot', 'projectRobotError')} />
                            <label>Robot Error</label>
                        </span>

                        <span className="radio-item">
                            <input checked={this.state.projectRobotError === 'project'} value="project" type="radio"
                                name="projectRobotError"
                                onChange={(e) => this.onChange('project', 'projectRobotError')} />
                            <label>Project Error</label>
                        </span>
                    </div>
                    <div className="input-line">
                        <div className="input-description">Date</div>
                        <div className="date-wrapper">
                            <DatePicker
                                selected={this.state.date}
                                onChange={date => this.setState({ date })}
                                startDate={this.state.date}
                                // dateFormat="Pp"
                                maxDate={new Date()}
                                shouldCloseOnSelect={true}
                                customInput={
                                    <span>{new TimeZone(this.props.project).getLocalTime(this.state.date, true)}</span>}
                            // customInput={<this.VoltageDateCustomInput/>}
                            />
                        </div>
                    </div>

                    <div className="input-line">
                        <div className="input-description">Robot Numbers (one or more)</div>
                        <input value={this.state.robots} placeholder="" type="text"
                            onChange={(e) => this.onRobotChange(e.target.value)} />
                    </div>
                    <div className="input-line">
                        <div className="input-description">Status</div>

                        <Dropdown
                            options={[{ label: "Pending", value: 'pending' }, { label: "Fixed Remotely", value: 'fixedRemotely' }, { label: "Fixed On Site", value: 'fixedOnSite' }]}
                            onChange={item => this.setState({ status: item.value })}
                            value={this.state.status}
                            className='dropdown'
                            controlClassName='control'
                        />
                    </div>
                    <div className="input-line">
                        <div className="input-description">Severity</div>
                        <Dropdown
                            options={SEVERITY}
                            onChange={item => this.setState({ severity: item.value })}
                            placeholder="Select severity"
                            value={this.state.severity}
                            className='dropdown'
                            controlClassName='control'
                        />
                    </div>

                </div> : null}

                {/* <div className="input-line">
                    <div className="input-description">System Description</div>
                    <input value={this.state.description} placeholder="" type="text"
                           onChange={(e) => this.onChange(e.target.value, 'description')}/>
                </div> */}

                <div className="input-line">
                    <div className="input-description">Error Type</div>
                    <Dropdown
                        options={DESCRIPTIONS}
                        onChange={(item) => this.setState({ mainDescription: item.value })}
                        value={this.state.mainDescription}
                        className='dropdown'
                        controlClassName='control'
                    />
                </div>

                <div className="input-line">
                    <div className="input-description">Replacements</div>
                    <Dropdown
                        options={REPLACEMENTS}
                        onChange={this.onAddReplacement}
                        placeholder="Select replaced item"
                        className='dropdown'
                        controlClassName='control'
                    />
                    <div className="projects">
                        {this.state.replacements.map(replacement =>
                            <li key={replacement} className="project">
                                <span className="text">{replacement}</span>
                                <span className="remove" onClick={() => this.removeReplacement(replacement)}><img
                                    src={deleteIcon} /></span>
                            </li>)}
                    </div>
                </div>
                {this.state.replacements.includes('Battery') &&
                    <div>
                        <div className="input-line">
                            <div className="input-description">New Battery Level (%)</div>
                            <input value={this.state.batteryLevelAfterReplacement} placeholder="" type="number"
                                onChange={(e) => this.setState({ batteryLevelAfterReplacement: e.target.value })}
                            />
                        </div>
                    </div>
                }
                {/*PCB questions*/}
                {this.state.replacements.includes('PCB') ?
                    <div>
                        <div className="input-line">
                            <div className="input-description">Robot Location</div>
                            <Dropdown
                                options={ROBOT_LOCATIONS}
                                onChange={(item) => this.setState({ pcb: { ...this.state.pcb, location: item.value } })}
                                value={this.state.pcb.location}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>
                        <div className="input-line">
                            <div className="input-description">Findings on the PCB</div>
                            <Dropdown
                                options={PCB_FINDINGS}
                                onChange={(item) => this.setState({ pcb: { ...this.state.pcb, findings: item.value } })}
                                value={this.state.pcb.findings}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>

                        <div className="input-line">
                            <div className="input-description">Last Activity before failure</div>
                            <Dropdown
                                options={PCB_LAST_ACTIVITY}
                                onChange={(item) => this.setState({ pcb: { ...this.state.pcb, lastActivity: item.value } })}
                                value={this.state.pcb.lastActivity}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>

                        <div className="input-line">
                            <div className="input-description">Battery voltage before disconnecting PCB (2 decimal points)</div>
                            <input value={this.state.pcb.batteryVoltageBefore} placeholder="" type="number"
                                onChange={(e) => this.setState({ pcb: { ...this.state.pcb, batteryVoltageBefore: e.target.value } })}
                            />
                        </div>

                        <div className="input-line">
                            <div className="input-description">Battery voltage after disconnecting PCB (2 decimal points)</div>
                            <input value={this.state.pcb.batteryVoltageAfter} placeholder="" type="number"
                                onChange={(e) => this.setState({ pcb: { ...this.state.pcb, batteryVoltageAfter: e.target.value } })}
                            />
                        </div>

                        <div className="input-line">
                            <div className="input-description">Physical condition of the card (does the card or the box contain dew/ water/ rust /moisture /  burning units)</div>
                            <input value={this.state.pcb.physicalCondition} placeholder="" type="string"
                                onChange={(e) => this.setState({ pcb: { ...this.state.pcb, physicalCondition: e.target.value } })}
                            />
                        </div>
                        <div className="input-line">
                            <div className="input-description">Was the robot pushed manually previously?</div>
                            <Dropdown
                                options={['Yes', 'No']}
                                onChange={(item) => this.setState({ pcb: { ...this.state.pcb, pushedManually: item.value } })}
                                value={this.state.pcb.pushedManually}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>


                    </div> : null}

                <div className="input-line">
                    <div className="input-description">Remarks</div>
                    <input value={this.state.remarks} placeholder="" type="text"
                        onChange={(e) => this.onChange(e.target.value, 'remarks')} />
                </div>


                {this.props.errors.length === 1 && !this.isNewError?

                    <div className="input-line">
                        <Attachments        
                            type="error"
                            projectId={this.props.project._id} errorId={this.props.errors[0]._id}                            
                            attachments={this.props.errors[0].attachments} numOfAttachments={3}
                        />
                    </div>
                    : null}

                {this.isNewError ?
                    <div className="buttons-wrapper">
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                    :
                    <div className="buttons-wrapper">
                        {this.showDeleteError() ?
                            <div className="delete-row" onClick={this.props.deleteError}>
                                <img src={deleteUserIcon} />
                                <span>Delete error{this.props.errors.length > 1 ? 's' : ''}</span>
                            </div> : null}
                        <div className="button cancel" onClick={this.props.closeEditError}>CANCEL</div>
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                }
            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.editErrorReducer,
        project: state.indexReducer.project,
        user: state.indexReducer.user,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            editError,
            createError,            
            getRobots,
            deleteError,
            editMultipleErrors,
            viewError
        }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditErrorComponent);