import {GET_PROJECT_SETTINGS_SUCCESS} from "../../../reducers/dashboardReducer";
import {LOCAL_STORAGE_PROJECT} from "../../../consts";


export const getProjectDetailsSuccess = (project) =>{
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
    return {
        type: GET_PROJECT_SETTINGS_SUCCESS,
        payload: {project}
    }
}