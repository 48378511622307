import React, { useState } from "react";
import { RefreshCw } from "lucide-react";
import { Tooltip } from "react-tooltip";
import "./spinningRefresh.scss";

const SpinningRefresh = ({ isSpinning, onClick, tooltipText, color }) => {
  const iconStyles = {
    color: color ? color : "#4b5563",
  };

  return (
    <>
      <button
        onClick={() => (!isSpinning ? onClick() : null)}
        className="refresh-button"
        aria-label={
          isSpinning ? "Stop refresh animation" : "Start refresh animation"
        }
        data-tooltip-id="my-tooltip"
        data-tooltip-content={tooltipText}
      >
        <RefreshCw
          className={`refresh-icon ${isSpinning ? "spinning" : ""}`}
          style={iconStyles}
          strokeWidth={3}
        />
      </button>
      <Tooltip id="my-tooltip" />
    </>
  );

  // return (

  //   <div className="spinning-refresh">

  //     <button
  //       onClick={() => (!isSpinning ? onClick() : null)}
  //       aria-label="Refresh"
  //       data-tooltip-id="my-tooltip"
  //       data-tooltip-content={tooltipText}
  //     >
  //       <RefreshCw style={iconStyles} className="refresh" />
  //     </button>
  //     <Tooltip id="my-tooltip" />
  //   </div>
  // );
};

export default SpinningRefresh;
