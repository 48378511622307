import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getVehicleLogsStarted} from "./getVehicleLogsStartedAction";
import {getVehicleLogsSuccess} from "./getVehicleLogsSuccessAction";
import {getVehicleLogsError} from "./getVehicleLogsErrorAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function getVehicleLogs(vehicleId, startTime, endTime) {

    return function (dispatch) {
        let url = HOST_URL+`/api/vehicles/${vehicleId}/logs?`;
        if (startTime)
            url += '&from='+startTime;

        if (endTime)
            url += '&to='+endTime;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getVehicleLogsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getVehicleLogsSuccess(response.data.vehicleLogs));
            }
            else {
                dispatch(getVehicleLogsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getVehicleLogsError(error));
        });
    };
}