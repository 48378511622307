import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getRobotSettingsSuccess} from "./getRobotSettingsSuccessAction";
import {getRobotSettingsError} from "./getRobotSettingsErrorAction";
import {getRobotSettingsStarted} from "./getRobotSettingsStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function getRobotSettings(projectId, search) {

    return function (dispatch) {
        let url = HOST_URL+'/api/robots/more/settings?projectId=' + projectId;
        if (search)
            url += '&search='+search;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotSettingsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getRobotSettingsSuccess(response.data.data));
            }
            else {
                dispatch(getRobotSettingsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotSettingsError(error));
        });
    };
}