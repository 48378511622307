import {GET_ROBOT_DETAILS_SUCCESS} from "../../../reducers/robotReducer";
import {LOCAL_STORAGE_ROBOT} from "../../../consts";


export const getRobotDetailsSuccess = (robot) =>{
    localStorage.setItem(LOCAL_STORAGE_ROBOT, JSON.stringify(robot));
    return {
        type: GET_ROBOT_DETAILS_SUCCESS,
        payload: {robot}
    }
}