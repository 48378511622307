import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { getFormattedDate } from "../../consts";

const BatteryReportsComponent = ({ project, date }) => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const apiHandler = new ApiHandler();
    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            try {
                const reports = await apiHandler.getRobotDailyReports({ projectId: project._id, date, reportType: 'battery' });
                
                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        getReports();

    }, [project, date]);

    return <>
        <table className="battery">
            <tbody>
                <tr>
                    <th className="robot-number">Robot</th>
                    <th>Avg. charge in 7 days</th>
                    <th>Num. of optimize in 7 days</th>
                    <th>Lowest pre-cleaning in 14 days</th>
                    <th>Optimize with battery > 30%</th>
                </tr>

                
                {reports.map((report) => {
                    return <tr key={report.robotId.robotNumber}>
                        <td className="robot-number">{report.robotId.robotNumber}</td>
                        <td>{report.battery.avgChargePercentage}</td>
                        <td>{report.battery.optimizeErrors.length}</td>
                        <td>{report.battery.lowestChargePercentage}</td>
                        <td>{report.battery.optimizeErrors.map(optimizeError=><div>
                            {optimizeError.batteryPercentage > 30 && <div>{getFormattedDate({date:optimizeError.date})} ({optimizeError.errorCode})</div>}
                        </div>)}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </>
}

export default BatteryReportsComponent;