import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editUserSuccess} from "./editUserSuccessAction";
import {editUserError} from "./editUserErrorAction";
import {editUserStarted} from "./editUserStartedAction";

export function editUser(params, user) {

    return function (dispatch) {
        let url = HOST_URL+'/api/users/' + user.id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editUserStarted());

        return axios.patch(url,params, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                response.data.user.isSaved = true;
                dispatch(editUserSuccess(user));
            }
            else {
                dispatch(editUserError(new Error(response.data.message)));

            }
        }).catch(error => {

            console.log(error.message);
            dispatch(editUserError(error));
        });
    };
}