import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import 'react-dropdown/style.css';
import './editError.scss';
import Modal from "../modal/modal";
import {editAvailability} from "./editAvailabilityAction";
import {viewAvailability} from "./viewAvailabilityAction";

class EditAvailabilityComponent extends Component {
    constructor(props) {
        super(props);        
        let maxInactiveForReason = 0;
        if (this.props.availability.totalNotCleanedRobots > 0)
            maxInactiveForReason += this.props.availability.totalNotCleanedRobots;

        if (this.props.availability.totalRobotsNotFinishedCleaning > 0)
            maxInactiveForReason += this.props.availability.totalRobotsNotFinishedCleaning;
        
        this.state = {
            ...this.props.availability,
            maxInactiveForReason,
        };
        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.availabilitySaved) {
            if (!this.props.availabilitySaved) {
                this.props.actions.viewAvailability();
                this.props.closeEditAvailability();
                return;
            }
        }
    }


    onChange = (event, param) => {
        const { value } = event.target;
        let state = {};

        if (event.target.type === 'text')        
            state[param] = value;
        else {
            if (value.length)
                state[param] = Math.floor(value);
            else 
                state[param] = undefined;
        }

        this.setState(state);
    }

    onSubmit = () => {
        if (this.state.totalInactiveForReason > this.state.maxInactiveForReason) {
            alert("Number of robots inactive for a reason cannot be greater than "+this.state.maxInactiveForReason);
            return;
        }

        if (this.state.totalInactiveForReason < 0) {
            alert("Number of robots inactive for a reason cannot be negative");
            return;
        }        
        
        if (this.state.totalInactiveForReason > this.state.maxInactiveForReason) {
            alert("Total number of inactive robots cannot be greater than "+this.state.maxInactiveForReason);
            return;
        }
        
        let params = {...this.state, robotLists:undefined};
        params.projectId = this.props.project._id;

        this.props.actions.editAvailability(params, this.props.availability._id);
    }


    render() {
        return (
            <Modal title={`Robot Availability: ${this.props.availability.date}`}
                   onClose={this.props.closeEditAvailability}>

                <div className="input-line">
                    <div className="input-description">Number of robots inactive for a reason</div>
                    <input value={this.state.totalInactiveForReason} placeholder="" type="number"
                           max={this.state.totalNotCleanedRobots}
                           onChange={(e) => this.onChange(e, 'totalInactiveForReason')}/>
                </div>                

                <div className="input-line">
                    <div className="input-description">Reason for inactive robots</div>
                    <input value={this.state.inactiveReason} placeholder="" type="text"
                           onChange={(e) => this.onChange(e, 'inactiveReason')}/>
                </div>


                <div className="buttons-wrapper">

                    <div className="button cancel" onClick={this.props.closeEditAvailability}>CANCEL</div>
                    <div className="button" onClick={this.onSubmit}>SAVE</div>
                </div>


            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    console.log("state.availabilityReducer",state.availabilityReducer)
    return {
        ...state.editAvailabilityReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            editAvailability,
            viewAvailability
        }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditAvailabilityComponent);