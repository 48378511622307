import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getProjectsStarted} from "./getProjectsStartedAction";
import {getProjectsSuccess} from "./getProjectsSuccessAction";
import {getProjectsError} from "./getProjectsErrorAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getProjects(search) {

    return function (dispatch) {
        let url = HOST_URL+'/api/projects/';
        if (search)
            url += "?search="+search;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getProjectsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getProjectsSuccess(response.data.projectGroups, response.data.searchResults));
            }
            else {
                dispatch(getProjectsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getProjectsError(error));
        });
    };
}