import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './robotsAndVehicles.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Dropdown from 'react-dropdown';
import DatePicker from "react-datepicker";
import robotsIcon from "../menu/robots-icon-selected.svg";
import 'react-dropdown/style.css';

import { getRobotsAndVehicles } from "./actions/getRobotsAndVehiclesAction";
import { deleteRobotVehicle } from "./actions/deleteAction";
import { dryRobot } from "./actions/dryAction";
import { getRobotsCsv } from "./actions/getRobotsCsv";
import { robotSelected } from "./actions/robotSelectedAction";
import { vehicleSelected } from "./actions/vehicleSelectedAction";
import Loader from "../loader/loader";
import { setRobotSettings } from "../robot/actions/setRobotSettingsAction";
import TableComponent from "../tableComponent/tableComponent";
import { updateItems } from "./actions/updateItemsAction";
import { LOCAL_STORAGE_LIVE_REPORT } from "../../consts";
import { getLiveReports } from "../dashboard/actions/getLiveReports";
import downloadIcon from "../availability/download-icon.svg";
import Modal from "../modal/modal";
import { TimeZone } from "../utils";
import SearchComponent from "../search/search";
import { withNavigation } from '../withNavigate';

const CHARGING_MARK_LIMIT = 16.4;
const CHARGING_CURRENT_LIMIT = 0.1;
const CHARGING_UPPER_PERCENTAGE_LIMIT = 95;
const CHARGING_LOWER_PERCENTAGE_LIMIT = 90;


class RobotsAndVehiclesComponent extends TableComponent {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }


        this.state = {
            sortParam: 'robotName',
            sortDirection: 1,
            date: new Date(),
            page: 0,
            filterTab: 0,
            groupFilter: '0',
            modeFilter: '',
            statusFilter: '',
            applyToGroup: false,
            items: []
        };

        this.timeZone = new TimeZone(this.props.project)
    }

    onFilterClicked(tab) {
        this.setState({ filterTab: tab, page: 0 });
        this.props.actions.getRobotsAndVehicles({
            projectId: this.props.project._id,
            timeZone: this.props.project.timezone.timeZoneId,
            date: this.state.date,
            page: 0,
            filter: tab,
            statusFilter: this.state.statusFilter,
            groupFilter: this.state.groupFilter,
            sortParam: this.state.sortParam,
            sortDirection: this.state.sortDirection,
        });
    }


    onTableSort = (param) => {
        console.log("this.state.sortParam", this.state.sortParam)
        if (param !== this.state.sortParam)
            this.setState({ sortDirection: 1, sortParam: param });
        else
            this.setState({ sortDirection: -1 * this.state.sortDirection });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.csv && (!this.props.csv || (this.props.csv !== nextProps.csv))) {
            this.saveCsvFile(nextProps.csv);
        }

        if (nextProps.items && nextProps.items !== this.props.items)
            this.setState({ items: JSON.parse(JSON.stringify(nextProps.items)) });
    }


    getLiveItem = (item) => {
        return this.props.liveReports.find(i => i.robot.name === item.robotName);
    };


    getLiverReports = () => {
        const projectId = this.props.project._id;

        const liveReports = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_REPORT) || '{}');
        if (liveReports[projectId]) {
            const date = new Date(liveReports[projectId].time);
            if (Date.now() - date.getTime() < 60 * 60 * 3600) { // in the last hour
                return { processId: liveReports[projectId].processId, date };
            }
        }
    }

    saveCsvFile = (text) => {
        const blob = new Blob([text], { type: 'text/csv' });
        const csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `robots ${this.props.project.projectName}_${'a'} ${this.timeZone.getLocalTime(this.state.date, true)}.csv`);
        tempLink.click();
    }

    dateChanged = date => {
        console.log("date", date)
        this.setState({ date, page: 0 });
        const projectId = this.props.project._id;
        this.props.actions.getRobotsAndVehicles({
            projectId,
            timeZone: this.props.project.timezone.timeZoneId,
            date,
            page: 0,
            filter: this.state.filterTab,
            statusFilter: this.state.statusFilter,
            groupFilter: this.state.groupFilter
        });
    }

    componentDidMount() {
        const projectId = this.props.project._id;
        this.props.actions.getRobotsAndVehicles({
            projectId,
            timeZone: this.props.project.timezone.timeZoneId,
            page: this.state.page,
            date: this.state.date,
            filter: this.state.filterTab
        });

        const liverReports = this.getLiverReports();
        if (liverReports) {

            this.handleLiveReports(liverReports.processId);
        }

        window.addEventListener("scroll", (e) => this.handleNavigation(e));
    }

    handleLiveReports = (processId) => {
        console.log("getLiveReports")
        const t0 = Date.now();
        const oneMinute = 60 * 10000;

        this.interval = setInterval(() => {

            const delta = Date.now() - t0;
            let shouldClearInterval = delta > oneMinute
            if (shouldClearInterval) {
                clearInterval(this.interval);
                return;
            }

            this.props.actions.getLiveReports(this.props.project._id, processId);
        }, 3000);
    }

    handleNavigation() {
        if (!this.page)
            return;

        // console.log(this.page.offsetHeight);
        const projectId = this.props.project._id;
        const hasMoreToLoad = this.props.statistics && this.state.items.length < this.props.statistics.totalRobots;
        if (window.scrollY + 2000 > this.page.offsetHeight && hasMoreToLoad && !this.props.isLoading) {
            const { page } = this.state;
            console.log("getting next page", page + 1)
            this.setState({ page: page + 1 })
            this.props.actions.getRobotsAndVehicles({
                projectId,
                timeZone: this.props.project.timezone.timeZoneId,
                page: page + 1,
                date: this.state.date,
                filter: this.state.filterTab,
                groupFilter: this.state.groupFilter,
                statusFilter: this.state.statusFilter,
            });
        }
    }

    searchClicked = (search) => {
        // this.props.actions.getRows(this.props.project._id, search);
    }

    robotSelected = (robot) => {
        this.props.actions.robotSelected({ ...robot });
        setTimeout(() => {
            this.props.navigate('/robot');
        }, 1000)

    }

    vehicleSelected = (vehicle) => {
        this.props.actions.vehicleSelected(vehicle);
        this.props.navigate("/vehicle");
    }

    onGroupSelected = (c, item) => {

        item.group = c.value;
        if (item.isVehicle)
            return;

        this.props.actions.setRobotSettings(item.robotId, { groupNumber: c.value });
    }

    onModeSelected = (c, item) => {

        item.mode = c.value;
        if (item.isVehicle)
            return;

        this.props.actions.setRobotSettings(item.robotId, { settings: { operationMode: c.value } });
    }

    onGroupFilterSelected = (c) => {
        this.setState({ groupFilter: c.value })
        this.props.actions.getRobotsAndVehicles({
            projectId: this.props.project._id,
            timeZone: this.props.project.timezone.timeZoneId,
            date: this.state.date,
            page: 0,
            filter: this.state.filterTab,
            modeFilter: this.state.modeFilter,
            statusFilter: this.state.statusFilter,
            groupFilter: c.value,
        });
    }

    onSearchChange = (search) => {
        this.setState({
            search,
            filterTab: -1,
            groupFilter: '0',
            statusFilter: '',

        });

        this.props.actions.getRobotsAndVehicles({
            projectId: this.props.project._id,
            timeZone: this.props.project.timezone.timeZoneId,
            date: this.state.date,
            search,
            page: 0,
            filter: 0,
            statusFilter: '',
            groupFilter: '0',
        });
    }


    onModeFilterSelected = (c) => {
        this.setState({ modeFilter: c.value })
        this.props.actions.getRobotsAndVehicles({
            projectId: this.props.project._id,
            timeZone: this.props.project.timezone.timeZoneId,
            date: this.state.date,
            page: 0,
            filter: this.state.filterTab,
            statusFilter: this.state.statusFilter,
            modeFilter: c.value,
            groupFilter: this.state.groupFilter,
        });
    }

    onStatusFilterSelected = (c) => {
        this.setState({ statusFilter: c.value })
        this.props.actions.getRobotsAndVehicles({
            projectId: this.props.project._id,
            timeZone: this.props.project.timezone.timeZoneId,
            date: this.state.date,
            page: 0,
            filter: this.state.filterTab,
            statusFilter: c.value,
            modeFilter: this.state.statusFilter,
            groupFilter: this.state.groupFilter,
        });
    }


    getData = () => this.state.items;

    publishData = (data) => this.props.actions.updateItems(data);

    selected = (item) => {
        item.isVehicle ? this.vehicleSelected(item) : this.robotSelected(item);
    }

    deleteRobot = (item) => {
        if (item.robotName)
            this.setState({ deleteRobot: item });
        else
            this.setState({ deleteVehicle: item });
    }

    dryRobot = (item) => {
        this.setState({ dryRobot: item });
    }

    downloadCsvFile = () => {
        this.props.actions.getRobotsCsv(this.props.project._id, this.state.date);
    }


    isChargingClass = (item) => {
        if (!item) {
            return;
        }

        if (this.props.project.settings.robotVersion === 4) {
            if (item.avgChargingCurrent > CHARGING_CURRENT_LIMIT && item.batteryPercentage < CHARGING_UPPER_PERCENTAGE_LIMIT)
                return 'blue';
            else if (item.avgChargingCurrent < CHARGING_CURRENT_LIMIT && item.batteryPercentage < CHARGING_LOWER_PERCENTAGE_LIMIT)
                return 'red';

        }
        else {
            if (item.voltage > CHARGING_MARK_LIMIT)
                return;

            if (item.isCharging)
                return 'blue';
            else
                return 'red';
        }
    }

    isChargingText = (item) => {

        if (!item || item.voltage > CHARGING_MARK_LIMIT)
            return;

        if (item.isCharging)
            return <span className="small">Charging</span>
        else
            return <span className="small">Not charging</span>
    }

    closeModal = () => this.setState({ deleteRobot: null, deleteVehicle: null, dryRobot: null });

    deleteRobotConfirmed = () => {
        this.props.actions.deleteRobotVehicle({ robot: this.state.deleteRobot, vehicle: this.state.deleteVehicle });
        this.closeModal();
    }

    dryRobotConfirmed = () => {
        this.props.actions.dryRobot({ robotId: this.state.dryRobot.robotId, projectId: this.props.project._id });
        this.closeModal();
    }

    filterTabClass(tab) {
        return tab === this.state.filterTab ? 'tab-filter active' : 'tab-filter';
    }


    getSortValue(param) {
        if (param !== this.state.sortParam)
            return 0

        return this.state.sortDirection;
    }

    applyToGroup = (e) => {

        this.setState({ applyToGroup: e.target.checked });
    }

    render() {
        return (
            <div className="page" ref={(ref) => this.page = ref}>
                {this.props.isLoading && this.state.page === 0 ? <Loader /> : null}
                <div className="menu-wrapper">
                    <Menu />
                </div>
                {this.state.deleteRobot || this.state.deleteVehicle ? <Modal
                    title={`Delete ${this.state.deleteRobot ? 'robot ' + this.state.deleteRobot.robotName : 'vehicle ' + this.state.deleteVehicle.vehicleName}?`}
                    onClose={this.closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={this.closeModal}>Cancel</div>
                        <div className="button" onClick={this.deleteRobotConfirmed}>Continue</div>

                    </div>

                </Modal> : null}

                {this.state.dryRobot ? <Modal
                    title={`Set robot ${this.state.dryRobot.robotName} as dry?`}
                    onClose={this.closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={this.closeModal}>Cancel</div>
                        <div className="button" onClick={this.dryRobotConfirmed}>Continue</div>

                    </div>

                </Modal> : null}


                <div className="content">
                    <PlantHeader title="Robots"
                        secondaryText={this.props.project.projectName} />
                    <div className="robots-main">
                        <div className="top">
                            {/*<Search onSearch={this.searchClicked}*/}
                            {/*        placeholder={this.props.project.arrayType ==='fixed' ? "search by robot number" : "search by robot or vehicle number"}/>*/}

                            {this.props.user.role !== "projectOwner" && this.props.statistics ? <>

                                <div className="date-wrapper">
                                    <SearchComponent placeholder="Search" searchChanged={this.onSearchChange}
                                        onSearch={this.onSearchChange} />
                                    <div className={this.filterTabClass(0)} onClick={() => this.onFilterClicked(0)}>
                                        All ({this.props.statistics.totalRobots})
                                    </div>
                                    <div className={this.filterTabClass(1)} onClick={() => this.onFilterClicked(1)}>
                                        Started cleaning ({this.props.statistics.totalRobotsStartedCleaning})
                                    </div>
                                    <div className={this.filterTabClass(2)} onClick={() => this.onFilterClicked(2)}>
                                        Error ({this.props.statistics.totalRobotsErrored})
                                    </div>
                                    <div className={this.filterTabClass(3)} onClick={() => this.onFilterClicked(3)}>
                                        Didn't finish ({this.props.statistics.totalRobotsNotFinished})
                                    </div>
                                    <div className={this.filterTabClass(4)} onClick={() => this.onFilterClicked(4)}>
                                        Didn't clean ({this.props.statistics.totalRobotsNotStartedCleaning})
                                    </div>
                                    {/*<div className="border-line"></div>*/}
                                </div>
                                <div className="group-filter">
                                    <Dropdown
                                        options={[
                                            { label: 'Groups', value: '0' },
                                            { label: 'Group 1', value: '1' },
                                            { label: 'Group 2', value: '2' },
                                            { label: 'Group 3', value: '3' }
                                        ]}
                                        // placeholder="All Groups"
                                        value={this.state.groupFilter}
                                        onChange={(c) => this.onGroupFilterSelected(c)}
                                        className='dropdown'
                                        controlClassName='control'
                                    />
                                </div>
                                <div className="group-filter">
                                    <Dropdown
                                        options={[
                                            { label: 'Statuses', value: '' },
                                            { label: 'Parking', value: 'parking' },
                                            { label: 'Finished cleaning', value: 'finished-cleaning' },
                                            { label: 'Drive forward', value: 'drive-forward' },
                                            { label: 'Drive backwards', value: 'drive-backwards' },
                                            { label: 'Error', value: 'error' }
                                        ]}
                                        // placeholder="All Groups"
                                        value={this.state.statusFilter}
                                        onChange={(c) => this.onStatusFilterSelected(c)}
                                        className='dropdown'
                                        controlClassName='control'
                                    />
                                </div>
                                <div className="group-filter">
                                    <Dropdown
                                        options={[
                                            { label: 'Modes', value: '' },
                                            { label: 'Autonomous', value: 'autonomous' },
                                            { label: 'Portable', value: 'portable' },
                                            { label: 'Inactive Client', value: 'inactiveClient' },
                                            { label: 'Inactive Airtouch', value: 'inactiveAirtouch' }
                                        ]}
                                        // placeholder="All Groups"
                                        value={this.state.modeFilter}
                                        onChange={(c) => this.onModeFilterSelected(c)}
                                        className='dropdown'
                                        controlClassName='control'
                                    />
                                </div>
                            </> : <div className="date-wrapper">
                                <SearchComponent placeholder="Search" searchChanged={this.onSearchChange}
                                    onSearch={this.onSearchChange} />
                            </div>}

                            <div className="date-wrapper">
                                <DatePicker
                                    selected={this.state.date}
                                    onChange={this.dateChanged}
                                    startDate={this.state.date}
                                    // dateFormat="Pp"
                                    maxDate={new Date()}
                                    shouldCloseOnSelect={true}
                                    customInput={<span>{this.timeZone.getLocalTime(this.state.date, true)}</span>}
                                // customInput={<this.VoltageDateCustomInput/>}
                                />
                            </div>

                            <div className="download" onClick={this.downloadCsvFile}><img src={downloadIcon} />DOWNLOAD
                            </div>
                        </div>
                        <div className="total-results">Total
                            results: {this.props.statistics ? this.props.statistics.numOfResults : 0}</div>
                        <table>
                            <thead>
                                <tr>
                                    {this.getHeaderTH('robotName', 'Robot', this.getSortValue('robotName'), this.onTableSort)}
                                    {this.props.project.arrayType === 'solar-tracking' ? this.getHeaderTH('vehicleName', 'Vehicle') : null}
                                    {this.getHeaderTH('groupNumber', 'Group')}
                                    {this.getHeaderTH('manualSettings', 'Settings')}
                                    {/*{this.getHeaderTH('mode', 'Mode')}*/}
                                    <th>Mode</th>
                                    {this.getHeaderTH('currentStatus', 'Status')}
                                    {this.getHeaderTH('currentRow.number', 'Row')}
                                    {this.getHeaderTH('totalCleaningDistance', 'Distance Cleaned')}
                                    {this.getHeaderTH('totalRowsCleaned', 'Rows Cleaned')}
                                    {this.props.user.role !== "projectOwner" ? this.getHeaderTH('voltage8', '8:00 AM Battery', this.getSortValue('voltage8'), this.onTableSort) : null}
                                    {this.props.user.role !== "projectOwner" ? this.getHeaderTH('voltage12', '12:00 PM Battery', this.getSortValue('voltage12'), this.onTableSort) : null}
                                    {this.getLiverReports() ? this.getHeaderTH('', 'Battery now') : null}
                                    {this.props.user.role !== "projectOwner" && this.getHeaderTH('reason', 'Reason')}
                                    {this.getHeaderTH('', 'Pre Cleaning Battery')}
                                    {this.getHeaderTH('', 'Post Cleaning Battery')}
                                    {this.getHeaderTH('motorCurrent', 'Motor / Rotor Current', this.getSortValue('motorCurrent'), this.onTableSort)}
                                    {this.props.user.role !== "projectOwner" && this.getHeaderTH('', 'Actions')}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items.map((item, index) => <tr key={index}>
                                    <td className="robot-name" onClick={() => this.selected(item)}>{item.robotName} {item.rpi ? `(${item.rpi})` : ''}
                                        {this.props.user.role !== "projectOwner" && item.manualCleaning ?
                                            <img className="active-today" src={robotsIcon} /> : null}
                                    </td>
                                    {this.props.project.arrayType === 'solar-tracking' ?
                                        <td onClick={() => this.selected(item)}>{item.vehicleName}</td> : null}
                                    <td>
                                        {this.props.user.role !== "projectOwner" ? <Dropdown
                                            options={[1, 2, 3]}
                                            // value={item.settings && item.groupNumber ? item.groupNumber + "" : "1"}
                                            value={item.groupNumber ? item.groupNumber + "" : "1"}
                                            onChange={(c) => this.onGroupSelected(c, item)}
                                            className='dropdown'
                                            controlClassName='control'
                                        /> :
                                            <span>{item.settings && item.groupNumber ? item.groupNumber + "" : "1"}</span>
                                        }
                                    </td>

                                    <td onClick={() => this.selected(item)}>{item.manualSettings ? 'Manual' : 'Default'}</td>
                                    <td>
                                        {this.props.user.role !== "projectOwner" ? <Dropdown
                                            options={[
                                                { label: 'Autonomous', value: 'autonomous' },
                                                { label: 'Portable', value: 'portable' },
                                                { label: 'Inactive Client', value: 'inactiveClient' },
                                                { label: 'Inactive Airtouch', value: 'inactiveAirtouch' }
                                            ]}
                                            value={item.operationMode}
                                            onChange={(c) => this.onModeSelected(c, item)}
                                            className='dropdown'
                                            controlClassName='control'
                                        /> :
                                            <span>{item.operationMode}</span>
                                        }
                                    </td>
                                    <td onClick={() => this.selected(item)}
                                        className={item.status === 'error' ? 'error' : ''}>
                                        {item.status}
                                        {item.errorCode ? <>
                                            <span className="error-code"><br />{item.errorCode}</span>
                                            {item.locationOnRow ? <><br />({Math.round(item.locationOnRow / 1000)}m)</> : null}
                                        </> : null}

                                    </td>

                                    <td onClick={() => this.selected(item)} className="row">
                                        {item.row ? item.row.number : ''}
                                    </td>
                                    <td className="distance-cleaned" onClick={() => this.selected(item)}>{item.totalCleaningDistance}</td>
                                    <td className="rows-cleaned" onClick={() => this.selected(item)}>{item.totalRowsCleaned}</td>
                                    {this.props.user.role !== "projectOwner" ? <td onClick={() => this.selected(item)}
                                        className={this.isChargingClass(item.voltageModel["8"])}>
                                        {item.voltageModel["8"] ? <div>
                                            <div>{item.voltageModel["8"].voltage}V</div>
                                            {item.voltageModel["8"].distancePossible !== undefined && <div>{Math.round(item.voltageModel["8"].distancePossible / 1000)}m</div>}
                                            {item.voltageModel["8"].batteryPercentage !== undefined && <div>{item.voltageModel["8"].batteryPercentage}%</div>}
                                            {item.voltageModel["8"].avgChargingCurrent !== undefined && <div>{item.voltageModel["8"].avgChargingCurrent}A</div>}
                                        </div> : ''}
                                        {this.isChargingText(item.voltageModel["8"])}
                                    </td> : null}
                                    {this.props.user.role !== "projectOwner" ? <td onClick={() => this.selected(item)}
                                        className={this.isChargingClass(item.voltageModel["12"])}>
                                        {item.voltageModel["12"] ? <div>
                                            <div>{item.voltageModel["12"].voltage}V</div>
                                            {item.voltageModel["12"].distancePossible !== undefined && <div>{Math.round(item.voltageModel["12"].distancePossible / 1000)}m</div>}
                                            {item.voltageModel["12"].batteryPercentage !== undefined && <div>{item.voltageModel["12"].batteryPercentage}%</div>}
                                            {item.voltageModel["12"].avgChargingCurrent !== undefined && <div>{item.voltageModel["12"].avgChargingCurrent}A</div>}
                                        </div> : ''}
                                        {this.isChargingText(item.voltageModel["12"])}
                                    </td> : null}
                                    {this.getLiverReports() ?
                                        <td onClick={() => this.selected(item)}
                                            className={this.isChargingClass(this.getLiveItem(item))}>
                                            {this.getLiveItem(item) ? <>
                                                <div>{this.getLiveItem(item).voltage}V</div>
                                                {this.props.project.settings.robotVersion === 4 && <>
                                                    {this.getLiveItem(item).distancePossible ? <div>{Math.round(this.getLiveItem(item).distancePossible / 1000)}m ({this.getLiveItem(item).batteryPercentage}%)</div> : null}
                                                    {/*<div>{item.voltageModel.preCleaningAvgChargingCurrent}A</div>*/}
                                                </>}
                                                {this.isChargingText(this.getLiveItem(item))}
                                            </> : null}
                                        </td>
                                        : null
                                    }
                                    {this.props.user.role !== "projectOwner" &&
                                        <td>
                                            {item.reason}
                                        </td>
                                    }
                                    <td onClick={() => this.selected(item)}>
                                        {item.voltageModel.preCleaningVoltage ? <div>
                                            <div>{item.voltageModel.preCleaningVoltage}V</div>
                                            {this.props.project.settings.robotVersion === 4 && <>
                                                {item.voltageModel.preCleaningDistancePossible ? <div>{Math.round(item.voltageModel.preCleaningDistancePossible / 1000)}m ({item.voltageModel.preCleaningBatteryPercentage}%)</div> : null}
                                                {/*<div>{item.voltageModel.preCleaningAvgChargingCurrent}A</div>*/}
                                            </>}
                                        </div> : ''}
                                    </td>
                                    <td onClick={() => this.selected(item)}>
                                        {item.voltageModel.postCleaningVoltage ? <div>
                                            <div>{item.voltageModel.postCleaningVoltage}V</div>
                                            {this.props.project.settings.robotVersion === 4 && <>
                                                {item.voltageModel.postCleaningDistancePossible ? <div>{Math.round(item.voltageModel.postCleaningDistancePossible / 1000)}m ({item.voltageModel.postCleaningBatteryPercentage}%)</div> : null}
                                                {/*<div>{item.voltageModel.postCleaningAvgChargingCurrent}A</div>*/}
                                            </>}
                                        </div> : ''}
                                    </td>
                                    <td onClick={() => this.selected(item)}>{item.motorCurrent || 0} / {item.rotorCurrent || 0} Amp</td>
                                    {this.props.user.role !== "projectOwner" &&
                                        <td className="actions">
                                            {this.props.user.role === "admin" || this.props.user.role === "countryAdmin" ? <div onClick={() => this.deleteRobot(item)}>Delete</div> : null}
                                            {this.props.user.role !== "projectOwner" && this.props.project.settings.fastDrying && <div onClick={() => this.dryRobot(item)}>Dry</div>}
                                        </td>}
                                </tr>
                                )}

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    return {
        ...state.robotsAndVehiclesReducer,
        project: state.indexReducer.project,
        user: state.indexReducer.user
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getRobotsAndVehicles,
            robotSelected,
            vehicleSelected,
            setRobotSettings,
            deleteRobotVehicle,
            dryRobot,
            updateItems,
            getLiveReports,
            getRobotsCsv
        }, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(RobotsAndVehiclesComponent));