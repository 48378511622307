import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getAvailabilitySuccess} from "./getAvailabilitySuccessAction";
import {getAvailabilityError} from "./getAvailabilityErrorAction";
import {getAvailabilityStarted} from "./getAvailabilityStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getAvailability(projectId, from, to) {
    if (!from) {
        from = new Date();
        from.setDate(from.getDate()-7);
    }

    if (!to)
        to = new Date();

    return function (dispatch) {
        let url = `${HOST_URL}/api/availability?projectId=${projectId}&from=${from.getTime()}&to=${to.getTime()}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getAvailabilityStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // dispatch(getAvailabilitySuccess(response.data.data));
                dispatch(getAvailabilitySuccess(response.data.reports));

            }
            else {
                dispatch(getAvailabilityError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getAvailabilityError(error));
        });
    };
}