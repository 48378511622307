import React, { Component, PropTypes, useEffect, useState } from "react";
import "./cleaningControl.scss";
import spinner from "./white_spinner.svg";

import {
  COMMAND_CONTINUE,
  COMMAND_GO_HOME,
  COMMAND_PAUSE,
  COMMAND_START,
  COMMAND_REMOTE_COMMAND,
  COMMAND_RESET,
} from "./sendCommand";
import greySpinner from "./grey_spinner.svg";

import playIcon from "./play-icon.svg";
import cleaningInProgress from "./cleaning-icon-spinning.svg";
import { sendCommandStarted } from "./sendCommandStartedAction";
import Modal from "../modal/modal";
import Dropdown from "react-dropdown";
import { camelCaseToText, dashedToTitle } from "../../consts";
import ApiHandler from "../api";

const RobotStatuses = [
  { label: "Idle", value: "idle" },
  { label: "Parking", value: "parking" },
  { label: "Finished cleaning", value: "finished-cleaning" },
  { label: "Drive backwards", value: "drive-backwards" },
  { label: "Drive forward", value: "drive-forward" },
  { label: "Error", value: "error" },
];

const ParkingStatuses = [
  "parking",
  "idle",
  "finished-cleaning",
  "error",
  "not-cleaning",
  "cleaning-prevented",
  "weather-error",
  "ups-error",
];

const CleaningStatuses = [
  "drop-off",
  "pickup",
  "waiting",
  "driving",
  "cleaning",
  "drive-forward",
  "drive-backwards",
  "start-cleaning",
];

const PausedStatuses = ["paused"];

const apiHandler = new ApiHandler();

const CleaningControlRobotComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [startCleaningSeconds, setStartCleaningSeconds] = useState(0);
  const [currentState, setCurrentState] = useState("");
  const [pendingCommand, setPendingCommand] = useState("");
  const [error, setError] = useState("");
  const [command, setCommand] = useState("");

  useEffect(() => {
    setStartCleaningSeconds(0);
  }, [props.startCleaningSeconds]);

  useEffect(() => {
    console.log("currentState changed", currentState);
  }, [currentState]);

  useEffect(() => {
    // debugger;
    if (props.robot) {
      setPendingCommand(props.robot.pendingCommand);
    }
  }, [props.robot]);

  useEffect(() => {
    // debugger;
    if (props.project) {
      setPendingCommand(props.project.pendingCommand);
    }
  }, [props.project]);

  useEffect(() => {
    if (command) setShowModal(true);
  }, [command]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartCleaningSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getCleaningTime = () => {
    if (props.pendingCommand) return;
    let total = Math.round(
      (props.startCleaningSeconds || 0) + startCleaningSeconds
    );
    let hours = Math.floor(total / 3600);
    total = total % 3600;
    let minutes = Math.floor(total / 60);
    let seconds = total % 60;
    if (hours && minutes < 10) minutes = "0" + minutes;

    if (seconds < 10) seconds = "0" + seconds;

    if (hours) return `${hours}:${minutes}:${seconds}`;
    else return `${minutes}:${seconds}`;
  };

  const getScheduledCleaning = () => {
    if (!props.cleaningTime) return;

    // console.log("props.cleaningTime",props.cleaningTime)

    if (props.cleaningTime.minute < 10)
      return `${props.cleaningTime.hour}:0${props.cleaningTime.minute}`;
    else return `${props.cleaningTime.hour}:${props.cleaningTime.minute}`;
  };

  const commandClicked = (command) => {
    // debugger;
    if (pendingCommand === command || props.pendingCommand === command) return;
    setCommand(command);
  };

  const commandButtonClicked = async () => {
    console.log("commandButtonClicked", command);
    const params = {
      projectId: props.project._id,
      category: props.category,
      value: props.categoryId || props.categoryValue,
    };
    setPendingCommand(command);
    if (props.onPendingCommand) props.onPendingCommand(command);
    if (command === COMMAND_RESET) {
      params.event = COMMAND_RESET;
      params.cmd = {
        name: props.category === "robot" ? "reset" : "reset-all",
        dest: undefined,
        data: undefined,
      };
    } else {
      params.event = command;
      params.rpi = props.rpi;
    }
    const reuslt = await apiHandler.sendProjectCommand(params);

    closeModal();
  };

  const switchToAutoMode = async () => {
    const params = {
      productId: props.project._id,
      event: COMMAND_REMOTE_COMMAND,
      category: props.category,
      value: props.categoryId || props.categoryValue,
      cmd: {
        name: "automode",
        dest: undefined,
        data: undefined,
      },
    };

    const reuslt = await apiHandler.sendProjectCommand(params);
  };

  const getStatusText = () => {
    if (props.category === "group")
      return `${capitalize(props.category)} ${props.categoryValue} - `;
    else return "";
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getModalTitle = () => {
    if (command === COMMAND_GO_HOME) return "Stop Cleaning (Go Home)";
    else if (command === COMMAND_START) return "Start Cleaning";
    else if (command === COMMAND_CONTINUE) return "Continue";
    else if (command === COMMAND_PAUSE) return "Pause";
    else if (command === COMMAND_RESET) return `Reset ${props.category}`;
  };

  const closeModal = () => {
    setShowModal(false);
    setCommand(null);
  };

  const isCleaningPrevented = () => {
    if (props.currentState !== "cleaning-prevented") return false;

    const hour = new Date().toLocaleString("en-US", {
      timeZone: props.timezone.timeZoneId,
      hour: "2-digit",
      hour12: false,
    });
    return hour > props.cleaningTime.hour;
  };

  const getMainClass = () => {
    if (isOffline() || isInactiveRobot()) return "offline cleaning-control";
    else if (!props.autoMode && props.category === "vehicle")
      return "offline auto-mode cleaning-control";
    return "cleaning-control";
  };

  const getOfflineTitle = () => {
    if (props.category === "vehicle") return "Vehicle is offline";

    if (isInactiveRobot()) {
      return "Robot is portable/inactive";
    }

    return props.project.projectName + " is offline";
  };

  const onRobotStatusChanged = async (item) => {
    if (!item.value || props.category === "vehicle") return;

    await apiHandler.setRobotSettings(props.robot.id, {
      currentStatus: item.value,
    });
  };

  const cleaningStatusDiv = (text) => {
    return (
      <div className="cleaning-status cleaning-pending">
        {changeStatus ? (
          <Dropdown
            options={RobotStatuses}
            value={props.currentState}
            // placeholder="Change status"
            onChange={onRobotStatusChanged}
            className="dropdown"
            controlClassName="control"
          />
        ) : (
          <div>
            {getStatusText()}
            {text} {props.pendingCommand ? "(Pending)" : null}
            {!props.pendingCommand && text === "Cleaning In Progress" ? (
              <img src={cleaningInProgress} />
            ) : null}
          </div>
        )}
        {props.category === "robot" && props.currentState ? (
          <div
            className="change-status"
            onClick={() => setChangeStatus(!changeStatus)}
          >
            {!changeStatus ? <span>change</span> : <span>close</span>}
          </div>
        ) : null}
      </div>
    );
  };

  const pauseButton = (
    <div
      className={
        pendingCommand === COMMAND_PAUSE ||
        props.pendingCommand === COMMAND_PAUSE
          ? "button pause loading"
          : "button pause"
      }
      onClick={() => commandClicked(COMMAND_PAUSE)}
    >
      <span className="pause-icon"></span>
      <span className="pause-icon"></span>
      <img src={greySpinner} className="spinner" />
      <span className="text">PAUSE</span>
    </div>
  );

  const goHomeButton = (
    <div
      className={
        pendingCommand === COMMAND_GO_HOME ||
        props.pendingCommand === COMMAND_GO_HOME
          ? "button stop loading"
          : "button stop"
      }
      onClick={() => commandClicked(COMMAND_GO_HOME)}
    >
      <span className="stop-icon"></span>
      <img src={spinner} className="spinner" />
      <span className="text">GO HOME</span>
    </div>
  );

  const startButton = (
    <div
      className={
        pendingCommand === COMMAND_START ||
        props.pendingCommand === COMMAND_START
          ? "button start start-loading"
          : "button start"
      }
      onClick={() => commandClicked(COMMAND_START)}
    >
      <img src={playIcon} className="icon" />
      <img src={spinner} className="spinner" />
      <span className="text">START</span>
    </div>
  );

  const isOffline = () => {
    return props.offline || props.project.connectionStatus === "offline";
  };

  const isInactiveRobot = () => {
    return props.robot && props.robot.operationMode === "inactive";
  };

  return (
    <div className={getMainClass()}>
      {showModal && !isOffline() && !isInactiveRobot() ? (
        <Modal title={getModalTitle()} onClose={closeModal}>
          <div className="buttons-wrapper">
            <div className="button cancel" onClick={closeModal}>
              Cancel
            </div>
            <div className="button" onClick={commandButtonClicked}>
              Continue
            </div>
          </div>
        </Modal>
      ) : null}

      {showModal && (isOffline() || isInactiveRobot()) && (
        <Modal title={getOfflineTitle()} onClose={closeModal}>
          <div className="buttons-wrapper">
            <div className="button cancel" onClick={closeModal}>
              Ok
            </div>
            {/*<div className="button" onClick={commandButtonClicked}>Continue</div>*/}
          </div>
        </Modal>
      )}

      {CleaningStatuses.includes(props.currentState) ? (
        <div className="upper">
          <div className="cleaning-status cleaning-in-progress">
            {cleaningStatusDiv(dashedToTitle(props.currentState))}
          </div>
          <div className="right">
            <div className="time">{getCleaningTime()}</div>
            {props.user.role !== "projectOwner" ? (
              <div className="buttons">
                {pauseButton}
                {goHomeButton}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {PausedStatuses.includes(props.currentState) ? (
        <div className="upper">
          {cleaningStatusDiv(dashedToTitle(props.currentState))}
          <div className="right">
            {props.user.role !== "projectOwner" ? (
              <div className="buttons">
                {startButton}
                {goHomeButton}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {!props.currentState || ParkingStatuses.includes(props.currentState) ? (
        <div className="upper">
          {cleaningStatusDiv(dashedToTitle(props.currentState))}
          <div className="right">
            {props.cleaningTime ? (
              <div className="scheduled-cleaning">
                {isCleaningPrevented() ? (
                  <span>Cleaning prevented at </span>
                ) : (
                  <span>Cleaning scheduled for </span>
                )}
                {getScheduledCleaning()}
              </div>
            ) : null}
            {props.user.role !== "projectOwner" ? (
              <div className="buttons">
                {startButton}
                {props.currentState === "error" ? goHomeButton : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {props.currentState === "going-home" ? (
        <div className="upper">
          {cleaningStatusDiv(dashedToTitle(props.currentState))}
          <div className="right">
            {/*<div className="time">06:44</div>*/}
            {props.user.role !== "projectOwner" ? (
              <div className="buttons">
                <div
                  className={
                    pendingCommand === COMMAND_PAUSE ||
                    props.pendingCommand === COMMAND_PAUSE
                      ? "button pause loading"
                      : "button pause"
                  }
                  onClick={() => commandClicked(COMMAND_PAUSE)}
                >
                  <span className="pause-icon"></span>
                  <span className="pause-icon"></span>
                  <img src={greySpinner} className="spinner" />
                  <span className="text">PAUSE</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {props.user.role !== "projectOwner" ? (
        <div className="auto-mode orange">
          {props.category === "vehicle" && (
            <span>
              {props.autoMode ? (
                <span>Auto Mode</span>
              ) : (
                <span>
                  Manual Mode{" "}
                  <span className="switch" onClick={switchToAutoMode}>
                    switch to auto
                  </span>
                </span>
              )}
            </span>
          )}

          <span
            className={
              pendingCommand === COMMAND_RESET ||
              props.pendingCommand === COMMAND_RESET
                ? "reset right pending"
                : "reset right"
            }
            onClick={() => commandClicked(COMMAND_RESET)}
          >
            <img src={greySpinner} className="spinner" />
            reset {props.category}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CleaningControlRobotComponent;
