import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getErrorsSuccess} from "./getErrorsSuccessAction";
import {getErrorsError} from "./getErrorsErrorAction";
import {getErrorsStarted} from "./getErrorsStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getErrors({projectId, search, robotId, vehicleId, from, to, errorCode, sort}) {
    if (!from) {
        from = new Date();
        from.setDate(from.getDate()-7);
    }

    if (!to)
        to = new Date();

    return function (dispatch) {
        let url = `${HOST_URL}/api/errors?projectId=${projectId}&from=${from.getTime()}&to=${to.getTime()}`;
        if (search)
            url += '&search='+search;
        if (vehicleId)
            url += '&vehicleId='+vehicleId;
        if (robotId)
            url += '&robotId='+robotId;
        if (errorCode)
            url += '&errorCode='+errorCode;
        if (sort)
            url += '&sort='+sort;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getErrorsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // dispatch(getErrorsSuccess(response.data.data));
                dispatch(getErrorsSuccess(response.data));

            }
            else {
                dispatch(getErrorsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getErrorsError(error));
        });
    };
}