export const VIEW_MESSAGE = 'VIEW_MESSAGE';
export const EDIT_MESSAGE_STARTED = 'EDIT_MESSAGE_STARTED';
export const EDIT_MESSAGE_ERROR = 'EDIT_MESSAGE_ERROR';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';


const INITIAL_STATE = {
    isLoading: false,
    attachments: [undefined,undefined,undefined]
}


export default (state = INITIAL_STATE, action) => {
    // console.log("action.type",action.type);

    const {attachments} = state;
    switch (action.type) {
        case VIEW_MESSAGE:
            return {
                ...state,
                messageSaved: undefined
            }
        case EDIT_MESSAGE_STARTED:
            return {
                ...state,
                // attachment: action.payload.attachment[0]
            }
        case EDIT_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                messageSaved: action.payload.message
            }
        case CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                messageSaved: action.payload.message
            }
        case EDIT_MESSAGE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}