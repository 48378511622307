export const VIEW_ERROR = 'VIEW_ERROR';
export const EDIT_ERROR_STARTED = 'EDIT_ERROR_STARTED';
export const EDIT_ERROR_ERROR = 'EDIT_ERROR_ERROR';
export const EDIT_ERROR_SUCCESS = 'EDIT_ERROR_SUCCESS';
export const ADD_ATTACHMENT_LOCALLY = 'ADD_ERROR_ATTACHMENT_LOCALLY';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ERROR_ATTACHMENT_SUCCESS';
export const ADD_ATTACHMENT_ERROR = 'ADD_ERROR_ATTACHMENT_ERROR';
export const ADD_ATTACHMENT_STARTED = 'ADD_ERROR_ATTACHMENT_STARTED'
export const DELETE_ATTACHMENT_LOCALLY = 'DELETE_ERROR_ATTACHMENT_LOCALLY';
export const GET_ROBOTS_SUCCESS = 'GET_ROBOTS_SUCCESS';
export const CREATE_ERROR_SUCCESS = 'CREATE_ERROR_SUCCESS';
export const EDIT_MULTIPLE_ERRORS_SUCCESS = 'EDIT_MULTIPLE_ERRORS_SUCCESS';


const INITIAL_STATE = {
    isLoading: false,
    attachments: [undefined,undefined,undefined]
}


export default (state = INITIAL_STATE, action) => {
    // console.log("action.type",action.type);

    const {attachments} = state;
    switch (action.type) {
        case ADD_ATTACHMENT_LOCALLY:
            attachments[action.payload.index] = action.payload.attachment;
            return {
                ...state,
                attachments
            }
        case DELETE_ATTACHMENT_LOCALLY:
            attachments[action.payload.index] = undefined;
            return {
                ...state,
                attachments
            }
        case GET_ROBOTS_SUCCESS:
            return {
                ...state,
                robots: action.payload
            }
        case ADD_ATTACHMENT_SUCCESS:
            break;
        case VIEW_ERROR:
            return {
                ...state,
                errorSaved: undefined
            }
        case EDIT_ERROR_STARTED:
            return {
                ...state,
                // attachment: action.payload.attachment[0]
            }
        case EDIT_ERROR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorSaved: action.payload.error
            }
        case EDIT_MULTIPLE_ERRORS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorSaved: action.payload.errors.length
            }
        case CREATE_ERROR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorSaved: action.payload.errors[0]
            }
        case EDIT_ERROR_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}