import React, {Component, PropTypes} from 'react';
import {withNavigation} from '../withNavigate';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './vehicle.scss';
import PlantHeader from '../plantHeader/plantHeader';
import CleaningPlan from '../cleaningPlan/cleaningPlan';
import Menu from "../menu/menu";

import {getVehicleLogs} from "./getVehicleLogsAction";
import RowsCleaned from './rowsCleaned.scss';
import RobotStatus from "../dashboard/robotStatus";
import RowStatus from "../dashboard/rowStatus";

import editPlanIcon from "./edit-plan.svg";
import Loader from "../loader/loader";
import {getErrors} from "../errors/actions/getErrorsAction";
import CleaningControlVehicleComponent from "../cleaningControl/cleaningControlVehicle";
import {getVehicleLogsSuccess} from "./getVehicleLogsSuccessAction";
import TableComponent from "../tableComponent/tableComponent";
import {getCleaningPlan} from "../cleaningPlan/getCleaningPlanAction";
import {downloadCleaningPlan} from "./downloadCleaningPlanAction";
import {uploadCleaningPlanCsv} from "./uploadCleaningPlanAction";
import {getVehicleDetails} from "./getVehicleDetailsAction";
import spinner from "../loader/orange_spinner.svg";
import {refreshRobotBatteries} from "../dashboard/actions/refreshRobotBatteries";
import Dropdown from "react-dropdown";
import {setVehicleSettings} from "./setVehicleSettingsAction";
import {initStateLog} from "./initStateLogsAction";
import Switch from "react-switch";
import checkIcon from "../errors/images/check-icon.svg";
import {getRows} from "../rows/getRowsAction";
import {TimeZone} from "../utils";

const robotsErrors = [
    {index: 3, error: 'stuck on table', color: '#f6112a'}
];

const AUTOMATIC_TABLE_ANGLE = 1000;


class VehicleComponent extends TableComponent {
    constructor(props) {
        super(props);

        if (!this.props.vehicle) {
            this.props.oldNavigate("/robots");
            return;
        }


        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        const settings = this.props.vehicle.settings || {
            robots: [{}, {}, {}, {}],
            tableAngle: AUTOMATIC_TABLE_ANGLE + '',
            notUpdated: true
        };
        if (!settings.robots || !settings.robots.length)
            settings.robots = [{}, {}, {}, {}];


        this.state = {
            settings,
            showCleaningPlan: false,
            errorLastUpdate: new Date(),
            errorsOnly: false
        };

        this.tableAngles = [{
            label: `Automatic`,
            value: AUTOMATIC_TABLE_ANGLE + ''
        }];
        for (let i = -45; i <= 45; i++) {
            this.tableAngles.push({
                label: `${i}°`,
                value: i + ''
            })
        }

        this.timeZone = new TimeZone(this.props.project);

    }

    logSwitchChange = (checked) => {
        this.setState({errorsOnly: checked});
        this.props.actions.initStateLog();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.processId && (nextProps.processId !==this.props.processId)) {
            // this.getLiveReports(nextProps.processId);
        }

        if (nextProps.vehicle.settings && this.state.settings.notUpdated) {
            const {settings} = nextProps.vehicle;
            if (!settings.robots || !settings.robots.length)
                settings.robots = [{}, {}, {}, {}];
            this.setState({settings})
        }
    }

    componentDidMount() {

        this.props.actions.getVehicleLogs(this.props.vehicle.vehicleId, 1);
        this.props.actions.getErrors(this.props.project._id, null, null, this.props.vehicle.vehicleId);
        this.props.actions.getCleaningPlan(this.props.project._id, this.props.vehicle.vehicleName);
        this.props.actions.getRows(this.props.project._id);
        this.getVehicleDetails();
    }

    getVehicleDetails = () => {
        setInterval(() => {
            const timestamp = this.props.stateMachineLogs.length? this.props.stateMachineLogs[0].timestamp: undefined;
            this.props.actions.getVehicleDetails(this.props.vehicle.vehicleId || this.props.vehicle.id, timestamp,this.state.errorsOnly);
        }, 3000);
    }

    refreshErrors = () => {
        this.setState({errorLastUpdate: new Date()});
        this.props.actions.getErrors(this.props.project._id, null, null, this.props.vehicle.vehicleId);
    }

    getErrorDescription = error => {
        return this.timeZone.getLocalTime(error.timestamp);
    }


    getErrorTitle = error => {
        let str = '';

        str += error.description;

        if (error.row)
            str += ' (row' + error.row.number + ')';

        return str;
    }

    getSeverityClass = (item) => {
        if (item.state ==='critical error')
            return 3;

        if (item.isError)
            return 2;
        // default
        return 1;
    }


    getData = () => this.props.logs;

    publishData = (data) => this.props.actions.getVehicleLogsSuccess(data);


    showCleaningPlan = (showCleaningPlan) => {

        this.setState({showCleaningPlan})
    }


    downloadCsv = (event) => {
        // console.log("this.props.vehicle", this.props.vehicle)
        this.props.actions.downloadCleaningPlan(this.props.vehicle.vehicleId || this.props.vehicle.id);
    }


    onFileChange = event => {

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );

        formData.append(
            "vehicleId",
            this.props.vehicle.vehicleId
        );

        this.fileUpload.value = "";

        this.props.actions.uploadCleaningPlanCsv(formData);
    };

    fileUploadClick = () => {
        this.fileUpload.click();
    }

    getCleaningDay = () => {
        const day1Plan = this.props.cleaningPlan.filter(item => item.day ===1);
        const day2Plan = this.props.cleaningPlan.filter(item => item.day ===2);

        if (day1Plan.length ===0 || day2Plan.length ===0)
            return 1;
        else if (day1Plan.length && !day2Plan.length)
            return 1;
        return new Date().getDay() % 2 ===0 ? 1 : 2;

    }

    getDailyCleaningPlan = () => {
        // console.log("this.props.cleaningPlan",this.props.cleaningPlan)
        const day1Plan = this.props.cleaningPlan.filter(item => item.day ===1);
        const day2Plan = this.props.cleaningPlan.filter(item => item.day ===2);
        if (day1Plan.length ===0 || day2Plan.length ===0)
            return this.props.cleaningPlan;

        if (new Date().getDay() % 2 ===0)
            return day1Plan;
        else
            return day2Plan;
    }

    pickupDropOff = (startDate, endDate) => {

        const date = startDate || endDate;
        if (!date)
            return;

        return `${this.timeZone.getLocalTime(date, true)} ${this.timeZone.getLocalTime(startDate, false, false, true)} - ${this.timeZone.getLocalTime(endDate, false, false, true) || ''}`
    }

    closeCleaningPlan = () => {
        this.showCleaningPlan(false);
        this.props.actions.getCleaningPlan(this.props.project._id, this.props.vehicle.vehicleName);
    }

    refreshRobotBatteries = () => {
        this.props.actions.refreshRobotBatteries(this.props.project._id, null, this.props.vehicle.vehicleName);
    }

    saveSettings = () => {
        this.setState({editSettings: false});

        this.props.actions.setVehicleSettings(this.props.vehicle.id, {
            settings: this.state.settings
        });
    }

    setCustomSettings = () => {
        this.setState({editSettings: true});
    }

    loadDefaultSettings = () => {

    }

    onRobotActivationChanged = (index, selected) => {

        const {settings} = this.state;
        const robot = settings.robots[index];
        robot.isActive = selected.value ==='active';
        this.setState({settings});
    }

    onRobotParkingChanged = (value, index) => {
        value = parseInt(value)
        const {settings} = this.state;
        const robot = settings.robots[index];

        if (!value || isNaN(value) || value < 1) {
            robot.parkingRow = undefined;
        } else {
            robot.parkingRow = value;
        }

        this.setState({settings});
    }

    onVehicleTableAngleChange = (selected) => {
        const {settings} = this.state;
        settings.tableAngle = parseInt(selected.value);

        this.setState({settings});
    }


    render() {
        // console.log("this.props.cleaningPlan",this.props.cleaningPlan)
        return (
            <div className="page">
                {this.props.isLoading ? <Loader/> : null}
                {this.state.showCleaningPlan ?
                    <CleaningPlan closeCleaningPlan={this.closeCleaningPlan}/> : null}
                <div className="menu-wrapper">
                    <Menu/>
                </div>
                <div className="content">
                    <PlantHeader title={"Vehicle " + this.props.vehicle.vehicleName} secondaryText={"Robots & Vehicles"}
                                 secondaryLink={'/robots'}
                                 thirdText={this.props.project.projectName}/>
                    <div className="plan-buttons">
                        {/*<div className="plan-button" onClick={() => this.showCleaningPlan(true)}>*/}
                        {/*    <img src={editPlanIcon}/>*/}
                        {/*    <span className="text">EDIT CLEANING PLAN</span>*/}
                        {/*</div>*/}
                        {/*<div className="plan-button" onClick={this.fileUploadClick}>*/}
                        {/*    <img src={exportPlanIcon}/>*/}
                        {/*    <span className="text">UPLOAD PLAN</span>*/}
                        {/*    <input ref={(ref) => this.fileUpload = ref} type="file" onChange={this.onFileChange}/>*/}
                        {/*</div>*/}
                        {/*<div className="plan-button" onClick={this.downloadCsv}>*/}
                        {/*    <img src={donwloadPlanIcon}/>*/}
                        {/*    <span className="text">DOWNLOAD PLAN</span>*/}
                        {/*</div>*/}

                    </div>
                    <div className="vehicles">
                        <div className="left">
                            {this.props.vehicle ? <div className="control-panel">
                                <div className="title">CONTROL PANEL</div>
                                <div className="status">
                                    {this.props.vehicle.connectionStatus ==="offline" ?
                                        <span className="offline"><span className="circle"></span>offline</span>
                                        :
                                        <span className="online"><span className="circle"></span>online</span>
                                    }
                                </div>
                                <CleaningControlVehicleComponent
                                    autoMode={this.props.vehicle && this.props.vehicle.isAutoMode}
                                    offline={!this.props.vehicle || this.props.vehicle.connectionStatus ==="offline"}
                                    category="vehicle"
                                    startCleaningSeconds={this.props.vehicle.startCleaningSeconds || 0}
                                    state={this.props.vehicle.state}
                                    currentStatus={this.props.vehicle.currentStatus}
                                    categoryValue={this.props.vehicle.vehicleName}
                                    errorDetails={this.props.vehicle.lastError ? this.props.vehicle.lastError.errorDetails : null}
                                    namespace="vehicle"/>
                                <div className="separator"></div>
                                <div className="lower">
                                    <div className="left">
                                        <div
                                            onClick={() => this.showCleaningPlan(true)}
                                            className="area-cleaned-title"
                                        >Current Location:{" "}
                                            <span
                                                className="current-row">{this.props.vehicle.row && this.props.vehicle.row.number ? 'Row' + this.props.vehicle.row.number : 'Parking'}</span>
                                            <span className="slash">/</span>Total cleaning
                                            plan: <b>{this.getDailyCleaningPlan().length} rows
                                                (day {this.getCleaningDay()})</b>
                                            <img src={editPlanIcon}/>
                                        </div>

                                        <RowsCleaned
                                            currentRow={this.props.vehicle.row && this.props.vehicle.row.number ? this.props.vehicle.row.number : -1}
                                            plan={this.getDailyCleaningPlan()}
                                            robots={this.props.vehicle.robots}
                                            rows={this.props.vehicle.rows || {}}
                                        />
                                    </div>
                                    <div className="right">
                                        {this.props.vehicle ? <div>
                                            <div className="robot-status-title">
                                                <span>Robot status: </span>

                                                <span
                                                    className="turquoise">{this.props.vehicle.robots.filter(r => r.currentStatus !=='error' && r.currentStatus !=='parking').length} of {this.props.vehicle.robots.length} are active</span>

                                            </div>
                                            <div className="robot-status-wrapper">

                                                {this.props.vehicle.robots.length ?
                                                    <RobotStatus totalNumber={this.props.vehicle.robots.length}
                                                                 defaultColor={'#05b2aa'}
                                                                 robots={this.props.vehicle.robots.sort((a, b) => a.name > b.name ? -1 : 1)}
                                                                 specialCases={robotsErrors}/> : null
                                                }
                                            </div>
                                        </div> : null}

                                        <div className="robot-status-title push-down pointer"
                                             onClick={() => this.props.navigate("/robotSettings")}>Rows exluded: <span
                                            className="orange">{this.props.rowsExcluded.length} of {this.getDailyCleaningPlan().length}</span>
                                        </div>
                                        <RowStatus
                                            totalNumber={this.getDailyCleaningPlan().length}
                                            excludedColor={'#e36510'}
                                            rowsExluded={this.props.rowsExcluded}
                                            onClick={() => this.props.navigate("/robotSettings")}
                                        />

                                    </div>

                                </div>
                            </div> : null}
                            <div className="vehicle-settings">
                                <div className="title">
                                    <span className="text">SETTINGS</span>
                                    {this.props.user.role !=="projectOwner" ?
                                        <span className="separator">|</span> : null}
                                    {this.props.user.role !=="projectOwner" ? <span>
                                        {this.state.editSettings ?
                                            <span className="custom"
                                                  onClick={this.saveSettings}>SAVE</span>
                                            :
                                            <span className="custom"
                                                  onClick={this.setCustomSettings}>Edit</span>
                                        }

                                        {this.state.showLoadDefault && this.state.editSettings ?
                                            <span className="custom show-default"
                                                  onClick={this.loadDefaultSettings}><span
                                                className="separator">|</span>LOAD DEFAULT SETTINGS</span> : null}
                                    </span> : null}

                                    <div className="refresh">
                                        {/*{this.state.isLoadingVoltages ?*/}
                                            {this.props.isLoadingRobotVoltages ?
                                            <span className="loading"><img
                                                src={spinner}/> Getting robot voltages...</span> : null}
                                        {this.props.isLoadingRobotVoltages && this.props.liveReport ? <span
                                            className="text">CURRENT VOLTAGE: {this.props.liveReport.voltage}V</span> : null}
                                        {this.props.user.role !=="projectOwner" && !this.props.isLoadingRobotVoltages && !this.props.liveReport ?
                                            <span className="text"
                                                  onClick={this.refreshRobotBatteries}>GET UPDATED VOLTAGE</span> : null}
                                    </div>

                                </div>
                                <div className="separator"></div>

                                <table className={this.state.editSettings ? 'settings' : 'settings disabled'}>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <td>Status</td>
                                        <td>Parking row</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Robot {this.state.settings.robots[0].robotName || '1'}</td>
                                        <td><Dropdown
                                            options={[{
                                                label: "Active",
                                                value: 'active'
                                            }, {label: "Inactive", value: 'inactive'}]}
                                            onChange={(val) => this.onRobotActivationChanged(0, val)}
                                            value={this.state.settings.robots[0].isActive ? 'active' : 'inactive'}
                                            className='dropdown'
                                            disabled={!this.state.editSettings}
                                            controlClassName='control'
                                            placeholderClassName='placeholder'
                                        /></td>
                                        <td>
                                            <input
                                                disabled={!this.state.editSettings}
                                                type="number"
                                                value={this.state.settings.robots[0].parkingRow || ''}
                                                onChange={(e) => this.onRobotParkingChanged(e.target.value, 0)}
                                            />
                                        </td>
                                        <td>Vehicle table angle</td>
                                        <td><Dropdown
                                            // placeholder={'Automatic'}
                                            options={this.tableAngles}
                                            onChange={this.onVehicleTableAngleChange}
                                            value={this.state.settings.tableAngle + ''}
                                            className='dropdown'
                                            disabled={!this.state.editSettings}
                                            controlClassName='control'
                                            placeholderClassName='placeholder'
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td>Robot {this.state.settings.robots[1].robotName || '2'}</td>
                                        <td><Dropdown
                                            options={[{
                                                label: "Active",
                                                value: 'active'
                                            }, {label: "Inactive", value: 'inactive'}]}
                                            onChange={(val) => this.onRobotActivationChanged(1, val)}
                                            value={this.state.settings.robots[1].isActive ? 'active' : 'inactive'}
                                            className='dropdown'
                                            disabled={!this.state.editSettings}
                                            controlClassName='control'
                                            placeholderClassName='placeholder'
                                        /></td>
                                        <td>
                                            <input
                                                disabled={!this.state.editSettings}
                                                type="number"
                                                value={this.state.settings.robots[1].parkingRow || ''}
                                                onChange={(e) => this.onRobotParkingChanged(e.target.value, 1)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Robot {this.state.settings.robots[2].robotName || '3'}</td>
                                        <td><Dropdown
                                            options={[{
                                                label: "Active",
                                                value: 'active'
                                            }, {label: "Inactive", value: 'inactive'}]}
                                            onChange={(val) => this.onRobotActivationChanged(2, val)}
                                            value={this.state.settings.robots[2].isActive ? 'active' : 'inactive'}
                                            className='dropdown'
                                            disabled={!this.state.editSettings}
                                            controlClassName='control'
                                            placeholderClassName='placeholder'
                                        /></td>
                                        <td>
                                            <input
                                                disabled={!this.state.editSettings}
                                                type="number"
                                                value={this.state.settings.robots[2].parkingRow || ''}
                                                onChange={(e) => this.onRobotParkingChanged(e.target.value, 2)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Robot {this.state.settings.robots[3].robotName || '4'}</td>
                                        <td><Dropdown
                                            options={[{
                                                label: "Active",
                                                value: 'active'
                                            }, {label: "Inactive", value: 'inactive'}]}
                                            onChange={(val) => this.onRobotActivationChanged(3, val)}
                                            value={this.state.settings.robots[3].isActive ? 'active' : 'inactive'}
                                            className='dropdown'
                                            disabled={!this.state.editSettings}
                                            controlClassName='control'
                                            placeholderClassName='placeholder'
                                        /></td>
                                        <td>
                                            <input
                                                disabled={!this.state.editSettings}
                                                type="number"
                                                value={this.state.settings.robots[3].parkingRow || ''}
                                                onChange={(e) => this.onRobotParkingChanged(e.target.value, 3)}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="errors">
                            <div className="header">
                                <span className="title">State Machine Log</span>
                                <span className="right">
                                    <Switch onChange={this.logSwitchChange} checked={this.state.errorsOnly}/>
                                </span>
                            </div>

                            {this.props.stateMachineLogs.map((item, index) => {
                                return <div className={'error level' + this.getSeverityClass(item)} key={index}>
                                        <span className="circle">{this.getSeverityClass(item) ===0 ?
                                            <img src={checkIcon}/> : '!'}</span>
                                    <div className="right-side">
                                        {item.state?<div className="error-title">{item.state.substring(0,1).toUpperCase()+item.state.substring(1)}{item.errorCode?` (${item.errorCode})`:''}</div>:null}
                                        <div className="error-description">{item.event} at {this.timeZone.getLocalTime(item.timestamp, null, null, true)}</div>
                                    </div>
                                </div>
                            })}
                        </div>


                        <table>
                            <tbody>
                            <tr>
                                {this.getHeaderTH('pickupTime', 'Pick up/Drop off')}
                                {this.getHeaderTH('row.number', 'Row')}
                                {this.getHeaderTH('robot.robotName', 'Robot')}
                                {this.getHeaderTH('row.length', 'Cleaning Distance')}
                                {this.getHeaderTH('', 'Drive Speed')}
                                {/*{this.getHeaderTH('voltage', 'Dropoff Voltage')}*/}
                                {this.getHeaderTH('tableAngle', 'Table Angle')}
                                {/*{this.getHeaderTH('axisPrecision', 'Axis precision')}*/}
                                {this.getHeaderTH('status', 'Status')}
                            </tr>


                            {this.props.logs.map((log, index) =>
                                <tr key={index} onClick={() => {
                                }}>
                                    <td>{this.pickupDropOff(log.pickupTime, log.dropOffTime)}</td>
                                    <td>{log.row.number}</td>
                                    <td>{log.robot ? log.robot.name : null}</td>
                                    <td>{log.row.length}</td>
                                    <td>Drive Speed</td>
                                    {/*<td>{log.voltage}</td>*/}
                                    <td>{log.tableAngle}</td>
                                    {/*<td>{log.axisPrecision}</td>*/}
                                    <td>{log.status} {log.errorCode ? <span>({log.errorCode})</span> : null}</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // console.log("state", state);
    return {
        user: state.indexReducer.user,
        project: state.indexReducer.project,
        vehicle: state.indexReducer.vehicle,
        ...state.vehicleReducer,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getVehicleDetails,
            initStateLog,
            getRows,
            downloadCleaningPlan,
            uploadCleaningPlanCsv,
            getVehicleLogs,
            getErrors,
            getCleaningPlan,
            setVehicleSettings,
            refreshRobotBatteries,
            getVehicleLogsSuccess
        }, dispatch),
    };
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(VehicleComponent));