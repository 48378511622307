import React, { Component, PropTypes, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './menu.scss';
import dashboardIcon from "./dashboard-icon.svg";
import errorsIcon from "./errors-icon.svg";
import robotsIcon from "./robots-icon.svg"
import rowsIcon from "./rows-icon.svg";
import tasksIcon from "./tasks-icon.png";
import weatherIcon from "./weather-icon.svg"
import wifiIcon from "./wifi-icon.png"
import wifiSelectedIcon from "./wifi-icon-selected.png"
import settingsIcon from "./settings-icon.svg"
import availabilityIcon from "./availability-icon.png";
import dailyActivityIcon from "./daily-activity-icon.png"

import reportsIcon from "./reports-icon.png";
import reportsSelectedIcon from "./reports-selected-icon.png";

import messagesIcon from "./messages-icon.png"

import dashboardSelectedIcon from "./dashboard-icon-selected.svg";
import errorsSelectedIcon from "./errors-icon-selected.svg";
import robotsSelectedIcon from "./robots-icon-selected.svg"
import rowsSelectedIcon from "./rows-icon-selected.svg";
import tasksSelectedIcon from "./tasks-icon-selected.png";
import settingsSelectedIcon from "./settings-icon-selected.svg"
import weatherSelectedIcon from "./weather-icon-selected.svg"
import availabilitySelectedIcon from "./availability-selected-icon.png";
import dailyActivitySelectedIcon from "./daily-activity-selected-icon.png"
import messagesSelectedIcon from "./messages-selected-icon.png"

import logo from "./logo.svg";
import { menuItemSelected } from "./menuItemSelectedAction";
import { LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY, MENU_URLS } from "../../consts";
import { withNavigation } from '../withNavigate';


const MenuItems = [{
    text: 'Dashboard',
    idleIcon: dashboardIcon,
    selectedIcon: dashboardSelectedIcon,
    url: '/dashboard'
}, {
    text: 'Robots',
    idleIcon: robotsIcon,
    selectedIcon: robotsSelectedIcon,
    url: ['/robots', '/robot', '/vehicle']
}, {
    text: 'Robot Settings',
    idleIcon: rowsIcon,
    selectedIcon: rowsSelectedIcon,
    url: '/robotSettings'
}, { //#979797        #e36510
    text: 'Tasks',
    idleIcon: tasksIcon,
    selectedIcon: tasksSelectedIcon,
    url: '/tasks'
}, {
    text: 'Messages',
    idleIcon: messagesIcon,
    selectedIcon: messagesSelectedIcon,
    url: '/messages'
}, {
    text: 'Errors',
    idleIcon: errorsIcon,
    selectedIcon: errorsSelectedIcon,
    url: '/errors'
}, {
    text: 'Error Resolution',
    idleIcon: errorsIcon,
    selectedIcon: errorsSelectedIcon,
    url: '/error-resolution'
}, {
    text: 'Daily Activity',
    idleIcon: dailyActivityIcon,
    selectedIcon: dailyActivitySelectedIcon,
    url: '/daily-activity'
}, {
    text: 'Availability',
    idleIcon: availabilityIcon,
    selectedIcon: availabilitySelectedIcon,
    url: '/availability'
}, {
    text: 'Reports',
    idleIcon: reportsIcon,
    selectedIcon: reportsSelectedIcon,
    url: '/reports'
}, {
    text: 'Weather',
    idleIcon: weatherIcon,
    selectedIcon: weatherSelectedIcon,
    url: '/weather'
}, {
    text: 'Repeaters',
    idleIcon: wifiIcon,
    selectedIcon: wifiSelectedIcon,
    url: '/repeaters'
}, {
    text: 'Settings',
    idleIcon: settingsIcon,
    selectedIcon: settingsSelectedIcon,
    url: '/settings'
}];

const VehicleItems = [{
    text: 'Dashboard',
    idleIcon: dashboardIcon,
    selectedIcon: dashboardSelectedIcon,
    url: '/vehicles/dashboard'
}, {
    text: 'Vehicles',
    idleIcon: robotsIcon,
    selectedIcon: robotsSelectedIcon,
    url: '/vehicles/list'
}]

const MenuComponent = (props) => {
    const [items, setItems] = useState(MenuItems);
    const [selectedItem, setSelectedItem] = useState(props.menuItem);
    const [project, setProject] = useState();
    const [user, setUser] = useState();

    const blockedForOwners = [
        'Robot Settings',
        'Tasks',
        'Messages',
        'Errors',
        'Error Resolution',
        'Reports',
        'Repeaters',
        'Settings',
    ]

    const blockedForOperator = [
        'Error Resolution'
    ]

    useEffect(() => {
        if (!localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
            this.props.navigate("/signin");
            return;
        }

        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        let items = [...MenuItems];
        if (project.arrayType !== 'fixed') {
            items = VehicleItems;
        }

        const blocked = [];
        if (user.role === "projectOwner") {
            blocked.push(...blockedForOwners);            
        } else if (user.role === "operator") {
            blocked.push(...blockedForOperator);
        }

        for (let i = 0; i < blocked.length; i++) {
            const item = items.find(item => item.text === blocked[i]);
            if (item)
                item.hide = true;
        }

        setItems(items);
    }, []);



    const menuItemClicked = (index) => {
        setSelectedItem(index);
        const item = items[index];

        let url = item.url instanceof Array ? item.url[0] : item.url;
        if (window.location.pathname !== url)
            window.location.href = url;
    }

    const logoClicked = () => {
        window.location.href = '/projects';
    }

    return (
        <div className="menu">
            <div className="logo" onClick={logoClicked}>
                <img src={logo} />
            </div>
            <div className="items">
                {items.map((item, index) => {
                    if (item.hide)
                        return;

                    let isSelected = item.url instanceof Array ? item.url.indexOf(window.location.pathname) !== -1 : window.location.pathname === item.url;
                    return <div key={index} className={'item' + (isSelected ? ' selected' : '')} onClick={() => menuItemClicked(index)}>
                        <img src={isSelected ? item.selectedIcon : item.idleIcon} />
                        <span className="text">{item.text}</span>
                    </div>
                })}
            </div>
        </div>
    );
}

export default withNavigation(MenuComponent);