import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {downloadCleaningPlanSuccess} from "./downloadCleaningPlanSuccessAction";
import {getCleaningPlanStarted} from "../cleaningPlan/getCleaningPlanStartedAction";

export function downloadCleaningPlan(vehicleId) {

    return function (dispatch) {
        let url = HOST_URL + '/api/plans/export?vehicleId=' + vehicleId;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCleaningPlanStarted());

        return axios.get(url, {headers: {Authorization: authToken}}).then((response) => {

            const element = document.createElement("a");
            const file = new Blob([response.data], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = "cleaning-plan.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            dispatch(downloadCleaningPlanSuccess());

        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            // dispatch(getRowsError(error));
        });
    };
}