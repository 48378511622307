import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import ApiHandler from "../api";

const apiHandler = new ApiHandler();
const VehicleSettings = ({vehicle, project, user, onSettingsChanged}) => {
    const [editVehicleSettings, setEditVehicleSettings] = useState(false);

    const saveVehicleSettings = async () => {
        try {
            await apiHandler.updateVehicleSettings(vehicle._id, project._id, vehicle.settings);
            setEditVehicleSettings(false);
        } catch (err) {
            alert("Can't update vehicle settings");
        }
    }

    return (<table>
        <tbody>
        <tr>
            <td colSpan="2">
                <div className="title">
                    <span className="text">VEHICLE SETTINGS</span>
                    {user.role !=="projectOwner" ?
                        <span className="separator">|</span> : null}
                    {user.role !=="projectOwner" ? <span>
                                        {editVehicleSettings ?
                                            <span className="custom"
                                                  onClick={saveVehicleSettings}>SAVE</span>
                                            :
                                            <span className="custom"
                                                  onClick={() => setEditVehicleSettings(true)}>EDIT</span>
                                        }
                                    </span> : null}

                </div>
                <div className="separator"></div>
            </td>
        </tr>
        <tr>
            <td>Activation Status</td>
            <td>
                <div className="vehicle">
                    <Switch
                        className="switch"
                        disabled={!editVehicleSettings}
                        onColor={'#05b2aa'}
                        onChange={(checked) => onSettingsChanged('enabled', checked)}
                        checked={vehicle.settings.enabled}/>
                    <span
                        className="name">{vehicle.settings.enabled ? 'Active' : 'Inactive'}</span>
                </div>
            </td>
        </tr>
        <tr>
            <td>Column Height [M]</td>
            <td><input type="tel" value={vehicle.settings.columnHeight || ''}
                       onChange={(e) => onSettingsChanged('columnHeight', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Tables to Clean Daily</td>
            <td><input type="tel" value={vehicle.settings.tablesPerDay || ''}
                       onChange={(e) => onSettingsChanged('tablesPerDay', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Cleaning Min Time</td>
            <td><input type="tel" value={vehicle.settings.cleaningMinTimeStr || ''}
                       placeholder="hh:mm"
                       onChange={(e) => onSettingsChanged('cleaningMinTimeStr', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Cleaning End Time</td>
            <td><input type="tel" value={vehicle.settings.cleaningMaxTimeStr || ''}
                       placeholder="hh:mm"
                       onChange={(e) => onSettingsChanged('cleaningMaxTimeStr', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Max Tilt [°]</td>
            <td><input type="tel" value={vehicle.settings.maxTilt || ''}
                       onChange={(e) => onSettingsChanged('maxTilt', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Min Tilt [°]</td>
            <td><input type="tel" value={vehicle.settings.minTilt || ''}
                       onChange={(e) => onSettingsChanged('minTilt', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        <tr>
            <td>Min Voltage [Volt]</td>
            <td><input type="tel" value={vehicle.settings.minVoltage || ''}
                       onChange={(e) => onSettingsChanged('minVoltage', e.target.value)}
                       disabled={!editVehicleSettings}/></td>
        </tr>
        </tbody>
    </table>)
}

export default VehicleSettings;