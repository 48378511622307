export const SEND_PHONE_NUMBER_STARTED = 'SEND_PHONE_NUMBER_STARTED';
export const SEND_PHONE_NUMBER_ERROR = 'SEND_PHONE_NUMBER_ERROR';
export const SEND_PHONE_NUMBER_SUCCESS = 'SEND_PHONE_NUMBER_SUCCESS';
export const SEND_CODE_ERROR = 'SEND_CODE_ERROR';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';

const INITIAL_STATE = {
    smsSent: false,
    phoneNumber: null,
    isLoading: false
}



export default (state = INITIAL_STATE, action)=>{
    // //console.log("action.type",action.type);
    switch(action.type){
        case SEND_PHONE_NUMBER_STARTED:
            return {
                ...state,
                isLoading: true,
                phoneNumber: action.payload.phoneNumber
            }
        case SEND_PHONE_NUMBER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                smsSent: action.payload.authToken? false: action.payload.smsSent,
                authToken: action.payload.authToken,
                error: null
            }
        case SEND_PHONE_NUMBER_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                countryName: action.payload.countryName
            }
        default:
            return state;
    }
}