export const VIEW_TASK = 'VIEW_TASK';
export const EDIT_TASK_STARTED = 'EDIT_TASK_STARTED';
export const EDIT_TASK_ERROR = 'EDIT_TASK_ERROR';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';


const INITIAL_STATE = {
    isLoading: false,
    attachments: [undefined,undefined,undefined]
}


export default (state = INITIAL_STATE, action) => {
    // console.log("action.type",action.type);

    const {attachments} = state;
    switch (action.type) {
        case VIEW_TASK:
            return {
                ...state,
                taskSaved: undefined
            }
        case EDIT_TASK_STARTED:
            return {
                ...state,
                // attachment: action.payload.attachment[0]
            }
        case EDIT_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                taskSaved: action.payload.task
            }
        case CREATE_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                taskSaved: action.payload.task
            }
        case EDIT_TASK_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}