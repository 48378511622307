import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";

import {refreshRobotBatteriesSuccess} from "./refreshRobotBatteriesSuccess";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {refreshRobotBatteriesStarted} from "./refreshRobotBatteriesStarted";

export function refreshRobotBatteries(projectId, robotId, vehicleId) {
    let category = 'project';
    if (robotId)
        category = 'robot';
    else if (vehicleId)
        category = 'vehicle';


    return function (dispatch) {
        let url = HOST_URL+`/api/projects/refresh/robotreports?category=${category}&projectId=${projectId}`;
        if (robotId)
            url += '&value=' + robotId;
        else if (vehicleId)
            url += '&value=' + vehicleId;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        // dispatch(getRobotLogsStarted());

        dispatch(refreshRobotBatteriesStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(refreshRobotBatteriesSuccess(response.data.data.processId));
            }
            // else {
            //     dispatch(getRobotLogsError(new Error(response.data.message)));
            // }
        }).catch(error => {
            // debugger;
            // dispatch(refreshRobotBatteriesSuccess(Date.now()%1000));
            // return;
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            // dispatch(getRobotLogsError(error));
        });
    };
}