import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './weather.scss';
import windIcon from "./wind-icon.svg";
import tempIcon from "./temp-icon.svg";
import rainIcon from "./rain-icon.svg";
import humidityIcon from "./humidity-icon.svg";
import clockIcon from "./clock-icon.svg";
import {getCurrentWeather} from "../weather/getCurrentWeatherAction";
import {getLocalTime, TimeZone} from "../utils";


class WeatherFooterComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const projectId = this.props.project._id;
        this.props.actions.getCurrentWeather(projectId);
    }

    getWindDirection = (angle)=>{
        let directions = [
            'N',
            'NE',
            'E',
            'SE',
            'S',
            'SW',
            'W',
            'NW'
        ];

        let index = Math.round((angle % 360)/45);
        return directions[index];
    }




    render() {
        return (
            <div className="weather">
                <div className="items" >
                    <div className="item">
                        <img src={tempIcon} />
                        <div className="texts">
                            <div className="name">Temperature</div>
                            <div className="value">{this.props.currentWeather.temperature}°C</div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={humidityIcon} />
                        <div className="texts">
                            <div className="name">Humidity</div>
                            <div className="value">{this.props.currentWeather.humidity}%</div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={rainIcon} />
                        <div className="texts">
                            <div className="name">Rain</div>
                            <div className="value">{this.props.currentWeather.rain}mm</div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={windIcon} />
                        <div className="texts">
                            <div className="name">Wind</div>
                            <div className="value">{this.props.currentWeather.windSpeed} K/h, {this.getWindDirection(this.props.currentWeather.windDirection)}</div>
                        </div>
                    </div>

                    <div className="item local-time">
                        <img src={clockIcon} />
                        <div className="texts">
                            <div className="name">Local time</div>
                            <div className="value">{new TimeZone(this.props.project).getLocalTime(new Date(),undefined,undefined, true)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    return {
        ...state.weatherReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({getCurrentWeather}, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(WeatherFooterComponent);