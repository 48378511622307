import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './messages.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Search from "../search/search";
import {bindActionCreators} from "redux";
import {getMessages} from "./actions/getMessagesAction";
import {deleteMessage} from "./actions/deleteMessageAction";
import {messageSelected} from "./messageSelectedAction";
import addIcon from "./add-icon.svg";
import EditMessage from "../editMessage/editMessage";
import Loader from "../loader/loader";
import TableComponent from "../tableComponent/tableComponent";
import {getMessagesSuccess} from "./actions/getMessagesSuccessAction";
import Modal from "../modal/modal";
import {viewMessage} from "../editMessage/viewMessageAction";
import {withNavigation} from '../withNavigate';
import {TimeZone} from "../utils";


class MessagesComponent extends TableComponent {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        this.state = {
            showEditMessage: false,
            // selectedMessage: Object.assign({}, this.newMessage),
        };

        this.newMessage = {
            projectId: this.props.project._id,
            created: Date.now(),
            expiryDate: Date.now(),
            description: '',
            level: 1
        }

        this.timeZone = new TimeZone(this.props.project)
    }





    componentWillReceiveProps(nextProps) {
        if (nextProps.messages) {
            console.log(nextProps.messages[1]);
        }
    }


    componentDidMount() {

        this.props.actions.getMessages(this.props.project._id, null);
    }

    messageSelected = (message) => {
        this.props.actions.viewMessage();

        this.setState({
            selectedMessage: {...message, dueDate: new Date(message.dueDate), created: new Date(message.created)},
            showEditMessage: true
        });
    }

    closeEditMessage = (showEditMessage) => {

        this.setState({showEditMessage});
    }

    getData = () => this.props.messages;

    publishData = (data) => this.props.actions.getMessagesSuccess(data);

    getRowText = (message) => {
        if (!message.row)
            return;

        if (message.locationOnRow && message.locationOnRow >= 0)
            return `${message.row.number} (${Math.round(message.locationOnRow/1000)}m)`;
        else
            return message.row.number
    }


    dateChanged = dates => {
        const [start, end] = dates;
        this.setState({startDate: start, endDate: end});
        if (start && end)
            this.props.actions.getMessages(this.props.project._id, this.state.search, null, null, start, end);
    };

    searchClicked = (search) => {
        this.setState({search});
        this.props.actions.getMessages(this.props.project._id, search);
    }

    createNewMessage = () => {

        this.props.actions.viewMessage();

        this.setState({
            selectedMessage: Object.assign({}, this.newMessage),
            showEditMessage: true
        });
    }


    closeDeleteMessageModal = ()=>{

        this.setState({showDeleteMessage: false, showEditMessage:true});
    }

    deleteMessage = ()=>{

        this.setState({showDeleteMessage: false, showEditMessage:false});
        this.props.actions.deleteMessage(this.state.selectedMessage._id);
    }

    render() {
        return (
            <div className="page">
                {this.props.isLoading ? <Loader/> : null}

                {this.state.showEditMessage ?
                    <EditMessage closeEditMessage={() => { this.closeEditMessage(false)}}
                                                       message={this.state.selectedMessage}
                               deleteMessage={()=>{this.setState({showDeleteMessage: true, showEditMessage:false})}}
                    /> : null}

                {this.state.showDeleteMessage?<Modal title={`Delete this message?`} onClose={this.closeDeleteMessageModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={this.closeDeleteMessageModal}>Cancel</div>
                        <div className="button" onClick={this.deleteMessage}>Delete forever</div>
                    </div>

                </Modal>:null}

                <div className="menu-wrapper">
                    <Menu/>
                </div>
                <div className="content">
                    <PlantHeader title={"Messages"} secondaryText={this.props.project.projectName}/>
                    <div className="messages-table">
                        <div className="top">
                            <Search onSearch={this.searchClicked} placeholder="search messages"/>

                            {this.props.user.role !=="projectOwner" ?
                                <div className="download" onClick={this.createNewMessage}><img src={addIcon}/>NEW MESSAGE
                                </div> : null}
                        </div>
                        <table>
                            <tbody>

                            <tr>
                                {this.getHeaderTH('created', 'Created')}
                                {this.props.project.arrayType ==='solar-tracking'?this.getHeaderTH('vehicle.vehicleNumber', 'Vehicle Number'):null}
                                {this.getHeaderTH('description', 'Description')}
                                {this.getHeaderTH('expiryDate', 'Expiry Date')}
                                {this.getHeaderTH('level', 'Level')}
                            </tr>
                            {this.props.messages.map((message, index) => {
                                return <tr key={index} onClick={() => this.messageSelected(message)}>
                                    <td className="date">{message.createdBy?.fullName}<br/>{this.timeZone.getLocalTime(message.created, false, true)}</td>
                                    {this.props.project.arrayType ==='solar-tracking'?<td>{message.vehicleId? message.vehicleId.vehicleNumber:''}</td>:null}
                                    <td>{message.description}</td>
                                    <td>{this.timeZone.getLocalTime(message.expiryDate, false, true)}</td>

                                    

                                    <td>{message.level}</td>
                                </tr>

                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.messagesReducer,
        user: state.indexReducer.user,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({getMessages, viewMessage, messageSelected, getMessagesSuccess, deleteMessage}, dispatch),
    };
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(MessagesComponent));