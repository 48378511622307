import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {deleteMessageError} from "./deleteMessageErrorAction";
import {deleteMessageSuccess} from "./deleteMessageSuccessAction";

export function deleteMessage(id) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/messages/' + id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        try {
            const response = await axios.delete(url, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(deleteMessageSuccess(response.data.message));
            } else {
                dispatch(deleteMessageError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(deleteMessageError(error));
        }
        ;
    };
}