import {ADD_ATTACHMENT_SUCCESS} from "./createProjectReducer";

export const EDIT_PROJECT_STARTED = 'EDIT_PROJECT_STARTED';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const GET_ATTACHMENTS_STARTED = 'GET_ATTACHMENTS_STARTED';
export const GET_ATTACHMENTS_ERROR = 'GET_ATTACHMENTS_ERROR';
export const GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS';
export const DELETE_ATTACHMENT_LOCALLY = 'DELETE_ATTACHMENT_LOCALLY';
export const ADD_ATTACHMENT_LOCALLY = 'ADD_ATTACHMENT_LOCALLY';



const INITIAL_STATE = {
    isLoading: false,
    attachments: [],
    deleted: [],
    added: []
}


export default (state = INITIAL_STATE, action) => {
    const {attachments} = state;


    switch (action.type) {
        case EDIT_PROJECT_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case EDIT_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                project: action.payload.project,
                isSaved: true
            }
        case EDIT_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        case GET_ATTACHMENTS_SUCCESS:
            console.log("GET_ATTACHMENTS_SUCCESS",action.payload.attachments);
            return {
                ...state,
                isLoading: false,
                attachments: action.payload.attachments
            }
        case DELETE_ATTACHMENT_LOCALLY:
            const {deleted} = state;
            deleted.push(attachments[action.payload.index]);
            attachments[action.payload.index] = {};
            return {
                ...state,
                attachments,
                deleted
            }
        case ADD_ATTACHMENT_LOCALLY:
            console.log("action.payload.attachment",action.payload.attachment);
            const {added} = state;
            added.push(attachments[action.payload.index]);
            attachments[action.payload.index] = action.payload.attachment;

            return {
                ...state,
                attachments,
                added
            }
        case ADD_ATTACHMENT_SUCCESS:
            attachments[action.payload.index] = action.payload.attachment;
            return {
                ...state,
                attachments,
            }
        default:
            return state;
    }
}