// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refresh-button {
  margin-right: 8px;
  padding: 0 0px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: background-color 0.2s ease; }

.refresh-button:hover {
  background-color: #f3f4f6; }

.refresh-icon {
  width: 18px;
  height: 18px;
  color: #4b5563; }

.refresh-icon.spinning {
  animation: spin 1s linear infinite; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/spinningRefresh/spinningRefresh.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,sCAAsC,EAAA;;AAGxC;EACE,yBAAyB,EAAA;;AAG3B;EACE,WAAW;EACX,YAAY;EACZ,cAAc,EAAA;;AAGhB;EACE,kCAAkC,EAAA;;AAGpC;EACE;IACE,uBAAuB,EAAA;EAEzB;IACE,yBAAyB,EAAA,EAAA","sourcesContent":[".refresh-button {\n    margin-right: 8px;\n    padding: 0 0px;\n    border-radius: 50%;\n    cursor: pointer;\n    border: none;\n    background: transparent;\n    transition: background-color 0.2s ease;\n  }\n  \n  .refresh-button:hover {\n    background-color: #f3f4f6;\n  }\n  \n  .refresh-icon {\n    width: 18px;\n    height: 18px;\n    color: #4b5563;\n  }\n  \n  .refresh-icon.spinning {\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
