import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editErrorSuccess} from "./editErrorSuccessAction";
import {editErrorError} from "./editErrorErrorAction";
import {editErrorStarted} from "./editErrorStartedAction";
import {uploadAttachment} from "../../utils";

export function editError(params, errorId) {

    return async function (dispatch) {
        let url = HOST_URL + '/api/errors/' + errorId;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editErrorStarted());

        try {
            const response = await axios.patch(url, params, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(editErrorSuccess(response.data.errorRecord));
            } else {
                dispatch(editErrorError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(editErrorError(error));
        }

    };
}