import React, {Component, PropTypes, useEffect, useRef, useState} from 'react';
import './repeaters.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import DatePicker from "react-datepicker";
import 'react-dropdown/style.css';

import Loader from "../loader/loader";
import {LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_USER_KEY} from "../../consts";
import Modal from "../modal/modal";
import { useNavigate } from 'react-router-dom';
import {TimeZone} from "../utils";
import SearchComponent from "../search/search";
import ApiHandler from "../api";
import ErrorTable from "../dashboard/errors-table";


const CHARGING_MARK_LIMIT = 16.4;

const apiHandler = new ApiHandler();
const RepeatersComponent = (props) => {
    const navigate = useNavigate();
    const [project, setProject] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT)));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)));
    const [date, setDate] = useState(new Date());
    const [page, setPage] = useState(0);
    const [repeaters, setRepeaters] = useState([]);
    const [timeZone, setTimeZone] = useState(new TimeZone(project));
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [deleteRepeater, setDeleteRepeater] = useState();
    const pageRef = useRef();

    useEffect(() => {
        if (!project) {
            navigate("/projects");
            return;
        }

        setProject(project);
        setUser(user);

        setTimeZone(new TimeZone(project));

        window.addEventListener("scroll", handleNavigation);
    }, []);

    useEffect(() => {
        handleRepeatersApi();
    },[project, date, page, search]);

    const handleRepeatersApi = async () => {
        setIsLoading(true);
        try {
            const result = await apiHandler.getRepeaters({
                projectId: project._id,
                timeZone: project.timezone.timeZoneId,
                date,
                page: 0,
                search
            });

            setRepeaters(result.repeaters);
            setTotal(result.total);
        } catch (err) {
            alert("Can't get repeaters data");
        } finally {
            setIsLoading(false);
        }
    }


    const dateChanged = date => {
        console.log("date", date)
        setDate(date);
        setPage(0);
    }

    const handleNavigation = (e) => {
        const projectId = project._id;
        const hasMoreToLoad = repeaters.length < total;
        if (window.scrollY + 2000 > pageRef.offsetHeight && hasMoreToLoad && !isLoading) {
            console.log("getting next page", page + 1);
            setPage(page + 1);
        }
    }

    const onSearchChange = (search) => {
        setSearch(search);
    }




    const isChargingClass = (item) => {
        if (!item) {
            return;
        }

        if (item.voltage > CHARGING_MARK_LIMIT)
            return;

        if (item.isCharging)
            return 'blue';
        else
            return 'red';

    }

    const isChargingText = (item) => {

        if (!item || item.voltage > CHARGING_MARK_LIMIT)
            return;

        if (item.isCharging)
            return <span className="small">Charging</span>
        else
            return <span className="small">Not charging</span>
    }

    const closeModal = () => setDeleteRepeater();

    const deleteRepeaterConfirmed = async () => {
        try {
            const result = await apiHandler.deleteRepeater(deleteRepeater._id);
            const index = repeaters.findIndex(item => item._id ===deleteRepeater._id);
            repeaters.splice(index, 1);
            setRepeaters([...repeaters]);
        } catch (err) {
            console.log(err);
            alert("Can't delete repeater");
        }
        closeModal();
    }


        return (
            <div className="page" ref={pageRef}>
                {isLoading && page ===0 && <Loader/>}
                <div className="menu-wrapper">
                    <Menu/>
                </div>
                {deleteRepeater ? <Modal
                    title={`Delete repeater ${deleteRepeater.repeaterNumber}?`}
                    onClose={closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={closeModal}>Cancel</div>
                        <div className="button" onClick={deleteRepeaterConfirmed}>Continue</div>

                    </div>

                </Modal> : null}


                <div className="content">
                    <PlantHeader title="Repeaters"
                                 secondaryText={project.projectName}/>
                    <div className="repeaters-main">
                        <div className="top">

                            <div className="date-wrapper">
                                <SearchComponent placeholder="Search" searchChanged={onSearchChange}
                                                 onSearch={onSearchChange}/>
                                {/*<div className="border-line"></div>*/}
                            </div>

                            <div className="date-wrapper">
                                <DatePicker
                                    selected={date}
                                    onChange={dateChanged}
                                    startDate={date}
                                    maxDate={new Date()}
                                    shouldCloseOnSelect={true}
                                    customInput={<span>{timeZone.getLocalTime(date, true)}</span>}
                                />
                            </div>

                        </div>
                        <div className="total-results">Total results: {total}</div>
                        <div className="left">
                        <table>
                            <thead>
                            <tr>
                                <th>Repeater</th>
                                <th>8:00 AM Battery</th>
                                <th>12:00 PM Battery</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {repeaters.map((item, index) =>
                                <tr key={index}>
                                    <td className="repeater-name">{item.repeaterNumber} {item.rpi ? `(${item.rpi})` : ''}</td>

                                    <td className={isChargingClass(item.report1)}>
                                        {item.report1 ? <div>{item.report1.voltage}V</div>  : ''}
                                        {isChargingText(item.report1)}
                                    </td>
                                    <td className={isChargingClass(item.report2)}>
                                        {item.report2 ? <div>{item.report2.voltage}V</div> : ''}
                                        {isChargingText(item.report2)}
                                    </td>

                                    <td className="actions">
                                        {user.role ==="admin"  || user.role ==="countryAdmin"?<div onClick={() => setDeleteRepeater(item)}>Delete</div>:null}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        </div>
                        <div className="right-column">
                            <ErrorTable
                                project={project}
                                user={user}
                                repeaters={true}
                                />
                        </div>
                    </div>

                </div>
            </div>
        )


}

export default RepeatersComponent;