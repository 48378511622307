import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editTaskSuccess} from "./editTaskSuccessAction";
import {editTaskError} from "./editTaskErrorAction";
import {editTaskStarted} from "./editTaskStartedAction";

export function editTask(params, id) {


    return async function (dispatch) {
        let url = HOST_URL + '/api/tasks/' + id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editTaskStarted());

        try {
            const response = await axios.patch(url, params, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(editTaskSuccess(response.data.task));
            } else {
                dispatch(editTaskError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(editTaskError(error));
        }
        ;
    };
}