import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {getVehicleLogsError} from "./getVehicleLogsErrorAction";
import {getVehicleLogsStarted} from "./getVehicleLogsStartedAction";
import {setVehicleSettingsSuccess} from "./setVehicleSettingsSuccessAction";

export function setVehicleSettings(vehicleId, settings) {

    return function (dispatch) {
        let url = HOST_URL+`/api/vehicles/${vehicleId}/settings`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getVehicleLogsStarted());

        return axios.patch(url, settings,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(setVehicleSettingsSuccess(response.data.vehicle));
            }
            else {
                dispatch(getVehicleLogsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getVehicleLogsError(error));
        });
    };
}