import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {setRobotSettingsSuccess} from "../robot/actions/setRobotSettingsSuccessAction";

export function changeStatus(robotId, currentStatus) {

    return function (dispatch) {
        let url = HOST_URL+`/api/robots/${robotId}/settings`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        return axios.patch(url, {currentStatus},{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                console.log("changed robot status to", currentStatus);
                dispatch(setRobotSettingsSuccess(response.data.robot));
            }
            else {
                console.log("cannot change robot status to", currentStatus);
            }
        }).catch(error => {
            console.log(error.message);
        });
    };
}