import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getDailyActivitySuccess} from "./getDailyActivitySuccessAction";
import {getDailyActivityError} from "./getDailyActivityErrorAction";
import {getDailyActivityStarted} from "./getDailyActivityStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function getDailyActivity(projectId, startDate, endDate, page) {

    return function (dispatch) {
        let url = `${HOST_URL}/api/projects/${projectId}/daily-activity?startDate=${startDate.getTime()}&endDate=${endDate? endDate.getTime():''}&page=${page}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getDailyActivityStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // dispatch(getDailyActivitySuccess(response.data.data));
                dispatch(getDailyActivitySuccess(response.data.result, response.data.loadMore, page));

            }
            else {
                dispatch(getDailyActivityError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getDailyActivityError(error));
        });
    };
}