import React, { useEffect, useRef, useState } from "react";
import "./dashboard.scss";
import ApiHandler from './api';
import { LOCAL_STORAGE_PROJECT } from "../../consts";
import { Bar } from "react-chartjs-2";
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import DatePicker from "react-datepicker";

const cleanedAreaDataInit = {
    labels: [],
    datasets: [{
        type: "bar",
        barPercentage: 0.4,
        label: 'Meters cleaned',
        backgroundColor: '#bbbfc0',
        hoverBackgroundColor: '#555e62',
        // hoverBorderColor
        data: []
    }]
};


const areaCleanedOptionsInit = {
    plugins: {
        legend: {
            display: false
        },
    },
    maintainAspectRatio: false,
    legend: {
        display: false,
        position: 'top',
    },
    responsive: true,
    // tooltips: {
    //     mode: 'label'
    // },
    elements: {
        line: {
            fill: false
        }
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false, // This will remove the grid lines for the X axis
            },
        },
        y: {
            stacked: false,
            grid: {
                display: false, // This will remove the grid lines for the X axis
            },
            type: 'linear',
            position: 'left',
            id: 'y-axis-1',

            labels: {
                show: true
            },
            ticks: {
                min: 0,
            }
        },
    },
};


const DailyCleaning = ({width, timeZone}) => {

    let now = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const [areaCleanedStartDate, setAreaCleanedStartDate] = useState(lastMonth);
    const [areaCleanedEndDate, setAreaCleanedEndDate] = useState(now);
    const [project, setProject] = useState();
    const [cleanedAreaData, setCleanedAreaData] = useState(cleanedAreaDataInit);
    const [areaCleanedOptions, setAreaCleanedOptions] = useState(areaCleanedOptionsInit);
    const apiHandler = new ApiHandler();

    const areaCleanedDateChanged = dates => {
        const [start, end] = dates;
        setAreaCleanedStartDate(start);
        setAreaCleanedEndDate(end);
    };


    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        setProject(project);
    }, []);


    useEffect(() => {
        if (!project)
            return;

        handleData();
    }, [project, areaCleanedStartDate, areaCleanedEndDate]);

    const handleData = async () => {
        const result = await apiHandler.getCleanedArea(project._id, areaCleanedStartDate, areaCleanedEndDate);
        

        cleanedAreaData.labels = result.reports.map(item => timeZone.getLocalTime(item.date, true).split("/").slice(0, 2).join("/")).reverse();
        cleanedAreaData.datasets[0].data = result.reports.map(item => Math.round(item.cleaningDistance)).reverse();

        areaCleanedOptions.scales.y.max = Math.round(Math.max(...cleanedAreaData.datasets[0].data) * 1.1);

        setCleanedAreaData(cleanedAreaData);
        setAreaCleanedOptions({ ...areaCleanedOptions });
    }


    return (<div>
        <div className="title-wrapper">
            <div className="title">DAILY CLEANING</div>
            <DatePicker
                selected={areaCleanedStartDate}
                onChange={areaCleanedDateChanged}
                startDate={areaCleanedStartDate}
                endDate={areaCleanedEndDate}
                selectsRange
                shouldCloseOnSelect={false}
                // customInput={<this.AreaDateCustomInput/>}
                customInput={<DateCustomInput
                    logsStartDate={areaCleanedStartDate}
                    logsEndDate={areaCleanedEndDate} />}
            />
        </div>
        <div className="wrapper">
            {!!cleanedAreaData.datasets[0].data.length && <Bar
                data={cleanedAreaData}
                options={areaCleanedOptions}
                // plugins={plugins}
                height={170}
                width={width}
            />}
        </div>
        <div className="labels">
            <div className="label">

                <span className="mark3 grey"></span>
                <span className="text">Total area cleaned per day</span>

            </div>
        </div>
    </div>)
}

export default DailyCleaning;