import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getProjectDetailsSuccess} from "./getProjectDetailsSuccessAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getProjectDetails(projectId) {

    return function (dispatch) {
        let url = HOST_URL+`/api/projects/${projectId}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        // dispatch(getRobotLogsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                const project = response.data.project;
                project.groups.forEach(g=>g.hasRobots = false);
                project.groups[0].hasRobots =true;
                project.robots.forEach(r=>project.groups[r.groupNumber-1].hasRobots = true);
                // console.log("project.groups",project.groups)
                dispatch(getProjectDetailsSuccess(project));
            }
            // else {
            //     dispatch(getRobotLogsError(new Error(response.data.message)));
            // }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                dispatch(unauthorizedError());
            // console.log(JSON.stringify(error));
            // dispatch(getRobotLogsError(error));
        });
    };
}