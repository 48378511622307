import {GET_LIVE_REPORTS_SUCCESS} from "./dashboardReducer";


export const GET_ROBOTS_AND_VEHICLES_STARTED = 'GET_ROBOTS_AND_VEHICLES_STARTED';
export const GET_ROBOTS_AND_VEHICLES_ERROR = 'GET_ROBOTS_AND_VEHICLES_ERROR';
export const GET_ROBOTS_AND_VEHICLES_SUCCESS = 'GET_ROBOTS_AND_VEHICLES_SUCCESS';
export const GET_ROBOTS_CSV_SUCCESS = 'GET_ROBOTS_CSV_SUCCESS';
export const GET_ROBOTS_CSV_ERROR = 'GET_ROBOTS_CSV_ERROR';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

export const DRY_SUCCESS = 'DRY_SUCCESS';

export const UPDATE_ITEMS = 'UPDATE_ITEMS';


const INITIAL_STATE = {
    items: [],
    isLoading: false,
    liveReports: [],
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case UPDATE_ITEMS:
            return {
                ...state,
                items: action.payload.items
            };
        case GET_ROBOTS_AND_VEHICLES_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case DRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_SUCCESS:
            const {robot} = action.payload;
            let newItems = [...state.items];
            const index = newItems.findIndex(item=> item.robotId ===robot.robotId);
            newItems.splice(index, 1);

            return {
                ...state,
                isLoading: false,
                items: newItems
            };

        case GET_ROBOTS_AND_VEHICLES_SUCCESS:
            let items = [];

            items = items.concat(action.payload.robots);

            if (!action.payload.replaceExisting)
                items = state.items.concat(items);

            const json = {
                ...state,
                isLoading: false,
                items,
            };

            if (action.payload.statistics && Object.values(action.payload.statistics).length)
                json.statistics = action.payload.statistics;
            return json;
        case GET_ROBOTS_AND_VEHICLES_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_ROBOTS_CSV_SUCCESS:
            return {
                ...state,
                csv: action.payload.csv,
                isLoading: false
            }
        case GET_ROBOTS_CSV_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        case GET_LIVE_REPORTS_SUCCESS:
            return {
                ...state,
                liveReports:  action.payload.liveReports
            };

        default:
            return state;
    }
}