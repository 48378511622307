import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getRobotSettingsStarted} from "./getRobotSettingsStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {downloadRobotSettingsSuccess} from "./downloadRobotSettingsSuccessAction";

export function downloadRobotSettingsCsv(projectId, projectName) {

    return function (dispatch) {
        let url = HOST_URL + '/api/robots/more/settings/export?projectId=' + projectId;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotSettingsStarted());

        return axios.get(url, {headers: {Authorization: authToken}}).then((response) => {
            console.log("hmmmm");
            const element = document.createElement("a");
            const file = new Blob([response.data], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `${projectName}_robotSettings.csv`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            dispatch(downloadRobotSettingsSuccess());

        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            // dispatch(getRobotSettingsError(error));
        });
    };
}