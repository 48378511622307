import {SET_VEHICLE_SETTINGS_SUCCESS} from "../../reducers/vehicleReducer";
import {LOCAL_STORAGE_ROBOT} from "../../consts";


export const setVehicleSettingsSuccess = (vehicle) =>{
    vehicle.vehicleId = vehicle._id;
    localStorage.setItem(LOCAL_STORAGE_ROBOT, JSON.stringify(vehicle));
    console.log("saving vehicle",vehicle);
    return {
        type: SET_VEHICLE_SETTINGS_SUCCESS,
        payload: {vehicle}
    }
}