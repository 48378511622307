import React, {useEffect, useState} from 'react';
import "./tag.scss";

const TagInput = ({initialTags, onTagsChanged,placeholder}) => {
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setTags(initialTags);
    },[initialTags]);

    const handleInputChange = (event) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setInputValue(numericValue);
    };

    const handleKeyUp = (event) => {
        console.log("handleKeyUp",event.key,"inputValue",inputValue)
        if (event.key ==='Enter' || event.key ===',') {
            event.preventDefault();
            addTag();
        }
        else if (event.key ==='Backspace' && inputValue.length ===0 && tags.length > 0) {
            removeTag(tags[tags.length - 1]);
        }
    };

    const addTag = () => {
        if (inputValue.trim() !=='') {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            setInputValue('');
            onTagsChanged(newTags);
        }
    };

    const removeTag = (tag) => {
        const newTags = tags.filter((t) => t !==tag);
        setTags(newTags);
        onTagsChanged(newTags);
    };

    return (
        <div className="tag-input">
            {tags.map((tag, index) => (
                <span key={index} className="tag" onClick={() => removeTag(tag)}>
          {tag}
                    {/*&times;*/}
        </span>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyUp}
                placeholder={placeholder || 'Enter value'}
            />
        </div>
    );
};

export default TagInput;