import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import DashboardComponent from './components/dashboard/dashboard';
import ProjectsComponent from './components/projects/groups';
import ImageComponent from './components/image/image'
import SignInComponent from './components/signin/signin';
import RowsComponent from './components/robotSettings/robotSettings';
import RobotsAndVehiclesComponent from './components/robotsAndVehicles/robotsAndVehicles';
import RepeatersComponent from './components/repeaters/repeaters';
import ErrorsComponent from './components/errors/errors';
import ErrorResolutionComponent from './components/errorResolution/errorResolution';

import TasksComponent from './components/tasks/tasks';
import MessagesComponent from './components/messages/messages';
import AvailabilityComponent from './components/availability/availability';
import DailyActivityComponent from './components/daily-activity/daily-activity';
import WeatherComponent from './components/weather/weather';
import SettingsComponent from './components/settings/settings';
import SettingsAdvancedComponent from './components/settings/advanced';
import RobotComponent from './components/robot/robot';
import VehicleComponent from './components/vehicle/vehicle';
import UsersComponent from './components/users/users';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { Route, Routes } from 'react-router'
import RobotSettingsComponent from "./components/robotSettings/robotSettings";
import VehicleDashboard from "./components/vehicles/dashboard/dashboard";
import VehicleList from "./components/vehicles/vehicles-list/vehicles";
import { BrowserRouter } from 'react-router-dom';
import store from './store'
import RobotDailyReportsComponent from './components/robotDailyReport/robotDailyReport';



if (window.location.protocol !=='https:' && !window.location.host.includes('localhost')) {
    window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
    <div>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<DashboardComponent />} />
                <Route path="settings" element={<SettingsComponent />} />
                <Route path="settings/advanced" element={<SettingsAdvancedComponent />} />
                <Route path="weather" element={<WeatherComponent />} />
                <Route path="errors" element={<ErrorsComponent />} />
                <Route path="error-resolution" element={<ErrorResolutionComponent />} />
                
                <Route path="tasks" element={<TasksComponent />} />
                <Route path="messages" element={<MessagesComponent />} />
                <Route path="availability" element={<AvailabilityComponent />} />
                <Route path="daily-activity" element={<DailyActivityComponent />} />
                <Route path="users" element={<UsersComponent />} />
                <Route path="robots" element={<RobotsAndVehiclesComponent />} />
                <Route path="repeaters" element={<RepeatersComponent />} />
                <Route path="robot" element={<RobotComponent />} />
                <Route path="vehicle" element={<VehicleComponent />} />
                <Route path="vehicles/dashboard" element={<VehicleDashboard />} />
                <Route path="vehicles/list" element={<VehicleList />} />
                <Route path="rows" element={<RowsComponent />} />
                <Route path="robotSettings" element={<RobotSettingsComponent />} />
                <Route path="dashboard" element={<DashboardComponent />} />
                <Route path="signin" element={<SignInComponent />} />
                <Route path="projects" element={<ProjectsComponent />} />
                <Route path="reports" element={<RobotDailyReportsComponent />} />                
                <Route path="reports/:reportType" element={<RobotDailyReportsComponent />} />                
                <Route path="projects/:projectGroupId" element={<ProjectsComponent />} />
                <Route path="images/:id" element={<ImageComponent />} />
            </Routes>

        </BrowserRouter>
    </div>
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();