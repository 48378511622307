// customHook.js
import { useNavigate } from 'react-router-dom';

export function withNavigation(Component) {
  return function NavigationComponent(props) {
    const navigate = useNavigate();
    const oldNavigate = (path) => {
      window.location.href = path;
    };

    return <Component {...props} navigate={navigate} oldNavigate={oldNavigate}/>;
  };
}
