import axios from "axios";

import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getRobotsSuccess} from "./getRobotsSuccessAction";
import {getRobotsError} from "./getRobotsErrorAction";
import {getRobotsStarted} from "./getRobotsStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {getDateStr} from "../../utils";

export function getRobotsAndVehicles({
                                         projectId, timeZone,
                                         search,
                                         date,
                                         page,
                                         filter,
                                         groupFilter,
                                         modeFilter,
                                         statusFilter}) {

    return async function (dispatch) {
        let url = HOST_URL + '/api/vehicles-and-robots?projectId=' + projectId;
        if (search)
            url += '&search=' + search;
        if (date)
            url += `&date=${getDateStr(date)}`;
        if (filter)
            url += `&filter=${filter}`;
        if (groupFilter)
            url += `&groupFilter=${groupFilter}`;
        if (modeFilter)
            url += `&modeFilter=${modeFilter}`;

        if (statusFilter)
            url += `&statusFilter=${statusFilter}`;
        dispatch(getRobotsStarted());

        try {
            // const result = await getData(dispatch, url, 0);
            const result = await getData(dispatch, url, page);
        } catch (error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotsError(error));
        }
    };
}

const getData = async (dispatch, url, page)=>{
    let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    url += '&page='+page;
    const response = await axios.get(url, {headers: {Authorization: authToken}});
    if (response.data.success) {
        dispatch(getRobotsSuccess(response.data.data.robots,page ===0, response.data.data.statistics));
    } else if (page ===0) {
        dispatch(getRobotsError(new Error(response.data.message)));
    }

    return response.data.data;
}