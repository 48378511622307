import {PROJECT_SELECTED} from "../../reducers/indexReducer";
import {LOCAL_STORAGE_PROJECT} from "../../consts";


export const projectSelected = (project) =>{
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
    return {
        type: PROJECT_SELECTED,
        payload: {project}
    }
}