import {GET_ROBOT_SETTINGS_SUCCESS} from "./robotSettingsReducer";
export const CLEANING_PLAN_STARTED = 'CLEANING_PLAN_STARTED';
export const CLEANING_PLAN_ERROR = 'CLEANING_PLAN_ERROR';
export const CLEANING_PLAN_SUCCESS = 'CLEANING_PLAN_SUCCESS';
export const SAVE_CLEANING_PLAN_SUCCESS = 'SAVE_CLEANING_PLAN_SUCCESS';
export const DOWNLOAD_CLEANING_PLAN_SUCCESS = 'DOWNLOAD_CLEANING_PLAN_SUCCESS';
export const ADD_EMPTY_ROW = 'ADD_EMPTY_ROW';


const INITIAL_STATE = {
    isLoading: false,
    cleaningPlan: [],
    rows: [],
    isSaved: false
}


export default (state = INITIAL_STATE, action) => {
    // console.log("action.type",action.type);
    switch (action.type) {
        case GET_ROBOT_SETTINGS_SUCCESS:
            return {
                ...state,
                rows: action.payload.rows
            };
        case DOWNLOAD_CLEANING_PLAN_SUCCESS:
            return {
                ...state,
                isLoading:false
            }

        case SAVE_CLEANING_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case CLEANING_PLAN_STARTED:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            }
        case CLEANING_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cleaningPlan: action.payload.cleaningPlan || []
            }
        case CLEANING_PLAN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        case ADD_EMPTY_ROW:
            let {cleaningPlan} = state;

                cleaningPlan = [{
                    rowNumber: '',
                    day: ''
                }].concat(cleaningPlan);
            return {
                ...state,
                cleaningPlan
            }
        default:
            return state;
    }
}