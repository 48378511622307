import Loader from "../../loader/loader";
import Menu from "../../menu/menu";
import PlantHeader from "../../plantHeader/plantHeader";
import React, {useEffect, useState} from "react";
import "./vehicles.scss";
import {
    LOCAL_STORAGE_PROJECT,
    LOCAL_STORAGE_USER_KEY,
} from "../../../consts";

import CleaningControlVehicleNew from "../cleaningControl/cleaningControlVehicleNew";
import ApiHandler from "../../api";
import Dropdown from "react-dropdown";
import TagInput from "../tagInput/tagInput";
import Switch from "react-switch";
import ErrorTable from "../../dashboard/errors-table";
import MessageTable from "../../dashboard/message-table";
import VehicleSettings from "../vehicle-settings";
import RobotSettings from "../robotSettings";

const apiHandler = new ApiHandler();

const VehicleList = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();
    const [user, setUser] = useState();
    const [currentStatus, setCurrentStatus] = useState('parking');
    const [vehicleList, setVehicleList] = useState([]);
    const [vehicle, setVehicle] = useState();


    let timeout;

    const getDoubleDigits = num => num < 10 ? `0${num}` : num;
    const getTimeStr = (time) => {
        if (!time)
            return undefined;

        return `${getDoubleDigits(time.hours)}:${getDoubleDigits(time.minutes)}`;
    }


    const handleVehiclesApi = async (project) => {
        try {
            const data = await apiHandler.getDashboard(project._id);
            setVehicleList(data);
            setVehicle(data[0].vehicle);
            /*

             */
        } catch (err) {
            alert("Can't get project data");
        }
    }


    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);

        handleVehiclesApi(project)
    }, []);

    useEffect(() => {
        if (vehicle && vehicle.settings.cleaningMinTime && !vehicle.settings.cleaningMinTimeStr) {
            setVehicle((vehicle) => ({
                ...vehicle,
                settings: {
                    ...vehicle.settings,
                    cleaningMinTimeStr: getTimeStr(vehicle.settings.cleaningMinTime),
                    cleaningMaxTimeStr: getTimeStr(vehicle.settings.cleaningMaxTime)
                }
            }));
        }
    }, [vehicle]);


    const onVehicleSelected = (item) => {
        const v = vehicleList.find(v => v.vehicle._id ===item.value);
        setVehicle(v.vehicle);
    }

    const onCleaningPlanChanged = (plan) => {
        if (timeout)
            clearInterval(timeout);

        timeout = setTimeout(async () => {
            try {
                await apiHandler.updateVehicleSettings(vehicle._id, project._id, {cleaningPlan: plan.map(p => parseInt(p))});
            } catch (err) {
                alert("Can't update vehicle settings");
            }
        }, 5000);
    }

    const robotSwitchChanged = async (checked, index) => {

        const {settings} = vehicle;
        settings.robots[index].enabled = checked;
        setVehicle({...vehicle, settings});

        try {
            await apiHandler.updateVehicleSettings(vehicle._id, project._id, settings);
        } catch (err) {
            alert("Can't update vehicle settings");
        }
    }






    const onSettingsChanged = (name, value) => {
        const {settings} = vehicle;
        settings[name] = value;
        if (name ==='cleaningMaxTimeStr' || name ==='cleaningMinTimeStr') {
            const splits = value.split(':').map(n => parseInt(n));
            if (splits.length ===2 && Number.isInteger(splits[0]) && Number.isInteger(splits[1])) {
                settings[name.replace("Str", "")] = {
                    hours: splits[0],
                    minutes: splits[1]
                }
            }

        }

        setVehicle({...vehicle, settings});
    }

    return (
        <div className="page">
            {isLoading && <Loader/>}


            <div className="menu-wrapper">
                <Menu/>
            </div>
            <div className="content">
                <PlantHeader title="Vehicles"
                             secondaryText={project ? project.projectName : ''}/>

                <div className="vehicle-selection">
                    {vehicle ? <Dropdown
                        options={vehicleList.map(item => ({
                            label: `Vehicle ${item.vehicle.vehicleNumber}`,
                            value: item.vehicle._id
                        }))}
                        onChange={onVehicleSelected}
                        value={vehicle._id}
                        className='dropdown'
                        controlClassName='control'
                    /> : null}
                </div>

                <div className="vehicles">
                    <div className="left">
                        {project ? <div className="control-panel">
                            <div className="title">CONTROL PANEL</div>
                            <div className="status">
                                {/*{project.connectionStatus ==="offline" ?*/}
                                {/*    <span className="offline"><span className="circle"></span>offline</span>*/}
                                {/*    :*/}
                                {/*    <span className="online"><span className="circle"></span>online</span>*/}
                                {/*}*/}
                                <span className="online"><span className="circle"></span>online</span>
                            </div>

                            <CleaningControlVehicleNew
                                project={project}
                                vehicle={vehicle}
                                user={user}
                                currentStatus={currentStatus}
                                offline={false}
                            />

                            <div className="separator"></div>
                        </div> : null}

                        {vehicle ? <div className="tables-grid">
                            <div className="table1">

                                <div className="head">
                                    <div className="title">
                                        <span className="text">WORK PLAN</span>
                                    </div>
                                    <div className="separator"></div>
                                </div>


                                <div className="label">Tables</div>
                                <div className="table-content tags"><TagInput placeholder="Enter table number"
                                                                              initialTags={vehicle.settings.cleaningPlan}
                                                                              onTagsChanged={onCleaningPlanChanged}/>
                                </div>

                                <div className="label">Robots</div>
                                <div className="table-content">
                                    <div className="vehicle-robots">
                                        {vehicle.settings.robots.map((robot, index) => <div className="robot">
                                            <span className="name">Robot {robot.robotNumber}</span>
                                            <Switch
                                                className="switch"
                                                onColor={'#05b2aa'}
                                                onChange={(checked) => robotSwitchChanged(checked, index)}
                                                checked={robot.enabled}/>
                                        </div>)}
                                    </div>
                                </div>
                            </div>

                            <div className="table2">
                                <VehicleSettings
                                    vehicle={vehicle}
                                    project={project}
                                    user={user}
                                    onSettingsChanged={onSettingsChanged}
                                />
                            </div>

                            <div className="table3">
                                <RobotSettings
                                    vehicle={vehicle}
                                    project={project}
                                    user={user}
                                    onSettingsChanged={onSettingsChanged}
                                    />
                            </div>
                        </div> : null}
                    </div>

                    <div className="right">
                        {user ? <ErrorTable
                            className="vehicle-errors"
                            project={project}
                            vehicle={vehicle}
                            user={user}
                        /> : null}

                        {project && vehicle ? <MessageTable
                            project={project}
                            vehicle={vehicle}
                            user={user}
                            className=''
                        /> : null}
                    </div>

                </div>
            </div>
        </div>

    )
}

export default VehicleList;