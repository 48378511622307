import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './editUser.scss';
import Modal from "../modal/modal";
import {editUser} from "./editUserAction";
import {addUser} from "./addUserAction";
import {COUNTRIES} from "../../consts";
import deleteUserIcon from "./delete-user-icon.svg";
import deleteIcon from "./delete-icon.svg";
import {deleteUser} from "./deleteUserAction";
import {getProjects} from "../projects/actions/getProjectsAction";



class EditUserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            projectGroups: [],
            countryAdminCodes: [],
            ...this.props.user,
        };
    }

    componentDidMount() {
        this.props.actions.getProjects();
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.setState({...nextProps.user});
        }

        if (nextProps.err && !this.props.err) {
            console.log(nextProps.err);

            let text = Object.values(nextProps.err.response.data).join(", ");
            alert(text);
        }
    }

    onChange = (value, param)=>{
        let state = {};

        state[param]  = value;

        this.setState(state);
    }

    onSubmit = ()=>{
        let missing = [];

        if (!this.state.fullName)
            missing.push('full name');

        if (!this.state.phoneNumber)
            missing.push('phone number');

        if (!this.state.role)
            missing.push('role');

        if (!this.state.email)
            missing.push('email');

        if (missing.length) {
            alert(`The following ${missing.length > 1?'fields are':'field is'} mandatory: ${missing.join(", ")}`);
            return;
        }

        let user = {...this.state};
        let params = {...this.state};

        if (params.projects)
            params.projects = params.projects.map(p=>p.id);

        if (params.projectGroups)
            params.projectGroups = params.projectGroups.map(p=>p.id);

        if (this.props.user) {
            this.props.actions.editUser(params, user);            
        }
        else {
            this.props.actions.addUser(params);
        }
        this.props.closeEditUser();
    }

    onRoleSelected = (item)=>{
        //console.log("item",item);
        this.setState({role:item.value});
    }

    getTitle = ()=>{
        //console.log("this.props",this.props)
        if (this.props.user)
            return 'Edit User';
        else
            return 'Add User';
    }

    onAddCountry = (item)=>{
        const {countryAdminCodes} = this.state;
        if (countryAdminCodes.includes(item.value))
            return;

        countryAdminCodes.push(item.value);
        this.setState({countryAdminCodes});
    }

    removeCountry = (country)=>{
        const {countryAdminCodes} = this.state;
        let i = countryAdminCodes.indexOf(country);
        countryAdminCodes.splice(i,1);
        this.setState({countryAdminCodes});
    }

    onAddProject = (item) => {
        console.log("item",item);
        const {projects} = this.state;
        if (projects.includes(item.value))
            return;

        projects.push({id:item.value, name: item.label});
        this.setState({projects});
    }

    onAddProjectGroup = (item) => {
        console.log("item",item);
        const {projectGroups} = this.state;
        if (projectGroups.includes(item.value))
            return;

        projectGroups.push({id:item.value, name: item.label});
        this.setState({projectGroups});
    }

    removeProject = (projectId)=>{
        const {projects} = this.state;
        let i = projects.findIndex(p=>p.id ===projectId);
        projects.splice(i,1);
        this.setState({projects});
    }

    removeProjectGroup = (projectGroupId)=>{
        const {projectGroups} = this.state;
        let i = projectGroups.findIndex(p=>p.id ===projectGroupId);
        projectGroups.splice(i,1);
        this.setState({projectGroups});
    }


    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeEditUser}>

                <div className="input-line">
                    <div className="input-description">Full Name</div>
                    <input value={this.state.fullName || ''} placeholder="" type="text" onChange={(e)=>this.onChange(e.target.value, 'fullName')}/>
                </div>
                <div className="input-line">
                    <div className="input-description">Phone Number</div>
                    <input value={this.state.phoneNumber || ''} placeholder="" type="tel" onChange={(e)=>this.onChange(e.target.value, 'phoneNumber')}/>
                </div>
                <div className="input-line">
                    <div className="input-description">Email</div>
                    <input value={this.state.email || ''} placeholder="" type="email" onChange={(e)=>this.onChange(e.target.value, 'email')}/>
                </div>
                <div className="input-line">
                    <div className="input-description">Role</div>
                    <Dropdown
                        options={[
                            {label:"Plant owner", value:'projectOwner'},
                            {label: "Operator", value:'operator'},
                            {label: "Country Admin", value:'countryAdmin'},
                            {label: "Admin", value:'admin'}
                            ]}
                        onChange={this.onRoleSelected}
                        value={this.state.role}
                        className='dropdown'
                        controlClassName='control'
                    />
                </div>

                {this.state.role ==='countryAdmin'?<div className="input-line">
                    <div className="input-description">Countries</div>
                    <Dropdown
                        options={COUNTRIES}
                        onChange={this.onAddCountry}
                        placeholder="Add country"
                        className='dropdown'
                        controlClassName='control'
                    />
                    <div className="countries">
                        {this.state.countryAdminCodes.map(country=>
                            <li key={country} className="country">
                                <span className="text">{country}</span>
                                <span className="remove" onClick={()=>this.removeCountry(country)}><img src={deleteIcon} /></span>
                            </li> )}
                    </div>
                </div>:null}

                {this.state.role ==='operator' || this.state.role ==='projectOwner'?
                    <div>
                        <div className="input-line">
                            <div className="input-description">Projects</div>
                            <Dropdown
                                options={this.props.projects.map(p=> ({label: p.projectName, value: p._id}))
                                }
                                onChange={this.onAddProject}
                                placeholder="Add project"
                                className='dropdown'
                                controlClassName='control'
                            />
                            <div className="projects">
                                {this.state.projects.map(project=>
                                    <li key={project.id} className="project">
                                        <span className="text">{project.name}</span>
                                        <span className="remove" onClick={()=>this.removeProject(project.id)}><img src={deleteIcon} /></span>
                                    </li> )}
                            </div>
                        </div>
                <div className="input-line">
                    <div className="input-description">Project Groups</div>
                    <Dropdown
                        options={this.props.projectGroups.map(pg=> ({label: pg.name, value: pg._id}))
                        }
                        onChange={this.onAddProjectGroup}
                        placeholder="Add project group"
                        className='dropdown'
                        controlClassName='control'
                    />
                    <div className="projects">
                        {this.state.projectGroups.map(projectGroup=>
                            <li key={projectGroup.id} className="project">
                                <span className="text">{projectGroup.name}</span>
                                <span className="remove" onClick={()=>this.removeProjectGroup(projectGroup.id)}><img src={deleteIcon} /></span>
                            </li> )}
                    </div>
                </div>
                    </div>:null}

                {this.props.user ?
                    <div className="buttons-wrapper">
                        <div className="delete-row" onClick={this.props.deleteUser}>
                            <img src={deleteUserIcon}/>
                            <span>Delete User</span>
                        </div>
                        <div className="button cancel" onClick={this.props.closeEditUser}>CANCEL</div>
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div> :
                    <div className="button" onClick={this.onSubmit}>SAVE</div>
                }

            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.editUserReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({editUser, addUser, getProjects }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditUserComponent);