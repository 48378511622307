import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './availability.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import {bindActionCreators} from "redux";
import {getAvailability} from "./actions/getAvailabilityAction";
import Loader from "../loader/loader";
import TableComponent from "../tableComponent/tableComponent";
import {getAvailabilitySuccess} from "./actions/getAvailabilitySuccessAction";
import DateCustomInput from "../dateCustomInput/dateCustomInput"
import DatePicker from "react-datepicker";
import downloadIcon from './download-icon.svg';
import EditAvailability from "../editAvailability/editAvailability";
import {withNavigation} from '../withNavigate';

import {TimeZone} from "../utils";
import Modal from '../modal/modal';
import ApiHandler from '../api';

class AvailabilityComponent extends TableComponent {
    constructor(props) {
        super(props);
        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        let now = new Date();
        let oneWeek = new Date();
        oneWeek.setMonth(oneWeek.getMonth() - 1);

        this.apiHandler = new ApiHandler();

        this.state = {
            showEditAvailability: false,
            startDate: oneWeek,
            endDate: now
        };

        this.timeZone = new TimeZone(this.props.project)
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.csv && (!this.props.csv || (this.props.csv !==nextProps.csv))) {
            this.saveCsvFile(nextProps.csv);
        }
    }


    componentDidMount() {

        this.props.actions.getAvailability(this.props.project._id, this.state.startDate, this.state.endDate);
    }

    itemSelected = (item)=> {
        if (this.props.user.role ==="projectOwner")
            return;
        this.setState({
            selectedAvailability: item,
            showEditAvailability: true
        });
    }


    closeEditAvailability = ()=> {
        this.setState({showEditAvailability:false});
    }


    dateChanged = dates => {
        const [start, end] = dates;
        this.setState({startDate: start, endDate: end});
        if (start && end)
            this.props.actions.getAvailability(this.props.project._id, start, end);
    };


    getData = ()=>this.props.reports;

    publishData = (data)=>this.props.actions.getAvailabilitySuccess(data);


    

    saveXlsxFile = (response) => {
        console.log("blob", response)
        const xlsxURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = xlsxURL;        

        const downloadFileName = `${this.props.project.projectName}-availability.xlsx`;

        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
    }

    downloadCsvFile = async ()=>{        
        const response = await this.apiHandler.getAvailabilityXlsx(this.props.project._id, this.state.startDate, this.state.endDate);
        this.saveXlsxFile(response);        
    }

    getNonCleaningRobots = (report)=>{
        return report.robotLists.notCleanedRobots;
    }

    getMalfunctioningRobots = (report, isToday)=>{
        // if (isToday)
        //     return [];

        return report.robotLists.robotsNotFinishedCleaning;

    }

    showRobotList = (e, robotList,title)=>{
        e.stopPropagation();
        e.preventDefault();

        this.setState({
            title,
            robots: robotList.sort((a,b)=>Number(a)-Number(b))
        });        
    }

    render() {
        return (
            <div className="page">
                {this.state.showEditAvailability?
                    <EditAvailability
                        closeEditAvailability={this.closeEditAvailability}
                        availability={this.state.selectedAvailability}
                    />
                :null}
                {this.props.isLoading?<Loader />:null}

                {this.state.title?<Modal title={this.state.title}
                   onClose={()=>this.setState({title:undefined})}>
                    <div className="modal-robots">
                        {this.state.robots.map((robot, index) => <span key={index} className='modal-robot'>{robot}</span>)}                        
                    </div>
                   </Modal>:null}

                <div className="menu-wrapper">
                    <Menu />
                </div>
                <div className="content">
                    <PlantHeader title={"Availability Report"} secondaryText={this.props.project.projectName}/>
                    <div className="availability">
                        <div className="top">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.dateChanged}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                selectsRange
                                shouldCloseOnSelect={false}
                                customInput={<DateCustomInput
                                    logsStartDate={this.state.startDate}
                                    logsEndDate={this.state.endDate}/>}
                            />

                            <div className="download" onClick={this.downloadCsvFile}><img src={downloadIcon} />DOWNLOAD REPORT</div>
                        </div>
                        <table>
                            <tbody>
                            <tr>
                                {this.getHeaderTH('timestamp', 'Date')}
                                {this.getHeaderTH('totalRobots', 'Total robots')}
                                {this.getHeaderTH('totalCleanedRobots', 'Cleaning robots')}
                                {this.getHeaderTH('totalNotCleanedRobots', 'Non Cleaning robots')}
                                {this.getHeaderTH('totalRobotsNotFinishedCleaning', 'Malfunctioning robots')}
                                {this.getHeaderTH('totalPortableInactive', 'Inactive client / Portable')}
                                {this.getHeaderTH('totalInactiveForReason', 'Inactive For Reason')}                                
                                {this.getHeaderTH('inactiveReason', 'Inactivity Reason')}                                
                                {this.getHeaderTH('availabilityPercent', 'Availability')}
                                {this.getHeaderTH('cleaningPreventedReason', 'Cleaning prevented reason')}

                            </tr>
                            {this.props.reports.map((report, index) => {
                                const isToday = this.timeZone.getLocalTime(report.timestamp,true) ==
                                    this.timeZone.getLocalTime(new Date(),true);

                                return <tr key={index} onClick={() => this.itemSelected(report) }>
                                    <td className="date">{this.timeZone.getLocalTime(report.timestamp,true)}</td>
                                    <td>{report.totalRobots}</td>
                                    <td>
                                        {report.totalCleanedRobots} 
                                        {report.totalCleanedRobots? <span className='show-list' onClick={(e)=>this.showRobotList(e, report.robotLists.cleanedRobots, "Cleaning Robots")}>show list</span>:null}
                                    </td>
                                    <td>{this.getNonCleaningRobots(report).length}
                                        {this.getNonCleaningRobots(report).length? <span className='show-list' onClick={(e)=>this.showRobotList(e, this.getNonCleaningRobots(report), "Non Cleaning Robots")}>show list</span>:null}
                                    </td>
                                    <td>{this.getMalfunctioningRobots(report,isToday).length}
                                        {this.getMalfunctioningRobots(report,isToday).length? <span className='show-list' onClick={(e)=>this.showRobotList(e, this.getMalfunctioningRobots(report,isToday), "Malfunctioning robots")}>show list</span>:null}
                                    </td>
                                    <td>{report.totalPortableInactive}
                                        {report.totalPortableInactive? <span className='show-list' onClick={(e)=>this.showRobotList(e, report.robotLists.portableInactiveRobots, "Portable/inactive")}>show list</span>:null}
                                    </td>
                                    <td>{report.totalInactiveForReason}</td>                                    
                                    <td>{report.inactiveReason}</td>                                                                       
                                    <td>{report.availabilityPercent? `${report.availabilityPercent}%`:'-'}</td>
                                    <td>{report.cleaningPreventedReason}</td>
                                </tr>

                                // robotLists: {
                                //     cleanedRobots: [Number],
                                //         notCleanedRobots: [Number],
                                //         robotsNotFinishedCleaning: [Number],
                                //         portableInactiveRobots: [Number],
                                //         autonomousRobots: [Number],
                                // }

                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.availabilityReducer,
        user: state.indexReducer.user,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({getAvailability, getAvailabilitySuccess}, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(AvailabilityComponent));