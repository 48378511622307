import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './editTask.scss';
import Modal from "../modal/modal";
import { editTask } from "./actions/editTaskAction";
import DatePicker from "react-datepicker";
import { createTask } from "./actions/createTaskAction";
import { deleteTask } from "../tasks/deleteTaskAction";
import deleteUserIcon from "../editUser/delete-user-icon.svg";
import { TimeZone } from "../utils";
import Attachments from "../attachments/attachments";
import Attachment from "../attachment/attachment";

const TASK_TYPES = [
    "Change wipers",
    "Change flippers",
    "Change batteries",
    "Change site screening",
    "Bearings",
    "Change motors",
    "Cleaning quality checking",
    "Other"
];

const TASK_STATUSES = {
    open: 'Open',
    inProgress: 'In progress',
    done: 'Done'
}

class EditTaskComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.task,
            files: []
        };

        this.isNewTask = this.props.task._id === undefined;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.taskSaved) {
            if (!this.props.taskSaved) {
                this.props.closeEditTask();
                return;
            }
        }

        if (nextProps.task && (!this.props.task || (this.props.task !== nextProps.task))) {
            alert(`Something went wrong`)
        }
    }


    componentDidMount() {

    }

    onChange = (value, param) => {
        let state = {};

        state[param] = value;

        this.setState(state);
    }

    onSubmit = () => {

        let params = { ...this.state };
        params.projectId = this.props.project._id;

        const mandatory = {
            status: 'Status',
            dueDate: 'Due date',
            taskType: 'Task type'
        }

        for (let key in mandatory) {
            if (!params[key])
                return alert(`Please fill in the ${mandatory[key]} field`);
        }

        if (this.isNewTask) {
            this.props.actions.createTask(params, this.state.files);
        } else {
            this.props.actions.editTask(params, this.props.task._id);
        }
    }

    onStatusSelected = (item) => {
        //console.log("item",item);
        this.setState({ status: item.value });
    }


    getTitle = () => {
        if (this.isNewTask)
            return 'New Task';
        else
            return 'Edit Task';
    }

    onTaskTypeChanged = (item) => {
        this.setState({ taskType: item.value });
    }

    onStatusChanged = (item) => {
        this.setState({ status: item.value });
    }

    dueDateChanged = dueDate => {
        console.log("dueDate", dueDate)
        this.setState({ dueDate });
    }

    onFilesChanged = (files) => {
        this.setState({ files });
    }

    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeEditTask}>
                {this.state.selectedAttachment ? <Attachment closeAttachment={this.closeAttachment} src={this.state.selectedAttachment.path} /> : null}
                <div className="input-line">
                    <div className="input-description">Task Type</div>
                    <Dropdown
                        options={TASK_TYPES}
                        onChange={this.onTaskTypeChanged}
                        placeholder="Select task type"
                        value={this.state.taskType}
                        className='dropdown'
                        controlClassName='control'
                    />
                </div>

                <div className="input-line">
                    <div className="input-description">Status</div>
                    <Dropdown
                        options={Object.entries(TASK_STATUSES).map(item => ({ label: item[1], value: item[0] }))}
                        onChange={this.onStatusChanged}
                        placeholder="Select status"
                        value={this.state.status}
                        className='dropdown'
                        controlClassName='control'
                    />
                </div>

                <div className="new-task-lines">

                    <div className="input-line">
                        <div className="input-description">Due Date</div>
                        <div className="date-wrapper">
                            <DatePicker
                                selected={this.state.dueDate}
                                onChange={this.dueDateChanged}
                                startDate={this.state.dueDate}
                                // dateFormat="Pp"
                                // maxDate={new Date()}
                                // shouldCloseOnSelect={true}
                                customInput={<span>{new TimeZone(this.props.project).getLocalTime(this.state.dueDate, true)}</span>}
                            // customInput={<this.VoltageDateCustomInput/>}
                            />
                        </div>
                    </div>



                </div>

                <div className="input-line">
                    <div className="input-description">Description</div>
                    <input value={this.state.description} placeholder="" type="text"
                        onChange={(e) => this.onChange(e.target.value, 'description')} />
                </div>

                {!this.isNewTask && <div className="input-line">
                    <Attachments
                        type="task"
                        projectId={this.props.project._id}
                        taskId={this.props.task._id}
                        attachments={this.props.task.attachments}
                    />
                </div>}

                {this.isNewTask ?
                    <div className="buttons-wrapper">
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                    :
                    <div className="buttons-wrapper">
                        {this.props.deleteTask ? <div className="delete-row" onClick={this.props.deleteTask}>
                            <img src={deleteUserIcon} />
                            <span>Delete task</span>
                        </div> : null}
                        <div className="button cancel" onClick={this.props.closeEditTask}>CANCEL</div>
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                }

            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.editTaskReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            editTask,
            createTask,
            deleteTask
        }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditTaskComponent);