// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper .modal {
  width: 35%;
  color: var(--almost-black); }
  .modal-wrapper .modal .buttons-wrapper {
    text-align: center; }
    .modal-wrapper .modal .buttons-wrapper .button {
      display: inline-block !important; }
  .modal-wrapper .modal .button.cancel {
    margin-right: 25px;
    color: var(--brown-grey);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--ice-blue);
    position: relative;
    top: -2px; }
  .modal-wrapper .modal .countries, .modal-wrapper .modal .projects {
    height: auto; }
  .modal-wrapper .modal .country, .modal-wrapper .modal .project {
    margin-top: 20px;
    background-color: var(--very-light-blue);
    padding: 5px 10px;
    border-radius: 8px;
    display: inline-block;
    margin-right: 17px; }
    .modal-wrapper .modal .country .text, .modal-wrapper .modal .project .text {
      display: inline-block;
      vertical-align: top;
      line-height: 27px; }
    .modal-wrapper .modal .country .remove, .modal-wrapper .modal .project .remove {
      cursor: pointer;
      margin-left: 10px; }
      .modal-wrapper .modal .country .remove img, .modal-wrapper .modal .project .remove img {
        height: 19px;
        margin-top: 3px; }
`, "",{"version":3,"sources":["webpack://./src/components/editUser/editUser.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,0BAA0B,EAAA;EAF5B;IAKI,kBAAkB,EAAA;IALtB;MAQM,gCAAgC,EAAA;EARtC;IAaI,kBAAkB;IAClB,wBAAwB;IACxB,0CAA0C;IAC1C,iCAAiC;IACjC,kBAAkB;IAClB,SAAS,EAAA;EAlBb;IA0BI,YAAY,EAAA;EA1BhB;IA6BI,gBAAgB;IAChB,wCAAwC;IACxC,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB,EAAA;IAlCtB;MAqCM,qBAAqB;MACrB,mBAAmB;MAEnB,iBAAiB,EAAA;IAxCvB;MA4CM,eAAe;MACf,iBAAiB,EAAA;MA7CvB;QAgDQ,YAAY;QACZ,eAAe,EAAA","sourcesContent":[".modal-wrapper .modal {\n  width: 35%;\n  color: var(--almost-black);\n\n  .buttons-wrapper {\n    text-align: center;\n\n    .button {\n      display: inline-block !important;\n    }\n  }\n\n  .button.cancel {\n    margin-right: 25px;\n    color: var(--brown-grey);\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n    background-color: var(--ice-blue);\n    position: relative;\n    top: -2px;\n  }\n\n  li {\n    //display: inline;\n  }\n\n  .countries, .projects {\n    height: auto;\n  }\n  .country, .project {\n    margin-top: 20px;\n    background-color: var(--very-light-blue);\n    padding: 5px 10px;\n    border-radius: 8px;\n    display: inline-block;\n    margin-right: 17px;\n\n    .text {\n      display: inline-block;\n      vertical-align: top;\n\n      line-height: 27px;\n    }\n\n    .remove {\n      cursor: pointer;\n      margin-left: 10px;\n\n      img {\n        height: 19px;\n        margin-top: 3px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
