export const VIEW_AVAILABILITY = 'VIEW_AVAILABILITY';
export const EDIT_AVAILABILITY_STARTED = 'EDIT_AVAILABILITY_STARTED';
export const EDIT_AVAILABILITY_ERROR = 'EDIT_AVAILABILITY_ERROR';
export const EDIT_AVAILABILITY_SUCCESS = 'EDIT_AVAILABILITY_SUCCESS';


const INITIAL_STATE = {
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    // console.log("action.type",action.type);
    switch (action.type) {
        case VIEW_AVAILABILITY:
            return {
                ...state,
                availabilitySaved: undefined
            }
        case EDIT_AVAILABILITY_STARTED:
            return {
                ...state,
                // attachment: action.payload.attachment[0]
            }
        case EDIT_AVAILABILITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                availabilitySaved: action.payload.availabilityReport
            }
        case EDIT_AVAILABILITY_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}