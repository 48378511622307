import axios from "axios";
import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getCurrentWeatherStarted} from "./getCurrentWeatherStartedAction";
import {getWeatherHistorySuccess} from "./getWeatherHistorySuccessAction";
import {getCurrentWeatherError} from "./getCurrentWeatherErrorAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";


export function getWeatherHistory(projectId, from = '', to = '') {
    console.log("projectId",projectId)
    return function (dispatch) {

        let url = HOST_URL+`/api/weather/logs?projectId=${projectId}&from=${from}&to=${to}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCurrentWeatherStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getWeatherHistorySuccess(response.data.weatherLogs.reverse()));
            }
            else {
                dispatch(getCurrentWeatherError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getCurrentWeatherError(error));
        });
    };
}