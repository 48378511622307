import React, { Component, PropTypes, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './settings.scss';
import placeholder from "./placeholder.jpeg";
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import { COUNTRIES, LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_USER_KEY } from "../../consts";
import robotsIcon from "../createProject/robots-icon.svg";
import ReactSwitch from 'react-switch';
import { useLocation } from 'react-router-dom';
import ApiHandler from '../api';

const descriptions = require('./advancedSettingsDescriptions.json');


const SettingsComponent = ({ }) => {
    const [project, setProject] = useState({});
    const [user, setUser] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [advancedSettings, setAdvancedSettings] = useState([]);

    const apiHandler = new ApiHandler();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);
        handleAdvancedSettings(project);

    }, []);

    const handleAdvancedSettings = async (project) => {
        
        try {
            // const result = await apiHandler.getProjectSettings(projectId);
            const advancedSettings = [];
            for (const key in project.settings.advanced) {
                if (!descriptions[key])
                    continue;

                const setting = project.settings.advanced[key];
                let description = `${descriptions[key].substring(0,1).toUpperCase()}${descriptions[key].substring(1)}`; 
                const { min, max } = setting;
                if (Number.isFinite(min) && Number.isFinite(max) && min !== max)
                    description += ` [${min}-${max}]`;

                advancedSettings.push({
                    name: key,                    
                    description,
                    isActive: setting.isActive,
                    value: setting.value,
                    min: setting.min,
                    max: setting.max
                });                
            }
            setAdvancedSettings(advancedSettings);            
        } catch (err) {
            console.log("Error getting project settings");
        }
    }


    const onSave = async () => {
        const advanced = {};
        for (const setting of advancedSettings) {
            setting.value = Number(setting.value);
            const { min, max } = setting;
            if ((Number.isFinite(max) && setting.value > max) || (Number.isFinite(min) && setting.value < min)) {
                alert(`${setting.description} value must be between ${setting.min} and ${setting.max}`);
                return;
            }

            advanced[setting.name] = {
                isActive: setting.isActive,
                value: setting.value
            }
        };

        try {
            const result = await apiHandler.editProject(project._id, { settings: { advanced } });
            // debugger;
            localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(result.project));

            setIsSaved(true);            
            setTimeout(() => {
                setIsSaved(false);
            }, 4000);
        } catch (err) {
            alert("Error saving project");
        }
    }


    const onChange = (value, name) => {
        const list = [...advancedSettings];
        const index = list.findIndex(x => x.name ===name);
        list[index].value = value;
        setAdvancedSettings(list);
    }

    const onSwitchChange = (isChecked, name) => {
        const list = [...advancedSettings];
        const index = list.findIndex(x => x.name ===name);
        list[index].isActive = isChecked;
        setAdvancedSettings(list);
    }

    return (
        <div className="page">
            <div className="menu-wrapper">
                <Menu />
            </div>
            <div className="content">

                <PlantHeader
                    title="Advanced"
                    secondaryText="Settings"
                    secondaryLink={'/settings'}
                    thirdText={project.projectName}
                    thirdLink={'/dashboard'}
                />

                <div className="advanced-settings settings">
                    {advancedSettings.map((setting, index) => {
                        return <div className="input-line">
                            <div className="input-description">                                
                                <span className="text left double">{setting.description}</span>
                            </div>

                            <div className="line">
                                <input className="left" 
                                    placeholder=""
                                    value={setting.value}
                                    type="number"
                                    min={setting.min}
                                    max={setting.max}
                                    disabled={!setting.isActive}
                                    onChange={(e) => onChange(e.target.value, setting.name)} />

                                <ReactSwitch
                                    className='line-switch'
                                    onColor="#e36510"
                                    onChange={(checked) => onSwitchChange(checked, setting.name)}
                                    checked={setting.isActive} />

                            </div>
                        </div>
                    })}


                    <div className="button" onClick={onSave}>SAVE</div>
                    {isSaved ? <div className="button-message">Project saved</div> : null}
                </div>
            </div>
        </div >
    )

}





export default SettingsComponent;