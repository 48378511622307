import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";

import {getRobotsError} from "./getRobotsErrorAction";
import {getRobotsStarted} from "./getRobotsStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {drySuccess} from "./drySuccessAction";

export function dryRobot({robotId, projectId}) {

    return async function (dispatch) {
        console.log("robot",robotId)

        let url = `${HOST_URL}/api/projects/${projectId}/set-as-dry`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotsStarted());

        try {
            const response = await axios.post(url, {robotId}, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(drySuccess());
            } else {
                dispatch(getRobotsError(new Error(response.data.message)));
            }
        } catch(error){
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotsError(error));
        };
    };
}