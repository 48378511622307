import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getCleaningPlanSuccess} from "./getCleaningPlanSuccessAction";
import {getCleaningPlanError} from "./getCleaningPlanErrorAction";
import {getCleaningPlanStarted} from "./getCleaningPlanStartedAction";

export function deleteCleaningPlan(params, rowId) {

    return function (dispatch) {
        let url = HOST_URL+'/api/panels/' + rowId;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCleaningPlanStarted());

        return axios.put(url,params, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getCleaningPlanSuccess(params));
            }
            else {
                dispatch(getCleaningPlanError(new Error(response.data.message)));
            }
        }).catch(error => {

            console.log(error.message);
            dispatch(getCleaningPlanError(error));
        });
    };
}