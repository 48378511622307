import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './editMessage.scss';
import Modal from "../modal/modal";
import {editMessage} from "./editMessageAction";
import {getFormattedDate, HOST_URL} from "../../consts";
import DatePicker from "react-datepicker";
import {createMessage} from "./createMessageAction";
import {deleteMessage} from "../messages/actions/deleteMessageAction";
import deleteUserIcon from "../editUser/delete-user-icon.svg";
import Attachment from "../attachment/attachment";
import {TimeZone} from "../utils";


class EditMessageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.message,
            expiryDate: this.props.message.expiryDate? new Date(this.props.message.expiryDate):new Date()
        };


        this.isNewMessage = this.props.message._id ===undefined;
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.messageSaved) {
            if (!this.props.messageSaved) {
                this.props.closeEditMessage();
                return;
            }
        }

        if (nextProps.message && (!this.props.message || (this.props.message !==nextProps.message))) {
            alert(`Something went wrong`)
        }
    }

    componentDidMount() {

    }

    onChange = (value, param) => {
        let state = {};

        state[param] = value;

        this.setState(state);
    }


    onSubmit = () => {

        let params = {...this.state};
        params.projectId = this.props.project._id;

        const mandatory = {
            description: 'Description'
        }

        for (let key in mandatory) {
            if (!params[key])
                return alert(`Please fill in the ${mandatory[key]} field`);
        }

        if (this.isNewMessage) {
            this.props.actions.createMessage(params);
        } else {
            this.props.actions.editMessage(params, this.props.message._id);
        }
    }

    getTitle = () => {
        if (this.isNewMessage)
            return 'New Message';
        else
            return 'Edit Message';
    }


    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeEditMessage}>
                {this.state.selectedAttachment?<Attachment closeAttachment={this.closeAttachment} src={this.state.selectedAttachment.path} />:null}

                <div className="input-line">
                    <div className="input-description">Description</div>
                    <div className="input-line">
                    <textarea value={this.state.description} placeholder="" type="text"
                           onChange={(e) => this.onChange(e.target.value, 'description')}/>
                    </div>

                    <div className="input-line">
                        <div className="input-description">Expiry Date</div>
                        <div className="date-wrapper">
                            <DatePicker
                                selected={this.state.expiryDate}
                                onChange={expiryDate=>this.setState({expiryDate})}
                                startDate={this.state.expiryDate}
                                // dateFormat="Pp"
                                // maxDate={new Date()}
                                shouldCloseOnSelect={true}
                                customInput={<span>{new TimeZone(this.props.project).getLocalTime(this.state.expiryDate || new Date(), true)}</span>}
                                // customInput={<this.VoltageDateCustomInput/>}
                            />
                        </div>
                    </div>

                    <div className="input-line">
                        <div className="input-description">Level</div>
                        <Dropdown
                            options={[{label: "1", value: '1'}, {label: "2", value: '2'}]}
                            onChange={item=>this.setState({level: item.value})}
                            value={this.state.level+''}
                            className='dropdown'
                            controlClassName='control'
                        />
                    </div>
                </div>

                {this.isNewMessage ?
                    <div className="buttons-wrapper">
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                    :
                    <div className="buttons-wrapper">
                        {this.props.deleteMessage? <div className="delete-row" onClick={this.props.deleteMessage}>
                            <img src={deleteUserIcon}/>
                            <span>Delete message</span>
                        </div> : null}
                        <div className="button cancel" onClick={this.props.closeEditMessage}>CANCEL</div>
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div>
                }

            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.editMessageReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            editMessage,
            createMessage,
            deleteMessage
        }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditMessageComponent);