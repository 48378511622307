import React, { Component, PropTypes, useEffect, useState } from 'react';
import './projects.scss';
import logo from "./images/logo.svg";
import plus from "./images/plus.svg";
import Search from "../search/search";
import location from "./images/location-icon.svg";
import power from "./images/power-icon.svg";
import robots from "./images/robots-icon.svg";
import vehicles from "./images/vehicles-icon.svg";
import dots from "./images/dots.svg";
import mapIcon from "./images/map-icon.png";
import pdfIcon from "./images/pdf-icon.png";
import { LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY } from "../../consts";
import CreateProject from "../createProject/createProject";
import UsersWidget from '../usersWidget/usersWidget';
import Loader from "../loader/loader";
import downloadIcon from "../availability/download-icon.svg";
import { getDateStr } from "../utils";
import taskOverdue from "./images/task-overdue.png";
import weatherError from "./images/weather-error.svg";
import bleError from "./images/ble-error.png";
import ApiHandler from '../api';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';


const ProjectGroupsComponent = ({ }) => {
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [projects, setProjects] = useState([]);    
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [projectGroups, setProjectGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [search, setSearch] = useState();
    const navigate = useNavigate();
    const apiHandler = new ApiHandler();
    const { projectGroupId } = useParams();

    useEffect(() => {        
        if (!projectGroupId) {
            setSelectedGroup(undefined);            
        } else if (projectGroups.length) {
            const projectGroup = projectGroups.find(pg => pg.projectGroup._id === projectGroupId);
            setSelectedGroup(projectGroup.projectGroup);
            setProjects(projectGroup.projects);
            setSearch(undefined);
        }
    }, [projectGroupId]);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
        setUser(user);
        if (!user || !localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || !user) {
            navigate("/signin");
            return;
        }

        // handleProjects();
    }, []);

    useEffect(() => {
        handleProjects(search);
    }, [search]);

    const handleProjects = async (search) => {
        setIsLoading(true);
        try {
            const result = await apiHandler.getProjects(search);
            if (!result) {
                return;
            }
            
            const { projectGroups, searchResults } = result;
            

            setProjectGroups(projectGroups);
            if (searchResults) {
                setProjects(searchResults);
                if (searchResults.length) {
                    setSelectedGroup({
                        name: 'Search results'
                    });
                }
            }
            else if (projectGroupId) {
                const projectGroup = projectGroups.find(pg => pg.projectGroup._id === projectGroupId);
                setSelectedGroup(projectGroup.projectGroup);
                setProjects(projectGroup.projects);
            } 

         
        } catch (error) {
            console.log(error);
            if (error.response?.status === 401) { 
                navigate("/signin");
                return;
            }
            
            alert("Error loading projects");
        } finally {
            setIsLoading(false);
        }
    }


    const goToSettings = (e, project) => {
        e.stopPropagation();
        localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
        window.location.href = '/settings';
    }

    const projectGroupSelected = (projectGroup) => {
        setSearch(undefined);
        navigate('/projects/' + projectGroup.projectGroup._id);        
    }

    const projectSelected = (project) => {
        localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
        window.location.href = project.arrayType === 'fixed' ? '/dashboard' : '/vehicles/dashboard';
    }

    const downloadErrors = async () => {
        setIsLoading(true);
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);

        try {
            const response = await apiHandler.getErrorsXlsx({from: startDate, to:endDate});
            
            const csvURL = window.URL.createObjectURL(response.data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            const startDateStr = getDateStr(startDate);
            const endDateStr = getDateStr(endDate);

            const downloadFileName = endDateStr === startDateStr ? `errors ${startDateStr}.xlsx` : `errors ${startDateStr}-${endDateStr}.xlsx`;

            tempLink.setAttribute('download', downloadFileName);
            tempLink.click();

        } catch (error) {
            console.log(error);
            alert("Error downloading errors");
        } finally {
            setIsLoading(false);
        }

    }

    const viewPdf = project => {
        if (!project.pdfUrl)
            return;

        window.open(project.pdfUrl, '_blank');
    }

    const goToMap = project => {
        if (!project.address)
            return;
        const mapUrl = `https://www.google.com/maps/search/?api=1&query=${project.lat && project.lon ? `${project.lat},${project.lon}` : project.address}`;
        window.open(mapUrl, '_blank');
    }

    const unselectProjectGroup = () => {
        setSelectedGroup(undefined);
        setProjects(undefined);
        setSearch(undefined);
        navigate('/projects');
    }

    const getHeaderText = () => {
        if (selectedGroup)
            return selectedGroup.name;

        else if (search)
            return `search: "${search}"`;
    }

    return (
        <div className="projects">
            <UsersWidget />
            {user && user.role !== "projectOwner" ?
                <span className="download-all-errors" onClick={downloadErrors}>
                    <img src={downloadIcon} />Download errors
                </span> : null}

            {showCreateProject ? <CreateProject closeCreateProject={() => setShowCreateProject(false)} projectGroups={projectGroups} /> : null}
            <div className="header">
                <div className={`${getHeaderText() ? 'pointer ' : ''}logo-wrapper`}
                    onClick={unselectProjectGroup}>
                    <img className="logo" src={logo} />
                </div>
                <div className="title"><span className={getHeaderText() ? "pointer" : ''}
                    onClick={unselectProjectGroup}>Projects </span>{getHeaderText() ?
                        <span>| {getHeaderText()}</span> : null}
                </div>
            </div>


            {isLoading ? <Loader /> : null}

            <div className="content">
                <Search onSearch={setSearch} placeholder="search by name or address" />

                {user.role === "admin" || user.role === "countryAdmin" ?
                    <div className='add-project' onClick={() => setShowCreateProject(true)}><img src={plus} /> <span
                        className="text">CREATE PROJECT</span></div>
                    : null}

                {!selectedGroup && projectGroups ? <div className="grid">
                    {projectGroups.map(projectGroup =>
                        <div className="item" key={projectGroup.projectGroup._id}
                            onClick={() => projectGroupSelected(projectGroup)}>
                            <div className="group">
                                <img className="group-image"
                                    src={projectGroup.projectGroup.thumbUrl} />
                            </div>
                            <div className="name">{projectGroup.projectGroup.name}</div>
                        </div>
                    )}

                </div> : null}

                {projects && selectedGroup ? <div className="grid">
                    {projects.map(project => {
                        return <div className="item" key={project._id}>
                            <div className="satellite">
                                <img className="satellite" src={project.imageURL}
                                    onClick={() => projectSelected(project)} />

                                <div className='address'>
                                    <img src={location} />
                                    {project.address}
                                </div>
                                <div className="oval first" onClick={(e) => goToSettings(e, project)}>
                                    <img src={dots} />
                                </div>

                                <div className="oval second" onClick={(e) => goToMap(project)}>
                                    <img src={mapIcon} />
                                </div>

                                {project.pdfUrl ? <div className="oval third" onClick={(e) => viewPdf(project)}>
                                    <img src={pdfIcon} />
                                </div> : null}


                            </div>
                            <div onClick={() => projectSelected(project)}>
                                <div className="name">{project.projectName}
                                    <div className="online-offline">
                                        {project.connectionStatus === 'online' ? <span className='online'>
                                            <span className="circle"></span>Online
                                        </span> :
                                            <span className='offline'>
                                                <span className="circle"></span>Offline
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="line">
                                    <div className="small-item">
                                        <span className='text'>{project.availabilityPercent || 0}%</span>
                                    </div>

                                    <span className='right yellow-errors'>{project.yellowErrors || 0}</span>
                                    <span className='right red-errors'>{project.redErrors || 0}</span>
                                    {project.hasBleError && <img className='error-icon' src={bleError} />}
                                    {project.hasOverdueTask && <img className='error-icon' src={taskOverdue} />}
                                    {project.hasWeatherError && <img className='error-icon' src={weatherError} />}

                                </div>
                                <div className="bottom">
                                    <div className="bottom-item">
                                        <img src={power} />
                                        <span className='text'>{project.totalPower}MW</span>
                                    </div>
                                    <div className="bottom-item">
                                        <img src={robots} />
                                        <span
                                            className='text'>{project.numberOfTypeOneRobots + project.numberOfTypeTwoRobots}</span>
                                    </div>
                                    {project.numberOfVehicles ? <div className="bottom-item">
                                        <img src={vehicles} />
                                        <span className='text'>{project.numberOfVehicles}</span>
                                    </div> : null}

                                </div>
                            </div>
                        </div>
                    })}

                </div> : null}
            </div>
        </div>
    )


}


export default ProjectGroupsComponent;