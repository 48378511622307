import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";

import {getRobotsError} from "./getRobotsErrorAction";
import {getRobotsStarted} from "./getRobotsStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {deleteSuccess} from "./deleteSuccessAction";

export function deleteRobotVehicle({robot,vehicle}) {

    return function (dispatch) {
        console.log("robot",robot, vehicle)

        let url = `${HOST_URL}/api/${robot?'robots/' + robot.robotId: 'vehicles/' + vehicle.vehicleId}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotsStarted());

        return axios.delete(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(deleteSuccess(robot, vehicle));
            }
            else {
                dispatch(getRobotsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotsError(error));
        });
    };
}