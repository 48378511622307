import React, { Component, PropTypes, useState } from 'react';
import { connect } from 'react-redux';
import './search.scss';
import searchIcon from "./search.svg";

const SearchComponent = ({ placeholder, onSearch }) => {
    const [progressWidth, setProgressWidth] = useState(0);
    const [search, setSearch] = useState('');
    const [lastSearch, setLastSearch] = useState('');
    let input;

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchClicked();
        }
    }

    const searchClicked = () => {
        input?.focus();
        
        if (search !== lastSearch) {
            setLastSearch(search);
            onSearch(search);
        }
    }

    return (
        <div className="search">
            <img src={searchIcon} onClick={searchClicked} />
            <input ref={ref => input = ref} placeholder={placeholder} onKeyDown={onKeyDown} onChange={(event) => setSearch(event.target.value)} />
        </div>
    )



}


function mapStateToProps(state, ownProps) {
    //
    //console.log("state.indexReducer", state.indexReducer)
    return {

    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);