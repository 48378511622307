import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {createProjectStarted} from "./createProjectStartedAction";
import {createProjectSuccess} from "./createProjectSuccessAction";
import {createProjectError} from "./createProjectErrorAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {uploadAttachment} from "../utils";

export function createProject(params, files) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    const uploadFiles = async (projectId, files, index)=>{
        for (let i=index; i<files.length; i++) {
            if (files[i]) {
                await uploadAttachment({
                    file: uploadFiles[i],
                    projectId
                });
            }
        }
    }

    return function (dispatch) {
        let url = HOST_URL+'/api/projects/';

        dispatch(createProjectStarted());

        return axios.post(url,params, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                if (!files.length)
                    dispatch(createProjectSuccess(response.data.project));
                else {
                    return uploadFiles(response.data.project._id, files, 0).then(result=>{
                        dispatch(createProjectSuccess(response.data.project));
                    }).catch(err=>{
                        dispatch(createProjectError(err));
                    });
                }
            }
            else {
                dispatch(createProjectError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            //console.log("shit");
            console.log(error.message);
            dispatch(createProjectError(error));
        });
    };


}