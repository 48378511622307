import {EDIT_ROBOT_SETTINGS_SUCCESS} from "./editRobotSettingsReducer";

export const GET_ROBOT_SETTINGS_STARTED = 'GET_ROBOT_SETTINGS_STARTED';
export const GET_ROBOT_SETTINGS_ERROR = 'GET_ROBOT_SETTINGS_ERROR';
export const GET_ROBOT_SETTINGS_SUCCESS = 'GET_ROBOT_SETTINGS_SUCCESS';
export const UPLOAD_ROBOT_SETTINGS_STARTED = 'UPLOAD_ROBOT_SETTINGS_STARTED';
export const UPLOAD_ROBOT_SETTINGS_ERROR = 'UPLOAD_ROBOT_SETTINGS_ERROR';
export const UPLOAD_ROBOT_SETTINGS_SUCCESS = 'UPLOAD_ROBOT_SETTINGS_SUCCESS';
export const DOWNLOAD_ROBOT_SETTINGS_SUCCESS = 'DOWNLOAD_ROBOT_SETTINGS_SUCCESS';


const INITIAL_STATE = {
    robotSettings: [],
    isLoading: false
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_ROBOT_SETTINGS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case DOWNLOAD_ROBOT_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case GET_ROBOT_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                robotSettings: action.payload.robotSettings
            };
        case EDIT_ROBOT_SETTINGS_SUCCESS:
            let existing = state.robotSettings.find(robot=>robot._id ===action.payload.robotSettings._id);
            let robotSettings = state.robotSettings;

            if (existing) {
                let index = robotSettings.indexOf(existing);


                if (action.payload.robotSettings.isDeleted)
                    robotSettings.splice(index, 1);
                else
                    robotSettings[index] = action.payload.robotSettings;

                robotSettings = [].concat(robotSettings);
            }
            else {
                robotSettings = [action.payload.robotSettings].concat(state.robotSettings);
            }

            return {
                ...state,
                robotSettings
            }
        case GET_ROBOT_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case UPLOAD_ROBOT_SETTINGS_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case UPLOAD_ROBOT_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                alert: 'File uploaded successfully'
            }
        case UPLOAD_ROBOT_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        default:
            return state;
    }
}