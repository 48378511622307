import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {getRobotsSuccess} from "./getRobotsSuccessAction";

export function getRobots(projectId) {

    return function (dispatch) {
        let url = HOST_URL+'/api/robots?projectId='+projectId;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);


        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getRobotsSuccess(response.data));
            }
            else {
                console.log(response);
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
        });
    };
}