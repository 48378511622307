import React, { Component, PropTypes } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./createProject.scss";
import clockIcon from "./clock-icon.svg";
import Modal from "../modal/modal";
import { createProject } from "./createProjectAction";

import attachmentIcon from "./attachment-icon.svg";
import locationIcon from "./location-icon.svg";
import powerIcon from "./power-icon.svg";
import robotsIcon from "./robots-icon.svg";
import vehiclesIcon from "./vehicles-icon.svg";
import weatherIcon from "./weather-icon.svg";
import { addAttachment } from "./addAttachmentAction";
import { COUNTRIES, camelCaseToText } from "../../consts";
import close from "../modal/close-icon.svg";
import ApiHandler from "../api";

class CreateProjectComponent extends Component {
  constructor(props) {
    super(props);
    this.apiHandler = new ApiHandler();

    this.state = {
      minMax: {},
      item: {
        row: "fixed",
      },
      arrayType: "fixed",
      settings: {},
      direction: "Left",
      groupCleaningTimes: [
        { groupNumber: 1, cleaningTime: { hour: "", minute: "" } },
        {
          groupNumber: 2,
          cleaningTime: { hour: "", minute: "" },
        },
        { groupNumber: 3, cleaningTime: { hour: "", minute: "" } },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project) this.props.closeCreateProject();

    if (nextProps.projectGroups) {
      debugger;
    }
  }

  async componentDidMount() {
    const minMax = await this.apiHandler.getMinMax();
    const { settings } = this.state;
    for (const key in minMax) {
      if (minMax[key].default !== undefined) {
        // debugger;
        settings[key] = minMax[key].default;
      }
    }
    this.setState({ minMax, settings });
  }

  onChange = (value, param) => {
    if (param.startsWith("settings.")) {
      const { settings } = this.state;
      param = param.replace("settings.", "");
      settings[param] = value;
      this.setState({ settings });
    } else {
      let state = {};
      state[param] = value;
      this.setState(state);
    }
  };

  onSubmit = () => {
    const required = [
      "projectName",
      "projectGroupId",
      "countryCode",
      "city",
      "streetAddress",
      "totalPower",
      "numberOfTypeOneRobots",
      "numberOfTypeTwoRobots",
      "windCleaningThreshold",
      "rainCleaningThreshold",
    ];

    let missing = required.filter((item) => !this.state[item]);

    if (missing.length) {
      // let list = missing.map(item => item.replace(/([A-Z])/g, ' $1').toLowerCase());
      alert(
        `${missing[0].replace(/([A-Z])/g, " $1").toLowerCase()} is mandatory`
      );
      return;
    }

    const requiredSettings = [
      "driveSpeedCleaning",
      "driveSpeedTransit",
      "rotorSpeedCleaning",
      "rotorSpeedTransit",
      "stopDistanceCleaning",
      "stopDistanceTransit",
      "robotVersion",
    ];

    missing = requiredSettings.filter((item) => !this.state.settings[item]);

    if (missing.length) {
      alert(
        `${missing[0]
          .replace("Id", "")
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()} is mandatory`
      );
      return;
    }

    if (
      !this.state.groupCleaningTimes[0].cleaningTime.hour ||
      !this.state.groupCleaningTimes[0].cleaningTime.minute
    ) {
      alert("Group 1 cleaning time is mandatory");
      return;
    }

    const { settings } = this.state;
    for (const key in settings) {
      if (!settings[key] || !this.state.minMax[key]) continue;

      const { min, max } = this.state.minMax[key];
      if ((min && min > settings[key]) || (max && max < settings[key])) {
        alert(
          `The value of ${camelCaseToText(
            key
          )} should be in the range [${min}-${max}]`
        );
        return;
      }
    }

    const params = { ...this.state };
    delete params.src0;
    delete params.src1;
    delete params.src2;

    const files = [];
    if (this.state.file0) files.push(this.state.file0);
    if (this.state.file1) files.push(this.state.file1);
    if (this.state.file2) files.push(this.state.file2);

    this.props.actions.createProject(params, files);
  };

  onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let state = {};
      state["file" + this.state.currentAttachment] = event.target.files[0];

      let reader = new FileReader();
      reader.onload = (event) => {
        state["src" + this.state.currentAttachment] = event.target.result;
        state.currentAttachment = null;

        this.setState(state);
        this.fileUpload.value = null;
      };

      reader.readAsDataURL(event.target.files[0]); // convert to base64 string
    }
  };

  onAddAttachmentClicked = (index) => {
    this.setState({ currentAttachment: index });
    this.fileUpload.click();
  };

  removeAttachment = (event, index) => {
    event.stopPropagation();
    let state = {};
    state["file" + index] = undefined;
    state["src" + index] = undefined;
    this.setState(state);
  };

  onCountrySelected = (item) => {
    this.setState({ countryCode: item.value });
  };

  onProjectGroupSelected = (item) => {
    this.setState({ projectGroupId: item.value });
  };

  onArrayTypeSelected = (item) => {
    this.setState({ arrayType: item.value });
  };

  onGroupCleaningTimeChanged = (groupNumber, value, param) => {
    const { groupCleaningTimes } = this.state;
    let groupCleaningTime = groupCleaningTimes.find(
      (g) => g.groupNumber === groupNumber
    );
    if (!groupCleaningTime) {
      groupCleaningTime = {
        groupNumber,
        cleaningTime: { hour: "", minute: "" },
      };
      groupCleaningTimes.push(groupCleaningTime);
    }

    if (param === "hour") {
      let hour = parseInt(value);
      if (isNaN(parseInt(value))) groupCleaningTime.cleaningTime.hour = "";
      else {
        if (hour < 0) hour = "0";
        else if (value.length === 2 && hour < 10) hour = value;
        else if (hour > 23) hour = value.substr(0, 1);
        groupCleaningTime.cleaningTime.hour = hour;
      }
    } else if (param === "minute") {
      let minute = parseInt(value);
      if (isNaN(parseInt(value))) {
        groupCleaningTime.cleaningTime.minute = "";
      } else {
        if (minute < 0) minute = "0";
        else if (value.length === 2 && minute < 10) minute = value;
        else if (minute > 59) minute = value.substr(0, 1);

        groupCleaningTime.cleaningTime.minute = minute;
      }
    }
    this.setState({ groupCleaningTimes });
  };

  renderLine = ({ leftText, rightText, leftParam, rightParam }) => {
    return (
      <div className="input-line">
        <div className="line">
          <div className="input-description">
            <span className="text left">{leftText}</span>
            {!!rightText && <span className="text right">{rightText}</span>}
          </div>
          <input
            className="left"
            placeholder=""
            type="number"
            value={this.state.settings[leftParam]}
            onChange={(e) =>
              this.onChange(e.target.value, `settings.${leftParam}`)
            }
          />
          {!!rightParam && (
            <input
              className="right"
              placeholder=""
              type="number"
              value={this.state.settings[rightParam]}
              onChange={(e) =>
                this.onChange(e.target.value, `settings.${rightParam}`)
              }
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Modal
        title="Create Project"
        onClose={this.props.closeCreateProject}
        className="create-project"
      >
        <div className="input-line">
          <div className="input-description">Project Name</div>
          <div className="line">
            <input
              className="left"
              placeholder=""
              type="text"
              onChange={(e) => this.onChange(e.target.value, "projectName")}
            />

            <Dropdown
              options={this.props.projectGroups.map((projectGroup) => ({
                label: projectGroup.name,
                value: projectGroup._id,
              }))}
              placeholder="Project Group"
              onChange={this.onProjectGroupSelected}
              className="dropdown right"
              controlClassName="control"
            />
          </div>
        </div>
        <div className="input-line">
          <div className="input-description">
            <img src={locationIcon} />
            <span className="text"> Location</span>
          </div>
          <div className="line">
            <Dropdown
              options={COUNTRIES}
              placeholder="Select country"
              onChange={this.onCountrySelected}
              className="dropdown"
              controlClassName="control"
            />
            <input
              className="right"
              placeholder="State/province"
              type="string"
              onChange={(e) => this.onChange(e.target.value, "state")}
            />
          </div>
          <div className="line">
            <input
              className="left"
              placeholder="City"
              type="text"
              onChange={(e) => this.onChange(e.target.value, "city")}
            />
            <input
              className="right"
              placeholder="Street address"
              type="text"
              onChange={(e) => this.onChange(e.target.value, "streetAddress")}
            />
          </div>
        </div>
        <div className="input-line">
          <div className="input-description">
            <img src={powerIcon} />
            <span className="text"> Total Power [MW]</span>
          </div>
          <input
            placeholder=""
            type="number"
            onChange={(e) => this.onChange(e.target.value, "totalPower")}
          />
        </div>

        {this.state.groupCleaningTimes.map((groupCleaningTime) => (
          <div className="input-line" key={groupCleaningTime.groupNumber}>
            <div className="input-description">
              Group {groupCleaningTime.groupNumber} - Daily cleaning time
            </div>
            <div className="line">
              <input
                className="left"
                placeholder="Hour"
                type="text"
                maxLength="2"
                value={groupCleaningTime.cleaningTime.hour}
                onChange={(e) =>
                  this.onGroupCleaningTimeChanged(
                    groupCleaningTime.groupNumber,
                    e.target.value,
                    "hour"
                  )
                }
              />
              <input
                className="right"
                placeholder="Minutes"
                type="text"
                maxLength="2"
                value={groupCleaningTime.cleaningTime.minute}
                onChange={(e) =>
                  this.onGroupCleaningTimeChanged(
                    groupCleaningTime.groupNumber,
                    e.target.value,
                    "minute"
                  )
                }
              />
            </div>
          </div>
        ))}

        <div className="input-line">
          <div className="input-description">
            <img src={clockIcon} />
            <span className="text">Cleaning Retry Period</span>
          </div>

          <div className="line full">
            <input
              placeholder="Period in minutes"
              type="number"
              value={this.state.cleaningAttemptEveryMins}
              onChange={(e) =>
                this.onChange(e.target.value, "cleaningAttemptEveryMins")
              }
            />
          </div>
        </div>

        <div className="input-line">
          <div className="input-description">
            <span className="text left double">Array Type</span>
            <span className="text right">Robot Version</span>
          </div>
          <div className="line">
            <Dropdown
              options={[
                { label: "Fixed", value: "fixed" },
                { label: "Solar tracking", value: "solar-tracking" },
              ]}
              onChange={this.onArrayTypeSelected}
              value={this.state.item ? this.state.item.row : null}
              className="dropdown left"
              controlClassName="control"
            />

            <div className="right robot-version">
              <input
                type="radio"
                name="robotVersion"
                onChange={(e) => this.onChange(3, "settings.robotVersion")}
                checked={this.state.settings.robotVersion === 3}
              />
              <label htmlFor="V3">V3</label>

              <input
                type="radio"
                name="robotVersion"
                onChange={(e) => this.onChange(4, "settings.robotVersion")}
                checked={this.state.settings.robotVersion === 4}
              />
              <label htmlFor="V4">V4</label>
            </div>
          </div>
        </div>

        {this.state.arrayType === "solar-tracking" ? (
          <div className="input-line">
            <div className="input-description">
              <img src={vehiclesIcon} />
              <span className="text"> Number of Vehicles</span>
            </div>
            <input
              placeholder=""
              type="number"
              onChange={(e) =>
                this.onChange(e.target.value, "numberOfVehicles")
              }
            />
          </div>
        ) : null}

        <div className="input-line">
          <div className="input-description">
            <img src={robotsIcon} />
            <span className="text"> Robots</span>
          </div>
          <div className="line">
            <input
              className="left"
              placeholder="# Automatic robots"
              type="number"
              maxLength="3"
              onChange={(e) =>
                this.onChange(e.target.value, "numberOfTypeOneRobots")
              }
            />
            <input
              className="right"
              placeholder="# Manual robots"
              type="number"
              maxLength="3"
              onChange={(e) =>
                this.onChange(e.target.value, "numberOfTypeTwoRobots")
              }
            />
          </div>
        </div>

        {this.renderLine({
          leftText: "Cleaning drive speed [m/minute]",
          rightText: "Transit drive speed [m/minute]",
          leftParam: "driveSpeedCleaning",
          rightParam: "driveSpeedTransit",
        })}

        {this.renderLine({
          leftText: "Cleaning rotor speed [RPM]",
          rightText: "Transit rotor speed [RPM]",
          leftParam: "rotorSpeedCleaning",
          rightParam: "rotorSpeedTransit",
        })}

        {this.renderLine({
          leftText: "Cleaning stop distance [mm]",
          rightText: "Transit stop distance [mm]",
          leftParam: "stopDistanceCleaning",
          rightParam: "stopDistanceTransit",
        })}

        {this.renderLine({
          leftText: "Drive Motor 0.5 sec current threshold [Amp]",
          rightText: "Cleaning Motor 0.5 sec current threshold [Amp]",
          leftParam: "halfSecCurrentThresholdDriveMotor",
          rightParam: "halfSecCurrentThresholdCleaningMotor",
        })}

        {this.renderLine({
          leftText: "Drive Motor 2 sec current threshold [Amp]",
          rightText: "Cleaning Motor 2 sec current threshold [Amp]",
          leftParam: "twoSecCurrentThresholdDriveMotor",
          rightParam: "twoSecCurrentThresholdCleaningMotor",
        })}

        {this.renderLine({
          leftText: "Drive Motor Min % - real RPM/target",
          rightText: "Cleaning Motor Min % - real RPM/target",
          leftParam: "rpmTargetRatioDriveMotor",
          rightParam: "rpmTargetRatioCleaningMotor",
        })}

        {this.renderLine({
          leftText: "Rotor 5 sec speed ratio [%]",
          leftParam: "cleanMotorSpeedRatioWhileTestingPERC",
          rightText: "Reserve battery threshold [Ah]",
          rightParam: "reserveBatteryThresholdAh",
        })}

        {this.renderLine({
          leftText: "Battery optimized threshold - low load [V]",
          leftParam: "batteryThresholdLowLoad",
          rightText: "Battery optimized threshold - high load [V]",
          rightParam: "batteryThresholdHighLoad",
        })}

        {this.renderLine({
          leftText: "Battery threshold - old [V]",
          leftParam: "batteryVoltageThreshold",
        })}

        <div className="input-line">
          <div className="input-description">
            <span className="text left">Robot operation mode</span>
            <span className="text right">Battery size [Ah]</span>
          </div>

          <div className="line">
            <Dropdown
              options={[
                { value: "autonomous", label: "Autonomous" },
                { value: "portable", label: "Portable" },
                // { value: "inactive", label: 'Inactive' }
              ]}
              onChange={(option) =>
                this.onChange(option.value, "settings.operationMode")
              }
              value={this.state.settings.operationMode}
              className="dropdown left"
              controlClassName="control"
              placeholderClassName="placeholder"
            />

            <Dropdown
              options={[
                { value: "9", label: "9" },
                { value: "18", label: "18" },
                { value: "24", label: "24" },
                { value: "30", label: "30" },
              ]}
              onChange={(option) =>
                this.onChange(option.value, "settings.batterySize")
              }
              value={this.state.settings.batterySize?.toString()}
              className="dropdown right"
              controlClassName="control"
              placeholderClassName="placeholder"
            />
          </div>
        </div>

        <div className="input-line">
          <div className="line thirds">
            <span className="checkbox third">
              <input
                type="checkbox"
                checked={this.state.settings.fastDrying}
                onChange={(e) =>
                  this.onChange(e.target.checked, "settings.fastDrying")
                }
              />
              <span className="text">Fast drying</span>
            </span>

            <span className="checkbox">
              <span className="center">
                <input
                  type="checkbox"
                  checked={this.state.settings.chargeFixDuringBatteryTest}
                  onChange={(e) =>
                    this.onChange(
                      e.target.checked,
                      "settings.chargeFixDuringBatteryTest"
                    )
                  }
                />
                <span className="text">Fix charging during battery test</span>
              </span>
            </span>
            <span className="checkbox right">
              <input
                type="checkbox"
                checked={this.state.settings.jammedRobotFixOnTable}
                onChange={(e) =>
                  this.onChange(
                    e.target.checked,
                    "settings.jammedRobotFixOnTable"
                  )
                }
              />
              <span className="text">Fix jammed robot on table</span>
            </span>
          </div>
        </div>
        <div className="input-line">
          <div className="line full">
            <div className="input-description">
              <span className="text">
                Double cleaning distance in row edges [meter]
              </span>
            </div>
            <input
              placeholder=""
              type="number"
              value={this.state.settings.rowEdgesDoubleCleaningDistance}
              maxLength="3"
              onChange={(e) =>
                this.onChange(
                  e.target.value,
                  "settings.rowEdgesDoubleCleaningDistance"
                )
              }
            />
          </div>
        </div>

        <div className="input-line">
          <div className="input-description">
            <img src={weatherIcon} />
            <span className="text"> Weather thresholds</span>
          </div>
          <div className="line full">
            <input
              placeholder="Wind speed [km/hour]"
              type="number"
              value={this.state.windCleaningThreshold}
              onChange={(e) =>
                this.onChange(e.target.value, "windCleaningThreshold")
              }
            />
          </div>
          <div className="line full">
            <input
              placeholder="Relative humidity [%]"
              type="number"
              value={this.state.humidityCleaningThreshold}
              onChange={(e) =>
                this.onChange(e.target.value, "humidityCleaningThreshold")
              }
            />
          </div>
          <div className="line full">
            <input
              placeholder="Rain [mm/hour]"
              type="number"
              value={this.state.rainCleaningThreshold}
              onChange={(e) =>
                this.onChange(e.target.value, "rainCleaningThreshold")
              }
            />
          </div>
        </div>

        {/* <div className="input-line">
                    <div className="input-description">
                        <img src={clockIcon} />
                        <span className="text">Availability start time</span>
                    </div>
                    <input className="left" placeholder="hh:mm" type="text" maxLength="5"                        
                        onChange={(e) => this.onAvailabilityTimeChanged(e.target.value)} />
                </div> */}

        <div className="input-line">
          <div className="input-description">
            <img src={attachmentIcon} />
            <span className="text"> Attachments</span>
          </div>
          <div className="line attachments">
            <div className="item">
              {this.state.src0 ? (
                <div className="image-wrapper">
                  <img
                    className="close"
                    src={close}
                    onClick={(e) => this.removeAttachment(e, 0)}
                  />
                  <img className="image" src={this.state.src0} />
                </div>
              ) : (
                <div
                  className="text"
                  onClick={() => this.onAddAttachmentClicked(0)}
                >
                  Add file 1
                </div>
              )}
            </div>
            <div className="item">
              {this.state.src1 ? (
                <div className="image-wrapper">
                  <img
                    className="close"
                    src={close}
                    onClick={(e) => this.removeAttachment(e, 1)}
                  />
                  <img className="image" src={this.state.src1} />
                </div>
              ) : (
                <div
                  className="text"
                  onClick={() => this.onAddAttachmentClicked(1)}
                >
                  Add file 2
                </div>
              )}
            </div>
            <div className="item last">
              {this.state.src2 ? (
                <div className="image-wrapper">
                  <img
                    className="close"
                    src={close}
                    onClick={(e) => this.removeAttachment(e, 2)}
                  />
                  <img className="image" src={this.state.src2} />
                </div>
              ) : (
                <div
                  className="text"
                  onClick={() => this.onAddAttachmentClicked(2)}
                >
                  Add file 3
                </div>
              )}
            </div>
            <input
              ref={(ref) => (this.fileUpload = ref)}
              type="file"
              onChange={this.onFileChange}
            />
          </div>
        </div>

        <div className="button" onClick={this.onSubmit}>
          SUBMIT
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  //
  // ("state",state)
  return {
    ...state.createProjectReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ createProject, addAttachment }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectComponent);
