import React, { useEffect, useRef, useState } from "react";
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import DatePicker from "react-datepicker";
import { Bar } from 'react-chartjs-2';
import ApiHandler from "./api";
import { LOCAL_STORAGE_PROJECT } from "../../consts";

const zeroPointVoltage = 15;
const maxVoltage = 17;

const batteryHistoryOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
    },
    responsive: true,    
    elements: {
        line: {
            fill: false
        }
    },    
    scales: {
        x: {            
            grid: {
                display: false, // This will remove the grid lines for the X axis
            },
        },
        y: {
            max: maxVoltage,
            min: zeroPointVoltage,
            grid: {
                display: false, // This will remove the grid lines for the X axis
            },
        },
    },
};

const batteryHistoryDataInit = {
    labels: [''],
    datasets: [{
        type: "line",
        borderColor: '#05b2aa',
        pointRadius: 2,
        borderWidth: 2,
        label: 'Avg. voltage',
        backgroundColor: '#05b2aa',
        data: [],
        yAxisID: 'y-axis-1'
    }]
};



const DailyVoltage = ({ width, timeZone }) => {


    let now = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const [startDate, setStartDate] = useState(lastMonth);
    const [endDate, setEndDate] = useState(now);
    const [batteryHistoryData, setBatteryHistoryData] = useState(batteryHistoryDataInit);
    
    const [project, setProject] = useState();
    

    const apiHandler = new ApiHandler();

    const handleData = async () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);        
        const { reports } = await apiHandler.getAvgVoltage(project._id, startDate, endDate);
        reports.reverse();
        batteryHistoryData.labels = reports.map(item => timeZone.getLocalTime(item.date, true).split("/").slice(0, 2).join("/"));
        batteryHistoryData.datasets[0].data = reports.map(item => item.voltageModel["8"] ? item.voltageModel["8"].voltage : 0);
        setBatteryHistoryData(batteryHistoryData);
    }

    useEffect(() => {
        
        if (!project)
            return;

        handleData();
    }, [project, startDate, endDate]);

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));    
        setProject(project);
    }, []);


    const voltagesDateChanged = dates => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    };

    return (<>
        <div className="history-graph first">
            <div className="title-wrapper">
                <div className="title">DAILY AVG VOLTAGE</div>
                <DatePicker
                    selected={startDate}
                    onChange={voltagesDateChanged}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    shouldCloseOnSelect={false}
                    customInput={<DateCustomInput
                        logsStartDate={startDate}
                        logsEndDate={endDate} />}                
                />
            </div>
            <div className="wrapper">
                {!!batteryHistoryData.datasets[0]?.data.length && <Bar
                    data={batteryHistoryData}
                    options={batteryHistoryOptions}                    
                    height={170}
                    width={width}
                />}
            </div>
            <div className="labels">
                <div className="label">

                    <span className="mark1 green"></span>
                    <span className="text">Avg. battery voltage</span>

                </div>
            </div>


        </div>
    </>)
}

export default DailyVoltage;