import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "./dashboard.scss";
import ApiHandler from './api';
import { LOCAL_STORAGE_PROJECT } from "../../consts";

const maxVoltage = 17;

const gaugeOptions = {
    circumference: 180,
    rotation: 270,    
    cutout: '85%',
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false
        }
    }
}



const AvgVoltage = ({ timeZone }) => {
    const [gaugeData, setGaugeData] = useState({labels:[],datasets:[]});
    const [avgVoltageHour, setAvgVoltageHour] = useState(0);
    const [avgVoltageReports, setAvgVoltageReports] = useState([]);
    const [project, setProject] = useState();
    

    const apiHandler = new ApiHandler();

    const handleData = async () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        
        const result = await apiHandler.getAvgVoltage(project._id, yesterday, new Date());        
        setAvgVoltageReports(result.reports);
    }

    useEffect(() => {
        
        if (!project)
            return;

        handleData();
    }, [project]);

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));    
        setProject(project);
    }, []);


    useEffect(() => {
        if (avgVoltageReports.length) {
            handleGaugeData();
        }
    }, [avgVoltageReports, avgVoltageHour]);

    const handleGaugeData = () => {

        const dataPoint = getAvgVolatageItem();

        if (!dataPoint)
            return;
        /******************/

        const data = {
            labels: [],
            datasets: [{}],
        }

        data.datasets[0].data = [Math.round(dataPoint.voltage * 100) / 100, maxVoltage];


        if (avgVoltageHour === 0) {
            data.datasets[0].backgroundColor = [
                '#e36510',
                '#e7e8e9'
            ];
        } else if (avgVoltageHour === 1) {
            data.datasets[0].backgroundColor = [
                '#05b2aa',
                '#e7e8e9'
            ];

        }

        data.labels = [timeZone.getLocalTime(new Date(), undefined, undefined, true), 'Full'];
        setGaugeData(data);
    }



    const getAvgVoltage = () => {
        if (!avgVoltageReports.length)
            return;

        const item = getAvgVolatageItem();
        return item ? item.voltage.toFixed(2) : 0;
    }

    const getAvgVolatageItem = () => {
        if (avgVoltageHour === 0)
            return avgVoltageReports[0].voltageModel["8"];
        else
            return avgVoltageReports[0].voltageModel["12"];
    }


    return (<>
        {!!avgVoltageReports.length && <div className="gauge">

            <div className="title">AVG VOLTAGE</div>

            <div className="wrapper">
                <Doughnut className="graph" data={gaugeData} options={gaugeOptions} width={200} />
                <div className="central">
                    <div className="main-text voltage">{getAvgVoltage()}</div>
                    <div className="sub-text">Average battery<br />voltage</div>
                </div>
            </div>
            <div className="hours">
                {avgVoltageReports[0].voltageModel["8"] ? <span
                    className={avgVoltageHour === 0 ? "hour selected" : "hour"}
                    onClick={() => setAvgVoltageHour(0)}>08:00</span>
                    : null}

                {avgVoltageReports[0].voltageModel["12"] ?
                    <span
                        className={avgVoltageHour === 1 ? "hour selected" : "hour"}
                        onClick={() => setAvgVoltageHour(1)}>12:00</span>
                    : null}
            </div>
        </div>}
    </>
    )
}

export default AvgVoltage;