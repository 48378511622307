import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './modal.scss';
import close from './close-icon.svg';

class ModalComponent extends Component {
    constructor(props) {
        super(props);

    }

    getClass = ()=>{
        if (this.props.className)
            return this.props.className + ' modal';

        return 'modal';
    }

    render() {
        return (
            <div className="modal-wrapper">
                <div className={this.getClass()}>
                    <img className="close" src={close} onClick={this.props.onClose}/>
                    <div className="title">{this.props.title}</div>
                    <div className="my-content">{this.props.children}</div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    //console.log("state.indexReducer", state.indexReducer)
    return {

    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);