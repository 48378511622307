import React, {useEffect, useState} from 'react';
import './cleaningControl.scss'
import spinner from "../../loader/white_spinner.svg";
import {
    COMMAND_CONTINUE,
    COMMAND_GO_HOME,
    COMMAND_PAUSE,
    COMMAND_START,
    COMMAND_RESET
} from "./sendCommand";
import greySpinner from "./grey_spinner.svg";

import playIcon from "./play-icon.svg";
import cleaningInProgress from "./cleaning-icon-spinning.svg";
import Modal from "../../modal/modal";
import ApiHandler from "../../api";


const ParkingStatuses = [
    'parking',
    'test',
]


const CleaningStatuses = [
    'driving',
    'pickup',
    'drop-off',
    'waiting',
]

const PausedStatuses = [
    'paused',
    'error',
    'emergency',
]

const apiHandler = new ApiHandler();

const CleaningControlVehicleNew = function ({
                                                project,
                                                vehicle,
                                                user,
                                                currentStatus,
                                                offline,
                                                errorDetails
                                            }) {
    const [showModal, setShowModal] = useState(false);
    const [startCleaningSeconds, setStartCleaningSeconds] = useState(0);
    const [command, setCommand] = useState('');
    const [cleaningTime, setCleaningTime] = useState();
    const [pendingCommand, setPendingCommand] = useState();

    useEffect(() => {
        const interval = setInterval(() => {
            setStartCleaningSeconds(startCleaningSeconds + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (vehicle)
            setCleaningTime(vehicle.cleaningTime);
        else
            setCleaningTime(project.groups[0].cleaningTime);

    }, [vehicle, project]);


    const getCleaningTime = () => {
        if (pendingCommand)
            return;

        let total = Math.round(startCleaningSeconds);
        let hours = Math.floor(total / 3600);
        total = total % 3600;
        let minutes = Math.floor(total / 60);
        let seconds = total % 60;
        if (hours && minutes < 10)
            minutes = '0' + minutes;

        if (seconds < 10)
            seconds = '0' + seconds;

        if (hours)
            return `${hours}:${minutes}:${seconds}`;
        else
            return `${minutes}:${seconds}`;
    }


    const getScheduledCleaning = () => {
        if (!cleaningTime)
            return;

        // console.log("cleaningTime",cleaningTime)

        if (cleaningTime.minute < 10)
            return `${cleaningTime.hour}:0${cleaningTime.minute}`;
        else
            return `${cleaningTime.hour}:${cleaningTime.minute}`;
    }


    const commandClicked = (command) => {
        if (pendingCommand)
            return;

        setCommand(command);
        setShowModal(true);
    }

    const sendCommand = async () => {
        console.log("sendCommand", command);
        setPendingCommand(command);
        setShowModal(false);

        try {
            const data = await apiHandler.sendCommand({
                projectId: project._id,
                command
            });
        } catch (err) {
            alert("Can't send command");
        } finally {
            setPendingCommand(null);
        }
    }

    const capitalize = (s) => {
        if (typeof s !=='string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const getModalTitle = () => {
        if (command ===COMMAND_GO_HOME)
            return 'Stop Cleaning (Go Home)';
        else if (command ===COMMAND_START)
            return 'Start Cleaning';
        else if (command ===COMMAND_CONTINUE)
            return 'Continue';
        else if (command ===COMMAND_PAUSE)
            return 'Pause';
        else if (command ===COMMAND_RESET)
            return 'Reset Vehicle'
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const isCleaningPrevented = () => {
        if (currentStatus !=='cleaning-prevented')
            return false;

        const hour = new Date().toLocaleString("en-US", {
            timeZone: project.timezone.timeZoneId,
            hour: '2-digit',
            hour12: false
        })
        return hour > cleaningTime.hour;
    }

    const getMainClass = () => {
        if (offline)
            return 'offline cleaning-control vehicle';
        else if (vehicle && !vehicle && vehicle.autoMode)
            return 'offline auto-mode cleaning-control vehicle';
        return 'cleaning-control vehicle'
    }

    const getOfflineTitle = () => {
        if (vehicle)
            return `Vehicle ${vehicle.vehicleNumber} is offline`;
        return project.projectName + 'is offline';
    }


    const cleaningStatusDiv = (text) => {
        return <div className="cleaning-status cleaning-pending">
            <div>{text} ({currentStatus}) {pendingCommand ? ': Pending' : null}
                {!pendingCommand && text ==='Cleaning In Progress' ? <img src={cleaningInProgress}/> : null}
            </div>
        </div>
    }

    const engineErrorsText = () => errorDetails.extraData ? `(engines: ${JSON.stringify(errorDetails.extraData.engineErrorCodes)})` : '';

    const getErrorDetails = () => {
        if (currentStatus !=='error')
            return;

        return <span
            className="error-details">{`${errorDetails.errorText || ''} ${engineErrorsText()}`}</span>
    }


    return (
        <div className={getMainClass()}>
            {showModal && !offline ?
                <Modal title={getModalTitle()} onClose={closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={closeModal}>Cancel</div>
                        <div className="button" onClick={sendCommand}>Continue</div>

                    </div>

                </Modal> : null}

            {showModal && offline ?
                <Modal title={getOfflineTitle()} onClose={closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={closeModal}>Ok</div>
                        {/*<div className="button" onClick={sendCommand}>Continue</div>*/}

                    </div>

                </Modal> : null}

            {CleaningStatuses.includes(currentStatus) ?
                <div className="upper">
                    <div className="cleaning-status cleaning-in-progress">
                        {cleaningStatusDiv('Cleaning In Progress')}
                    </div>
                    <div className="right">
                        <div className="time">{getCleaningTime()}</div>
                        {user.role !=="projectOwner" ? <div className="buttons">

                            <div
                                className={pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
                                onClick={() => commandClicked(COMMAND_PAUSE)}>
                                <span className="pause-icon"></span>
                                <span className="pause-icon"></span>
                                <img src={greySpinner} className="spinner"/>
                                <span className="text">PAUSE</span>
                            </div>

                            <div
                                className={pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
                                onClick={() => commandClicked(COMMAND_GO_HOME)}>
                                <span className="stop-icon"></span>
                                <img src={spinner} className="spinner"/>
                                <span className="text">GO HOME</span>
                            </div>

                        </div> : null}
                    </div>
                </div> : null}
            {PausedStatuses.includes(currentStatus) ?
                <div className="upper">
                    {cleaningStatusDiv(currentStatus ==='error' ? 'Error' : currentStatus ==='emergency' ? 'Emergency' : 'Cleaning Paused')}
                    <div className="right">
                        {user.role !=="projectOwner" ? <div className="buttons">

                            <div
                                className={pendingCommand ===COMMAND_CONTINUE ? 'button start loading' : 'button start'}
                                onClick={() => commandClicked(COMMAND_CONTINUE)}>
                                <img src={playIcon} className="icon"/>
                                <img src={spinner} className="spinner"/>
                                <span className="text">CONTINUE</span>
                            </div>

                            <div
                                className={pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
                                onClick={() => commandClicked(COMMAND_GO_HOME)}>
                                <span className="stop-icon"></span>
                                <img src={spinner} className="spinner"/>
                                <span className="text">GO HOME</span>
                            </div>
                        </div> : null}
                    </div>
                </div> : null
            }
            {!currentStatus || ParkingStatuses.includes(currentStatus) ?
                <div className="upper">
                    {cleaningStatusDiv(currentStatus ==='parking' ? 'Parking' : 'Self Test')}
                    <div className="right">
                        {cleaningTime ? <div className="scheduled-cleaning">
                            {isCleaningPrevented() ? <span>Cleaning prevented at </span> :
                                <span>Cleaning scheduled for </span>}
                            {getScheduledCleaning()}</div> : null}
                        {user.role !=="projectOwner" ? <div className="buttons">
                            <div
                                className={pendingCommand ===COMMAND_START ? 'button start loading' : 'button start'}
                                onClick={() => commandClicked(COMMAND_START)}>
                                <img src={playIcon} className="icon"/>
                                <img src={spinner} className="spinner"/>
                                <span className="text">START</span>
                            </div>
                        </div> : null}
                    </div>
                </div> : null
            }


            {currentStatus ==='going-home' ?
                <div className="upper">
                    {cleaningStatusDiv('Going Home')}
                    <div className="right">
                        {/*<div className="time">06:44</div>*/}
                        {user.role !=="projectOwner" ? <div className="buttons">

                            <div
                                className={pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
                                onClick={() => commandClicked(COMMAND_PAUSE)}>
                                <span className="pause-icon"></span>
                                <span className="pause-icon"></span>
                                <img src={greySpinner} className="spinner"/>
                                <span className="text">PAUSE</span>
                            </div>
                        </div> : null}
                    </div>
                </div> : null}
            {vehicle ?
                <div>
                    {getErrorDetails()}
                    {user.role !=="projectOwner" ? <div className="auto-mode orange">
                        {vehicle && vehicle.autoMode ?
                            <span>Auto Mode</span>
                            : <span>Manual Mode</span>
                        }

                        <span className="reset right"
                              onClick={() => commandClicked(COMMAND_RESET)}>reset vehicle</span>

                    </div> : null}
                </div>
                : null
            }
        </div>
    )


};


export default CleaningControlVehicleNew;