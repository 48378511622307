import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {getCleaningPlanStarted} from "../cleaningPlan/getCleaningPlanStartedAction";
import {getCleaningPlanSuccess} from "../cleaningPlan/getCleaningPlanSuccessAction";
import {getCleaningPlanError} from "../cleaningPlan/getCleaningPlanErrorAction";

export function uploadCleaningPlanCsv(fromData) {

    return function (dispatch) {
        let url = HOST_URL + '/api/plans/import';

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCleaningPlanStarted());

        return axios.post(url, fromData, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getCleaningPlanSuccess(response.data.panelRows));
            }
            else {
                dispatch(getCleaningPlanError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            if (error.response.data && error.response.data.message)
                alert(error.response.data.message);

            dispatch(getCleaningPlanError(error));
        });
    };
}