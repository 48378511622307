export const ADD_ROW_STARTED = 'ADD_ROW_STARTED';
export const ADD_ROW_ERROR = 'ADD_ROW_ERROR';
export const ADD_ROW_SUCCESS = 'ADD_ROW_SUCCESS';


const INITIAL_STATE = {
    isLoading: false
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case ADD_ROW_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case ADD_ROW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newRow: action.payload.row
            }
        case ADD_ROW_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        default:
            return state;
    }
}