import {EDIT_ROBOT_SETTINGS_SUCCESS} from "./editRobotSettingsReducer";
import {EDIT_USER_SUCCESS} from "./editUserReducer";

export const GET_USERS_STARTED = 'GET_USERS_STARTED';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const ALERT_SHOWN = 'ALERT_SHOWN';

const INITIAL_STATE = {
    users: [],
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_USERS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload.users
            };

        case GET_USERS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };

        case EDIT_USER_SUCCESS:
            let {users} = state;
            const existing = users.find(user=>(user.id || user._id) ===action.payload.user._id);


            if (existing) {

                let index = users.indexOf(existing);


                if (action.payload.user.isDeleted)
                    users.splice(index, 1);
                else
                    users[index] = action.payload.user;

                users = [].concat(users);
            }
            else {

                users = [action.payload.user].concat(state.users);
            }
            return {
                ...state,
                users,
                closeEdit: true
            }

        default:
            return state;
    }
}