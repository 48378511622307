// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-widget {
  position: absolute;
  top: 35px;
  right: 30px;
  width: 260px;
  z-index: 10; }
  .users-widget .icon-wrapper {
    width: 100%;
    float: right; }
  .users-widget .icon {
    float: right;
    cursor: pointer; }
  .users-widget .users-menu {
    display: inline-block;
    box-shadow: -3px 5px 5px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: solid 1px #e9e9ec;
    z-index: 100; }
    .users-widget .users-menu div {
      display: inline-block; }
    .users-widget .users-menu .item.clickable {
      cursor: pointer; }
    .users-widget .users-menu .item.clickable:hover {
      background-color: var(--very-light-blue); }
    .users-widget .users-menu .item {
      padding: 16px;
      border-bottom: solid 1px var(--very-light-blue);
      width: 100%; }
      .users-widget .users-menu .item .text {
        width: 100%;
        font-size: 17px;
        letter-spacing: -0.27px;
        color: var(--gunmetal); }
      .users-widget .users-menu .item .sub-text {
        width: 100%;
        margin-top: 4px;
        font-size: 15px;
        letter-spacing: -0.23px;
        color: var(--brown-grey); }
`, "",{"version":3,"sources":["webpack://./src/components/usersWidget/usersWidget.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,WAAW,EAAA;EALb;IAQI,WAAW;IACX,YAAY,EAAA;EAThB;IAaI,YAAY;IACZ,eAAe,EAAA;EAdnB;IAqBI,qBAAqB;IACrB,+CAAgD;IAChD,uBAAuB;IACvB,yBAAyB;IACzB,YAAY,EAAA;IAzBhB;MAmBM,qBAAqB,EAAA;IAnB3B;MA2BM,eAAe,EAAA;IA3BrB;MA8BM,wCAAwC,EAAA;IA9B9C;MAkCM,aAAa;MACb,+CAA+C;MAC/C,WAAW,EAAA;MApCjB;QAuCQ,WAAW;QACX,eAAe;QACf,uBAAuB;QACvB,sBAAsB,EAAA;MA1C9B;QA8CQ,WAAW;QACX,eAAe;QACf,eAAe;QACf,uBAAuB;QACvB,wBAAwB,EAAA","sourcesContent":[".users-widget {\n  position: absolute;\n  top: 35px;\n  right: 30px;\n  width: 260px;\n  z-index: 10;\n\n  .icon-wrapper {\n    width: 100%;\n    float: right;\n  }\n\n  .icon {\n    float: right;\n    cursor: pointer;\n  }\n\n  .users-menu {\n    div {\n      display: inline-block;\n    }\n    display: inline-block;\n    box-shadow: -3px 5px 5px 2px rgba(0, 0, 0, 0.10);\n    background-color: white;\n    border: solid 1px #e9e9ec;\n    z-index: 100;\n    .item.clickable {\n      cursor: pointer;\n    }\n    .item.clickable:hover {\n      background-color: var(--very-light-blue);\n    }\n\n    .item {\n      padding: 16px;\n      border-bottom: solid 1px var(--very-light-blue);\n      width: 100%;\n\n      .text {\n        width: 100%;\n        font-size: 17px;\n        letter-spacing: -0.27px;\n        color: var(--gunmetal);\n      }\n\n      .sub-text {\n        width: 100%;\n        margin-top: 4px;\n        font-size: 15px;\n        letter-spacing: -0.23px;\n        color: var(--brown-grey);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
