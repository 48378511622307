import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './cleaningPlan.scss';
import Modal from "../modal/modal";
import {getCleaningPlan} from "./getCleaningPlanAction";
import deleteCleaningPlanIcon from './delete-plan-icon.svg';
import {editCleaningPlan} from "./editCleaningPlanAction";
import {deleteCleaningPlan} from "./deleteCleaningPlanAction";
import {addEmptyRow} from "./addEmptyRow";
import Loader from "../loader/loader";
import {getRows} from "../rows/getRowsAction";


class CleaningPlanComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day1: this.props.cleaningPlan.filter(item => item.day ===1).map(cp => cp.rowNumber).join(", "),
            day2: this.props.cleaningPlan.filter(item => item.day ===2).map(cp => cp.rowNumber).join(", "),
        };
    }

    componentDidMount() {
        this.props.actions.getCleaningPlan(this.props.project._id, this.props.vehicle.vehicleName);
        this.props.actions.getRows(this.props.project._id)
    }


    componentWillReceiveProps(nextProps) {
        //console.log("nextProps", nextProps);
        if (nextProps.isSaved && this.props.isSaved !==nextProps.isSaved) {
            console.log("nextProps.isSaved", nextProps.isSaved)
            this.props.closeCleaningPlan();
        }
        if (nextProps.cleaningPlan && nextProps.cleaningPlan !==this.props.cleaningPlan) {
            this.setState({
                day1: nextProps.cleaningPlan.filter(item => item.day ===1).map(cp => cp.rowNumber).join(", "),
                day2: nextProps.cleaningPlan.filter(item => item.day ===2).map(cp => cp.rowNumber).join(", "),
            })
        }

    }

    onChange = (value, day) => {
        const letters = ['0','1','2','3','4','5','6','7','8','9',',',' ']
        let str='';
        for (let i=0;i<value.length; i++) {
            if (letters.includes(value.charAt(i)))
                str+=value.charAt(i);
        }

        const state = {};
        state[day] = str;
        this.setState(state);
    }

    onSubmit = () => {
        const params = {
            vehicleId: this.props.vehicle.vehicleId,
            data: [
                this.state.day1.split(",").map(s => Number(s)).filter(n => !isNaN(n) && n > 0),
                this.state.day2.split(",").map(s => Number(s)).filter(n => !isNaN(n) && n > 0)
            ]
        }

        console.log("params", params)

        this.props.actions.editCleaningPlan(params);
    }


    getTitle = () => {
        if (this.props.vehicle)
            return 'Vehicle ' + this.props.vehicle.vehicleName + ' Cleaning Plan'
        else
            return 'Vehicle Cleaning Plan';
    }

    onOrderUpClicked = (item) => {

    }

    onOrderDownClicked = (item) => {

    }

    onDeleteRowClicked = (item) => {

    }

    onDaySelected = (value, item) => {
        item.day = value;
    }

    addRow = () => {
        this.props.actions.addEmptyRow(this.props.project._id, this.props.vehicle.vehicleId)
    }


    getDayClass = (day) => {
        return this.isCleaningDay(day) ? 'today' : 'tomorrow';
    }

    isCleaningDay = (day) => {
        //only one day is filled

        if (this.state.day1.length && !this.state.day2.length)
            return day ===1;
        else if (new Date().getDay() % 2 ===0)
            return day ===1;
        else
            return day ===2;
    }

    dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    getDayText = (day) => {
        if (this.isCleaningDay(day))
            return 'Running today';
        else if (this.state.day1.length && !this.state.day2.length)
            return '';
        else if (new Date().getDay() ===5)
            return `Next run: ${this.dayNames[0]}`;
        else
            return `Next run: ${this.dayNames[new Date().getDay() + 1 % 7]}`;
    }

    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeCleaningPlan}>
                {this.props.isLoading ? <Loader/> : null}
                <div className="upper">
                    <div className="total-cleaning-plan">
                        Cleaning Plan has<span className="bold"> {this.props.cleaningPlan.length} Rows</span>
                    </div>
                </div>
                <table className="cleaning-table">

                    <tbody>
                    <tr>
                        <th>Day</th>
                        <th>Rows separated by comma</th>
                    </tr>
                    <tr className={this.getDayClass(1)}>
                        <td>
                            <div>Day 1</div>
                            <div className="day-text">{this.getDayText(1)}</div>
                        </td>
                        <td><input value={this.state.day1} onChange={(e) => this.onChange(e.target.value, 'day1')}/>
                        </td>
                    </tr>
                    <tr className={this.getDayClass(2)}>
                        <td>
                            <div>Day 2</div>
                            <div className="day-text">{this.getDayText(2)}</div>
                        </td>
                        <td><input value={this.state.day2} onChange={(e) => this.onChange(e.target.value, 'day2')}/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                {this.props.cleaningPlan ?
                    <div className="buttons-wrapper">
                        <div className="delete-row"
                             onClick={() => this.props.actions.deleteCleaningPlan(this.props.cleaningPlan._id)}>
                            {this.props.cleaningPlan.length ? <span>
                            <img src={deleteCleaningPlanIcon}/>
                            <span>Delete Cleaning Plan</span>
                                </span> : null}
                        </div>
                        <div className="button cancel" onClick={this.props.closeCleaningPlan}>CANCEL</div>
                        <div className="button" onClick={this.onSubmit}>SAVE</div>
                    </div> :
                    <div className="button" onClick={this.onSubmit}>SAVE</div>
                }
            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    console.log("*********************");
    console.log("state.cleaningPlanReducer", state.cleaningPlanReducer)
    return {
        ...state.cleaningPlanReducer,
        project: state.indexReducer.project,
        vehicle: state.indexReducer.vehicle
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getRows,
            addEmptyRow,
            getCleaningPlan,
            editCleaningPlan,
            deleteCleaningPlan
        }, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(CleaningPlanComponent);