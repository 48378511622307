import {GET_PROJECTS_SUCCESS} from "./projectsReducer";

export const EDIT_USER_STARTED = 'EDIT_USER_STARTED';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';

const INITIAL_STATE = {
    isLoading: false,
    projectGroups: [],
    projects: []
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case EDIT_USER_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case EDIT_USER_SUCCESS:

            return {
                ...state,
                isLoading: false
            }

        case EDIT_USER_ERROR:
            return {
                ...state,
                err: action.payload.error,
                isLoading: false
            }
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projectGroups: (action.payload.projectGroups || []).map(pg=>pg.projectGroup),
                projects: (action.payload.projectGroups || []).reduce((list, projectGroup)=>list.concat(projectGroup.projects),[])
            }
        default:
            return state;
    }
}