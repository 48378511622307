import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getCleaningPlanSuccess} from "./getCleaningPlanSuccessAction";
import {getCleaningPlanError} from "./getCleaningPlanErrorAction";
import {getCleaningPlanStarted} from "./getCleaningPlanStartedAction";

export function getCleaningPlan(projectId, vehicleName) {

    return function (dispatch) {
        // console.log("vehicleId",vehicleId)
        let url = `${HOST_URL}/api/plans/?vehicleName=${vehicleName}&projectId=${projectId}`;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCleaningPlanStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getCleaningPlanSuccess(response.data.data));
            }
            else {
                dispatch(getCleaningPlanError(new Error(response.data.message)));
            }
        }).catch(error => {

            console.log(error.message);
            dispatch(getCleaningPlanError(error));
        });
    };
}