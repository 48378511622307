import { useEffect, useState } from "react";
import Modal from "../modal/modal";
import attachmentIcon from "../createProject/attachment-icon.svg";
import close from "../modal/close-icon.svg";
import "./createGroup.scss";
import ApiHandler from "../api";

const api = new ApiHandler();

const CreateGroup = ({ closeCreateGroup, projectGroupId, onGroupCreated }) => {
  const [groupName, setGroupName] = useState("");
  const [groupImage, setGroupImage] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!groupName) {
      alert("Please enter a group name");
      return;
    }

    if (!groupImage) {
      alert("Please add an image");
      return;
    }

    const result = await api.createGroup({
      projectGroupId,
      file: groupImage,
      name: groupName,
    });

    closeCreateGroup();
    onGroupCreated();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setGroupImage(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewSrc(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeAttachment = () => {
    setGroupImage(null);
    setPreviewSrc(null);
  };

  return (
    <Modal
      title="Create Group"
      onClose={closeCreateGroup}
      className="create-group"
    >
      <div className="create-group-content">
        <div className="input-line">
          <div className="input-description">Group Name</div>
          <div className="line full">
            <input
              placeholder="Enter group name"
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </div>
        </div>

        <div className="input-line">
          <div className="line attachments">
            <div
              className="item"
              onClick={() => document.getElementById("fileUpload").click()}
            >
              {previewSrc ? (
                <div className="image-wrapper">
                  <img
                    className="close"
                    src={close}
                    onClick={removeAttachment}
                    alt="remove"
                  />
                  <img className="image" src={previewSrc} alt="preview" />
                </div>
              ) : (
                <div className="add-image-text">Add image</div>
              )}
            </div>
            <input
              id="fileUpload"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="button" onClick={handleSubmit}>
          SAVE
        </div>
      </div>
    </Modal>
  );
};

export default CreateGroup;
