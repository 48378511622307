// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
:root {
  --almost-black: #0d191f;
  --turquoise: #05b2aa;
  --rusty-orange: #e36510;
  --very-light-blue: #e7e8e9;
  --gun-metal: #555e62;
  --light-grey-blue: #b9bcbe;
  --cherry-red: #f6102a;
  --white: #ffffff;
  --blue-grey: #898b8d; }

.row-status.robot-status {
  background-color: var(--very-light-blue);
  height: 15px;
  border-radius: 5px; }

.robot-status-title {
  margin: 20px 0 10px 0; }

.separator {
  margin: 0 10px; }

.robot-status {
  height: 15px; }
  .robot-status .item {
    border-radius: 10px;
    height: 100%;
    display: inline-block; }
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/robotStatus.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEhB;EACE,uBAAe;EACf,oBAAY;EACZ,uBAAe;EACf,0BAAkB;EAClB,oBAAY;EACZ,0BAAkB;EAClB,qBAAa;EACb,gBAAQ;EACR,oBAAY,EAAA;;AAGd;EACE,wCAAwC;EACxC,YAAY;EACZ,kBAAkB,EAAA;;AAGpB;EACE,qBAAqB,EAAA;;AAGvB;EACE,cAAc,EAAA;;AAGhB;EACE,YAAY,EAAA;EADd;IAII,mBAAmB;IACnB,YAAY;IACZ,qBAAqB,EAAA","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);\n\n:root {\n  --almost-black: #0d191f;\n  --turquoise: #05b2aa;\n  --rusty-orange: #e36510;\n  --very-light-blue: #e7e8e9;\n  --gun-metal: #555e62;\n  --light-grey-blue: #b9bcbe;\n  --cherry-red: #f6102a;\n  --white: #ffffff;\n  --blue-grey: #898b8d;\n}\n\n.row-status.robot-status {\n  background-color: var(--very-light-blue);\n  height: 15px;\n  border-radius: 5px;\n}\n\n.robot-status-title {\n  margin: 20px 0 10px 0;\n}\n\n.separator {\n  margin: 0 10px;\n}\n\n.robot-status {\n  height: 15px;\n\n  .item {\n    border-radius: 10px;\n    height: 100%;\n    display: inline-block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
