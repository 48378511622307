import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY, getFormattedDate } from "../consts";
import axios from "axios";
import { getDateStr } from "./utils";
import { deleteUser } from './editUser/deleteUserAction';
import { editError } from './editError/actions/editErrorAction';

export default class ApiHandler {
    constructor(props) {
        this.authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    }

    get = async (url, key = 'result') => {
        const response = await axios.get(url, { headers: { Authorization: this.authToken } });

        if (key)
            return response.data[key];
        else
            return response.data;
    }

    getFile = async (url) => {
        return await axios.get(url, { headers: { Authorization: this.authToken }, responseType: 'blob' });
    }

    delete = async (url) => {
        const response = await axios.delete(url, { headers: { Authorization: this.authToken } });
        return response.data.result;
    }

    post = async (url, data, headers = {}, key = 'result') => {
        const response = await axios.post(url, data, { headers: { ...headers, Authorization: this.authToken } });                
        return response.data[key];
    }


    patch = async (url, data, headers = {}) => {
        const response = await axios.patch(url, data, { headers: { ...headers, Authorization: this.authToken } });
        return response.data;
    }

    getDashboard = async (projectId) => this.get(`${HOST_URL}/api/vehicles/dashboard?projectId=${projectId}`);

    sendCommand = async ({ projectId, command, vehicleId, robotNumber }) => {
        const body = {
            projectId,
            command,
            vehicleId,
            robotNumber,
        }
        const url = `${HOST_URL}/api/vehicles/command`;
        return this.post(url, body);
    }

    updateVehicleSettings = async (vehicleId, projectId, settings) => {
        const body = {
            projectId,
            vehicleId,
            settings
        }
        const url = `${HOST_URL}/api/vehicles/settings`;
        return this.post(url, body);
    }
    getRepeaters = async ({ projectId, page = 0, timeZone, date, search }) => {
        return this.get(`${HOST_URL}/api/repeaters?projectId=${projectId}&page=${page}&timeZone=${timeZone}&date=${getDateStr(date)}&search=${search}`);
    }

    deleteRepeater = async (repeaterId) => {
        const url = `${HOST_URL}/api/repeaters/${repeaterId}`;
        return this.delete(url);
    }

    getProjectSettings = async (projectId) => {
        const url = `${HOST_URL}/api/settings/${projectId}`;
        const response = await axios.get(url, { headers: { Authorization: this.authToken } });
        return response.data.project;
    }

    getProjectAttachments = async (projectId) => {
        return this.get(`${HOST_URL}/api/attachments?projectId=${projectId}`);
    }



    deleteAttachments = async (projectId, attachments) => {
        for (let i = 0; i < attachments.length; i++) {
            const url = `${HOST_URL}/api/attachments/${attachments[i]._id}`;
            await this.delete(url);
        }
    }



    uploadAttachment = ({ file, projectId, errorId, taskId }) => {
        if (!file)
            return;

        let url = HOST_URL + '/api/attachments';

        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectId', projectId);

        if (errorId)
            formData.append('errorId', errorId);
        if (taskId)
            formData.append('taskId', taskId);

        return this.post(url, formData, { 'content-type': 'multipart/form-data' })
    }


    editProject = async (projectId, params, uploadFiles = [], deletedAttachments = []) => {
        let url = HOST_URL + '/api/projects/' + projectId;

        const response = await this.patch(url, params);

        if (uploadFiles.length && uploadFiles[0]) {
            await this.uploadAttachment({
                file: uploadFiles[0],
                projectId: response.data.project._id
            });
        }

        if (deletedAttachments.length)
            await this.deleteAttachments(response.data.project._id, deletedAttachments, 0);

        return response;
    };


    setRobotSettings = async (robotId, data) => {
        let url = HOST_URL + `/api/robots/${robotId}/settings`;
        const response = await this.patch(url, data);
        return response.robot;
    }

    getErrors = async ({ projectId, search, robotId, vehicleId, from, to, errorCode, sort, page }) => {
        if (!from) {
            from = new Date();
            from.setDate(from.getDate() - 7);
        }

        if (!to)
            to = new Date();

        let url = `${HOST_URL}/api/errors?projectId=${projectId}&from=${from.getTime()}&to=${to.getTime()}`;
        if (search)
            url += '&search=' + search;
        if (vehicleId)
            url += '&vehicleId=' + vehicleId;
        if (robotId)
            url += '&robotId=' + robotId;
        if (errorCode)
            url += '&errorCode=' + errorCode;
        if (sort)
            url += '&sort=' + sort;
        if (page)
            url += '&page=' + page;

        const response = await this.get(url, null);

        return response;
    }


    getAvailabilityXlsx = async (projectId, from, to) => {
        if (!from) {
            from = new Date();
            from.setDate(from.getDate() - 7);
        }

        if (!to)
            to = new Date();

        let url = `${HOST_URL}/api/availability/export?projectId=${projectId}&from=${from.getTime()}&to=${to.getTime()}`;

        const response = await this.getFile(url);
        return response;
    }

    getErrorsXlsx = async ({ projectId, search, robotId, vehicleId, from, to, errorCode, sort }) => {
        if (!from) {
            from = new Date();
            from.setDate(from.getDate() - 7);
        }

        if (!to)
            to = new Date();

        let url = `${HOST_URL}/api/errors/export?projectId=${projectId}&from=${from.getTime()}&to=${to.getTime()}`;
        if (search)
            url += '&search=' + search;
        if (vehicleId)
            url += '&vehicleId=' + vehicleId;
        if (robotId)
            url += '&robotId=' + robotId;
        if (errorCode)
            url += '&errorCode=' + errorCode;
        if (sort)
            url += '&sort=' + sort;      

        const response = await this.getFile(url);
        return response;
    }


    deleteError = async (ids) => {

        let url = HOST_URL + '/api/errors/' + ids.join(",");
        const response = await this.delete(url);
        return ids;
    }

    getUsers = async ({ search = '', projectId = '', role = '' }) => {
        let url = `${HOST_URL}/api/users?search=${search}&projectId=${projectId}&role=${role}`;
        return this.get(url, 'users');
    }

    deleteUser = async (userId) => {
        let url = HOST_URL + '/api/users/' + userId;
        return this.delete(url);
    }

    getProjects = async (search) => {
        console.log(new Error());
        let url = HOST_URL + '/api/projects/';
        if (search)
            url += "?search=" + search;
        return this.get(url, '');
    }

    getMinMax = async () => {
        let url = `${HOST_URL}/api/projects/minMax`;
        return this.get(url, 'minMax');
    }

    getRobotDailyReports = async ({ projectId, date, reportType = '' }) => {
        date = getFormattedDate({ date, dateWithYearDashes: true });
        let url = `${HOST_URL}/api/robot-daily-reports?projectId=${projectId}&date=${date}&reportType=${reportType}`;

        return this.get(url, 'robotDailyReports');
    }

    getRobotDailyReportXlsx = async ({ projectId, date, reportType }) => {
        date = getFormattedDate({ date, dateWithYearDashes: true });
        let url = `${HOST_URL}/api/robot-daily-reports/export?projectId=${projectId}&date=${date}&reportType=${reportType}`;

        const response = await this.getFile(url);
        return response;
    }

    getRobotDailyReportSummary = async ({ projectId, date, reportType }) => {
        date = getFormattedDate({ date, dateWithYearDashes: true });
        let url = `${HOST_URL}/api/robot-daily-reports/summary?projectId=${projectId}&date=${date}&reportTypeFilter=${reportType}`;
        return this.get(url, 'reports');
    }



    getRobotNumbers = async (projectId) => {
        let url = `${HOST_URL}/api/robots/numbers?projectId=${projectId}`;
        return this.get(url, 'robotNumbers');
    }

    createErrorResolution = async (params) => {
        let url = `${HOST_URL}/api/error-resolutions`;
        return this.post(url, params, null, 'errorResolution');
    }

    getErrorResolutions = async ({ projectId, robotNumber, technicianId, status, page = 0 }) => {
        let url = `${HOST_URL}/api/error-resolutions?projectId=${projectId}&page=${page}`
        if (robotNumber)
            url += '&robotNumber=' + robotNumber;
        if (technicianId)
            url += '&technicianId=' + technicianId;
        if (status)
            url += '&status=' + status;

        return this.get(url);
    }

    editErrorResolution = async (errorResolutionId, params) => {
        let url = `${HOST_URL}/api/error-resolutions/${errorResolutionId}`;
        return this.patch(url, params);
    }

    createAttachment = async ({ projectId, file, type, errorId, taskId, errorResolutionId }) => {
        let url = HOST_URL + '/api/attachments/';

        const formData = new FormData();
        formData.append('file', file)
        formData.append('projectId', projectId)

        if (errorId)
            formData.append('errorId', errorId)
        if (errorResolutionId)
            formData.append('errorResolutionId', errorResolutionId);
        if (taskId)
            formData.append('taskId', taskId)
        if (type)
            formData.append('type', type)        
    
        const result = await this.post(url, formData, { 'content-type': 'multipart/form-data' }, 'attachment');        
        return result;
        
    }

    deleteAttachment = async (errorId) => {
        let url = HOST_URL + '/api/attachments/' + errorId;
        return this.delete(url);
    }

    updateAttachment = async (attachmentId, params) => {
        let url = HOST_URL + '/api/attachments/' + attachmentId;
        return this.patch(url, params);
    }
}