import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getRobotLogsStarted} from "./getRobotLogsStartedAction";
import {getRobotLogsError} from "./getRobotLogsErrorAction";
import {setRobotSettingsSuccess} from "./setRobotSettingsSuccessAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function setRobotSettings(robotId, settings) {


    return function (dispatch) {
        let url = HOST_URL+`/api/robots/${robotId}/settings`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotLogsStarted());

        return axios.patch(url, settings,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(setRobotSettingsSuccess(response.data.robot));
            }
            else {
                dispatch(getRobotLogsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotLogsError(error));
        });
    };
}