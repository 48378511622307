import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import 'react-dropdown/style.css';
import './editRobotSettings.scss';
import Modal from "../modal/modal";
import {editRobotSettings} from "./editRobotSettingsAction";
import Dropdown from "react-dropdown";


class EditRobotSettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            ...this.props.robot,
            moreSettings: {...this.props.robot.moreSettings} || {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.robotSettingsSaved) {
            this.props.closeEditRobotSettings();
            return;
        }


        if (nextProps.robot && nextProps.robot._id !==this.props.robot._id)
            this.setState(nextProps.robot);

        if (nextProps.error && this.props.error !==nextProps.error)
            if (nextProps.error.response)
                alert(nextProps.error.response.data.message);
            else
                console.log(nextProps.error);
    }

    onChange = (value, param) => {
        const {moreSettings} = this.state;        

        moreSettings[param] = value;

        this.setState({moreSettings});
    }

    onSubmit = () => {        
        let params = {moreSettings: this.state.moreSettings};
        params.projectId = this.props.project._id;

        this.props.actions.editRobotSettings(this.props.robot._id, params);

    }

    onDirectionSelected = (item) => {
        //console.log("item",item);
        this.setState({direction: item.value});
    }

    getTitle = () => {
        return `Robot ${this.props.robot.robotName} Settings`;
    }

    items = [
        {
            text: "Row Number",
            value: "rowNumber",
            type: "number"
        },
        {
            text: "Row Length [m]",
            value: "rowLength",
            type: "number"
        },
        {
            text: "Serial Number",
            value: "serialNumber",
            type: "number"
        },
        {
            text: "PCB Version",
            value: "pcbNumber",
            type: "text"
        },
        {
            text: "Software Version",
            value: "swVersion",
            type: "text"
        },
        {
            text: "Flippers",
            value: "flippers",
            type: "checkbox"
        },
        {
            text: "Wheel Extension",
            value: "wheelExtension",
            type: "checkbox"
        },
        {
            text: "4 X 4",
            value: "fourByFour",
            type: "checkbox"
        },
        {
            text: "Bridges",
            value: "bridges",
            type: "checkbox"
        },
        {
            text: "Integral Panel",
            value: "integralPanel",
            type: "checkbox"
        },
        {
            text: "OCM",
            value: "OCM",
            type: "checkbox"
        },
        {
            text: "Robot version",
            value: "robotVersion",
            type: "text"
        },
        {
            text: "Panel size",
            value: "numberOfPanels",
            type: "text"
        },

        {
            text: "Docking Station",
            value: "dockingStation",
            type: "dropdown",
            options: [
                {value: "yes", label: 'Yes'},
                {value: "no", label: 'No'},
                {value: "partial", label: 'Partial'}
            ]
        },
        {
            text: "Battery Size(AH)",
            value: "batterySize",
            type: "dropdown",
            options: [
                {value: "9", label: '9'},
                {value: "18", label: '18'},
                {value: "24", label: '24'},
                {value: "30", label: '30'}
            ]
        },
        {
            text: "Free Text",
            value: "freeText",
            type: "text"
        }
    ]

    getClass = (item) => item.type ==='checkbox' ? 'input-line checkbox' : 'input-line';

    render() {
        return (
            <Modal title={this.getTitle()} onClose={this.props.closeEditRobotSettings}>

                {this.items.map(item =>
                    item.type ==='checkbox' ?
                        <div key={item.value} className="input-line checkbox">
                            <input checked={this.state.moreSettings[item.value]} placeholder="" type={item.type}
                                   onChange={(e) => this.onChange(e.target.checked, item.value)}/>
                            <div className="input-description">{item.text}</div>
                        </div> : item.type ==='dropdown' ?
                            <div key={item.value} className="input-line">
                                <div className="input-description">{item.text}</div>
                                <Dropdown
                                    options={item.options}
                                    onChange={(option)=>this.onChange(option.value, item.value)}
                                    value={this.state.moreSettings[item.value] !== undefined? this.state.moreSettings[item.value]+"": ""}
                                    className='dropdown'
                                    controlClassName='control'
                                    placeholderClassName='placeholder'
                                />
                            </div> :

                            <div key={item.value} className="input-line">
                                <div className="input-description">{item.text}</div>
                                <input value={this.state.moreSettings[item.value]} placeholder="" type={item.type}
                                       onChange={(e) => this.onChange(e.target.value, item.value)}/>
                            </div>
                )}

                <div className="buttons-wrapper">

                    <div className="button cancel" onClick={this.props.closeEditRobotSettings}>CANCEL</div>
                    <div className="button" onClick={this.onSubmit}>SUBMIT</div>
                </div>

            </Modal>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //

    return {
        ...state.editRobotSettingsReducer,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({editRobotSettings}, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EditRobotSettingsComponent);