import { TimeZone } from "../utils";
import React, { useEffect, useState } from "react";
import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY } from "../../consts";
import axios from "axios";
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import DatePicker from "react-datepicker";

const RobotLogs = ({ robot, project }) => {

    const [timeZone, setTimeZone] = useState();
    const [logs, setLogs] = useState([]);
    const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());


    useEffect(() => {
        setTimeZone(new TimeZone(project))
    }, [project]);

    useEffect(() => {
        getLogs();
    }, [robot, startDate, endDate]);

    const getLogs = async (removeExisting = true) => {
        if (removeExisting)
            setLogs([]);

        let url = HOST_URL + `/api/robots/${robot._id}/logs?`;
        if (startDate && endDate)
            url += `&from=${startDate.getTime()}&to=${endDate.getTime()}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        try {
            const response = await axios.get(url, { headers: { Authorization: authToken } });
            if (response.data.success) {
                setLogs(response.data.robotLogs);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const logsDateChanged = (dates) => {
        
        console.log("dates", dates);
        const start = dates[0];
        const end = dates[1];

        if (start && end) {
            if (start.getTime() ===end.getTime()) {
                start.setHours(0);
                start.setMinutes(0);
                start.setSeconds(0);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59)
            }
        }
        setStartDate(start);
        setEndDate(end);
    }


    return (
        <>
            <DatePicker
                selected={startDate}
                onChange={logsDateChanged}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                // onSelect={this.onDateSelected}
                shouldCloseOnSelect={false}
                customInput={<DateCustomInput logsStartDate={startDate}
                    logsEndDate={endDate} />}
            />


            <table className="robot-logs">
                <thead>
                    <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Row</th>
                        <th>Cleaning<br />Distance</th>
                        <th className="error-cell">Errors</th>
                        <th>Pre-cleaning<br />Voltage</th>
                        <th>Post-cleaning<br />Voltage</th>
                        <th>Rotor Avg.<br />Current</th>
                        <th>Motor Avg.<br />Current</th>
                        <th>Status</th>

                    </tr>
                </thead>
                <tbody>

                    {logs.map((log, index) =>
                        <tr key={index} onClick={() => {
                        }}>
                            <td>{log.startTime ? timeZone.getLocalTime(log.startTime) : null}</td>
                            <td>{log.endTime ? timeZone.getLocalTime(log.endTime) : null}</td>
                            <td>{log.row.number} {log.locationOnRow?`(${Math.round(log.locationOnRow / 1000)}m)`:null}</td>
                            <td>{log.endTime && log.row.length? Math.round(log.row.length / 10000) * 10 : 0}m</td>
                            <td className="error-cell">{log.errors.join(", ")}
                            {log.locationOnRow ? <span>{" "}({Math.round(log.locationOnRow/1000)}m)</span> : null}
                            </td>
                            <td>{log.preCleaningVoltage || 0}V
                                {project.settings.robotVersion ===4 && <>
                                    {Number.isFinite(log.preCleaningDistancePossible) ? <div>{Math.round(log.preCleaningDistancePossible / 1000)}m ({log.preCleaningBatteryPercentage}%)</div> : null}
                                    {log.preCleaningAvgChargingCurrent ? <div>{log.preCleaningAvgChargingCurrent}A</div> : null}
                                </>}
                            </td>
                            <td>{log.postCleaningVoltage || 0}V
                                {project.settings.robotVersion ===4 && <>
                                    {Number.isFinite(log.postCleaningDistancePossible) && Number.isFinite(log.postCleaningBatteryPercentage) && <div>{Math.round(log.postCleaningDistancePossible / 1000)}m ({log.postCleaningBatteryPercentage}%)</div>}
                                    {!Number.isFinite(log.postCleaningDistancePossible) && Number.isFinite(log.postCleaningBatteryPercentage) && <div>({log.postCleaningBatteryPercentage}%)</div>}
                                    {log.postCleaningAvgChargingCurrent ? <div>{log.postCleaningAvgChargingCurrent}A</div> : null}
                                </>}
                            </td>
                            <td>{log.rotorCurrent || 0} Amp</td>
                            <td>{log.motorCurrent || 0} Amp</td>
                            <td>{log.status}</td>
                        </tr>
                    )}

                </tbody>
            </table>
        </>
    )
}

export default RobotLogs;