import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editMessageSuccess} from "./editMessageSuccessAction";
import {editMessageError} from "./editMessageErrorAction";
import {editMessageStarted} from "./editMessageStartedAction";

export function editMessage(params, id) {


    return async function (dispatch) {
        let url = HOST_URL + '/api/messages/' + id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editMessageStarted());

        try {
            const response = await axios.patch(url, params, {headers: {Authorization: authToken}});

            if (response.data.success) {
                dispatch(editMessageSuccess(response.data.message));
            } else {
                dispatch(editMessageError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(editMessageError(error));
        }
        ;
    };
}