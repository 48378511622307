import {CREATE_PROJECT_SUCCESS} from "./createProjectReducer";
import {GET_ERRORS_CSV_SUCCESS, GET_ERRORS_STARTED} from "./errorsReducer";

export const GET_PROJECTS_STARTED = 'GET_PROJECTS_STARTED';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';


const INITIAL_STATE = {
    projectGroups: undefined,
    isLoading: false,
    searchResults: undefined
}



export default (state = INITIAL_STATE, action)=>{
    // //console.log("action.type",action.type);
    switch(action.type){
        case GET_ERRORS_CSV_SUCCESS:
            
            return {
                ...state,
                blob: action.payload.blob,
                isLoading: false
            }
        case GET_PROJECTS_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case GET_ERRORS_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                projectGroups: action.payload.projectGroups,
                searchResults: action.payload.searchResults
            }
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                projects: [action.payload.project].concat(state.projects)
            }
        case GET_PROJECTS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }

        default:
            return state;
    }
}