import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './robotStatus.scss';
import { Tooltip } from 'react-tooltip'


class RowStatusComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // this.wrapper = null;
    }

    componentWillReceiveProps(nextProps) {

    }


    componentDidMount() {
        //console.log("this.wrapper", this.wrapper);
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({
            itemWidth: this.wrapper.offsetWidth / this.props.totalNumber - 0.001
        });
    }

    componentWillUnmount() {

    }


    getArray = () => {
        let arr = [];
        for (let i = 0; i < this.props.totalNumber; i++) {
            arr.push(i);
        }

        return arr;
    }

    getItemStyle = (item, index) => {
        let style = {
            width: index ? this.state.itemWidth + 'px' : (this.state.itemWidth - 1) + 'px'
        };

        if (this.props.rowsExluded.includes(index))
            style.backgroundColor = this.props.excludedColor;

        return style;
    }

    getTooltipText = (rowNumber) => {
        if (this.props.rowsExluded.includes(rowNumber))
            return 'Row ' + rowNumber + ': excluded';

        return 'Row ' + rowNumber;
    }


    render() {
        return (
            <div>
                <div className="robot-status row-status pointer" ref={(ref) => this.wrapper = ref}>
                    {this.getArray().map((item, index) => {
                        return <div
                            key={index}
                            className="item"
                            style={this.getItemStyle(item, index)}                            
                            data-tooltip-id="my-tooltip" 
                            data-tooltip-content={this.getTooltipText(item)}
                        ></div>
                    })}
                </div>
                <Tooltip id="my-tooltip" />
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    //console.log("ownProps",ownProps)
    return {
        // ...state.shelf3dReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(RowStatusComponent);