import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import './settings.scss';
import placeholder from "./placeholder.jpeg";
import PlantHeader from '../plantHeader/plantHeader';
import Attachments from '../attachments/attachments';
import Menu from "../menu/menu";
import locationIcon from "../createProject/location-icon.svg";
import Dropdown from 'react-dropdown';
import { COUNTRIES, LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_USER_KEY, camelCaseToText } from "../../consts";
import powerIcon from "../createProject/power-icon.svg";
import vehiclesIcon from "../createProject/vehicles-icon.svg";
import robotsIcon from "../createProject/robots-icon.svg";
import clockIcon from "../createProject/clock-icon.svg";
import weatherIcon from "../createProject/weather-icon.svg";
import attachmentIcon from "../createProject/attachment-icon.svg";
import { bindActionCreators } from "redux";
import { editProject } from "./actions/editProjectAction";
import { getAttachments } from "./actions/getAttachmentsAction";
import Attachment from "../attachment/attachment";
import Loader from "../loader/loader";
import Modal from "../modal/modal";
import { getProjectSettings } from "./actions/getProjectSettingsAction";
import { withNavigation } from '../withNavigate';

class SettingsComponent extends Component {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        this.changedSettings = [];

        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        this.state = {
            ...project,
            saveRequiresConfirmation: false,
            showModal: false,
            files: []
        };
        if (!this.state.settings)
            this.state.settings = {};

        if (!this.state.groups)
            this.state.groups = [];

        if (this.state.groups.length < 3) {
            for (let i = this.state.groups.length; i < 3; i++)
                this.state.groups.push({ groupNumber: i + 1, cleaningTime: {} })
        }

        for (let i = 0; i < 3; i++) {
            if (!this.state.groups[i].cleaningTime)
                this.state.groups[i].cleaningTime = {};
            else
                this.state.groups[i].cleaningTime.text = this.getCleaningTimeText(this.state.groups[i], 'cleaningTime')

            if (!this.state.groups[i].maxGroupCleaningTime)
                this.state.groups[i].maxGroupCleaningTime = {};
            else
                this.state.groups[i].maxGroupCleaningTime.text = this.getCleaningTimeText(this.state.groups[i], 'maxGroupCleaningTime')
        }

        this.state.settings.availabilityTime.text = this.getCleaningTimeText(this.state.settings, 'availabilityTime')
    }


    componentWillReceiveProps(nextProps) {
        if (!this.props.isSaved && nextProps.isSaved) {
            this.props.actions.getAttachments(this.props.project._id);
        }

        if (nextProps.project) {
            this.setState({ settings: { ...this.state.settings, minMax: nextProps.project.settings.minMax } });
        }
    }


    componentDidMount() {

        this.props.actions.getProjectSettings(this.props.project._id);
        this.props.actions.getAttachments(this.props.project._id);
    }

    onAvailabilityTimeChanged = (value) => {
        const { settings } = this.state;

        settings.availabilityTime.text = value;

        let setHour = true;
        let splits = value.split(":");
        if (splits.length < 0)
            setHour = false;

        let hour = splits[0];
        let minute = splits[1];

        if (isNaN(hour) || isNaN(minute) || hour === '' || minute === '')
            setHour = false;

        if (hour < 0 || hour > 23)
            setHour = false;

        if (minute < 0 || minute > 59)
            setHour = false;

        if (setHour) {
            settings.availabilityTime.minute = parseInt(minute);
            settings.availabilityTime.hour = parseInt(hour);
        }

        console.log("setHour", setHour, "minute", minute, "hour", hour);

        this.setState({ settings, saveRequiresConfirmation: false });
        this.changedSettings.push('availabilityTime');
    }

    onForwardDirectionSelected = (item) => {
        const { settings } = this.state;
        settings.forwardRotorDirection = item.value;
        this.setState({ settings, saveRequiresConfirmation: true });
    }

    onBackwardDirectionSelected = (item) => {
        const { settings } = this.state;
        settings.backwardRotorDirection = item.value;
        this.setState({ settings, saveRequiresConfirmation: true });
    }

    onFilesChanged = (files) => {
        this.setState({ files });
    }

    onFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let state = {};
            state['file' + this.state.currentAttachment] = event.target.files[0];

            let reader = new FileReader();
            reader.onload = event => {

                const thumbUrl = event.target.result.includes("application/pdf") ?
                    placeholder : event.target.result;
                this.props.actions.addAttachmentLocally(this.state.currentAttachment, { thumbUrl });
                state.currentAttachment = null;

                this.setState(state);
                this.fileUpload.value = null;
            }

            reader.readAsDataURL(event.target.files[0]); // convert to base64 string
        }
    }

    onGroupCleaningTimeChanged = (groupNumber, value, field) => {
        const { groups } = this.state;
        let allowed = [':', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        if (value.length && !allowed.includes(value.substring(value.length - 1))) {
            console.log("value.substring(value.length-1))", value.substring(value.length - 1))
            return;
        }

        let group = groups.find(g => g.groupNumber === groupNumber);
        if (!group) {
            group = { groupNumber, cleaningTime: {} };
            groups.push(group);
        }

        group[field].text = value;

        let setHour = true;
        let splits = value.split(":");
        if (splits.length < 0)
            setHour = false;

        let hour = splits[0];
        let minute = splits[1];

        if (isNaN(hour) || isNaN(minute) || hour === '' || minute === '')
            setHour = false;

        if (hour < 0 || hour > 23)
            setHour = false;

        if (minute < 0 || minute > 59)
            setHour = false;

        if (setHour) {
            group[field].minute = parseInt(minute);
            group[field].hour = parseInt(hour);
        }

        console.log("setHour", setHour, group[field], "minute", minute, "hour", hour)

        this.setState({ groups });
    }


    onSave = () => {
        let required = [
            'projectName',
            'totalPower',
            'countryCode',
            'city',
            'streetAddress',
            'numberOfTypeOneRobots',
            'numberOfTypeTwoRobots',
            'driveSpeedCleaning',
            'driveSpeedTransit',
            'rotorSpeedCleaning',
            'rotorSpeedTransit',
            'windCleaningThreshold',
            'humidityCleaningThreshold',
            'rainCleaningThreshold'
        ]


        let missing = required.filter(item => !this.state[item]);

        if (missing.length) {
            let list = missing.map(item => item.replace(/([A-Z])/g, ' $1').toLowerCase());

            // alert("The following fields are mandatory: " + list);
            // return;
        }


        const { settings } = this.state;
        for (const key in settings) {
            if (!settings[key] || !settings.minMax[key])
                continue;

            const { min, max } = settings?.minMax[key];
            if ((min && min > settings[key]) || (max && max < settings[key])) {
                alert(`The value of ${camelCaseToText(key)} should be in the range [${min}-${max}]`);
                return;
            }
        }


        if (this.state.saveRequiresConfirmation) {
            this.setState({ showModal: true });
            return;
        }

        const { params, filesAdded } = this.getEditProjectParams(false);
        this.props.actions.editProject(this.props.project._id, params, filesAdded, this.props.deleted);
        this.changedSettings = [];
    }

    getEditProjectParams = (applyToAllRobots) => {
        const params = { ...this.state, applyToAllRobots };
        for (const key in params.settings) {
            if (!this.changedSettings.includes(key))
                delete params.settings[key];
        }

        delete params.robots;

        params.groupCleaningTimes = [...params.groups];
        params.groups = undefined;


        const filesAdded = [];
        if (this.state.file0)
            filesAdded.push(this.state.file0);
        if (this.state.file1)
            filesAdded.push(this.state.file1);
        if (this.state.file2)
            filesAdded.push(this.state.file2);

        return { params, filesAdded };
    }

    closeModal = (applyToAllRobots) => {
        const { params, filesAdded } = this.getEditProjectParams(applyToAllRobots);

        this.props.actions.editProject(this.props.project._id, params, filesAdded, this.props.deleted);
        this.setState({ showModal: false });
        this.changedSettings = [];
    }


    onCountrySelected = (item) => {
        this.setState({ countryCode: item.value });
    }

    onChange = (value, param) => {
        if (param.startsWith('settings.')) {
            const { settings } = this.state;
            param = param.replace("settings.", "");
            settings[param] = value;
            this.setState({ settings, saveRequiresConfirmation: true });
            if (!this.changedSettings.includes(param))
                this.changedSettings.push(param);

        } else {
            let state = {}
            if (param === 'lat' && (Number(value) > 90 || Number(value) < -90))
                return;
            if (param === 'lon' && (Number(value) > 180 || Number(value) < -180))
                return;

            state[param] = value;
            this.setState(state);
        }
    }

    closeAttachment = () => {
        this.setState({ selectedAttachment: null })
    }

    selectAttachment = (selectedAttachment) => {
        if (!selectedAttachment.mimetype) {
            window.alert("Please save project to view the image");
            return;
        }
        if (selectedAttachment.mimetype.includes('pdf'))
            return window.open(selectedAttachment.url);

        this.setState({ selectedAttachment })
    }

    getCleaningTimeText = (group, field) => {
        if (!group[field].hour)
            return '';
        return `${group[field].hour < 10 ? '0' : ''}${group[field].hour}:${group[field].minute < 10 ? '0' : ''}${group[field].minute}`;
    }

    renderLine = ({ leftText, leftParam, rightText, rightParam }) => <div className="input-line">
        <div className="line">
            <div className="input-description">
                <span className="text left">{leftText}</span>
                {rightText && rightParam ? <span className="text right">{rightText}</span> : null}
            </div>
            <input className="left" placeholder="" type="number"
                value={this.state.settings[leftParam]}
                onChange={(e) => this.onChange(e.target.value, `settings.${leftParam}`)} />
            {rightText && rightParam ? <input className="right" placeholder="" type="number"
                value={this.state.settings[rightParam]}
                onChange={(e) => this.onChange(e.target.value, `settings.${rightParam}`)} /> : null}
        </div>
    </div>


    render() {
        return (
            <div className="page">
                {this.props.isLoading ? <Loader /> : null}
                {this.state.selectedAttachment ? <Attachment closeAttachment={this.closeAttachment}
                    src={this.state.selectedAttachment.url} /> : null}
                {this.state.showModal && this.state.saveRequiresConfirmation ?
                    <Modal title="Robot settings have changed" onClose={this.closeModal}>
                        <div className="robot-settings-changed">
                            Would you like to apply the changes to robots with manual settings?<br />
                        </div>
                        <div className="buttons-wrapper">
                            <div className="button cancel" onClick={() => this.closeModal(true)}>Default and manual
                            </div>
                            <div className="button" onClick={() => this.closeModal(false)}>Default robots only</div>
                        </div>

                    </Modal> : null}
                <div className="menu-wrapper">
                    <Menu />
                </div>
                <div className="content">

                    <PlantHeader title={"Settings"} secondaryText={this.state.projectName}>
                        {this.props.user.role === "admin" && <div className='advanced-settings-button'>
                            <button onClick={() => this.props.navigate('/settings/advanced', { state: this.state.settings })} >Advanced settings</button>
                        </div>}
                    </PlantHeader>

                    <div className="settings">
                        <div className="input-line">
                            <div className="input-description">
                                <img src={robotsIcon} />
                                <span className="text left double">Project Name</span>

                                <span className="text right"><img src={powerIcon} /><span style={{ verticalAlign: 'top' }}>Total Power [MW]</span></span>
                            </div>

                            <div className="line">
                                <input className="left" placeholder="Project name" type="text"
                                    value={this.state.projectName}
                                    onChange={(e) => this.onChange(e.target.value, 'projectName')} />
                                <input className="right" placeholder="Total power" type="number"
                                    value={this.state.totalPower}
                                    onChange={(e) => this.onChange(e.target.value, 'totalPower')} />
                            </div>
                        </div>


                        <div className="input-line">
                            <div className="input-description">
                                <img src={locationIcon} />
                                <span className="text"> Location</span>
                            </div>
                            <div className="line">
                                <Dropdown
                                    options={COUNTRIES}
                                    value={this.state.countryCode}
                                    placeholder="Select country"
                                    onChange={this.onCountrySelected}
                                    className='dropdown'
                                    controlClassName='control'
                                />
                                <input className="right"
                                    placeholder="State/province"
                                    value={this.state.state}
                                    type="string"
                                    onChange={(e) => this.onChange(e.target.value, 'state')} />
                            </div>
                            <div className="line">
                                <input className="left" placeholder="City" type="text"
                                    value={this.state.city}
                                    onChange={(e) => this.onChange(e.target.value, 'city')} />
                                <input className="right" placeholder="Street address" type="text"
                                    value={this.state.streetAddress}
                                    onChange={(e) => this.onChange(e.target.value, 'streetAddress')} />
                            </div>

                            <div className="line">
                                <input className="left" placeholder="Latitude" type="number"
                                    min="-90" max="90"
                                    value={this.state.lat}
                                    onChange={(e) => this.onChange(e.target.value, 'lat')} />
                                <input className="right" placeholder="Longitude" type="number"
                                    min="-180" max="180"
                                    value={this.state.lon}
                                    onChange={(e) => this.onChange(e.target.value, 'lon')} />
                            </div>

                        </div>
                        <div className="separator" />
                        {this.state.groups.map(group => <div className="input-line" key={group.groupNumber}>
                            <div className="input-description">
                                <img src={clockIcon} />
                                <span
                                    className="text left double">Group {group.groupNumber} - Daily cleaning time</span>
                                <span className="text right">Group {group.groupNumber} - Latest cleaning time</span>
                            </div>
                            {/*<div className="input-description">Group {group.groupNumber} - Daily cleaning time (hh:mm)</div>*/}
                            <div className="line">
                                <input className="left" placeholder="hh:mm" type="text" maxLength="5"
                                    value={group.cleaningTime.text}
                                    onChange={(e) => this.onGroupCleaningTimeChanged(group.groupNumber, e.target.value, 'cleaningTime')} />

                                <input className="right" placeholder="hh:mm" type="text" maxLength="5"
                                    value={group.maxGroupCleaningTime.text}
                                    onChange={(e) => this.onGroupCleaningTimeChanged(group.groupNumber, e.target.value, 'maxGroupCleaningTime')} />
                            </div>
                        </div>
                        )}

                        <div className="input-line">
                            <div className="input-description">
                                <img src={clockIcon} />
                                <span className="text">Cleaning Retry Period</span>
                            </div>

                            <div className="line full">
                                <input placeholder="Period in minutes" type="number"
                                    value={this.state.cleaningAttemptEveryMins}
                                    onChange={(e) => this.onChange(e.target.value, 'cleaningAttemptEveryMins')} />
                            </div>
                        </div>

                        <div className="separator" />

                        <div className="input-line">
                            <div className="input-description">
                                <img src={robotsIcon} />
                                <span className="text left double">Array Type</span>
                                <span className="text right">Robot Version</span>
                            </div>

                            <div className="line">
                                <Dropdown
                                    options={[{ label: "Fixed", value: 'fixed' }, {
                                        label: "Solar tracking",
                                        value: 'solar-tracking'
                                    }]}
                                    onChange={this.onArrayTypeSelected}
                                    value={"Fixed"}
                                    className='dropdown left'
                                    controlClassName='control'
                                />
                                <div className="right right-radio">
                                    <input type="radio" name="robotVersion" value={this.state.settings.robotVersion}
                                        checked={this.state.settings.robotVersion === 3}
                                        onChange={(e) => e.target.checked ? this.onChange(3, 'settings.robotVersion') : null}
                                    />
                                    <label htmlFor="V3">V3</label>

                                    <input type="radio" name="robotVersion" value={this.state.settings.robotVersion}
                                        checked={this.state.settings.robotVersion === 4}
                                        onChange={(e) => e.target.checked ? this.onChange(4, 'settings.robotVersion') : null}
                                    />
                                    <label htmlFor="V4">V4</label>

                                </div>
                            </div>
                        </div>

                        {this.state.arrayType === 'solar-tracking' ? <div className="input-line">
                            <div className="input-description">
                                <img src={vehiclesIcon} />
                                <span className="text"> Number of Vehicles</span>
                            </div>
                            <input placeholder="" type="number"
                                value={this.state.numberOfVehicles}
                                onChange={(e) => this.onChange(e.target.value, 'numberOfVehicles')} />
                        </div>
                            :
                            <div className="input-line">
                                <div className="input-description">
                                    <img src={robotsIcon} />
                                    <span className="text left double">Number of automatic robots</span>
                                    <span className="text right">Number of manual robots</span>
                                </div>

                                <div className="line">
                                    <input className="left" placeholder="# Automatic robots" type="number" maxLength="3"
                                        value={this.state.numberOfTypeOneRobots}
                                        onChange={(e) => this.onChange(e.target.value, 'numberOfTypeOneRobots')} />
                                    <input className="right" placeholder="# Manual robots" type="number" maxLength="3"
                                        value={this.state.numberOfTypeTwoRobots}
                                        onChange={(e) => this.onChange(e.target.value, 'numberOfTypeTwoRobots')} />
                                </div>
                            </div>
                        }

                        <div className="separator" />

                        {this.renderLine({
                            leftText: 'Cleaning drive speed [m/minute]',
                            rightText: 'Transit drive speed [m/minute]',
                            leftParam: 'driveSpeedCleaning',
                            rightParam: 'driveSpeedTransit'
                        })}

                        {this.renderLine({
                            leftText: 'Cleaning rotor speed [RPM]',
                            rightText: 'Transit rotor speed [RPM]',
                            leftParam: 'rotorSpeedCleaning',
                            rightParam: 'rotorSpeedTransit'
                        })}

                        {this.renderLine({
                            leftText: 'Cleaning stop distance [mm]',
                            rightText: 'Transit stop distance [mm]',
                            leftParam: 'stopDistanceCleaning',
                            rightParam: 'stopDistanceTransit'
                        })}

                        {this.renderLine({
                            leftText: 'Drive Motor 0.5 sec current threshold [Amp]',
                            rightText: 'Cleaning Motor 0.5 sec current threshold [Amp]',
                            leftParam: 'halfSecCurrentThresholdDriveMotor',
                            rightParam: 'halfSecCurrentThresholdCleaningMotor'
                        })}

                        {this.renderLine({
                            leftText: 'Drive Motor 2 sec current threshold [Amp]',
                            rightText: 'Cleaning Motor 2 sec current threshold [Amp]',
                            leftParam: 'twoSecCurrentThresholdDriveMotor',
                            rightParam: 'twoSecCurrentThresholdCleaningMotor'
                        })}

                        {this.renderLine({
                            leftText: 'Drive Motor Min % - real RPM/target',
                            rightText: 'Cleaning Motor Min % - real RPM/target',
                            leftParam: 'rpmTargetRatioDriveMotor',
                            rightParam: 'rpmTargetRatioCleaningMotor'
                        })}

                        {this.renderLine({
                            leftText: 'Rotor 5 sec speed ratio [%]',
                            leftParam: 'cleanMotorSpeedRatioWhileTestingPERC',
                            rightText: 'Reserve battery threshold [Ah]',
                            rightParam: 'reserveBatteryThresholdAh'
                        })}

                        {this.renderLine({
                            leftText: 'Battery optimized threshold - low load [V]',
                            leftParam: 'batteryThresholdLowLoad',
                            rightText: 'Battery optimized threshold - high load [V]',
                            rightParam: 'batteryThresholdHighLoad'
                        })}

                        {this.renderLine({
                            leftText: 'Battery threshold - old [V]',
                            leftParam: 'batteryVoltageThreshold',
                        })}

                        <div className="input-line">
                            <div className="input-description">
                                <span className="text left">Robot operation mode</span>
                                <span className="text right">Battery size [Ah]</span>
                            </div>

                            <Dropdown
                                options={[
                                    { value: "autonomous", label: 'Autonomous' },
                                    { value: "portable", label: 'Portable' },
                                    // { value: "inactive", label: 'Inactive' }
                                ]}
                                onChange={(option) => this.onChange(option.value, 'settings.operationMode')}
                                value={this.state.settings.operationMode}
                                className='dropdown left'
                                controlClassName='control'
                                placeholderClassName='placeholder'
                            />

                            <Dropdown
                                options={[
                                    { value: "9", label: '9' },
                                    { value: "18", label: '18' },
                                    { value: "24", label: '24' },
                                    { value: "30", label: '30' }
                                ]}
                                onChange={(option) => this.onChange(option.value, 'settings.batterySize')}
                                value={this.state.settings.batterySize?.toString()}
                                className='dropdown right'
                                controlClassName='control'
                                placeholderClassName='placeholder'
                            />
                        </div>

                        <div className="input-line">
                            <div className="line">
                                <span className="checkbox third">
                                    <input type="checkbox"
                                        checked={this.state.settings.fastDrying}
                                        onChange={(e) => this.onChange(e.target.checked, 'settings.fastDrying')} />
                                    <span className="text">Fast drying</span>
                                </span>

                                <span className="checkbox">
                                    <span className="center">
                                        <input type="checkbox"
                                            checked={this.state.settings.chargeFixDuringBatteryTest}
                                            onChange={(e) => this.onChange(e.target.checked, 'settings.chargeFixDuringBatteryTest')} />
                                        <span className="text">Fix charging during battery test</span>
                                    </span>
                                </span>
                                <span className="checkbox right">
                                    <input type="checkbox"
                                        checked={this.state.settings.jammedRobotFixOnTable}
                                        onChange={(e) => this.onChange(e.target.checked, 'settings.jammedRobotFixOnTable')} />
                                    <span className="text">Fix jammed robot on table</span>
                                </span>
                            </div>
                        </div>
                        <div className="input-line">
                            <div className="line full">
                                <div className="input-description">
                                    <span className="text">Double cleaning distance in row edges [meter]</span>
                                </div>
                                <input placeholder="" type="number"
                                    value={this.state.settings.rowEdgesDoubleCleaningDistance}
                                    maxLength="3"
                                    onChange={(e) => this.onChange(e.target.value, 'settings.rowEdgesDoubleCleaningDistance')} />
                            </div>
                        </div>


                        <div className="input-line">
                            <div className="input-description">
                                <img src={weatherIcon} />
                                <span className="text">Wind speed [km/hour]</span>
                            </div>
                            <div className="line full">
                                <input placeholder="Wind speed [km/hour]" type="number"
                                    value={this.state.windCleaningThreshold}
                                    onChange={(e) => this.onChange(e.target.value, 'windCleaningThreshold')} />
                            </div>
                        </div>

                        <div className="input-line">
                            <div className="input-description">
                                <img src={weatherIcon} />
                                <span className="text">Relative humidity [%]</span>
                            </div>

                            <div className="line full">
                                <input placeholder="Relative humidity [%]" type="number"
                                    value={this.state.humidityCleaningThreshold}
                                    onChange={(e) => this.onChange(e.target.value, 'humidityCleaningThreshold')} />
                            </div>
                        </div>

                        <div className="input-line">
                            <div className="input-description">
                                <img src={weatherIcon} />
                                <span className="text">Rain [mm/hour]</span>
                            </div>
                            <div className="line full">
                                <input placeholder="Rain [mm/hour]" type="number"
                                    value={this.state.rainCleaningThreshold}
                                    onChange={(e) => this.onChange(e.target.value, 'rainCleaningThreshold')} />
                            </div>
                        </div>

                        <div className="input-line">
                            <div className="input-description">
                                <img src={clockIcon} />
                                <span className="text">Availability start time</span>
                            </div>
                            <input className="left" placeholder="hh:mm" type="text" maxLength="5"
                                value={this.state.settings.availabilityTime ? this.state.settings.availabilityTime.text : ''}
                                onChange={(e) => this.onAvailabilityTimeChanged(e.target.value)} />
                        </div>



                        <div className="input-line">
                            <div className="input-description">
                                <img src={attachmentIcon} />
                                <span className="text"> Attachments</span>
                            </div>
                            <div className="line">
                                <Attachments
                                    projectId={this.props.project._id}                                    
                                    type="projectImage"
                                    attachments={this.props.attachments}
                                />
                            </div>
                        </div>

                        <div className="button" onClick={this.onSave}>SAVE</div>
                        {this.props.isSaved ? <div className="button-message">Project saved</div> : null}
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //

    return {
        ...state.settingsReducer,
        project: state.indexReducer.project,
        user: state.indexReducer.user
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            editProject,
            getAttachments,            
            getProjectSettings
        }, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(SettingsComponent));