// import axios from "axios";
//
// import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
// import {addAttachmentStarted} from "./addAttachmentStartedAction";
// import {addAttachmentSuccess} from "./addAttachmentSuccessAction";
// import {addAttachmentError} from "./addAttachmentErrorAction";
// import {unauthorizedError} from "../signin/unauthorizedErrorAction";
// import {uploadAttachment} from "../utils";
//
// export function addAttachment(projectId, file, index) {
//
//     return async function (dispatch) {
//         dispatch(addAttachmentStarted());
//         try {
//             const attachment = await uploadAttachment({
//                 file,
//                 projectId
//             });
//
//             dispatch(addAttachmentSuccess(attachment, index));
//         } catch (err) {
//             dispatch(addAttachmentError(err));
//         }
//     };
// }