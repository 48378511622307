import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editUserSuccess} from "./editUserSuccessAction";
import {editUserError} from "./editUserErrorAction";
import {editUserStarted} from "./editUserStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function addUser(params) {

    return function (dispatch) {
        let url = HOST_URL+'/api/users/';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editUserStarted());

        return axios.post(url,params, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(editUserSuccess(response.data.user));
            }
            else {
                dispatch(editUserError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editUserError(error));
        });
    };
}