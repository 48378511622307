import axios from "axios";
import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {sendCommandStarted} from "./sendCommandStartedAction";
import {sendCommandSuccess} from "./sendCommandSuccessAction";
import {sendCommandError} from "./sendCommandErrorAction";


export const COMMAND_RESET = 'RESET';
export const COMMAND_START = 'START_CLEANING';
export const COMMAND_PAUSE = 'PAUSE_CLEANING';
export const COMMAND_CONTINUE = 'CONTINUE_CLEANING';
export const COMMAND_GO_HOME = 'GO_HOME';
export const COMMAND_REMOTE_COMMAND = 'REMOTE_COMMAND';

export function sendCommand(namespace) {
    return ({id, event, category, value, cmd, rpi})=> {

        return function (dispatch) {
            let url = HOST_URL + '/api/commands/projects/' + id;
            let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
            dispatch(sendCommandStarted(namespace, event));

            const params = {
                event,
                category,
                value,
                cmd,
                rpi
            }

            return axios.post(url, params, {headers: {Authorization: authToken}}).then((response) => {
                if (response.data.success) {
                    dispatch(sendCommandSuccess(namespace));
                } else {
                    dispatch(sendCommandError(namespace, new Error(response.data.message)));
                }
            }).catch(error => {
                console.log(error.message);
                dispatch(sendCommandError(namespace, error));
            });
        };
    }
}