import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getCleaningPlanError} from "./getCleaningPlanErrorAction";
import {getCleaningPlanStarted} from "./getCleaningPlanStartedAction";
import {saveCleaningPlanSuccess} from "./editCleaningPlanSuccessAction";

export function editCleaningPlan(params) {

    return function (dispatch) {
        let url = HOST_URL+'/api/plans';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCleaningPlanStarted());

        return axios.post(url,params, {headers: {Authorization: authToken}}).then((response) => {

            if (response.data.success) {

                dispatch(saveCleaningPlanSuccess(params));
            }
            else {
                dispatch(getCleaningPlanError(new Error(response.data.message)));
            }
        }).catch(error => {
            console.log(error.stack);
            console.log("adsdasd", error.message);
            dispatch(getCleaningPlanError(error));
        });
    };
}