import { TimeZone, isEqual } from "../utils";
import React, { useEffect, useRef, useState } from "react";
import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY, camelCaseToText } from "../../consts";
import axios from "axios";
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import DatePicker from "react-datepicker";
import DashboardApiHandler from "../dashboard/api";
import spinner from "./color_spinner.svg"
import Dropdown from 'react-dropdown';
import ApiHandler from "../api";
import Modal from "../modal/modal";

const RobotSettings = ({ robot, project, user, batteryDataReady }) => {
    const [editSettings, setEditSettings] = useState(false);
    const [showSaveSettings, setShowSaveSettings] = useState(false);
    const [showLoadDefault, setShowLoadDefault] = useState(false);
    const [isLoadingVoltages, setIsLoadingVoltages] = useState(false);
    const [batteryPercentage, setBatteryPercentage] = useState();

    const [settings, setSettings] = useState({});
    const [liveReport, setLiveReport] = useState();
    const [processId, setProcessId] = useState(null);
    const [liveInterval, setLiveInterval] = useState(null);

    useEffect(() => {
        getLiveReports(processId);
    }, [processId]);

    useEffect(() => {
        // getReportOnce();
    }, [batteryDataReady]);


    const dashboardApiHandler = new DashboardApiHandler();
    const apiHandler = new ApiHandler();
    const prevRobot = useRef();

    useEffect(() => {     
        

        if (robot && (!prevRobot.current || prevRobot.current.id !== robot.id || !isEqual(robot.settings, settings))) {
            setSettings(robot.settings);
            setIsLoadingVoltages(false);
            setProcessId(null);
            setLiveReport(null);
            setLiveReport(null);
            console.log("robot has changed, settings live report to null");
        } else {
            console.log("robot has not changed", robot, prevRobot.current);
        }

        prevRobot.current = robot;
    }, [robot]);

    const loadDefaultSettings = () => {
        
        const { settings } = project;
        settings.setToDefault = true;
        setSettings(settings);
        setShowLoadDefault(false);
    }

    const refreshRobotBatteries = async () => {
        setIsLoadingVoltages(true);
        try {
            const result = await dashboardApiHandler.refreshRobotBatteries(project._id, robot.id);
            
            setProcessId(result.data.processId);
        } catch (error) {
            console.log(error);
            alert("Error getting updated robot voltage");
        }
    }

    const handleShowLoadDefault = (currentSettings) => {
        if (!currentSettings)
            return true;

        for (let key in project.settings) {
            if (project.settings[key] !== currentSettings[key])
                return false;
        }
        return true;
    }


    const onChange = (value, param) => {
        
        const tmpSettings = { ...settings };
        tmpSettings[param] = value;
        tmpSettings.setToDefault = false;
        setSettings(tmpSettings);
        setShowLoadDefault(!handleShowLoadDefault(tmpSettings));
    }

    const getReportOnce = async () => {
        const data = await dashboardApiHandler.getLiveReports(project._id, processId);
        const report = data.reports.find(report => report.robot.id === robot.id);

        if (report) {
            setLiveReport(report);

            setIsLoadingVoltages(false);
            setProcessId(null);
        }
    }

    const getLiveReports = (processId) => {
        window.clearInterval(liveInterval);

        if (!processId)
            return;

        setLiveReport(null);

        console.log("old liveInterval", liveInterval)

        let interval = window.setInterval(async () => {
            const data = await dashboardApiHandler.getLiveReports(project._id, processId);
            const report = data.reports.find(report => report.robot.id === robot.id);

            if (report) {
                setLiveReport(report);
                window.clearInterval(liveInterval);
                console.log("stopping getLiveReports", liveReport);
                setIsLoadingVoltages(false);
                setProcessId(null);
            }

        }, 3000);
        setLiveInterval(interval);
    }

    const saveSettings = async () => {

        try {
            await apiHandler.setRobotSettings(robot.id, { settings, batteryPercentage });
        } catch (error) {
            console.log(error);
            alert("Error saving robot settings");
        }

        setShowSaveSettings(false);
        setEditSettings(false);
    }

    const settingsInput = (param) => {        
        return <input
            disabled={!editSettings}
            type="number"
            min={settings.minMax?.[param].min}
            max={settings.minMax?.[param].max}
            value={settings[param]}
            onChange={(e) => onChange(e.target.value, param)}
        />
    }

    const onSaveClicked = () => {
        for (const key in settings) {
            if (!settings[key] || !settings.minMax[key])
                continue;

            const { min, max } = settings?.minMax[key];
            if ((min && min > settings[key]) || (max && max < settings[key])) {
                alert(`The value of ${camelCaseToText(key)} should be in the range [${min}-${max}]`);
                return;
            }
        }

        setShowSaveSettings(true);
    }


    return (<>
        <div className="robot-settings">
            {showSaveSettings ? <Modal
                title={`Save robot settings?`}
                onClose={() => setShowSaveSettings(false)}>
                <div className="buttons-wrapper">
                    <div className="button cancel" onClick={() => setShowSaveSettings(false)}>Cancel</div>
                    <div className="button" onClick={saveSettings}>Continue</div>

                </div>

            </Modal> : null}
            <div className="title">
                <span className="text">SETTINGS</span>
                {user.role !== "projectOwner" ?
                    <span className="separator">|</span> : null}
                {user.role !== "projectOwner" ? <span>
                    {editSettings ?
                        <span className="custom"
                            onClick={onSaveClicked}>SAVE</span>
                        :
                        <span className="custom"
                            onClick={() => setEditSettings(true)}>Edit</span>
                    }

                    {showLoadDefault && editSettings ?
                        <span className="custom show-default"
                            onClick={loadDefaultSettings}><span
                                className="separator">|</span>LOAD DEFAULT SETTINGS</span> : null}
                </span> : null}

                {user.role !== "projectOwner" && project.connectionStatus !== "offline" ? <div className="refresh">
                    {isLoadingVoltages && <span className="loading"><img src={spinner} /> GETTING VOLTAGE...</span>}
                    {!isLoadingVoltages && <>

                        {liveReport ?
                            <>
                                <span className="text">VOLTAGE: {liveReport.voltage}V</span>
                                {project.settings.robotVersion === 4 && <>{" "}{"|"}{" "}
                                    <span className="text">DISTANCE POSSIBLE: {Math.round(liveReport.distancePossible / 1000)}m</span>{" "}{"|"}{" "}
                                    <span className="text">CHARGING CURRENT: {liveReport.avgChargingCurrent}A</span>{" "}{"|"}{" "}
                                    <span className="text">BATTERY LEVEL: {liveReport.batteryPercentage}%</span>
                                </>}
                            </>
                            : <span className="text" onClick={refreshRobotBatteries}>GET UPDATED VOLTAGE</span>
                        }
                    </>}
                </div> : null}

            </div>
            <div className="separator"></div>

            {settings ? <div className="columns">



                <table className={editSettings ? 'settings table1' : 'settings disabled table1'}>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>Cleaning Mode</td>
                            <td>Transit Mode</td>
                        </tr>
                        <tr>
                            <td>Drive speed [m/minute]</td>
                            <td>{settingsInput('driveSpeedCleaning')}</td>
                            <td>{settingsInput('driveSpeedTransit')}</td>
                        </tr>
                        <tr>
                            <td>Rotor speed [RPM]</td>
                            <td>{settingsInput('rotorSpeedCleaning')}</td>
                            <td>{settingsInput('rotorSpeedTransit')}</td>
                        </tr>

                        <tr>
                            <td>Stop Distance [mm]</td>
                            <td>{settingsInput('stopDistanceCleaning')}</td>
                            <td>{settingsInput('stopDistanceTransit')}</td>
                        </tr>
                    </tbody>
                </table>

                <table className={editSettings ? 'settings table2' : 'settings disabled table2'}>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>Drive Motor</td>
                            <td>Cleaning Motor</td>
                        </tr>
                        <tr>
                            <td>0.5 sec current threshold [Amp]</td>
                            <td>{settingsInput('halfSecCurrentThresholdDriveMotor')}</td>
                            <td>{settingsInput('halfSecCurrentThresholdCleaningMotor')}</td>
                        </tr>
                        <tr>
                            <td>2 sec current threshold [Amp]</td>
                            <td>{settingsInput('twoSecCurrentThresholdDriveMotor')}</td>
                            <td>{settingsInput('twoSecCurrentThresholdCleaningMotor')}</td>
                        </tr>
                        <tr>
                            <td>Min % - real RPM/target</td>
                            <td>{settingsInput('rpmTargetRatioDriveMotor')}</td>
                            <td>{settingsInput('rpmTargetRatioCleaningMotor')}</td>
                        </tr>

                    </tbody>
                </table>

                <table className={editSettings ? 'settings table3' : 'settings disabled table3'}>
                    <tbody>
                        <tr><td></td><td>Value</td></tr>
                        <tr>
                            <td>Rotor 5 sec speed ratio [%]</td>
                            <td>{settingsInput('cleanMotorSpeedRatioWhileTestingPERC')}</td>
                        </tr>
                        {project.settings.robotVersion === 4 &&
                            <tr>
                                <td>Updated Battery Level [%]</td>
                                <td>
                                    <input
                                        disabled={!editSettings}
                                        type="number"
                                        min="0"
                                        max="100"
                                        value={batteryPercentage}
                                        onChange={(e) => setBatteryPercentage(Number(e.target.value))}
                                    />
                                </td>
                            </tr>}

                        <tr>
                            <td>Battery optimized threshold - high load [V]</td>
                            <td>{settingsInput('batteryThresholdHighLoad')}</td>
                        </tr>

                        <tr>
                            <td>Battery optimized threshold - low load [V]</td>
                            <td>{settingsInput('batteryThresholdLowLoad')}</td>
                        </tr>

                        <tr>
                            <td>Reserve battery threshold [Ah]</td>
                            <td>{settingsInput('reserveBatteryThresholdAh')}</td>
                        </tr>

                        <tr>
                            <td>Battery threshold - old [V]</td>
                            <td>{settingsInput('batteryVoltageThreshold')}</td>
                        </tr>

                        <tr>
                            <td>Double cleaning distance in row edges [m]</td>
                            <td>{settingsInput('rowEdgesDoubleCleaningDistance')}</td>
                        </tr>

                        <tr>
                            <td>Fast drying</td>
                            <td className="checkbox-wrapper">
                                <span className="checkbox-wrapper">
                                    <input type="checkbox"
                                        disabled={!editSettings}
                                        checked={settings.fastDrying}
                                        onChange={(e) => onChange(e.target.checked, 'fastDrying')} />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Fix charging during battery test</td>
                            <td className="checkbox-wrapper">
                                <span className="checkbox-wrapper">
                                    <input type="checkbox"
                                        disabled={!editSettings}
                                        checked={settings.chargeFixDuringBatteryTest}
                                        onChange={(e) => onChange(e.target.checked, 'chargeFixDuringBatteryTest')} />
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <td>Fix Jammed robot on table</td>
                            <td className="checkbox-wrapper">
                                <span className="checkbox-wrapper">
                                    <input type="checkbox"
                                        disabled={!editSettings}
                                        checked={settings.jammedRobotFixOnTable}
                                        onChange={(e) => onChange(e.target.checked, 'jammedRobotFixOnTable')} />
                                </span>

                            </td>
                        </tr>



                    </tbody>
                </table>

            </div > : null
            }
        </div >

    </>)
}

export default RobotSettings;