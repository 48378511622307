import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { LOCAL_STORAGE_PROJECT, LOCAL_STORAGE_USER_KEY, camelCaseToText, getFormattedDate } from "../../consts";
import { TimeZone } from "../utils";
import DatePicker from "react-datepicker";
import Loader from "../loader/loader";
import Menu from "../menu/menu";
import PlantHeader from "../plantHeader/plantHeader";
import Dropdown from "react-dropdown";
import Search from "../search/search";
import downloadIcon from "../availability/download-icon.svg";
import InactivityReports from "./inactivity";
import BatteryReports from "./battery";
import ChargingBalanceReports from "./chargingBalance";
import RepeatedErrorsComponent from "./repeatedErrors";
import { useNavigate, useParams } from "react-router";
import TableComponent from "./table";
import "./robotDailyReports.scss";


const RobotDailyReportsComponent = ({ }) => {
    const navigate = useNavigate();

    const [project, setProject] = useState({});
    const [user, setUser] = useState({});
    const [timeZone, setTimeZone] = useState();
    const [date, setDate] = useState(new Date());// - 24 * 60 * 60 * 1000));
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');    
    const [reportSelected, setReportSelected] = useState('');

    const { reportType } = useParams();
    

    const apiHandler = new ApiHandler();

    useEffect(() => {
        if (reportType && reportTypes[reportType]) {
            setReportSelected(reportType);
        } else {
            setReportSelected('');
        }
    }, [reportType]);

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);
        // setTimeZone(new TimeZone(project));
    }, []);


    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            
            try {                
                const reports = await apiHandler.getRobotDailyReportSummary({ projectId: project._id, date, reportType: reportSelected });
                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        if (project._id) {
            getReports();
        }
    }, [project, date]);

    const reportTypes = {
        inactive: 'inactive',
        battery: 'battery',
        chargingBalance: 'chargingBalance',
        repeatedErrors: 'repeatedErrors',
        table: 'table'
    }

    const dateChanged = (date) => {
        setDate(date);
    }

    const onReportTypeFilterSelected = (item) => {
        navigate(`/reports/${item.value}`);   
    }


    const downloadXlsxFile = async () => {        
        if (!reportSelected)
            return;
        const response = await apiHandler.getRobotDailyReportXlsx({ projectId: project._id, date, reportType: reportSelected});
        saveXlsxFile(response);
    }

    const saveXlsxFile = (response) => {
        
        if (!reportSelected)
            return;
        
        const xlsxURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = xlsxURL;        

        const downloadFileName = `${project.projectName}-${reportSelected}-${getFormattedDate({ date, dateOnly:true})}.xlsx`;

        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
    }


    return <>
        <div className="page">
            {isLoading ? <Loader /> : null}

            <div className="menu-wrapper">
                <Menu />
            </div>
            <div className="content">
                {reportSelected ?
                    <PlantHeader
                        title={camelCaseToText(reportSelected)}
                        secondaryText={"Reports"}
                        secondaryLink={'/reports'}
                        thirdText={project.projectName}
                        thirdLink={'/dashboard'}
                    /> :

                    <PlantHeader title={"Reports"} secondaryText={project.projectName} />
                }

                <div className="errors robot-reports">
                    <div className="top">
                        <Search onSearch={setSearch} placeholder="search by robot number" />

                        <DatePicker
                            selected={date}
                            onChange={dateChanged}
                            shouldCloseOnSelect={true}
                        />

                        <div className="error-filter error-codes">
                            <Dropdown
                                placeholder="All issues"
                                options={[{
                                    label: "All issues",
                                    value: ''
                                }].concat(Object.keys(reportTypes).map(reportType => ({
                                    value: reportType,
                                    label: camelCaseToText(reportType)
                                })))}
                                onChange={onReportTypeFilterSelected}
                                // value={errorCodeFilter}
                                className='dropdown'
                                controlClassName='control'
                                value={reportSelected}
                            />
                        </div>

                        <div className="download left" onClick={downloadXlsxFile}><img src={downloadIcon} />
                            DOWNLOAD REPORT
                        </div>
                    </div>
                    <div className="total">

                    </div>
                    {!reportSelected && <table className="general">
                        <tbody>
                            <tr>
                                <th className="robot-number">Robot</th>
                                <th onClick={() => navigate('inactive')}>Inactivity</th>
                                <th onClick={() => navigate('battery')}>Battery</th>
                                <th onClick={() => navigate('chargingBalance')}>Charging Balance</th>
                                <th onClick={() => navigate('repeatedErrors')}>Repeated Errors</th>
                                <th onClick={() => navigate('table')}>Table</th>
                            </tr>

                            {reports.map((report) => {
                                return <tr key={report.robotNumber}>
                                    <td className="robot-number">{report.robotNumber}</td>
                                    <td>{report.reportTypes.includes(reportTypes.inactive) ? 'X' : ''}</td>
                                    <td>{report.reportTypes.includes(reportTypes.battery) ? 'X' : ''}</td>
                                    <td>{report.reportTypes.includes(reportTypes.chargingBalance) ? 'X' : ''}</td>
                                    <td>{report.reportTypes.includes(reportTypes.repeatedErrors) ? 'X' : ''}</td>
                                    <td>{report.reportTypes.includes(reportTypes.table) ? 'X' : ''}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>}
                    {reportSelected === reportTypes.inactive && <InactivityReports project={project} date={date} />}
                    {reportSelected === reportTypes.battery && <BatteryReports project={project} date={date} />}
                    {reportSelected === reportTypes.chargingBalance && <ChargingBalanceReports project={project} date={date} />}
                    {reportSelected === reportTypes.repeatedErrors && <RepeatedErrorsComponent project={project} date={date} />}
                    {reportSelected === reportTypes.table && <TableComponent project={project} date={date} />}
                    
                </div>
            </div>
        </div>
    </>;


}

export default RobotDailyReportsComponent;