import React, { Component, PropTypes, useEffect, useState } from 'react';
import './users.scss';
import Search from "../search/search";
import EditUser from "../editUser/editUser";
import plus from "../projects/images/plus.svg";
import Loader from "../loader/loader";
import Modal from "../modal/modal";
import logo from "../projects/images/logo.svg";
import { useNavigate } from 'react-router';
import ApiHandler from '../api';

const UsersComponent = ({ }) => {
    const [showEditUser, setShowEditUser] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const apiHandler = new ApiHandler();

    // useEffect(() => {
    //     if (closeEdit) {
    //         setSelectedUser(null);
    //         setShowEditUser(false);
    //     }
    // }, [closeEdit]);

    const handleUsers = async (search) => {
        setIsLoading(true);
        const users = await apiHandler.getUsers({search});
        setUsers(users);
        setIsLoading(false);
    }

    useEffect(() => {
        handleUsers();
    }, []);

    const searchClicked = (search) => {
        handleUsers(search);
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setShowEditUser(true);
    }

    const getInitials = (user) => {
        let names = user.fullName.split(" ");
        if (names.length === 1)
            return names[0].substr(0, 1);
        else if (names.length === 2)
            return names[0].substr(0, 1) + names[1].substr(0, 1);
        else
            return names[0].substr(0, 1) + names[names.length - 1].substr(0, 1);
    }

    const getColor = (index) => {
        const colors = [
            '#e36510',
            '#05b2aa',
            '#726fb4',
            '#ff514c',
            '#ffa933',
            '#5ea1ed',
        ]
        return colors[index % colors.length]
    }

    const addUser = () => {
        setSelectedUser(null);
        setShowEditUser(true);
    }

    const getRole = user => {
        console.log(user.role)
        if (user.role === 'countryAdmin')
            return 'Country Admin';
        if (user.role === 'projectOwner')
            return 'Project Owner';

        return user.role.slice(0, 1).toUpperCase() + user.role.slice(1);
    }


    const closeDeleteUserModal = () => {
        setShowDeleteUser(false);
        setShowEditUser(true);
    }

    const showDeleteUserModal = () => {
        setShowDeleteUser(true);
        setShowEditUser(false);
    }

    const deleteUser = async () => {
        setShowDeleteUser(false);
        setShowEditUser(true);
        await apiHandler.deleteUser(selectedUser.id);
        const users = await apiHandler.getUsers();
        setUsers(users);
    }



    return (
        <div className="page">
            {isLoading ? <Loader /> : null}
            {showEditUser &&
                <EditUser
                    closeEditUser={() => setShowEditUser(false)}
                    user={selectedUser}
                    deleteUser={showDeleteUserModal}
                />
            }

            {showDeleteUser && <Modal title={`Delete the user ${selectedUser.fullName}`} onClose={closeDeleteUserModal}>
                <div className="buttons-wrapper">
                    <div className="button cancel" onClick={closeDeleteUserModal}>Cancel</div>
                    <div className="button" onClick={deleteUser}>Delete forever</div>
                </div>

            </Modal>}


            {/*<div className="menu-wrapper">*/}
            {/*<Menu />*/}
            {/*</div>*/}
            <div className="header special">
                <div className="logo-wrapper" onClick={() => navigate("/projects")}>
                    <img className="logo" src={logo} />
                </div>
                <div className="title">Users</div>

                <div className='add-user' onClick={addUser}><img src={plus} /> <span className="text">ADD USER</span></div>
            </div>
            <div className="content special">
                {/*<div className="users-title">*/}

                {/*<div className="main">Users</div>*/}

                {/*<div className="button" onClick={this.addUser}>*/}
                {/*<img src={plus} />*/}
                {/*<span className="text">ADD USER</span>*/}
                {/*</div>*/}

                {/*</div>*/}

                <div className="users">
                    <div className="top">
                        <Search onSearch={searchClicked} placeholder="search users by name" />
                    </div>
                    <table>
                        <tr>
                            <th>Full Name</th>
                            <th>Phone Number</th>
                            <th>Role</th>
                            <th>Countries / Groups /Projects</th>
                        </tr>
                        <tbody>
                            {users.map((user, index) => {
                                return <tr key={index} onClick={() => userSelected(user)}>
                                    <td className="name">
                                        <span className="initials" style={{ backgroundColor: getColor(index) }}>{getInitials(user)}</span>
                                        <span className="name-text">{user.fullName}</span>
                                    </td>
                                    <td className="phone">{user.phoneNumber}</td>
                                    <td>{getRole(user)}</td>
                                    <td>
                                        <ul>
                                            {user.countryAdminCodes ? user.countryAdminCodes.map(country => <li key={country} className="country">{country}</li>) : null}
                                            {user.projectGroups ? user.projectGroups.map(pg => <li key={pg.id} className="project">{pg.name}</li>) : null}
                                            {user.projects ? user.projects.map(project => <li key={project.id} className="project">{project.name}</li>) : null}
                                        </ul>
                                    </td>
                                </tr>

                            })}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}


export default UsersComponent;