import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './cleaningControl.scss'
import {bindActionCreators} from "redux";
import spinner from './white_spinner.svg';
import {
    COMMAND_CONTINUE,
    COMMAND_GO_HOME,
    COMMAND_PAUSE,
    COMMAND_START,
    COMMAND_REMOTE_COMMAND,
    sendCommand, COMMAND_RESET
} from "./sendCommand";
import greySpinner from "./grey_spinner.svg";

import playIcon from "./play-icon.svg";
import cleaningInProgress from "./cleaning-icon-spinning.svg";
import {sendCommandStarted} from "./sendCommandStartedAction";
import Modal from "../modal/modal";
import Dropdown from "react-dropdown";
import {changeStatus} from "../cleaningPlan/changeStatusAction";
import { camelCaseToText, dashedToTitle } from '../../consts';

const RobotStatuses = [
    {label: 'Idle', value: 'idle'},
    {label: 'Parking', value: 'parking'},
    {label: 'Finished cleaning', value: 'finished-cleaning'},
    {label: 'Drive backwards', value: 'drive-backwards'},
    {label: 'Drive forward', value: 'drive-forward'},
    {label: 'Error', value: 'error'}
];

const ParkingStatuses = [
    'parking',
    'idle',
    'finished-cleaning',
    'error',
    'not-cleaning',
    'cleaning-prevented',
    'weather-error',
    'ups-error'
]


const CleaningStatuses = [
    'drop-off',
    'pickup',
    'waiting',
    'driving',
    'cleaning',
    'drive-forward',
    'drive-backwards',
    'start-cleaning'
]

const PausedStatuses = [
    'paused'
]

class CleaningControlRobotComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            changeStatus: false,
            startCleaningSeconds: this.props.startCleaningSeconds || 0
        }

        setInterval(() => {
            this.setState({startCleaningSeconds: this.state.startCleaningSeconds + 1});
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentState)
            console.log("nextProps.currentState", nextProps.currentState);

        if (this.props.isPending ===true && nextProps.isPending ===false)
            this.props.actions.sendCommandStarted(this.props.namespace, null);

        if (nextProps.startCleaningSeconds && this.props.startCleaningSeconds !==nextProps.startCleaningSeconds)
            this.setState({startCleaningSeconds: nextProps.startCleaningSeconds})
    }

    getCleaningTime = () => {
        if (this.props.isPending)
            return;

        let total = Math.round(this.state.startCleaningSeconds);
        let hours = Math.floor(total / 3600);
        total = total % 3600;
        let minutes = Math.floor(total / 60);
        let seconds = total % 60;
        if (hours && minutes < 10)
            minutes = '0' + minutes;

        if (seconds < 10)
            seconds = '0' + seconds;

        if (hours)
            return `${hours}:${minutes}:${seconds}`;
        else
            return `${minutes}:${seconds}`;
    }


    getScheduledCleaning = () => {
        if (!this.props.cleaningTime)
            return;

        // console.log("this.props.cleaningTime",this.props.cleaningTime)

        if (this.props.cleaningTime.minute < 10)
            return `${this.props.cleaningTime.hour}:0${this.props.cleaningTime.minute}`;
        else
            return `${this.props.cleaningTime.hour}:${this.props.cleaningTime.minute}`;
    }


    commandClicked = (command) => {
        if (this.props.isPending)
            return;

        this.setState({showModal: true, command});
    }

    sendCommand = () => {
        console.log("sendCommand", this.state.command, this.state.command ===COMMAND_RESET)
        const params = {
            id: this.props.project._id,
            category: this.props.category,
            value: this.props.categoryId || this.props.categoryValue,
        }
        if (this.state.command ===COMMAND_RESET) {
            params.event = COMMAND_RESET;
            params.cmd = {
                name: this.props.category ==='robot'?'reset':'reset-all',
                dest: undefined,
                data: undefined
            }
            this.props.actions.sendCommand(params);
        } else {
            params.event = this.state.command;
            params.rpi = this.props.rpi;
            this.props.actions.sendCommand(params);
        }

        this.closeModal();
    }

    switchToAutoMode = () => {
        this.props.actions.sendCommand({
            id: this.props.project._id,
            event: COMMAND_REMOTE_COMMAND,
            category: this.props.category,
            value: this.props.categoryId || this.props.categoryValue,
            cmd: {
                name: 'automode',
                dest: undefined,
                data: undefined
            }
        });
    }

    getStatusText = () => {
        if (this.props.category ==='group')
            return `${this.capitalize(this.props.category)} ${this.props.categoryValue} - `;
        else
            return '';
    }

    getCurrentStateText = () => {

    }


    capitalize = (s) => {
        if (typeof s !=='string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    getModalTitle = () => {
        if (this.state.command ===COMMAND_GO_HOME)
            return 'Stop Cleaning (Go Home)';
        else if (this.state.command ===COMMAND_START)
            return 'Start Cleaning';
        else if (this.state.command ===COMMAND_CONTINUE)
            return 'Continue';
        else if (this.state.command ===COMMAND_PAUSE)
            return 'Pause';
        else if (this.state.command ===COMMAND_RESET)
            return `Reset ${this.props.category}`;
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    isCleaningPrevented = () => {
        if (this.props.currentState !=='cleaning-prevented')
            return false;

        const hour = new Date().toLocaleString("en-US", {
            timeZone: this.props.timezone.timeZoneId,
            hour: '2-digit',
            hour12: false
        })
        return hour > this.props.cleaningTime.hour;
    }

    getMainClass = () => {
        if (this.isOffline() || this.isInactiveRobot())
            return 'offline cleaning-control';
        else if (!this.props.autoMode && this.props.category ==='vehicle')
            return 'offline auto-mode cleaning-control';
        return 'cleaning-control'
    }

    getOfflineTitle = () => {
        if (this.props.category ==='vehicle')
            return 'Vehicle is offline';

        if (this.isInactiveRobot()) {
            return 'Robot is portable/inactive';
        }

        return this.props.project.projectName + ' is offline';
    }

    onRobotStatusChanged = (item) => {
        if (!item.value || this.props.category ==='vehicle')
            return;

        this.props.actions.changeStatus(this.props.robot.id, item.value);
    }

    cleaningStatusDiv = (text) => {
        return <div className="cleaning-status cleaning-pending">
            {this.state.changeStatus ? <Dropdown
                    options={RobotStatuses}
                    value={this.props.currentState}
                    // placeholder="Change status"
                    onChange={this.onRobotStatusChanged}
                    className='dropdown'
                    controlClassName='control'
                /> :
                <div>{this.getStatusText()}{text} {this.props.isPending ? '(Pending)' : null}
                    {!this.props.isPending && text ==='Cleaning In Progress' ? <img src={cleaningInProgress}/> : null}
                </div>
            }
            {this.props.category ==='robot' && this.props.currentState ?
                <div className="change-status" onClick={() => this.setState({changeStatus: !this.state.changeStatus})}>
                    {!this.state.changeStatus ? <span>change</span> : <span>close</span>}
                </div> : null}
        </div>
    }


    pauseButton = <div
        className={this.props.pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
        onClick={() => this.commandClicked(COMMAND_PAUSE)}>
        <span className="pause-icon"></span>
        <span className="pause-icon"></span>
        <img src={greySpinner} className="spinner"/>
        <span className="text">PAUSE</span>
    </div>;

    goHomeButton = <div
        className={this.props.pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
        onClick={() => this.commandClicked(COMMAND_GO_HOME)}>
        <span className="stop-icon"></span>
        <img src={spinner} className="spinner"/>
        <span className="text">GO HOME</span>
    </div>;

    startButton = <div
        className={this.props.pendingCommand ===COMMAND_START ? 'button start loading' : 'button start'}
        onClick={() => this.commandClicked(COMMAND_START)}>
        <img src={playIcon} className="icon"/>
        <img src={spinner} className="spinner"/>
        <span className="text">START</span>
    </div>;


    isOffline = () => {
        return this.props.offline || this.props.project.connectionStatus ==="offline";
    }

    isInactiveRobot = () => {
        return this.props.robot && this.props.robot.operationMode ==="inactive";
    }

    render() {
        // console.log("currentState", this.props.currentState, "this.props.pendingCommand=", this.props.pendingCommand);

        return (
            <div className={this.getMainClass()}>
                {this.state.showModal && !this.isOffline() && !this.isInactiveRobot()?
                    <Modal title={this.getModalTitle()} onClose={this.closeModal}>
                        <div className="buttons-wrapper">
                            <div className="button cancel" onClick={this.closeModal}>Cancel</div>
                            <div className="button" onClick={this.sendCommand}>Continue</div>

                        </div>

                    </Modal> : null}

                {this.state.showModal && (this.isOffline() || this.isInactiveRobot()) &&
                    <Modal title={this.getOfflineTitle()} onClose={this.closeModal}>
                        <div className="buttons-wrapper">
                            <div className="button cancel" onClick={this.closeModal}>Ok</div>
                            {/*<div className="button" onClick={this.sendCommand}>Continue</div>*/}

                        </div>

                    </Modal>}

                {CleaningStatuses.includes(this.props.currentState) ?
                    <div className="upper">
                        <div className="cleaning-status cleaning-in-progress">
                            {this.cleaningStatusDiv(dashedToTitle(this.props.currentState))}
                        </div>
                        <div className="right">
                            <div className="time">{this.getCleaningTime()}</div>
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">
                                {this.pauseButton}
                                {this.goHomeButton}
                            </div> : null}
                        </div>
                    </div> : null}
                {PausedStatuses.includes(this.props.currentState) ?
                    <div className="upper">
                        {this.cleaningStatusDiv(dashedToTitle(this.props.currentState))}
                        <div className="right">
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">
                                {this.startButton}
                                {this.goHomeButton}
                            </div> : null}
                        </div>
                    </div> : null
                }
                {!this.props.currentState || ParkingStatuses.includes(this.props.currentState) ?
                    <div className="upper">
                        {this.cleaningStatusDiv(dashedToTitle(this.props.currentState))}
                        <div className="right">
                            {this.props.cleaningTime ? <div className="scheduled-cleaning">
                                {this.isCleaningPrevented() ? <span>Cleaning prevented at </span> :
                                    <span>Cleaning scheduled for </span>}
                                {this.getScheduledCleaning()}</div> : null}
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">
                                {this.startButton}
                                {this.props.currentState ==='error' ? this.goHomeButton : null}
                            </div> : null}
                        </div>
                    </div> : null
                }


                {this.props.currentState ==='going-home' ?
                    <div className="upper">
                        {this.cleaningStatusDiv(dashedToTitle(this.props.currentState))}
                        <div className="right">
                            {/*<div className="time">06:44</div>*/}
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">

                                <div
                                    className={this.props.pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
                                    onClick={() => this.commandClicked(COMMAND_PAUSE)}>
                                    <span className="pause-icon"></span>
                                    <span className="pause-icon"></span>
                                    <img src={greySpinner} className="spinner"/>
                                    <span className="text">PAUSE</span>
                                </div>
                            </div> : null}
                        </div>
                    </div> : null}
                {this.props.user.role !=="projectOwner" ?
                    <div className="auto-mode orange">
                        {this.props.category ==='vehicle' &&
                            <span>
                                {this.props.autoMode ?
                                    <span>Auto Mode</span>
                                    :
                                    <span>Manual Mode <span className="switch"
                                                            onClick={this.switchToAutoMode}>switch to auto</span></span>
                                }
                            </span>
                        }

                        <span className="reset right"
                              onClick={() => this.commandClicked(COMMAND_RESET)}>reset {this.props.category}</span>

                    </div>
                    : null
                }
            </div>
        )
    }

};


const mapStateToProps = (state, ownProps) => {

    let
        myState = state[ownProps.namespace + 'CleaningControlReducer'];


    return {
        ...myState,
        project: state.indexReducer.project,
        robot: state.indexReducer.robot,
        vehicle: state.indexReducer.vehicle,
        user: state.indexReducer.user
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators({
            sendCommand: sendCommand(ownProps.namespace),
            sendCommandStarted,
            changeStatus
        }, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CleaningControlRobotComponent);