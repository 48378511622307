import {ADD_ROW_SUCCESS} from "./addRowReducer";

export const GET_ROWS_STARTED = 'GET_ROWS_STARTED';
export const GET_ROWS_ERROR = 'GET_ROWS_ERROR';
export const GET_ROWS_SUCCESS = 'GET_ROWS_SUCCESS';
export const UPLOAD_ROWS_STARTED = 'UPLOAD_ROWS_STARTED';
export const UPLOAD_ROWS_ERROR = 'UPLOAD_ROWS_ERROR';
export const UPLOAD_ROWS_SUCCESS = 'UPLOAD_ROWS_SUCCESS';
export const DOWNLOAD_ROWS_SUCCESS = 'DOWNLOAD_ROWS_SUCCESS';


const INITIAL_STATE = {
    rows: [],
    isLoading: false
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_ROWS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case DOWNLOAD_ROWS_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case GET_ROWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rows: action.payload.rows
            };
        case ADD_ROW_SUCCESS:
            let existing = state.rows.find(row=>row._id ===action.payload.row._id);
            let rows = state.rows;

            if (existing) {
                let index = rows.indexOf(existing);


                if (action.payload.row.isDeleted)
                    rows.splice(index, 1);
                else
                    rows[index] = action.payload.row;

                rows = [].concat(rows);
            }
            else {
                rows = [action.payload.row].concat(state.rows);
            }

            return {
                ...state,
                rows
            }
        case GET_ROWS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case UPLOAD_ROWS_STARTED:
            return {
                ...state,
                isLoading: true
            }
        case UPLOAD_ROWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                alert: 'File uploaded successfully'
            }
        case UPLOAD_ROWS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            }
        default:
            return state;
    }
}