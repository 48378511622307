import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {deleteTaskError} from "./deleteTaskErrorAction";
import {deleteTaskSuccess} from "./deleteTaskSuccessAction";

export function deleteTask(id) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/tasks/' + id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        try {
            const response = await axios.delete(url, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(deleteTaskSuccess(response.data.task));
            } else {
                dispatch(deleteTaskError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(deleteTaskError(error));
        }
        ;
    };
}