import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './loader.scss';
import spinner from './white_spinner.svg';

class LoaderComponent extends Component {
    constructor(props) {
        super(props);

    }

    getClass = ()=>{
        if (this.props.className)
            return this.props.className + ' modal';

        return 'modal';
    }

    render() {
        return (
            <div className="loader">
                <div className="spinner-wrapper">
                    <img src={spinner} />
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    //console.log("state.indexReducer", state.indexReducer)
    return {

    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(LoaderComponent);