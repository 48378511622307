import {GET_ERRORS_SUCCESS} from "./errorsReducer";
import {GET_LIVE_REPORTS_SUCCESS, REFRESH_ROBOT_BATTERIES_SUCCESS} from "./dashboardReducer";
import {LOCAL_STORAGE_ROBOT} from "../consts";
import {ROBOT_SELECTED} from "./indexReducer";

export const GET_ROBOT_LOGS_STARTED = 'GET_ROBOT_LOGS_STARTED';
export const GET_ROBOT_LOGS_ERROR = 'GET_ROBOT_LOGS_ERROR';
export const GET_ROBOT_LOGS_SUCCESS = 'GET_ROBOT_LOGS_SUCCESS';
export const SET_ROBOT_SETTINGS_SUCCESS = 'SET_ROBOT_SETTINGS_SUCCESS';
export const GET_ROBOT_DETAILS_SUCCESS = 'GET_ROBOT_DETAILS_SUCCESS';


const INITIAL_STATE = {
    logs: [],
    errors:[],
    isLoading: true,
    robotLoadedFromServer: false,
    robot: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROBOT)),
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_LIVE_REPORTS_SUCCESS:
            if (state.robot) {
                return {
                    ...state,
                    liveReport:  action.payload.liveReports.find(item=>item.robot.name ===state.robot.robotName)
                };
            }
            else
                return state;
        case REFRESH_ROBOT_BATTERIES_SUCCESS:
            return {
                ...state,
                processId: action.payload.processId
            }
        case GET_ROBOT_DETAILS_SUCCESS:
            return {
                ...state,
                robotLoadedFromServer: true,
                isLoading: false,//state.logs?false:true,
                robot: action.payload.robot
            };

        case ROBOT_SELECTED:
            return {
                ...state,
                isLoading: true,
                robot: action.payload.robot,
                liveReport: undefined,
                processId: undefined
            };

        case SET_ROBOT_SETTINGS_SUCCESS:
            console.log("action.payload.robot",action.payload.robot)
            const robot = {
                ...state.robot,
                settings: action.payload.robot.settings,
                currentStatus: action.payload.robot.currentStatus,
                batteryPercentage: action.payload.robot.batteryPercentage
            };

            return {
                ...state,
                robot
            }
        case GET_ERRORS_SUCCESS:
            return {
                ...state,
                errors: action.payload.errors
            };

        default:
            return state;
    }
}