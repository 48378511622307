import { camelCaseToText } from "../../consts";

const getAdditionalData = (error) => {
    const fields = [
        { field: "temperature", units: "°C", errorCodes: ["TEMPERATURE_TOO_HIGH"] },
        {
            field: "rotorCurrent", units: "A", errorCodes: [
                "CLEAN_MOTOR_STALL_STAY_HOME",
                "CLEAN_MOTOR_NOT_ROTATING",
                "CLEAN_MOTOR_UNDERCURRENT",
                "CLEAN_MOTOR_ERROR_STAY_HOME",
            ]
        },
        {
            field: "rotorSpeed", units: "rpm", errorCodes: [
                "CLEAN_MOTOR_STALL_STAY_HOME",
                "CLEAN_MOTOR_NOT_ROTATING",
                "CLEAN_MOTOR_UNDERCURRENT",
                "CLEAN_MOTOR_ERROR_STAY_HOME",
                "CLEAN_MOTOR_STALL_STAY_HOME"
            ]
        },
        { field: "cleaningDistance", units: "m" },
        {
            field: "batteryPercentage", units: "%", errorCodes: [
                "RESET_WHILE_ACTIVE",
                "NOT_ENOUGH_BATTERY_GO_BACK",
                "OPTIMIZED_DRIVE_HOME",
                "TOTAL_OVERCURRENT_EVENT",
                "TOTAL_OVERCURRENT_GO_HOME",
                "OPTIMIZE_GO_BACK",
                "NOT_ENOUGH_BATTERY_OPTIMIZE",
                "OPTIMIZE_ON_WAY_BACK",
            ]
        },
        {
            field: "voltage", units: "V", errorCodes: [
                "RESET_WHILE_ACTIVE",
                "NOT_ENOUGH_BATTERY_GO_BACK",
                "OPTIMIZED_DRIVE_HOME",
                "TOTAL_OVERCURRENT_EVENT",
                "TOTAL_OVERCURRENT_GO_HOME",
                "OPTIMIZE_GO_BACK",
                "NOT_ENOUGH_BATTERY_OPTIMIZE",
                "OPTIMIZE_ON_WAY_BACK",
            ]
        },
        {
            field: "driveMotorCurrent", units: "A", errorCodes: [
                "DRIVE_MOTOR_NOT_ROTATING"
            ]
        },
        {
            field: "driveMotorSpeed", units: "rpm", errorCodes: [
                "DRIVE_MOTOR_NOT_ROTATING"
            ]
        },
        {
            field: "batteryCurrent", units: "A", errorCodes: [
                "CLEAN_MOTOR_STALL_STAY_HOME",
                "CLEAN_MOTOR_NOT_ROTATING",
                "CLEAN_MOTOR_UNDERCURRENT",
                "CLEAN_MOTOR_ERROR_STAY_HOME",
                "DRIVE_MOTOR_NOT_ROTATING",
            ]
        },
        { field: "motorFault", units: "" },
        {
            field: "locationOnRow", units: "m", errorCodes: [
                "USER_ABORTED",
                "TIMEOUT_REACHED_GO_HOME",
                "TIMEOUT_REACHED",
                "RESET_WHILE_ACTIVE",
                "CLEAN_STALL_GO_HOME",
                "DRIVE_STALL_GO_HOME",
                "CLEAN_STALL_HALT",
                "DRIVE_STALL_HALT",
                "CLEAN_MOTOR_RPM_TOO_LOW",
                "CLEAN_OVERCURRENT_OBSTACLE",
                "CLEAN_OVERCURRENT_GO_HOME",
                "CLEAN_OVERCURRENT_HALT",
                "DRIVE_PEAK_LOAD_OBSTACLE",
                "DRIVE_PEAK_LOAD_GO_HOME",
                "DRIVE_PEAK_LOAD_HALT",
                "DRIVE_HIGH_LOAD_OBSTACLE",
                "DRIVE_HIGH_LOAD_GO_HOME",
                "DRIVE_HIGH_LOAD_HALT",
                "DRIVE_MOTOR_LOW_LOAD_LOW_RPM",
                "NOT_ENOUGH_BATTERY_GO_BACK",
                "OPTIMIZED_DRIVE_HOME",
                "OPTIMIZE_GO_BACK",
                "TOTAL_OVERCURRENT_EVENT",
                "TOTAL_OVERCURRENT_GO_HOME",
                "NOT_ENOUGH_BATTERY_OPTIMIZE",
                "OPTIMIZE_ON_WAY_BACK",
            ]
        },
    ]

    const getFieldName = (field) => {
        if (field === "locationOnRow") {
            return "Location";
        } else if (field === "voltage") {
            return "V";
        } else {
            return camelCaseToText(field);
        }
    }

    const list = fields.map(item => {
        const { field, units, errorCodes } = item;
        if (error[field]) {//} && errorCodes?.includes(error.errorCode)) {                
            if (field === 'voltage' && !errorCodes?.includes(error.errorCode)) {                   
                return;
            }             

            if (field === 'motorFault' || field === 'cleaningDistance') {
                return;
            }

            if (["cleaningDistance", "locationOnRow"].includes(field)) {
                const location = Math.round(error[field] / 1000);
                if (location)
                    return { field: getFieldName(field), value: location, units }
                else 
                    return;
            }
            return {
                field: camelCaseToText(field),
                value: typeof error[field] === 'string' ? error[field].replaceAll(/_/g, ' ') : error[field],
                units
            }
        }
    }).filter(item => !!item);

    return list.map((item, index) => <div className="item" key={index}>{item.field}: <span className='value'>{item.value}{item.units}</span></div>);
}

export default getAdditionalData;