import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { camelCaseToText, getFormattedDate } from "../../consts";

const InactivityReportsComponent = ({ project, date }) => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const apiHandler = new ApiHandler();
    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            try {
                const reports = await apiHandler.getRobotDailyReports({ projectId: project._id, date, reportType: 'inactive' });
                
                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        getReports();

    }, [project, date]);

    return <>
        <table className="inactivity">
            <tbody>
                <tr>
                    <th className="robot-number">Robot</th>
                    <th>Operation Mode</th>
                    <th>Last working day</th>                    
                </tr>

                {reports.map((report) => {
                    return <tr key={report.robotId.robotNumber}>
                        <td className="robot-number">{report.robotId.robotNumber}</td>
                        <td>{camelCaseToText(report.operationMode)}</td>
                        <td>{report.inActive.lastReportDate? 
                            getFormattedDate({date: report.inActive.lastReportDate, dateOnly: true}):
                            'Over 30 days ago'}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </>
}

export default InactivityReportsComponent;