import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editAvailabilityStarted} from "./editAvailabilityStartedAction";
import {editAvailabilitySuccess} from "./editAvailabilitySuccessAction";
import {editAvailabilityError} from "./editAvailabilityErrorAction";

export function editAvailability(params, id, file) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/availability/' + id;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editAvailabilityStarted());

        try {
            const response = await axios.patch(url, params, {headers: {Authorization: authToken}});
            if (response.data.success) {
                    dispatch(editAvailabilitySuccess(response.data.availabilityReport));

            } else {
                dispatch(editAvailabilityError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(editAvailabilityError(error));
        }
        ;
    };
}