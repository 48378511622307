import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editProjectStarted} from "./editProjectStartedAction";
import {editProjectError} from "./editProjectErrorAction";
import {getAttachmentsSuccess} from "./getAttachmentsSuccessAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";

export function getAttachments(projectId) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    return function (dispatch) {
        let url = HOST_URL+'/api/attachments/?projectId=' + projectId;

        dispatch(editProjectStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                response.data.attachments.forEach(a=>a.path = HOST_URL + a.path);
                dispatch(getAttachmentsSuccess(response.data.attachments));
            }
            else {
                dispatch(editProjectError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editProjectError(error));
        });
    };


}