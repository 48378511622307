import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editProjectStarted} from "./editProjectStartedAction";
import {editProjectSuccess} from "./editProjectSuccessAction";
import {editProjectError} from "./editProjectErrorAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {uploadAttachment} from "../../utils";

export function editProject(projectId, params, uploadFiles, deletedAttachments, applyToAllRobots) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    const deleteAttachments = (projectId, attachments, index)=>{
        if (!attachments[index])
            return index;

        let url = HOST_URL+'/api/attachments/' + attachments[index]._id;

        return axios.delete(url, {headers: {Authorization: authToken, 'content-type': 'multipart/form-data'}}).then((response) => {
            if (response.data.success) {
                index++;
                return deleteAttachments(projectId, attachments, index);
            }
            else {
                throw new Error(response.data.message);
            }
        });
    }

    return async function (dispatch) {
        let url = HOST_URL+'/api/projects/' + projectId;

        dispatch(editProjectStarted());

        try {
            const response = await axios.patch(url,params, {headers: {Authorization: authToken}});
            if (!response.data.success) {
                dispatch(editProjectError(new Error(response.data.message)));
                return;
            }


            if (uploadFiles.length && uploadFiles[0]) {
                await uploadAttachment({
                    file: uploadFiles[0],
                    projectId: response.data.project._id
                });
            }

            if (deletedAttachments.length)
                await deleteAttachments(response.data.project._id, deletedAttachments, 0);

            dispatch(editProjectSuccess(response.data.project));

        } catch(error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editProjectError(error));
        };
    };


}