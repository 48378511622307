import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {getRobotsCsvSuccess} from "./getRobotsCsvSuccessAction";
import {getRobotsCsvError} from "./getRobotsCsvErrorAction";
import {getRobotsStarted} from "./getRobotsStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {getDateStr} from "../../utils";

export function getRobotsCsv(projectId, date) {
    return function (dispatch) {
        let url = `${HOST_URL}/api/vehicles-and-robots/export?projectId=${projectId}`;
        if (date)
            url += `&date=${getDateStr(date)}`;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getRobotsStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success ===false) {
                dispatch(getRobotsCsvError(new Error(response.data.message)));
            }
            else {
                dispatch(getRobotsCsvSuccess(response.data));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getRobotsCsvError(error));
        });
    };
}