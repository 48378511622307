import React, { useEffect, useRef, useState } from "react";
import '../cleaningControl/cleaningControl.scss';
import 'react-dropdown/style.css';
import spinner from '../cleaningControl/white_spinner.svg';
import {
    COMMAND_CONTINUE,
    COMMAND_GO_HOME,
    COMMAND_PAUSE,
    COMMAND_START,
    COMMAND_RESET
} from "../cleaningControl/sendCommand";
import greySpinner from "../cleaningControl/grey_spinner.svg";

import playIcon from "../cleaningControl/play-icon.svg";
import Modal from "../modal/modal";
import Dropdown from "react-dropdown";
import ApiHandler from '../dashboard/api';

const apiHandler = new ApiHandler();

const ManualCleaningControl = (props) => {
    console.log("props.project", props.project);
    const [showModal, setShowModal] = useState(false);
    const [command, setCommand] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState();
    const [rpis, setRpis] = useState([]);
    const [selectedRpi, setSelectedRpi] = useState();

    useEffect(() => {
        setSelectedGroup(props.project.groups[0]);
    }, [props.project]);

    useEffect(() => {
        if (selectedGroup)
            setRpis(selectedGroup.rpis);
    }, [selectedGroup]);

    useEffect(() => {
        if (rpis && rpis.length > 0)
            setSelectedRpi(rpis[0]);
    }, [rpis]);

    const commandClicked = (command) => {
        if (props.isPending)
            return;

        setShowModal(true);
        setCommand(command);
    }

    const sendCommand = async () => {
        console.log("sendCommand", command, command ===COMMAND_RESET)
        const params = {
            projectId: props.project._id,
            category: 'group',
            value: selectedGroup.groupNumber,
            event: command,
            rpi: selectedRpi
        }

        await apiHandler.sendCommand(params);

        closeModal();
    }


    const getModalTitle = () => {
        if (command ===COMMAND_GO_HOME)
            return 'Stop Cleaning (Go Home)';
        else if (command ===COMMAND_START)
            return 'Start Cleaning';
        else if (command ===COMMAND_CONTINUE)
            return 'Continue';
        else if (command ===COMMAND_PAUSE)
            return 'Pause';

    }

    const closeModal = () => setShowModal(false);


    const pauseButton = <div
        className={props.pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
        onClick={() => commandClicked(COMMAND_PAUSE)}>
        <span className="pause-icon"></span>
        <span className="pause-icon"></span>
        <img src={greySpinner} className="spinner" />
        <span className="text">PAUSE</span>
    </div>;

    const goHomeButton = <div
        className={props.pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
        onClick={() => commandClicked(COMMAND_GO_HOME)}>
        <span className="stop-icon"></span>
        <img src={spinner} className="spinner" />
        <span className="text">GO HOME</span>
    </div>;

    const startButton = <div
        className={props.pendingCommand ===COMMAND_START ? 'button start loading' : 'button start'}
        onClick={() => commandClicked(COMMAND_START)}>
        <img src={playIcon} className="icon" />
        <img src={spinner} className="spinner" />
        <span className="text">START</span>
    </div>;

    const onGroupSelected = (option) => {
        const group = props.project.groups.find(group => group.groupNumber === Number(option.value));
        setSelectedGroup(group);
        setRpis(group.rpis);
        setSelectedRpi('');
    }

    const onRpiSelected = (option) => {
        setSelectedRpi(option.value);
    }

    return (
        <div className="manual-cleaning">
            {showModal ?
                <Modal title={getModalTitle()} onClose={closeModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={closeModal}>Cancel</div>
                        <div className="button" onClick={sendCommand}>Continue</div>

                    </div>

                </Modal>
                : null}

            <div className="upper">

                <div className="title">MANUAL CONTROLL</div>
                <div className="dropdowns">
                    <Dropdown
                        options={props.project.groups.map(group => ({ label: "Group " + group.groupNumber, value: group.groupNumber + "" }))}
                        onChange={onGroupSelected}
                        value={selectedGroup?selectedGroup.groupNumber + "":""}
                        className='dropdown'
                        controlClassName='control'
                    />

                    {rpis ? <Dropdown
                        options={rpis.map(rpi => ({ label: "Subnet " + rpi, value: rpi + "" }))}
                        // options={[{ label: '1', value: '1' }, { label: '2', value: '2' }]}
                        onChange={onRpiSelected}
                        value={selectedRpi}
                        className='dropdown'
                        controlClassName='control'
                    /> : null}
                </div>

                <div className="buttons">
                    {startButton}
                    {pauseButton}
                    {goHomeButton}
                </div>
            </div>
        </div>
    )
}




export default ManualCleaningControl;