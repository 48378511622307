// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: block;
  z-index: 1000;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(1, 1, 1, 0.3);
  top: 0;
  left: 0; }
  .loader .spinner-wrapper {
    width: 200px;
    height: 200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: calc(50% - 100px);
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
    .loader .spinner-wrapper img {
      display: block;
      width: 140px;
      height: 140px;
      margin-left: auto;
      margin-right: auto;
      top: calc(50% - 70px);
      position: relative; }
`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.scss"],"names":[],"mappings":"AACA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,aAAa;EACb,WAAW;EACX,8BAA0B;EAC1B,MAAM;EACN,OAAO,EAAA;EART;IAWI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,OAAO;IACP,oCAAiC;IACjC,mBAAmB,EAAA;IAnBvB;MAsBM,cAAc;MACd,YAAY;MACZ,aAAa;MACb,iBAAiB;MACjB,kBAAkB;MAClB,qBAAqB;MACrB,kBAAkB,EAAA","sourcesContent":["\n.loader {\n  display: block;\n  z-index: 1000;\n  position: fixed;\n  height: 100vh;\n  width: 100%;\n  background: rgba(1,1,1,.3);\n  top: 0;\n  left: 0;\n\n  .spinner-wrapper {\n    width: 200px;\n    height: 200px;\n    margin-right: auto;\n    margin-left: auto;\n    position: relative;\n    top: calc(50% - 100px);\n    left: 0;\n    background-color: rgba(0,0,0,0.5);\n    border-radius: 10px;\n\n    img {\n      display: block;\n      width: 140px;\n      height: 140px;\n      margin-left: auto;\n      margin-right: auto;\n      top: calc(50% - 70px);\n      position: relative;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
