import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './robotStatus.scss';
import { Tooltip } from 'react-tooltip'


class RobotStatusComponent extends Component {
    constructor(props) {
        super(props);
        this.itemsRef = {};
        this.state = {};
        // this.wrapper = null;
    }

    componentWillReceiveProps(nextProps) {
        // console.log("nextProps.robots.length",nextProps.robots.length, "this.props.robots.length",this.props.robots.length)
        if (!this.props.totalNumber || this.props.totalNumber !==nextProps.totalNumber)
            this.updateDimensions(nextProps.totalNumber);
        else if (!this.props.robots.length || this.props.robots.length !==nextProps.robots.length)
            this.updateDimensions(nextProps.robots.length);
    }


    componentDidMount() {
        //console.log("this.wrapper", this.wrapper);
        this.updateDimensions(this.props.totalNumber);
        window.addEventListener('resize', ()=>this.updateDimensions(this.props.totalNumber));
    }

    updateDimensions = (totalNumber) => {
        if (!this.wrapper)
            return;
        let itemWidth = Math.floor(this.wrapper.offsetWidth * 0.67 / totalNumber - 0.02);
        if (itemWidth > 10)
            itemWidth--;
        const itemMargin = Math.min(Math.floor(this.wrapper.offsetWidth * 0.33 / (totalNumber - 1)),10);
        this.setState({
            itemWidth,
            itemMargin
        }, () => {
            // console.log("itemWidth", itemWidth, "itemMargin", itemMargin, this.wrapper.offsetWidth, totalNumber);
        });
    }

    componentWillUnmount() {

    }


    getArray = () => {        
        if (this.props.robots) {
            return this.props.robots.map(robot => {
                let color = '#dcdedf';
                if (!robot.currentStatus)
                    color = '#111';
                if (robot.currentStatus ==='error') {
                    if (robot.isCurrentlyStuckOnRow)
                        color = '#f6112a';
                    else
                        color = '#e36511';
                } else if (['cleaning', 'drive-forward', 'drive-backwards', 'start-cleaning', 'finished-cleaning'].includes(robot.currentStatus)) {
                    // console.log("robot", robot)
                    color = '#05b2aa';
                } else {

                }


                let error = robot.currentStatus ==='error' ? `Robot ${robot.robotName} error` : null;
                let text = `${robot.robotName}: ${robot.currentStatus || 'inactive'}`;
                if (robot.voltage && robot.voltage>=0)
                    text += ` (${robot.voltage}V)`;

                return {
                    index: this.props.robots.indexOf(robot),
                    error,
                    name: robot.robotName,
                    text,
                    color
                }
            })
        }

        let arr = [];
        for (let i = 0; i < this.props.robots.length; i++) {
            arr.push({
                index: i,
                name: 'Robot ' + i,
                color: this.props.defaultColor
            });
        }


        this.props.specialCases.forEach(item => {
            if (!arr[item.index])
                return;

            arr[item.index].color = item.color;
            arr[item.index].error = item.error;
        });

        return arr;
    }

    getItemStyle = (item, index) => {
        return {
            width: index ? this.state.itemWidth + 'px' : (this.state.itemWidth - 1) + 'px',
            marginLeft: index ? (this.state.itemMargin) + 'px' : 0,
            backgroundColor: item.color
        }
    }

    getTooltipText = (item) => {

        if (item.text)
            return item.text;

        let txt = item.name + ': ';
        txt += item.error ? item.error : 'active';
        return txt;
    }


    render() {
        return (
            <div>
                <div className="robot-status" ref={(ref) => this.wrapper = ref}>
                    {this.getArray().map((item, index) => {
                        // console.log(this.getTooltipText(item));
                        return <div
                            key={index}
                            className="item"
                            style={this.getItemStyle(item, index)}                            
                            data-tooltip-id="my-tooltip" 
                            data-tooltip-content={this.getTooltipText(item)}
                        >
                        </div>
                    })}
                </div>
                <Tooltip id="my-tooltip" />
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.exampleReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(RobotStatusComponent);