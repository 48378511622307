// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  width: 25%;
  height: 24px;
  margin: 0 0;
  display: inline-block; }
  .search img {
    width: 24px;
    height: 100%;
    margin-right: 7px; }
  .search input {
    width: calc(100% - 31px);
    height: 20px;
    font-size: 14px;
    color: var(--gun-metal);
    display: inline-block;
    border: 0;
    vertical-align: top;
    outline: none;
    background: none; }
  .search input:focus {
    border: none;
    border-bottom: 1px solid var(--light-grey-blue); }
  .search input.text-box::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--light-grey-blue);
    opacity: 1;
    /* Firefox */ }
  .search input.text-box:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--light-grey-blue); }
  .search input.text-box::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--light-grey-blue); }
`, "",{"version":3,"sources":["webpack://./src/components/search/search.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,WAAW;EACX,qBAAqB,EAAA;EAJvB;IAOI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAA;EATrB;IAaI,wBAAwB;IACxB,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,qBAAqB;IACrB,SAAS;IACT,mBAAmB;IAEnB,aAAa;IACb,gBAAgB,EAAA;EAtBpB;IA0BI,YAAY;IAEZ,+CAA+C,EAAA;EA5BnD;IA+BgC,yCAAA;IAC5B,6BAA6B;IAC7B,UAAU;IAAE,YAAA,EAAa;EAjC7B;IAoCyC,4BAAA;IACrC,6BAA6B,EAAA;EArCjC;IAwC0C,mBAAA;IACtC,6BAA6B,EAAA","sourcesContent":[".search {\n  width: 25%;\n  height: 24px;\n  margin: 0 0;\n  display: inline-block;\n\n  img {\n    width: 24px;\n    height: 100%;\n    margin-right: 7px;\n  }\n\n  input {\n    width: calc(100% - 31px);\n    height: 20px;\n    font-size: 14px;\n    color: var(--gun-metal);\n    display: inline-block;\n    border: 0;\n    vertical-align: top;\n\n    outline: none;\n    background: none;\n  }\n\n  input:focus {\n    border: none;\n    //background-color: red;\n    border-bottom: 1px solid var(--light-grey-blue);\n  }\n\n  input.text-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */\n    color: var(--light-grey-blue);\n    opacity: 1; /* Firefox */\n  }\n\n  input.text-box:-ms-input-placeholder { /* Internet Explorer 10-11 */\n    color: var(--light-grey-blue);\n  }\n\n  input.text-box::-ms-input-placeholder { /* Microsoft Edge */\n    color: var(--light-grey-blue);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
