import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editMessageStarted} from "./editMessageStartedAction";
import {createMessageSuccess} from "./createMessageSuccessAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {editMessageError} from "./editMessageErrorAction";

export function createMessage(params) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/messages/';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editMessageStarted());

        try {
            const response = await axios.post(url, params, {headers: {Authorization: authToken}});

            if (response.data.success) {
                dispatch(createMessageSuccess(response.data.message));
            } else {
                dispatch(editMessageError(new Error(response.data.message)));
            }
        } catch (error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());
            
            console.log(error.message);
            dispatch(editMessageError(error));
        }
    };
}