import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {uploadRobotSettingsStarted} from "./uploadRobotSettingsStartedAction";
import {uploadRobotSettingsError} from "./uploadRobotSettingsErrorAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";
import {getRobotSettingsSuccess} from "./getRobotSettingsSuccessAction";
export function uploadRobotSettings(projectId, file) {

    return function (dispatch) {
        let url = HOST_URL+'/api/robots/more/settings/import';

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(uploadRobotSettingsStarted());

        const formData = new FormData();
        formData.append('file',file)
        formData.append('projectId',projectId)

        return axios.post(url,formData, {headers: {Authorization: authToken, 'content-type': 'multipart/form-data'}}).then(async (response) => {
            if (response.data.success) {
                let url = HOST_URL+'/api/robots/more/settings?projectId=' + projectId;
                const r = await axios.get(url,{headers: {Authorization: authToken}});
                if (r.data.success)
                    dispatch(getRobotSettingsSuccess(r.data.data));
                else
                    dispatch(uploadRobotSettingsError(new Error("can't get robot settings")));
            }
            else {
                dispatch(uploadRobotSettingsError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            //console.log("shit");
            console.log(error.message);
            dispatch(uploadRobotSettingsError(error));
        });
    };
}