// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --almost-black: #0d191f;
  --turquoise: #05b2aa;
  --purple: #726fb4;
  --rusty-orange: #e36510;
  --very-light-blue: #e7e8e9;
  --gun-metal: #555e62;
  --light-grey-blue: #b9bcbe;
  --white: #ffffff;
  --blue-grey: #898b8d;
  --brown-grey: #979797;
  --cherry-red: #f6102a;
  --mango: #ffa933;
}

html, body, html {
  font-family: 'Roboto', sans-serif;
  background-color: var(--white);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: Roboto;
  box-sizing: border-box;
}

th {
  position: relative;
}

.sort-up {
  transform: rotate(180deg);
  height: 16px;
  position: absolute;
  right: 5px;
}

.sort-down {
  height: 16px;
  margin-left: 10px;
  position: absolute;
  right: 5px;

}

.gone {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,0BAA0B;EAC1B,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;;AAEZ;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[":root {\n  --almost-black: #0d191f;\n  --turquoise: #05b2aa;\n  --purple: #726fb4;\n  --rusty-orange: #e36510;\n  --very-light-blue: #e7e8e9;\n  --gun-metal: #555e62;\n  --light-grey-blue: #b9bcbe;\n  --white: #ffffff;\n  --blue-grey: #898b8d;\n  --brown-grey: #979797;\n  --cherry-red: #f6102a;\n  --mango: #ffa933;\n}\n\nhtml, body, html {\n  font-family: 'Roboto', sans-serif;\n  background-color: var(--white);\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n* {\n  font-family: Roboto;\n  box-sizing: border-box;\n}\n\nth {\n  position: relative;\n}\n\n.sort-up {\n  transform: rotate(180deg);\n  height: 16px;\n  position: absolute;\n  right: 5px;\n}\n\n.sort-down {\n  height: 16px;\n  margin-left: 10px;\n  position: absolute;\n  right: 5px;\n\n}\n\n.gone {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
