import axios from "axios";
import {submitPhoneSuccess} from "./submitPhoneSuccessAction";
import {submitPhoneError} from "./submitPhoneErrorAction";
import {submitPhoneStarted} from "./submitPhoneStartedAction";
import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY} from "../../consts";

export function submitPhone(phoneNumber, authCode) {

    return function (dispatch) {
        let url = HOST_URL+'/api/auth/login';
        dispatch(submitPhoneStarted());
        return axios.post(url, {phoneNumber, authCode}, {headers: {}}).then((response) => {
            if (response.data.success) {
                if (response.data.authToken) {
                    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response.data.authToken);
                    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(response.data.user));
                }

                dispatch(submitPhoneSuccess(response.data.authToken));
            }
            else {
                dispatch(submitPhoneError(authCode, null));
            }
        }).catch(error => {
            let message = error.message;
            if (error.response) {
                if (error.response.status ===404)
                    message = "Invalid phone number";
                else if (error.response && error.response.status ===401)
                    message = "Invalid code";
            }

            dispatch(submitPhoneError(authCode, message));
        });
    };
}