import React, {Component, PropTypes} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './signin.scss';
import background from "./sigin-bg.svg";
import logo from "./logo-big.svg";
import {submitPhone} from "./submitPhoneAction";
import Loader from "../loader/loader";
import {withNavigation} from '../withNavigate';

class Signin extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authToken)            
            this.props.oldNavigate("/projects");
    }

    submitPhoneNumber = () => {

        if (!this.state.phone)
            return alert("Please enter your phone number");

        if (this.props.smsSent && !this.state.code)
            return alert("Please enter the code sent by SMS");

        this.props.actions.submitPhone(this.state.phone.replace(/-| /g, ""), this.state.code);
    }

    phoneNumberChanged = (phone) => {
        this.setState({phone});
    }

    codeChanged = (code) => {
        this.setState({code});
    }


    handleKeyDown = (e)=>{
        console.log("handleKeyDown");
        if (e.key ==='Enter') {
            this.submitPhoneNumber();
        }
    }

    render() {
        return (
            <div className="signin" style={{backgroundImage: "url(" + background + ")"}}>
                {this.props.isLoading ? <Loader/> : null}
                <img className="logo" src={logo}/>
                <div className="white-box" style={{display: this.props.smsSent ? 'block' : 'none'}}>
                    <div className="title">
                        <span>Confirmation Code</span>
                    </div>
                    <div className="sub-text">
                        <span>We sent a code to the number {this.props.phoneNumber}</span>
                    </div>
                    <input className="text-box" name="pincode" placeholder="Confirmation code"
                           onChange={(event) => this.codeChanged(event.target.value)} onKeyDown={this.handleKeyDown}/>
                    <div className="button" onClick={() => this.submitPhoneNumber()}>SIGN IN</div>
                    <div className="error">{this.props.error}</div>
                </div>
                <div className="white-box" style={{display: this.props.smsSent ? 'none' : 'block'}}>
                    <div className="title">
                        <span>Sign In</span>
                    </div>
                    <div className="sub-text">
                        <span>Enter your phone number to receive an SMS with a single entry code</span>
                    </div>
                    <input className="text-box" name="phone" placeholder="+1-984-8792342"
                           onChange={(event) => this.phoneNumberChanged(event.target.value)} onKeyDown={this.handleKeyDown}/>
                    <div className="button" onClick={() => this.submitPhoneNumber()}>SUBMIT</div>
                    <div className="error">{this.props.error}</div>
                </div>

            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.signinReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({submitPhone}, dispatch),
    };
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(Signin));