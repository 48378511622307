import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getTasksSuccess} from "./getTasksSuccessAction";
import {getTasksError} from "./getTasksErrorAction";
import {getTasksStarted} from "./getTasksStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function getTasks(projectId, search) {

    return function (dispatch) {
        let url = `${HOST_URL}/api/tasks?projectId=${projectId}`;
        if (search)
            url += '&search='+search;
        
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getTasksStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                // dispatch(getTasksSuccess(response.data.data));
                dispatch(getTasksSuccess(response.data));

            }
            else {
                dispatch(getTasksError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getTasksError(error));
        });
    };
}