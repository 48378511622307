import {
    LOCAL_STORAGE_PROJECT,
    LOCAL_STORAGE_ROBOT,
    LOCAL_STORAGE_USER_KEY,
    LOCAL_STORAGE_VEHICLE,
    LOCAL_STORAGE_TOKEN_KEY,
    MENU_URLS
} from "../consts";

export const PROJECT_SELECTED = 'PROJECT_SELECTED';
export const ROBOT_SELECTED = 'ROBOT_SELECTED';
export const VEHICLE_SELECTED = 'VEHICLE_SELECTED';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const UNAUTHORIZED = 'UNAUTHORIZED';


const getMenuItem = ()=> {
    for (let i = 0; i < MENU_URLS.length; i++) {
        const menuItem = MENU_URLS[i];

        if (typeof menuItem ==="string") {
            if (menuItem ===window.location.pathname)
                return i;
        } else if (menuItem instanceof Array && menuItem.includes(window.location.pathname))
            return i;

    }
}

const INITIAL_STATE = {
    project: JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT)),
    robot: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROBOT)),
    vehicle: JSON.parse(localStorage.getItem(LOCAL_STORAGE_VEHICLE)),
    user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)),
    menuItem: getMenuItem()
}



export default (state = INITIAL_STATE, action)=>{
    // //console.log("action.type",action.type);
    switch(action.type){
        case UNAUTHORIZED:
            window.localStorage.removeItem(LOCAL_STORAGE_PROJECT);
            window.localStorage.removeItem(LOCAL_STORAGE_ROBOT);
            window.localStorage.removeItem(LOCAL_STORAGE_VEHICLE);
            window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
            window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);

            window.location.href= "/signin";
            console.log("UNAUTHORIZED!!!");
            return {};

        case PROJECT_SELECTED:
            return {
                ...state,
                project: action.payload.project,

            }
        case VEHICLE_SELECTED:
            return {
                ...state,

                vehicle: action.payload.vehicle
            }
        case ROBOT_SELECTED:
            return {
                ...state,
                robot: action.payload.robot,

            }
        case MENU_ITEM_SELECTED:
            return {
                ...state,
                menuItem: action.payload.menuItem
            }
        default:
            //&& action.payload.response && action.payload.response.status ===401
            if (action.payload && action.payload.error && action.payload.error.response && action.payload.error.response.status ===401) {
                // this.props.navigate(":;
                // throw new Error(action.payload.error);
                // console.log("action.payload.error",JSON.stringify(action.payload.error));
            }


            return state;
    }
}