import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editErrorSuccess} from "./editErrorSuccessAction";
import {editErrorError} from "./editErrorErrorAction";
import {editErrorStarted} from "./editErrorStartedAction";

export function editRobotSettings(params, rowId) {

    return function (dispatch) {
        let url = HOST_URL+'/api/panels/' + rowId;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editErrorStarted());

        return axios.put(url,params, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(editErrorSuccess(params));
            }
            else {
                dispatch(editErrorError(new Error(response.data.message)));
            }
        }).catch(error => {

            console.log(error.message);
            dispatch(editErrorError(error));
        });
    };
}