import React, { useEffect, useRef, useState } from "react";
import "./dashboard.scss";

import ApiHandler from "./api";
import orangeSpinner from "../loader/orange_spinner.svg";
import { Bar } from "react-chartjs-2";
import {
    LOCAL_STORAGE_LIVE_REPORT,
    LOCAL_STORAGE_PROJECT,
    LOCAL_STORAGE_USER_KEY
} from "../../consts";

const apiHandler = new ApiHandler();

const zeroPointVoltage = 15;
const startPointVoltage = 15.2;
const maxVoltage = 17;


const zeroPointPercentage = 0;
const startPointPercentage = 2;
const maxPercentage = 100;



const initialRobotBatteriesData = {
    labels: [],
    datasets: [
        {
            barPercentage: 0.4,
            label: 'Charging status',
            backgroundColor: '#726fb4',
            data: []
        },
        {
            barPercentage: 0.4,
            label: '08:00',
            backgroundColor: '#e36510',
            data: []
        }, {
            barPercentage: 0.4,
            label: '12:00',
            backgroundColor: '#05b2aa',
            data: []
        }, {
            barPercentage: 0.4,
            label: 'No connection',
            backgroundColor: '#f6102a',
            data: []
        }, {
            barPercentage: 0.4,
            label: 'now',
            backgroundColor: '#ffa933',
            data: []
        }]
};


const RobotBatteries = (props) => {
    const [isLoadingVoltages, setIsLoadingVoltages] = useState(false);
    const [voltageType, setVoltageType] = useState('scheduled');
    const batteriesGraph = useRef(null);
    const [project, setProject] = useState();
    const [user, setUser] = useState();
    const [robotBatteriesData, setRobotBatteriesData] = useState(initialRobotBatteriesData);
    const [copy1, setCopy1] = useState([]);
    const [copy2, setCopy2] = useState([]);

    const [zeroPoint, setZeroPoint] = useState(0);
    const [startPoint, setStartPoint] = useState(0);
    const [maxPoint, setMaxPoint] = useState(0);
    const [options, setOptions] = useState();

    const [liveInterval, setLiveInterval] = useState();

    const round = (num) => Math.round(num * 100) / 100;

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);

        const zeroPoint = project.settings.robotVersion === 3 ? zeroPointVoltage : zeroPointPercentage;
        const startPoint = project.settings.robotVersion === 3 ? startPointVoltage : startPointPercentage;
        const maxPoint = project.settings.robotVersion === 3 ? maxVoltage : maxPercentage;
        setZeroPoint(zeroPoint);
        setStartPoint(startPoint);
        setMaxPoint(maxPoint);
        
        handleBatteriesApi(project);

        return () => {
            window.clearInterval(liveInterval);
        }
    }, []);

    useEffect(() => {
        
        if (!robotBatteriesData.datasets[1].data.length)
            return;

        const options = {
            maintainAspectRatio: false,
    
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'x',
                    // intersect: false,
                    callbacks: {
                        title: (context, data)=> {
        
                            return 'Robot ' + context[0].label;
                        },
                        label: (context, data) => {
                            
                            const units = project && project.settings.robotVersion === 3 ? 'V' : '%';
                            if (context.datasetIndex === 0)
                                return context.value !== zeroPoint ? 'Noon Charging' : '';
                            else if (context.datasetIndex === 1)
                                return context.value === zeroPoint ? '' : `${robotBatteriesData.datasets[1].label}: ${context.formattedValue}${units}`;
                            else if (context.datasetIndex === 2)
                                return context.value === zeroPoint ? '' : `${robotBatteriesData.datasets[2].label}: ${context.formattedValue}${units}`;
                            else if (context.datasetIndex === 3)
                                return context.value !== zeroPoint ? 'No connection today' : '';
                            else if (context.datasetIndex === 4)
                                return context.value === zeroPoint ? '' : `${robotBatteriesData.datasets[4].label}: ${context.formattedValue}${units}`;
                        },
                        labelColor: (context) => {
                            let backgroundColor;
                            if (context.datasetIndex === 0)
                                backgroundColor = '#726fb4';
                            else if (context.datasetIndex === 1)
                                backgroundColor = '#e36510';
                            else if (context.datasetIndex === 2)
                                backgroundColor = '#05b2aa';
                            else if (context.datasetIndex === 3)
                                backgroundColor = '#f6102a';
        
                            return {
                                backgroundColor
                            }
                        },
                    },
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false, // This will remove the grid lines for the X axis
                    },
                },
                y: {
                    stacked: false,
                    max: maxPoint,
                    min: zeroPoint,
                    grid: {
                        display: false, // This will remove the grid lines for the X axis
                    },
                },
            },
            
        };
        
        setOptions(options);
    }, [robotBatteriesData]);

   
    const getLiveReports = (processId) => {
        console.log("getLiveReports")
        const t0 = Date.now();
        const oneMinute = 60 * 10000;
        const twoMinutes = 2 * oneMinute;

        const intervalId = window.setInterval(async () => {

            const isFull = robotBatteriesData.datasets[1].data.length ==
                robotBatteriesData.datasets[4].data.filter(val => val !== undefined && val !== zeroPoint).length;

            const delta = Date.now() - t0;
            let shouldClearInterval = project.arrayType === 'fixed' ?
                isFull || delta > twoMinutes
                :
                delta > oneMinute;

            if (shouldClearInterval) {
                window.clearInterval(liveInterval);
                setIsLoadingVoltages(false);
                return;
            }

            const local = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_REPORT) || '{}');
            local[project._id] = {
                processId,
                time: new Date()
            };
            localStorage.setItem(LOCAL_STORAGE_LIVE_REPORT, JSON.stringify(local));

            const data = await apiHandler.getLiveReports(project._id, processId);
            const liveReports = data.reports.map(report => ({
                name: report.robot.name,
                batteryPercentage: report.batteryPercentage,
                voltage: report.voltage,
                isCharging: report.isCharging,
                timestamp: report.timestamp
            }));
            // if (liveReports.length) {
            //     debugger;
            // }
            onLiveReports(liveReports);

        }, 3000);

        setLiveInterval(intervalId);
    }

    const handleBatteriesApi = async (project) => {
        let now = new Date();
        let lastNight = new Date();
        lastNight.setHours(0, 0, 0, 0);
        const data = await apiHandler.getRobotBatteries(project._id, lastNight, now);
        const robotVoltages = data.robotVoltages;

        const tmp = { ...initialRobotBatteriesData };

        tmp.labels = robotVoltages.map(robotVoltage => robotVoltage.name);

        let data0 = [], data1 = [], data2 = [], data3 = [];
        robotVoltages.map((robotVoltage, index) => {
            if (!robotVoltage.voltage) {
                data3.push(startPoint);
                data0.push(zeroPoint);
                data1.push(zeroPoint);
                data2.push(zeroPoint);
            } else {
                data3.push(zeroPoint);
                data1.push(robotVoltage.voltage["8"] ? round(robotVoltage.voltage["8"].value) : zeroPoint);
                if (robotVoltage.voltage["12"]) {
                    data2.push(round(robotVoltage.voltage["12"].value));
                    data0.push(robotVoltage.voltage["12"] && robotVoltage.voltage["12"].isCharging ? startPoint : zeroPoint);
                } else {
                    data2.push(zeroPoint);
                    data0.push(robotVoltage.voltage["8"] && robotVoltage.voltage["8"].isCharging ? startPoint : zeroPoint);
                }
            }

        });

        tmp.datasets[0].data = data0;
        tmp.datasets[1].data = data1;
        tmp.datasets[2].data = data2;
        tmp.datasets[3].data = data3;
        
        setRobotBatteriesData(tmp);
    }

    const onLiveReports = (liveReports) => {
        let data4 = [];
        let data0 = [];
        let data1 = [];
        let data2 = [];
        
        const tmp = JSON.parse(JSON.stringify(robotBatteriesData));
        if (tmp.datasets[1].data.length) {
            tmp.datasets[1].data.forEach((vol8, index) => {
                let liveReport = liveReports.find(liveReport => liveReport.name === tmp.labels[index]);

                if (liveReport) {
                    console.log("liveReport", liveReport);
                    data0.push(liveReport.isCharging ? startPoint : 0);
                    if (project.settings.robotVersion === 4)
                        data4.push(round(liveReport.batteryPercentage));
                    else
                        data4.push(round(liveReport.voltage));                        
                } else {
                    data0.push(0);
                    data4.push(zeroPoint);
                }
                data1.push(zeroPoint);
                data2.push(zeroPoint);

            });
        } else {
            if (project.settings.robotVersion === 4)
                data4 = liveReports.map(liveReport => round(liveReport.batteryPercentage));
            else
                data4 = liveReports.map(liveReport => round(liveReport.voltage));

            data1.push(zeroPointPercentage);
            data2.push(zeroPointPercentage);

            tmp.labels = liveReports.map(liveReport => liveReport.name);
        }
        tmp.datasets[0].data = data0;
        tmp.datasets[1].data = data1;
        tmp.datasets[2].data = data2;
        tmp.datasets[4].data = data4;
        setRobotBatteriesData(tmp);
    }

    const getChargingHour = () => {
        if (robotBatteriesData.datasets[4].data.filter(d => d !== undefined).length)
            return 'now';
        else if (robotBatteriesData.datasets[2].data.filter(d => d !== 0 && d !== zeroPoint).length)
            return '12:00';
        else
            return '08:00';
    }
    const onChangeVoltageType = async (voltageType) => {
        let isLoadingVoltages = false;
        const tmp = JSON.parse(JSON.stringify(robotBatteriesData));

        if (voltageType === 'scheduled') {            
            window.clearInterval(liveInterval);
            setVoltageType(voltageType);
            if (copy2 && copy1) {
                tmp.datasets[2].data = copy2;
                tmp.datasets[1].data = copy1;
                
                setRobotBatteriesData(tmp);
            }
        } else if (voltageType === 'now') {
            if (!isLoadingVoltages) {
                isLoadingVoltages = true;
                setIsLoadingVoltages(isLoadingVoltages);
                try {
                    const result = await apiHandler.refreshRobotBatteries(project._id);
                    getLiveReports(result.data.processId);
                } catch (err) {
                    console.log(err);
                    window.alert("Can't get updated robot data");
                    setIsLoadingVoltages(false);
                    return;
                }
            }

            setCopy2([...tmp.datasets[2].data]);
            setCopy1([...tmp.datasets[1].data]);
            tmp.datasets[2].data = tmp.datasets[2].data.map(value => zeroPoint);
            tmp.datasets[1].data = tmp.datasets[1].data.map(value => zeroPoint);
            
            setRobotBatteriesData(tmp);
        }
        setVoltageType(voltageType);
        setIsLoadingVoltages(isLoadingVoltages);
    }

    

    return !user || !project ? <></> : (<>
        <div className="stacked-graph" ref={batteriesGraph}>
            <div className="title">ROBOT BATTERIES {project.settings.robotVersion === 3 ? '(VOLTS)' : '(% FULL)'}</div>
            {user.role !== "projectOwner" ? <div className="voltage-type">

                <span className="radio-item">
                    <input checked={voltageType === 'now'} value="now" type="radio"
                        name="voltageType"
                        onChange={(e) => onChangeVoltageType('now')} />
                    <label>Now</label>
                    {isLoadingVoltages && <img src={orangeSpinner} />}
                </span>

                <span className="radio-item">
                    <input checked={voltageType === 'scheduled'} value="scheduled"
                        type="radio"
                        name="voltageType"
                        onChange={(e) => onChangeVoltageType('scheduled')} />
                    <label>8:00 / 12:00</label>
                </span>
            </div> : null}
            <div className="wrapper wide">
                {!!robotBatteriesData.datasets[0].data.length && !!options && <Bar
                    data={robotBatteriesData}
                    options={options}
                    height={180}
                />}
                
            </div>
            <div className="labels">
                <div className="label">

                    <span className="mark orange"></span>
                    <span className="text">08:00</span>

                    <span className="mark green"></span>
                    <span className="text">12:00</span>

                    <span className="mark purple"></span>
                    <span className="text">Charging ({getChargingHour()})</span>

                    <span className="mark red"></span>
                    <span className="text">No Connection</span>

                    {robotBatteriesData.datasets[4].data.filter(d => d !== undefined && d !== zeroPoint).length ?
                        <span>
                            <span className="mark yellow"></span>
                            <span className="text">Now</span>
                        </span>
                        : null}

                </div>
            </div>
        </div>

    </>)
}

export default RobotBatteries;