import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './tasks.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Search from "../search/search";
import {bindActionCreators} from "redux";
import {getTasks} from "./getTasksAction";
import {deleteTask} from "./deleteTaskAction";
import {taskSelected} from "./taskSelectedAction";
import {getFormattedDate, HOST_URL} from "../../consts";
import addIcon from "./add-icon.svg";
import EditTask from "../editTask/editTask";
import Loader from "../loader/loader";
import TableComponent from "../tableComponent/tableComponent";
import {getTasksSuccess} from "./getTasksSuccessAction";
import Modal from "../modal/modal";
import {viewTask} from "../editTask/actions/viewTaskAction";
import {withNavigation} from '../withNavigate';
import {TimeZone} from "../utils";
import Attachment from "../attachment/attachment";


class TasksComponent extends TableComponent {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.navigate("/projects");
            return;
        }

        this.state = {
            showEditTask: false,
            // selectedTask: Object.assign({}, this.newTask),
        };

        this.timeZone = new TimeZone(this.props.project)
    }


    newTask = {
        projectId: this.props.project._id,
        created: Date.now(),
        dueDate: Date.now(),
        status: '',
        taskType: '',
        description: '',
        createdBy: {},
        closedBy: {}
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.tasks) {
            console.log(nextProps.tasks[1]);
        }
    }


    componentDidMount() {

        this.props.actions.getTasks(this.props.project._id, null);
    }

    taskSelected = (task) => {
        this.props.actions.viewTask();

        this.setState({
            selectedTask: {...task, dueDate: new Date(task.dueDate), created: new Date(task.created)},
            showEditTask: true
        });
    }

    closeEditTask = (showEditTask) => {

        this.setState({showEditTask});
    }

    getData = () => this.props.tasks;

    publishData = (data) => this.props.actions.getTasksSuccess(data);

    getRowText = (task) => {
        if (!task.row)
            return;

        if (task.locationOnRow && task.locationOnRow >= 0)
            return `${task.row.number} (${Math.round(task.locationOnRow/1000)}m)`;
        else
            return task.row.number
    }


    dateChanged = dates => {
        const [start, end] = dates;
        this.setState({startDate: start, endDate: end});
        if (start && end)
            this.props.actions.getTasks(this.props.project._id, this.state.search, null, null, start, end);
    };

    searchClicked = (search) => {
        this.setState({search});
        this.props.actions.getTasks(this.props.project._id, search);
    }

    createNewTask = () => {
        this.props.actions.viewTask();

        this.setState({
            selectedTask: Object.assign({}, this.newTask),
            showEditTask: true
        });
    }


    closeDeleteTaskModal = ()=>{

        this.setState({showDeleteTask: false, showEditTask:true});
    }

    deleteTask = ()=>{

        this.setState({showDeleteTask: false, showEditTask:false});
        this.props.actions.deleteTask(this.state.selectedTask._id);
    }

    attachmentClicked = (e, attachment)=>{
        e.stopPropagation();
        window.open(attachment.url, "_blank");
    }


    render() {
        return (
            <div className="page">
                {this.props.isLoading ? <Loader/> : null}

                {this.state.showEditTask ?
                    <EditTask closeEditTask={() => { this.closeEditTask(false)}}
                                                       task={this.state.selectedTask}
                               deleteTask={()=>{this.setState({showDeleteTask: true, showEditTask:false})}}
                    /> : null}

                {this.state.showDeleteTask?<Modal title={`Delete this task?`} onClose={this.closeDeleteTaskModal}>
                    <div className="buttons-wrapper">
                        <div className="button cancel" onClick={this.closeDeleteTaskModal}>Cancel</div>
                        <div className="button" onClick={this.deleteTask}>Delete forever</div>
                    </div>

                </Modal>:null}

                {this.state.attachment?<Attachment src={this.state.attachment} closeAttachment={()=>this.setState({attachment:undefined})} />:null}

                <div className="menu-wrapper">
                    <Menu/>
                </div>
                <div className="content">
                    <PlantHeader title={"Tasks"} secondaryText={this.props.project.projectName}/>
                    <div className="tasks">
                        <div className="top">
                            <Search onSearch={this.searchClicked} placeholder="search tasks"/>

                            {this.props.user.role !=="projectOwner" ?
                                <div className="download" onClick={this.createNewTask}><img src={addIcon}/>CREATE TASK
                                </div> : null}
                        </div>
                        <table>
                            <tbody>

                            <tr>
                                {this.getHeaderTH('created', 'Created')}
                                {this.getHeaderTH('dueDate', 'Due Date')}
                                {this.getHeaderTH('status', 'Status')}
                                {this.getHeaderTH('taskType', 'Task Type')}
                                {this.getHeaderTH('description', 'Description')}
                                {this.getHeaderTH('closedDate', 'Closed')}
                                {this.getHeaderTH('attachments', 'Attachments')}
                            </tr>
                            {this.props.tasks.map((task, index) => {
                                return <tr key={index} onClick={() => this.taskSelected(task)}>
                                    <td className="date">{task.createdBy.fullName}<br/>{this.timeZone.getLocalTime(task.created, true)}</td>
                                    <td className="date">{this.timeZone.getLocalTime(task.dueDate, true)}</td>
                                    {/*<td className="date">{this.timeZone.getLocalTime(new Date(),true,false)}</td>*/}
                                    <td>{task.status ==='inProgress'?'In progress':task.status}</td>
                                    <td>{task.taskType}</td>
                                    <td>{task.description}</td>
                                    <td className="date">{task.closedDate?<span>{task.closedBy.fullName}<br/>
                                        {this.timeZone.getLocalTime(new Date(),true)}</span>:null}
                                    </td>
                                    <td className="image">{task.attachments.map(attachment=><img src={attachment.thumbUrl} onClick={(e)=>this.attachmentClicked(e, attachment)}/>)}</td>
                                </tr>

                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.tasksReducer,
        user: state.indexReducer.user,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({getTasks, viewTask, taskSelected, getTasksSuccess, deleteTask}, dispatch),
    };
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(TasksComponent));