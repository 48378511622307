import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './cleaningControl.scss'
import {bindActionCreators} from "redux";
import spinner from "../loader/orange_spinner.svg";
import {
    COMMAND_CONTINUE,
    COMMAND_GO_HOME,
    COMMAND_PAUSE,
    COMMAND_START,
    COMMAND_REMOTE_COMMAND,
    sendCommand, COMMAND_RESET
} from "./sendCommand";
import greySpinner from "./grey_spinner.svg";

import playIcon from "./play-icon.svg";
import cleaningInProgress from "./cleaning-icon-spinning.svg";
import {sendCommandStarted} from "./sendCommandStartedAction";
import Modal from "../modal/modal";
import Dropdown from "react-dropdown";
import {changeStatus} from "../cleaningPlan/changeStatusAction";

const RobotStatuses = [
    {label: 'Idle', value: 'idle'},
    {label: 'Parking', value: 'parking'},
    {label: 'Finished cleaning', value: 'finished-cleaning'},
    {label: 'Drive backwards', value: 'drive-backwards'},
    {label: 'Drive forward', value: 'drive-forward'},
    {label: 'Error', value: 'error'}
];

const ParkingStatuses = [
    'parking',
    'test',
]


const CleaningStatuses = [
    'driving',
    'pickup',
    'drop-off',
    'waiting',
]

const PausedStatuses = [
    'paused',
    'error',
    'emergency',
]

class CleaningControlVehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            changeStatus: false,
            startCleaningSeconds: this.props.startCleaningSeconds || 0
        }

        setInterval(() => {
            this.setState({startCleaningSeconds: this.state.startCleaningSeconds + 1});
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentStatus)
            console.log("nextProps.currentStatus", nextProps.currentStatus);

        if (this.props.isPending ===true && nextProps.isPending ===false)
            this.props.actions.sendCommandStarted(this.props.namespace, null);

        if (nextProps.startCleaningSeconds && this.props.startCleaningSeconds !==nextProps.startCleaningSeconds)
            this.setState({startCleaningSeconds: nextProps.startCleaningSeconds})

        if (nextProps.autoMode)
            this.setState({isSwitching: false});
    }

    getCleaningTime = () => {
        if (this.props.isPending)
            return;

        let total = Math.round(this.state.startCleaningSeconds);
        let hours = Math.floor(total / 3600);
        total = total % 3600;
        let minutes = Math.floor(total / 60);
        let seconds = total % 60;
        if (hours && minutes < 10)
            minutes = '0' + minutes;

        if (seconds < 10)
            seconds = '0' + seconds;

        if (hours)
            return `${hours}:${minutes}:${seconds}`;
        else
            return `${minutes}:${seconds}`;
    }


    getScheduledCleaning = () => {
        if (!this.props.cleaningTime)
            return;

        // console.log("this.props.cleaningTime",this.props.cleaningTime)

        if (this.props.cleaningTime.minute < 10)
            return `${this.props.cleaningTime.hour}:0${this.props.cleaningTime.minute}`;
        else
            return `${this.props.cleaningTime.hour}:${this.props.cleaningTime.minute}`;
    }


    commandClicked = (command) => {
        if (this.props.isPending)
            return;

        this.setState({showModal: true, command});
    }

    sendCommand = () => {
        console.log("sendCommand", this.state.command)
        if (this.state.command ===COMMAND_RESET)
            this.props.actions.sendCommand({
                id: this.props.project._id,
                event: COMMAND_RESET,
                category: this.props.category,
                value: this.props.categoryId || this.props.categoryValue,
                cmd: {
                    name: 'reset-all',
                    dest: undefined,
                    data: undefined
                }
            });
        else
            this.props.actions.sendCommand({
                id: this.props.project._id,
                event: this.state.command,
                category: this.props.category,
                value: this.props.categoryId || this.props.categoryValue,
            });

        this.closeModal();
    }

    switchToAutoMode = () => {
        this.setState({isSwitching: true});
        this.props.actions.sendCommand({
            id: this.props.project._id,
            event: COMMAND_REMOTE_COMMAND,
            category: this.props.category,
            value: this.props.categoryId || this.props.categoryValue,
            cmd: {
                name: 'automode',
                dest: undefined,
                data: undefined
            }
        });
    }

    getStatusText = () => {
        if (this.props.category ==='group')
            return `${this.capitalize(this.props.category)} ${this.props.categoryValue} - `;
        else
            return '';
    }

    getcurrentStatusText = () => {

    }


    capitalize = (s) => {
        if (typeof s !=='string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    getModalTitle = () => {
        if (this.state.command ===COMMAND_GO_HOME)
            return 'Stop Cleaning (Go Home)';
        else if (this.state.command ===COMMAND_START)
            return 'Start Cleaning';
        else if (this.state.command ===COMMAND_CONTINUE)
            return 'Continue';
        else if (this.state.command ===COMMAND_PAUSE)
            return 'Pause';
        else if (this.state.command ===COMMAND_RESET)
            return 'Reset Vehicle'
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    isCleaningPrevented = () => {
        if (this.props.currentStatus !=='cleaning-prevented')
            return false;

        const hour = new Date().toLocaleString("en-US", {
            timeZone: this.props.timezone.timeZoneId,
            hour: '2-digit',
            hour12: false
        })
        return hour > this.props.cleaningTime.hour;
    }

    getMainClass = () => {
        if (this.props.offline)
            return 'offline cleaning-control';
        else if (!this.props.autoMode && this.props.category ==='vehicle')
            return 'offline auto-mode cleaning-control';
        return 'cleaning-control'
    }

    getOfflineTitle = () => {
        if (this.props.category ==='vehicle')
            return 'Vehicle is offline';
        return this.props.project.projectName + ' is offline';
    }

    onRobotStatusChanged = (item) => {
        if (!item.value || this.props.category ==='vehicle')
            return;

        this.props.actions.changeStatus(this.props.robot.id, item.value);
    }

    cleaningStatusDiv = (text) => {
        return <div className="cleaning-status cleaning-pending">
            {this.state.changeStatus ? <Dropdown
                    options={RobotStatuses}
                    value={this.props.currentStatus}
                    // placeholder="Change status"
                    onChange={this.onRobotStatusChanged}
                    className='dropdown'
                    controlClassName='control'
                /> :
                <div>{this.getStatusText()}{text} ({this.props.state}) {this.props.isPending ? ': Pending' : null}
                    {!this.props.isPending && text ==='Cleaning In Progress' ? <img src={cleaningInProgress}/> : null}
                </div>
            }
            {this.props.category ==='robot' && this.props.currentStatus ?
                <div className="change-status" onClick={() => this.setState({changeStatus: !this.state.changeStatus})}>
                    {!this.state.changeStatus ? <span>change</span> : <span>close</span>}
                </div> : null}
        </div>
    }

    engineErrorsText = ()=> this.props.errorDetails.extraData? `(engines: ${JSON.stringify(this.props.errorDetails.extraData.engineErrorCodes)})`: '';

    errorDetails = () => {
        if (this.props.currentStatus !=='error')
            return;

        return <span
            className="error-details">{`${this.props.errorDetails.errorText || ''} ${this.engineErrorsText()}`}</span>
    }



    render() {
        console.log("currentStatus", this.props.currentStatus, "this.props.pendingCommand=", this.props.pendingCommand);

        return (
            <div className={this.getMainClass()}>
                {this.state.showModal && !this.props.offline ?
                    <Modal title={this.getModalTitle()} onClose={this.closeModal}>
                        <div className="buttons-wrapper">
                            <div className="button cancel" onClick={this.closeModal}>Cancel</div>
                            <div className="button" onClick={this.sendCommand}>Continue</div>

                        </div>

                    </Modal> : null}

                {this.state.showModal && this.props.offline ?
                    <Modal title={this.getOfflineTitle()} onClose={this.closeModal}>
                        <div className="buttons-wrapper">
                            <div className="button cancel" onClick={this.closeModal}>Ok</div>
                            {/*<div className="button" onClick={this.sendCommand}>Continue</div>*/}

                        </div>

                    </Modal> : null}

                {CleaningStatuses.includes(this.props.currentStatus) ?
                    <div className="upper">
                        <div className="cleaning-status cleaning-in-progress">
                            {this.cleaningStatusDiv('Cleaning In Progress')}
                        </div>
                        <div className="right">
                            <div className="time">{this.getCleaningTime()}</div>
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">

                                <div
                                    className={this.props.pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
                                    onClick={() => this.commandClicked(COMMAND_PAUSE)}>
                                    <span className="pause-icon"></span>
                                    <span className="pause-icon"></span>
                                    <img src={greySpinner} className="spinner"/>
                                    <span className="text">PAUSE</span>
                                </div>

                                <div
                                    className={this.props.pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
                                    onClick={() => this.commandClicked(COMMAND_GO_HOME)}>
                                    <span className="stop-icon"></span>
                                    <img src={spinner} className="spinner"/>
                                    <span className="text">GO HOME</span>
                                </div>

                            </div> : null}
                        </div>
                    </div> : null}
                {PausedStatuses.includes(this.props.currentStatus) ?
                    <div className="upper">
                        {this.cleaningStatusDiv(this.props.currentStatus ==='error' ? 'Error' : this.props.currentStatus ==='emergency' ? 'Emergency' : 'Cleaning Paused')}
                        <div className="right">
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">

                                <div
                                    className={this.props.pendingCommand ===COMMAND_CONTINUE ? 'button start loading' : 'button start'}
                                    onClick={() => this.commandClicked(COMMAND_CONTINUE)}>
                                    <img src={playIcon} className="icon"/>
                                    <img src={spinner} className="spinner"/>
                                    <span className="text">CONTINUE</span>
                                </div>

                                <div
                                    className={this.props.pendingCommand ===COMMAND_GO_HOME ? 'button stop loading' : 'button stop'}
                                    onClick={() => this.commandClicked(COMMAND_GO_HOME)}>
                                    <span className="stop-icon"></span>
                                    <img src={spinner} className="spinner"/>
                                    <span className="text">GO HOME</span>
                                </div>
                            </div> : null}
                        </div>
                    </div> : null
                }
                {!this.props.currentStatus || ParkingStatuses.includes(this.props.currentStatus) ?
                    <div className="upper">
                        {this.cleaningStatusDiv(this.props.currentStatus ==='parking' ? 'Parking' : 'Self Test')}
                        <div className="right">
                            {this.props.cleaningTime ? <div className="scheduled-cleaning">
                                {this.isCleaningPrevented() ? <span>Cleaning prevented at </span> :
                                    <span>Cleaning scheduled for </span>}
                                {this.getScheduledCleaning()}</div> : null}
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">
                                <div
                                    className={this.props.pendingCommand ===COMMAND_START ? 'button start loading' : 'button start'}
                                    onClick={() => this.commandClicked(COMMAND_START)}>
                                    <img src={playIcon} className="icon"/>
                                    <img src={spinner} className="spinner"/>
                                    <span className="text">START</span>
                                </div>
                            </div> : null}
                        </div>
                    </div> : null
                }


                {this.props.currentStatus ==='going-home' ?
                    <div className="upper">
                        {this.cleaningStatusDiv('Going Home')}
                        <div className="right">
                            {/*<div className="time">06:44</div>*/}
                            {this.props.user.role !=="projectOwner" ? <div className="buttons">

                                <div
                                    className={this.props.pendingCommand ===COMMAND_PAUSE ? 'button pause loading' : 'button pause'}
                                    onClick={() => this.commandClicked(COMMAND_PAUSE)}>
                                    <span className="pause-icon"></span>
                                    <span className="pause-icon"></span>
                                    <img src={greySpinner} className="spinner"/>
                                    <span className="text">PAUSE</span>
                                </div>
                            </div> : null}
                        </div>
                    </div> : null}
                {this.props.category ==='vehicle' ?
                    <div>
                        {this.errorDetails()}
                        {this.props.user.role !=="projectOwner"?<div className="auto-mode orange">
                            {this.props.autoMode ?
                                <span>Auto Mode</span>
                                :
                                this.state.isSwitching ?
                                        <span className="loading"><img
                                            src={spinner}/>Switching to auto...</span>:

                                <span>Manual Mode <span className="switch"
                                                        onClick={this.switchToAutoMode}>switch to auto</span></span>
                            }


                            <span className="reset right"
                                  onClick={() => this.commandClicked(COMMAND_RESET)}>reset vehicle</span>

                        </div>:null}
                    </div>
                    : null
                }
            </div>
        )
    }

};


const mapStateToProps = (state, ownProps) => {

    const myState = state[ownProps.namespace + 'CleaningControlReducer'];

    return {
        ...myState,
        project: state.indexReducer.project,
        robot: state.indexReducer.robot,
        vehicle: state.indexReducer.vehicle,
        user: state.indexReducer.user
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators({
            sendCommand: sendCommand(ownProps.namespace),
            sendCommandStarted,
            changeStatus
        }, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CleaningControlVehicleComponent);