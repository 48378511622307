import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {editUserSuccess} from "./editUserSuccessAction";
import {editUserError} from "./editUserErrorAction";
import {editUserStarted} from "./editUserStartedAction";

export function deleteUser(userId) {

    return function (dispatch) {
        let url = HOST_URL+'/api/users/' + userId;
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editUserStarted());

        return axios.delete(url, {headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(editUserSuccess({isDeleted: true, _id: userId}));
            }
            else {
                dispatch(editUserError(new Error(response.data.message)));
            }
        }).catch(error => {

            console.log(error.message);
            dispatch(editUserError(error));
        });
    };
}