import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editErrorError} from "./editErrorErrorAction";
import {editErrorStarted} from "./editErrorStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {editMultipleErrorsSuccess} from "./editMultipleErrorsSuccessAction";

export function editMultipleErrors(params) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/errors/multiple';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editErrorStarted());

        try {
            const response = await axios.post(url, params, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(editMultipleErrorsSuccess(response.data.errors));
            } else {
                dispatch(editErrorError(new Error(response.data.message)));
            }
        } catch (error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editErrorError(error));
        }
    };
}