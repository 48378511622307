import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import {getCurrentWeatherSuccess} from "./getCurrentWeatherSuccessAction";
import {getCurrentWeatherError} from "./getCurrentWeatherErrorAction";
import {getCurrentWeatherStarted} from "./getCurrentWeatherStartedAction";
import {unauthorizedError} from "../signin/unauthorizedErrorAction";

export function getCurrentWeather(projectId) {

    return function (dispatch) {
        let url = HOST_URL+'/api/weather?projectId=' + projectId;

        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(getCurrentWeatherStarted());

        return axios.get(url,{headers: {Authorization: authToken}}).then((response) => {
            if (response.data.success) {
                dispatch(getCurrentWeatherSuccess(response.data.currentWeather));
            }
            else {
                dispatch(getCurrentWeatherError(new Error(response.data.message)));
            }
        }).catch(error => {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(getCurrentWeatherError(error));
        });
    };
}