import {GET_ERRORS_SUCCESS} from "./errorsReducer";
import {CLEANING_PLAN_SUCCESS, SAVE_CLEANING_PLAN_SUCCESS} from "./cleaningPlanReducer";
import {GET_ROBOT_SETTINGS_SUCCESS} from "./robotSettingsReducer";
import {REFRESH_ROBOT_BATTERIES_STARTED, REFRESH_ROBOT_BATTERIES_SUCCESS} from "./dashboardReducer";

export const GET_VEHICLE_LOGS_STARTED = 'GET_VEHICLE_LOGS_STARTED';
export const GET_VEHICLE_LOGS_ERROR = 'GET_VEHICLE_LOGS_ERROR';
export const GET_VEHICLE_LOGS_SUCCESS = 'GET_VEHICLE_LOGS_SUCCESS';

export const GET_VEHICLE_DETAILS_STARTED = 'GET_VEHICLE_DETAILS_STARTED';
export const GET_VEHICLE_DETAILS_ERROR = 'GET_VEHICLE_DETAILS_ERROR';
export const GET_VEHICLE_DETAILS_SUCCESS = 'GET_VEHICLE_DETAILS_SUCCESS';
export const INIT_STATE_LOG = 'INIT_STATE_LOG';

export const SET_VEHICLE_SETTINGS_SUCCESS = 'SET_VEHICLE_SETTINGS_SUCCESS';


const INITIAL_STATE = {
    logs: [],
    errors: [],
    cleaningPlan: [],
    rowsExcluded: [],
    isLoading: true,
    stateMachineLogs: []
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    let rowsExcluded = [];

    switch (action.type) {
        case INIT_STATE_LOG:
            return {
                ...state,
                stateMachineLogs: [],
                isLoading: true
            }
        case SET_VEHICLE_SETTINGS_SUCCESS:
            console.log("action.payload.vehicle",action.payload.vehicle)
            const vehicle = {...state.vehicle};
            vehicle.currentStatus = action.payload.vehicle.currentStatus;
            return {
                ...state,
                isLoading: false,
                vehicle
            }
        case GET_VEHICLE_DETAILS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_VEHICLE_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                vehicle: action.payload.vehicleDetails,
                stateMachineLogs: action.payload.stateMachineLogs.concat(state.stateMachineLogs)
            };
        case GET_VEHICLE_DETAILS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_VEHICLE_LOGS_SUCCESS:
            return {
                ...state,
                // isLoading: false,
                logs: action.payload.logs
            };

        case GET_VEHICLE_LOGS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                // isLoading: false
            };
        case GET_ERRORS_SUCCESS:
            return {
                ...state,
                errors: action.payload.errors
            };
        case REFRESH_ROBOT_BATTERIES_STARTED:
            return {
                ...state,
                isLoadingRobotVoltages: true
            }
        case REFRESH_ROBOT_BATTERIES_SUCCESS:
            return {
                ...state,
                processId: action.payload.processId,
                isLoadingRobotVoltages: false
            }
        case SAVE_CLEANING_PLAN_SUCCESS:
            return {
                ...state
            };
        case CLEANING_PLAN_SUCCESS:

            if (state.rows && state.rows.length) {
                rowsExcluded = state.rows.filter(row=>row.excludeCleaning).map(row => row.rowNumber);
                rowsExcluded = rowsExcluded.filter(rowNumber=> action.payload.cleaningPlan.find(cp=>cp.rowNumber ===rowNumber));
            }
            // console.log("action.payload.cleaningPlan", action.payload.cleaningPlan || [])
            return {
                ...state,
                // isLoading: false,
                cleaningPlan: action.payload.cleaningPlan || [],
                rowsExcluded
            }
        case GET_ROBOT_SETTINGS_SUCCESS:
            if (state.cleaningPlan.length) {
                rowsExcluded = action.payload.rows.filter(row=>row.excludeCleaning).map(row=>row.rowNumber);
                rowsExcluded = rowsExcluded.filter(rowNumber=> state.cleaningPlan.find(cp=>cp.rowNumber ===rowNumber));
            }

            return {
                ...state,
                // isLoading: false,
                rows: action.payload.rows,
                rowsExcluded
            };

        default:
            return state;
    }
}