import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './daily-activity.scss'
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import {bindActionCreators} from "redux";
import {getDailyActivity} from "./getDailyActivityAction";
import Loader from "../loader/loader";
import TableComponent from "../tableComponent/tableComponent";
import {getDailyActivitySuccess} from "./getDailyActivitySuccessAction";
import {withNavigation} from '../withNavigate';
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import DatePicker from "react-datepicker";

class DailyActivityComponent extends TableComponent {
    constructor(props) {
        super(props);
        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 14);
        this.state = {
            page: 0,
            endDate,
            startDate
        };
    }


    datesChanged = (dates)=> {
        const [startDate, endDate] = dates;

        this.setState({startDate, endDate});

        if (startDate && endDate) {
            this.setState({page: 0});
            this.props.actions.getDailyActivity(this.props.project._id, startDate, endDate, 0);
        }

    }


    componentDidMount() {
        this.props.actions.getDailyActivity(this.props.project._id, this.state.startDate, this.state.endDate, this.state.page);
        window.addEventListener("scroll", (e) => this.handleNavigation(e));
    }

    errorSelected = (error)=> {
        if (this.props.user.role ==="projectOwner")
            return;
        this.setState({
            selectedError: error,
            showEditError: true
        });
    }


    closeEditError = (showEditError)=> {
        this.setState({showEditError});
    }

    getData = ()=>this.props.reports;

    publishData = (data)=>this.props.actions.getDailyActivitySuccess(data);


    downloadCsvFile = ()=>{
        // this.props.actions.getDailyActivityCsv(this.props.project._id, this.state.startDate, this.state.endDate);
    }

    saveCsvFile = (text)=>{
        const blob= new Blob([text], {type: 'text/csv'});
        const csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'filename.csv');
        tempLink.click();
    }



    getNewList = (report)=>{
        const list = [];
        for (const item of this.props.reports[0].data) {
            const {date} = item;
            const currentItem = report.data.find(item=>item.date ===date)
            list.push({
                date,
                cleaningDistance: currentItem?currentItem.cleaningDistance:0
            });
        }
        return list;
    }

    handleNavigation() {
        console.log("window.scrollY",window.scrollY);

        if (window.scrollY + 2000 > this.page.offsetHeight && this.props.loadMore && !this.props.isLoading) {
            const {page} = this.state;
            console.log("getting next page", page + 1)
            this.setState({page: page + 1})
            this.props.actions.getDailyActivity(this.props.project._id, this.state.startDate, this.state.endDate, page + 1);
        }
    }

    getCellClass = (rowLength, distanceCleaned)=>{
        // debugger;
        if (distanceCleaned ===0)
            return 'no-cleaning';
        if (distanceCleaned < rowLength)
            return 'partial-cleaning';
        if (distanceCleaned >= rowLength)
            return 'full-cleaning';

    }

    render() {
        return (
            <div className="page" ref={(ref) => this.page = ref}>
                {this.props.isLoading && this.state.page ===0?<Loader />:null}

                <div className="menu-wrapper">
                    <Menu />
                </div>
                <div className="content">
                    <PlantHeader title={"Daily Activity"} secondaryText={this.props.project.projectName}/>
                    <div className="dailyActivity">

                        <DatePicker
                            // selected={this.state.startDate}
                            onChange={this.datesChanged}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            selectsRange
                            shouldCloseOnSelect={false}
                            customInput={<DateCustomInput
                                logsStartDate={this.state.startDate}
                                logsEndDate={this.state.endDate}/>}
                            // customInput={<this.VoltageDateCustomInput/>}
                        />

                        {this.props.reports.length?<table>
                            <tbody>
                            <tr>
                                {this.getHeaderTH('robotName', 'Robot')}
                                {this.getHeaderTH('groupNumber', 'group')}
                                {this.getHeaderTH('rowLength', 'Row Length [m]')}
                                {this.props.reports[0].data.map(item=>
                                    this.getHeaderTH('date', `${new Date(item.date).getDate()}/${new Date(item.date).getMonth()+1}`)
                                )}

                            </tr>
                            {this.props.reports.map((report, index) => {

                                return <tr key={index} onClick={() => this.errorSelected(report) }>
                                    <td>{report.robotName}{report.rpi?` (${report.rpi})`:''}</td>
                                    <td>{report.groupNumber}</td>
                                    <td>{report.rowLength}</td>
                                    {this.getNewList(report).map((item,j)=><td key={j} className={this.getCellClass(report.rowLength, item.cleaningDistance)}>{item.cleaningDistance}</td>)}
                                </tr>

                            })}

                            </tbody>
                        </table>:null}
                    </div>
                </div>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.dailyActivityReducer,
        user: state.indexReducer.user,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({getDailyActivity, getDailyActivitySuccess}, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(DailyActivityComponent));