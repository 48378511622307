import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {deleteErrorError} from "./deleteErrorErrorAction";
import {deleteErrorSuccess} from "./deleteErrorSuccessAction";

export function deleteError(ids) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/errors/' + ids.join(",");
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        try {
            const response = await axios.delete(url, {headers: {Authorization: authToken}});
            if (response.data.success) {
                dispatch(deleteErrorSuccess(ids));
            } else {
                dispatch(deleteErrorError(new Error(response.data.message)));
            }
        } catch (error) {
            console.log(error.message);
            dispatch(deleteErrorError(error));
        }
        ;
    };
}