export const SEND_COMMAND_STARTED = 'SEND_COMMAND_STARTED';
export const SEND_COMMAND_ERROR = 'SEND_COMMAND_ERROR';
export const SEND_COMMAND_SUCCESS = 'SEND_COMMAND_SUCCESS';

const INITIAL_STATE = {
    pendingCommand: false
}


export default (namespace)=>(state = INITIAL_STATE, action) => {

    switch (action.type) {
        case namespace + SEND_COMMAND_STARTED:
            return {
                ...state,
                pendingCommand: action.payload.pendingCommand
            }
        case namespace + SEND_COMMAND_SUCCESS:
            return {
                ...state,
                // pendingCommand: false
            }
        case namespace + SEND_COMMAND_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pendingCommand: false
            }

        default:
            return state;
    }
}