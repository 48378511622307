import React, { Component, PropTypes, useEffect, useState } from 'react';
import '../errors/errors.scss';
import PlantHeader from '../plantHeader/plantHeader';
import Menu from "../menu/menu";
import Search from "../search/search";
import { camelCaseToText, LOCAL_STORAGE_USER_KEY, LOCAL_STORAGE_PROJECT, getFormattedDate } from "../../consts";
import { getDateStr, TimeZone } from "../utils";
import Dropdown from "react-dropdown";
import ApiHandler from '../api';
// import getAdditionalData from './utils';
import Loader from '../loader/loader';
import checkIcon from "../errors/images/check-icon.svg";
import attachmentIcon from "../errors/images/attachment.png";
import addIcon from "../errors/images/add-icon.svg";
import repeaterIcon from "../menu/wifi-icon.png";
import exclamationIcon from "../errors/images/exclamation-icon.svg";
import EditErrorResolution from '../editErrorResolution/editErrorResolution';



const ErrorsResolutionComponent = ({ }) => {
    const [project, setProject] = useState({});
    const [user, setUser] = useState({});
    const [search, setSearch] = useState('');
    const [showError, setShowError] = useState(false);
    const [selectedErrorResolution, setSelectedErrorResolution] = useState([]);
    const [errorResolutions, setErrorResolutions] = useState([]);
    const [showCloseMultipleErrors, setShowCloseMultipleErrors] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [sort, setSort] = useState('date');
    const [timeZone, setTimeZone] = useState();
    const [errorCodeFilter, setErrorCodeFilter] = useState('important');
    const [showDeleteError, setShowDeleteError] = useState(false);
    const [showEditErrorResolution, setShowEditErrorResolution] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState('');
    const [errorCodes, setErrorCodes] = useState([]);
    const [totalRedErrors, setTotalRedErrors] = useState(0);
    const [totalYellowErrors, setTotalYellowErrors] = useState(0);
    const [isNew, setIsNew] = useState(false);
    const [robotNumbers, setRobotNumbers] = useState([]);
    const [technicians, setTechnicians] = useState([]);
    const [page, setPage] = useState(0);

    const apiHandler = new ApiHandler();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROJECT));
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

        setProject(project);
        setUser(user);
    }, []);

    useEffect(() => {
        if (!project || !project._id)
            return;

        handleErrorResolution();
        getRobotNumbers();
        getTechnicians();
    }, [errorCodeFilter, sort, search, project])

    const getTechnicians = async () => {
        const technicians = await apiHandler.getUsers({ projectId: project._id, role: 'operator' });
        setTechnicians(technicians);
    }

    const getRobotNumbers = async () => {
        const robotNumbers = await apiHandler.getRobotNumbers(project._id);
        setRobotNumbers(robotNumbers);
    }

    const handleErrorResolution = async () => {
        setIsLoading(true);
        const { total, errorResolutions } = await apiHandler.getErrorResolutions({
            projectId: project._id,
            search,
            sort,
            page
        });

        // errorResolutions.forEach(errorResolution => {
        //     if (errorResolution.resolvedAt)
        //         errorResolution.resolvedAt = new Date(errorResolution.resolvedAt);
        // });

        setErrorResolutions(errorResolutions);
        setIsLoading(false);
    }

    const onSortSelected = async option => {
        setSort(option.value);
    }

    const errorResolutionSelected = (errorResolution) => {

        setSelectedErrorResolution(errorResolution);
        setShowEditErrorResolution(true);
        setIsNew(false);
    }

    const createErrorResolution = () => {
        // props.actions.viewError();        
        setShowEditErrorResolution(true);
        setIsNew(true);
    }

    const attachmentClicked = (e, attachment) => {
        e.stopPropagation();
        window.open(attachment.url, "_blank");
    }

    const closeEditErrorResolution = (reload) => {

        setShowEditErrorResolution(false)
        if (reload) {
            window.setTimeout(() => {
                handleErrorResolution();
            }, 1000);
        }
    }

    return (
        <div className="page">
            {isLoading ? <Loader /> : null}

            {showEditErrorResolution ?
                <EditErrorResolution
                    project={project}
                    user={user}
                    isNew={isNew}
                    existingErrorResolution={selectedErrorResolution}
                    robotNumbers={robotNumbers}
                    technicians={technicians}
                    closeEditErrorResolution={closeEditErrorResolution}
                // onErrorSaved={onErrorSaved}
                // errors={selectedErrors}
                // deleteError={onDeleteError}
                /> : null}


            <div className="menu-wrapper">
                <Menu />
            </div>
            <div className="content">
                <PlantHeader title={"Error Resolutions"} secondaryText={project.projectName} />
                <div className="errors">
                    <div className="top">
                        {/* <Search onSearch={setSearch} placeholder="search by robot number" /> */}


                        {/* <div className="error-filter error-codes">

                        </div> */}

                        <div className="error-filter">
                            <Dropdown
                                placeholder="Sort"
                                options={[{
                                    label: "Sort by date",
                                    value: 'date'
                                }, {
                                    label: "Sort by robot",
                                    value: 'robot'
                                }]}
                                onChange={onSortSelected}
                                // value={errorCodeFilter}
                                className='dropdown'
                                controlClassName='control'
                            />
                        </div>

                        <div className="download" onClick={createErrorResolution}>
                            <img src={addIcon} />NEW ERROR RESOLUTION
                        </div>
                    </div>
                    <div className="total">

                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th className=''>#</th>
                                <th className=''>Date</th>
                                <th className=''>Robot</th>
                                <th className=''>Technician</th>
                                <th className=''>Status</th>
                                <th className=''>Findings</th>
                                <th className=''>Who is responsible</th>
                                <th className=''>Location</th>
                                <th className=''>Parts replaced</th>
                                <th className=''>Remarks</th>
                                <th><img src={attachmentIcon} /></th>
                            </tr>
                            {errorResolutions.map((errorResolution, index) => {
                                return <tr key={index} onClick={() => errorResolutionSelected(errorResolution)}>
                                    <td>{errorResolution.numerator}</td>
                                    <td>{getFormattedDate({ date: errorResolution.resolvedAt || errorResolution.createdAt })}</td>
                                    <td>{errorResolution.robotId.robotNumber}</td>
                                    <td>{errorResolution.technicianId?.fullName}</td>
                                    <td className={'status ' + errorResolution.status}>{errorResolution.status}</td>
                                    <td>{errorResolution.findings.map(finding => <div>
                                        {finding}
                                    </div>)}</td>
                                    <td>{errorResolution.responsibility}</td>
                                    <td>{errorResolution.location !== undefined ? `${errorResolution.location}m` : null}</td>
                                    <td>{errorResolution.replacements.map(replacement => <div>{replacement}</div>)}</td>
                                    <td>{errorResolution.remarks}</td>

                                    <td className="image">{errorResolution.attachments && errorResolution.attachments.map(
                                        attachment => <img
                                            src={attachment.url}
                                            onClick={(e) => attachmentClicked(e, attachment)}
                                        />)}
                                    </td>

                                </tr>

                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ErrorsResolutionComponent;