import { combineReducers } from 'redux';
import signinReducer from './signinReducer';
import projectsReducer from  './projectsReducer';
import indexReducer from  './indexReducer';
import { routerReducer } from 'react-router-redux'
import robotSettingsReducer from "./robotSettingsReducer";
import editRobotSettingsReducer from "./editRobotSettingsReducer";
import rowsReducer from "./rowsReducer";
import addRowReducer from "./addRowReducer";

import createProjectReducer from "./createProjectReducer";
import robotsAndVehiclesReducer from "./robotsAndVehiclesReducer";
import robotReducer from "./robotReducer";
import vehicleReducer from "./vehicleReducer";
import cleaningPlanReducer from "./cleaningPlanReducer";
import errorsReducer from "./errorsReducer";
import editErrorReducer from "./editErrorReducer";
import usersReducer from "./usersReducer";
import availabilityReducer from "./availabilityReducer"
import editUserReducer from "./editUserReducer";
import editTaskReducer from "./editTaskReducer";
import editMessageReducer from "./editMessageReducer";
import settingsReducer from "./settingsReducer";
import weatherReducer from "./weatherReducer";
import dashboardReducer from "./dashboardReducer";
import cleaningControlReducer from "./cleaningControlReducer";
import dailyActivityReducer from "./dailyActivityReducer";
import editAvailabilityReducer from "./editAvailabilityReducer";
import tasksReducer from "./tasksReducer";
import messagesReducer from "./messagesReducer";

export default combineReducers({
    signinReducer,
    group1CleaningControlReducer: cleaningControlReducer("group1"),
    group2CleaningControlReducer: cleaningControlReducer("group2"),
    group3CleaningControlReducer: cleaningControlReducer("group3"),
    robotCleaningControlReducer: cleaningControlReducer("robot"),
    vehicleCleaningControlReducer: cleaningControlReducer("vehicle"),
    dashboardReducer,
    cleaningPlanReducer,
    settingsReducer,
    weatherReducer,
    projectsReducer,
    indexReducer,
    editRobotSettingsReducer,
    robotSettingsReducer,
    rowsReducer,
    addRowReducer,
    robotsAndVehiclesReducer,
    robotReducer,
    vehicleReducer,
    createProjectReducer,
    errorsReducer,
    editErrorReducer,
    usersReducer,
    editUserReducer,
    editTaskReducer,
    editMessageReducer,
    messagesReducer,
    availabilityReducer,
    dailyActivityReducer,
    editAvailabilityReducer,
    tasksReducer,
    routing: routerReducer,

});