import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './image.scss';
import {bindActionCreators} from "redux";
import {withNavigation} from '../withNavigate';

class ImageComponent extends Component {
    constructor(props) {
        super(props);

        if (!this.props.project) {
            this.props.oldNavigate("/projects");
            return;
        }

        console.log("this.props",this.props)

        this.state = {
            imagePath: this.props.params.id
        };
    }


    componentWillReceiveProps(nextProps) {
    }


    componentDidMount() {

        // this.props.actions.getImage(this.props.project._id, null, null, null, this.state.startDate, this.state.endDate);
    }

    render() {
        return (
            <div className="page">
                <img src={this.state.imagePath} />
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // ("state",state)
    return {
        ...state.errorsReducer,
        user: state.indexReducer.user,
        project: state.indexReducer.project
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch),
    };
}


export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(ImageComponent));