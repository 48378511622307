// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-input .tag {
  background-color: var(--turquoise);
  border-radius: 5px;
  color: white;
  padding: 1px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block; }

.tag-input input {
  border: 0;
  border-bottom: 1px solid var(--blue-grey);
  margin-left: 10px;
  font-size: 17px;
  width: auto; }

.tag-input input:focus {
  outline: none; }
`, "",{"version":3,"sources":["webpack://./src/components/vehicles/tagInput/tag.scss"],"names":[],"mappings":"AAAA;EAEI,kCAAkC;EAClC,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB,EAAA;;AARzB;EAYI,SAAS;EACT,yCAAyC;EACzC,iBAAiB;EACjB,eAAe;EACf,WAAW,EAAA;;AAhBf;EAoBI,aAAa,EAAA","sourcesContent":[".tag-input {\n  .tag {\n    background-color: var(--turquoise);\n    border-radius: 5px;\n    color: white;\n    padding: 1px 8px;\n    margin-right: 5px;\n    margin-bottom: 5px;\n    display: inline-block;\n  }\n\n  input {\n    border: 0;\n    border-bottom: 1px solid var(--blue-grey);\n    margin-left: 10px;\n    font-size: 17px;\n    width: auto;\n  }\n\n  input:focus {\n    outline: none;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
