import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editErrorError} from "./editErrorErrorAction";
import {editErrorStarted} from "./editErrorStartedAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {createErrorSuccess} from "./createErrorSuccessAction";
// import {addAttachment} from "../../attachment/addAttachment";

export function createError(params, files) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/errors/';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editErrorStarted());

        try {
            const response = await axios.post(url, params, {headers: {Authorization: authToken}});
            if (response.data.success) {

                // for (const file of files.filter(f => !!f)) {
                //     try {
                //         const result = await addAttachment({
                //             projectId: params.projectId,
                //             file,
                //             errorId: response.data.errorResult._id
                //         });
                //         response.data.errorResult.attachments.push(result.data.attachment);
                //     } catch (err) {
                //         alert("Can't upload attachment");
                //     }
                // }
                dispatch(createErrorSuccess(response.data.errorResult));
            } else {
                dispatch(editErrorError(new Error(response.data.message)));
            }
        } catch (error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());

            console.log(error.message);
            dispatch(editErrorError(error));
        }
    };
}