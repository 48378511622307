import axios from "axios";

import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY } from "../../../consts";
import { editProjectStarted } from "./editProjectStartedAction";
import { editProjectError } from "./editProjectErrorAction";
import { unauthorizedError } from "../../signin/unauthorizedErrorAction";
import { getProjectSettingsSuccess } from "./getProjectSettingsSuccessAction";

export function udpateProjectData(project) {
  return function (dispatch) {
    dispatch(getProjectSettingsSuccess(project));
  };
}
