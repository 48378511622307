import React, { Component, PropTypes, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "./projects.scss";
import logo from "./images/logo.svg";
import plus from "./images/plus.svg";
import Search from "../search/search";
import location from "./images/location-icon.svg";
import power from "./images/power-icon.svg";
import robots from "./images/robots-icon.svg";
import vehicles from "./images/vehicles-icon.svg";
import dots from "./images/dots.svg";
import mapIcon from "./images/map-icon.png";
import pdfIcon from "./images/pdf-icon.png";
import deleteIcon from "./images/delete-icon.svg";
import {
  LOCAL_STORAGE_PROJECT,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USER_KEY,
} from "../../consts";
import CreateProject from "../createProject/createProject";
import UsersWidget from "../usersWidget/usersWidget";
import Loader from "../loader/loader";
import downloadIcon from "../availability/download-icon.svg";
import { getDateStr } from "../utils";
import taskOverdue from "./images/task-overdue.png";
import weatherError from "./images/weather-error.svg";
import bleError from "./images/ble-error.png";
import ApiHandler from "../api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import CreateGroup from "../create-group/createGroup";

const ProjectGroupsComponent = ({}) => {
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState();
  const [includeArchived, setIncludeArchived] = useState(false);
  const navigate = useNavigate();
  const apiHandler = new ApiHandler();
  const { projectGroupId } = useParams();
  const [topLevelItem, setTopLevelItem] = useState({
    children: [],
    projects: [],
    projectGroup: {},
  }); // {children: [], projects: [], projectGroup: {}}
  const [currentItem, setCurrentItem] = useState(); // {children: [], projects: [], projectGroup: {}}

  useEffect(() => {
    if (!projectGroupId) {
      setCurrentItem(topLevelItem);
      // debugger;
      setSearch(undefined);
    } else if (topLevelItem) {
      const item = getAllProjectGroups(topLevelItem).find(
        (group) => group.projectGroup._id === projectGroupId
      );

      debugger;

      setCurrentItem(item);
      // debugger;
      setSearch(undefined);
    }

    if (topLevelItem) {
      setAllProjects(getAllProjects(topLevelItem));
    }
  }, [topLevelItem, projectGroupId]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    setUser(user);
    if (!user || !localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || !user) {
      navigate("/signin");
      return;
    }

    // getAllData();
  }, []);

  useEffect(() => {
    getAllData(search, includeArchived);
  }, [search, includeArchived]);

  const getAllProjects = (item, list = []) => {
    // list.push(projectGroups.projects)
    // setAllProjects(result);

    list.push(...item.projects);
    for (const projectGroup of item.children) {
      list.push(...getAllProjects(projectGroup));
    }

    return list.sort((a, b) => a.projectName.localeCompare(b.projectName));
  };

  const getAllProjectGroups = (item, list = []) => {
    list.push(item);

    for (const child of item.children) {
      list.push(...getAllProjectGroups(child));
    }

    // return list.sort((a, b) => a.name.localeCompare(b.name));
    return list;
  };

  const getAllData = async (search, includeArchived) => {
    setIsLoading(true);
    try {
      const result = await apiHandler.getProjects(search, includeArchived);
      if (!result) {
        return;
      }

      const { projectGroups, topLevelProjects, searchResults } = result;

      if (searchResults) {
        setCurrentItem({
          children: [],
          projects: searchResults,
          projectGroup: { name: "Search results" },
        });
      } else {
        setTopLevelItem({
          children: projectGroups,
          projects: topLevelProjects,
          projectGroup: {},
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        navigate("/signin");
        return;
      }

      alert("Error loading projects");
    } finally {
      setIsLoading(false);
    }
  };

  const goToSettings = (e, project) => {
    e.stopPropagation();
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
    window.location.href = "/settings";
  };

  const projectGroupSelected = (item) => {
    setSearch(undefined);
    setCurrentItem(item);
    navigate("/projects/" + item.projectGroup._id);
  };

  const projectSelected = (project) => {
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
    window.location.href =
      project.arrayType === "fixed" ? "/dashboard" : "/vehicles/dashboard";
  };

  const deleteFromGroup = async (project) => {
    const deleteConfirm = window.confirm(
      `Would you like to remove ${project.projectName} from the group?`
    );
    if (!deleteConfirm) return;

    const result = await apiHandler.editProject(project._id, {
      projectGroupId: "",
    });
    getAllData(search, includeArchived);
  };

  const downloadErrors = async () => {
    setIsLoading(true);
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);

    try {
      const response = await apiHandler.getErrorsXlsx({
        from: startDate,
        to: endDate,
      });

      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      const startDateStr = getDateStr(startDate);
      const endDateStr = getDateStr(endDate);

      const downloadFileName =
        endDateStr === startDateStr
          ? `errors ${startDateStr}.xlsx`
          : `errors ${startDateStr}-${endDateStr}.xlsx`;

      tempLink.setAttribute("download", downloadFileName);
      tempLink.click();
    } catch (error) {
      console.log(error);
      alert("Error downloading errors");
    } finally {
      setIsLoading(false);
    }
  };

  const viewPdf = (project) => {
    if (!project.pdfUrl) return;

    window.open(project.pdfUrl, "_blank");
  };

  const goToMap = (project) => {
    if (!project.address) return;
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${
      project.lat && project.lon
        ? `${project.lat},${project.lon}`
        : project.address
    }`;
    window.open(mapUrl, "_blank");
  };

  const unselectProjectGroup = () => {
    setCurrentItem(topLevelItem);
    setSearch(undefined);
    navigate("/projects");
  };

  const getHeaderText = () => {
    if (currentItem?.projectGroup) return currentItem.projectGroup.name;
    else if (search) return `search: "${search}"`;
  };

  const onAddProject = async (item) => {
    const result = await apiHandler.editProject(item.value, { projectGroupId });
    getAllData(search, includeArchived);
  };

  const deleteGroup = async (e, projectGroup) => {
    e.preventDefault();
    e.stopPropagation();
    debugger;
    const deleteConfirm = window.confirm(
      `Would you like to remove ${projectGroup.name} from the group?`
    );
    if (!deleteConfirm) return;

    const result = await apiHandler.deleteProjectGroup(projectGroup._id);
    getAllData(search, includeArchived);
  };

  return (
    <div className="projects">
      <UsersWidget />
      {user && user.role !== "projectOwner" ? (
        <span className="download-all-errors" onClick={downloadErrors}>
          <img src={downloadIcon} />
          Download errors
        </span>
      ) : null}

      {showCreateGroup ? (
        <CreateGroup
          closeCreateGroup={() => setShowCreateGroup(false)}
          onGroupCreated={() => getAllData(search, includeArchived)}
          projectGroupId={projectGroupId}
        />
      ) : null}

      {showCreateProject ? (
        <CreateProject
          closeCreateProject={() => setShowCreateProject(false)}
          projectGroups={getAllProjectGroups(topLevelItem)
            .filter((item) => item.projectGroup?.name)
            .map((item) => ({
              name: item.projectGroup.name,
              _id: item.projectGroup._id,
            }))}
        />
      ) : null}
      <div className="header">
        <div
          className={`${getHeaderText() ? "pointer " : ""}logo-wrapper`}
          onClick={unselectProjectGroup}
        >
          <img className="logo" src={logo} />
        </div>
        <div className="title">
          <span
            className={getHeaderText() ? "pointer" : ""}
            onClick={unselectProjectGroup}
          >
            Projects{" "}
          </span>
          {getHeaderText() ? <span>| {getHeaderText()}</span> : null}
        </div>
      </div>

      {isLoading ? <Loader /> : null}

      <div className="content">
        <div className="search-wrapper">
          <Search onSearch={setSearch} placeholder="Search project by name" />

          {user.role === "admin" && (
            <div className="include-archived">
              <input
                type="checkbox"
                checked={includeArchived}
                onChange={(e) => setIncludeArchived(e.target.checked)}
              />
              <span
                className="label"
                onClick={() => setIncludeArchived(!includeArchived)}
              >
                Show archived projects
              </span>
            </div>
          )}
        </div>

        {user.role === "admin" || user.role === "countryAdmin" ? (
          <>
            <div
              className="add-project"
              onClick={() => setShowCreateProject(true)}
            >
              <img src={plus} /> <span className="text">CREATE PROJECT</span>
            </div>
          </>
        ) : null}

        {currentItem ? (
          <div className="grid">
            {currentItem.children?.map((item) => (
              <div
                className="item"
                key={currentItem.projectGroup._id}
                onClick={() => projectGroupSelected(item)}
              >
                <div className="group">
                  <img
                    className="group-image"
                    src={item.projectGroup.thumbUrl}
                  />
                  {user.role === "admin" && (
                    <div
                      className="oval left"
                      onClick={(e) => deleteGroup(e, item.projectGroup)}
                    >
                      <img src={deleteIcon} />
                    </div>
                  )}
                </div>
                <div className="name">{item.projectGroup.name}</div>
              </div>
            ))}
            {currentItem.projects?.map((project) => {
              return (
                <div
                  className={project.isArchived ? "item archived" : "item"}
                  key={project._id}
                >
                  <div className="satellite">
                    <img
                      className="satellite"
                      src={project.imageURL}
                      onClick={() => projectSelected(project)}
                    />

                    <div className="address">
                      <img src={location} />
                      {project.address}
                    </div>
                    {user.role === "admin" && (
                      <div
                        className="oval left"
                        onClick={(e) => deleteFromGroup(project)}
                      >
                        <img src={deleteIcon} />
                      </div>
                    )}

                    <div
                      className="oval first"
                      onClick={(e) => goToSettings(e, project)}
                    >
                      <img src={dots} />
                    </div>

                    <div
                      className="oval second"
                      onClick={(e) => goToMap(project)}
                    >
                      <img src={mapIcon} />
                    </div>

                    {project.pdfUrl ? (
                      <div
                        className="oval third"
                        onClick={(e) => viewPdf(project)}
                      >
                        <img src={pdfIcon} />
                      </div>
                    ) : null}
                  </div>
                  <div onClick={() => projectSelected(project)}>
                    <div className="name">
                      {project.projectName}
                      <div className="online-offline">
                        {project.connectionStatus === "online" ? (
                          <span className="online">
                            <span className="circle"></span>Online
                          </span>
                        ) : (
                          <span className="offline">
                            <span className="circle"></span>Offline
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="line">
                      <div className="small-item">
                        <span className="text">
                          {project.availabilityPercent || 0}%
                        </span>
                      </div>

                      <span className="right yellow-errors">
                        {project.yellowErrors || 0}
                      </span>
                      <span className="right red-errors">
                        {project.redErrors || 0}
                      </span>
                      {project.hasBleError && (
                        <img className="error-icon" src={bleError} />
                      )}
                      {project.hasOverdueTask && (
                        <img className="error-icon" src={taskOverdue} />
                      )}
                      {project.hasWeatherError && (
                        <img className="error-icon" src={weatherError} />
                      )}
                    </div>
                    <div className="bottom">
                      <div className="bottom-item">
                        <img src={power} />
                        <span className="text">{project.totalPower}MW</span>
                      </div>
                      <div className="bottom-item">
                        <img src={robots} />
                        <span className="text">
                          {project.numberOfTypeOneRobots +
                            project.numberOfTypeTwoRobots}
                        </span>
                      </div>
                      {project.numberOfVehicles ? (
                        <div className="bottom-item">
                          <img src={vehicles} />
                          <span className="text">
                            {project.numberOfVehicles}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
            {user.role === "admin" && (
              <div className="item add-new-group">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Dropdown
                    controlClassName="add-project-to-group"
                    placeholderClassName="placeholder"
                    menuClassName="menu-add-project-to-group"
                    value={undefined}
                    options={allProjects?.map((p) => ({
                      label: p.projectName,
                      value: p._id,
                    }))}
                    onChange={onAddProject}
                    placeholder="ADD PROJECT"
                    className="dropdown-add-project-to-group"
                  />

                  <div
                    className="create-group"
                    onClick={() => setShowCreateGroup(true)}
                  >
                    <img src={plus} />{" "}
                    <span className="text">CREATE GROUP</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectGroupsComponent;
