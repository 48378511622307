// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper .modal {
  width: 60%; }
  .modal-wrapper .modal .main-attachment {
    width: 100%;
    max-height: 80vh; }
`, "",{"version":3,"sources":["webpack://./src/components/attachment/attachment.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA;EADd;IAIM,WAAW;IACX,gBAAgB,EAAA","sourcesContent":[".modal-wrapper .modal {\n    width: 60%;\n  \n    .main-attachment {\n      width: 100%;\n      max-height: 80vh;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
