export const GET_DAILY_ACTIVITY_STARTED = 'GET_DAILY_ACTIVITY_STARTED';
export const GET_DAILY_ACTIVITY_ERROR = 'GET_DAILY_ACTIVITY_ERROR';
export const GET_DAILY_ACTIVITY_SUCCESS = 'GET_DAILY_ACTIVITY_SUCCESS';
export const GET_DAILY_ACTIVITY_CSV_ERROR = 'GET_DAILY_ACTIVITY_CSV_ERROR';
export const GET_DAILY_ACTIVITY_CSV_SUCCESS = 'GET_DAILY_ACTIVITY_CSV_SUCCESS';

const INITIAL_STATE = {
    reports: [],
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DAILY_ACTIVITY_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_DAILY_ACTIVITY_SUCCESS:

            return {
                ...state,
                isLoading: false,
                reports: action.payload.page ===0? action.payload.reports: state.reports.concat(action.payload.reports),
                loadMore: action.payload.loadMore,
            };

        case GET_DAILY_ACTIVITY_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_DAILY_ACTIVITY_CSV_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case GET_DAILY_ACTIVITY_CSV_SUCCESS:
            return {
                ...state,
                csv: action.payload.csv,
                isLoading: false
            };
        default:
            return state;
    }
}