import axios from "axios";

import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../../consts";
import {editTaskStarted} from "./editTaskStartedAction";
import {createTaskSuccess} from "./createTaskSuccessAction";
import {unauthorizedError} from "../../signin/unauthorizedErrorAction";
import {editTaskError} from "./editTaskErrorAction";
// import {addAttachment} from "../../attachment/addAttachment";

export function createTask(params, files) {
    return async function (dispatch) {
        let url = HOST_URL + '/api/tasks/';
        let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        dispatch(editTaskStarted());

        try {
            const response = await axios.post(url, params, {headers: {Authorization: authToken}});
            response.data.task.attachments = [];
            if (response.data.success) {
                // for (const file of files.filter(f=>!!f)) {
                //     try {
                //         const result = await addAttachment({
                //             projectId: params.projectId,
                //             file,
                //             taskId: response.data.task._id
                //         });
                //         response.data.task.attachments.push(result.data.attachment);
                //     } catch (err) {
                //         alert("Can't upload attachment");
                //     }
                // }
                // dispatch(createTaskSuccess(response.data.task));
            } else {
                dispatch(editTaskError(new Error(response.data.message)));
            }
        } catch (error) {
            if (error.response && error.response.status ===401)
                return dispatch(unauthorizedError());
            
            console.log(error.message);
            dispatch(editTaskError(error));
        }
    };
}