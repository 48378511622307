import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './usersWidget.scss';
import usersIcon from "./user-icon.svg";
import {
    LOCAL_STORAGE_PROJECT,
    LOCAL_STORAGE_ROBOT,
    LOCAL_STORAGE_TOKEN_KEY,
    LOCAL_STORAGE_USER_KEY,
    LOCAL_STORAGE_VEHICLE
} from "../../consts";
import {withNavigation} from '../withNavigate';

class UsersWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
    }

    manageUsers = ()=>{
        this.props.navigate("/users");
    }

    logout = ()=>{
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
        localStorage.removeItem(LOCAL_STORAGE_VEHICLE);
        localStorage.removeItem(LOCAL_STORAGE_ROBOT);
        localStorage.removeItem(LOCAL_STORAGE_PROJECT);
        this.props.navigate("/signin");
    }


    render() {
        return (
            <div className="users-widget">
                <img className="icon" src={usersIcon} onClick={()=>this.setState({showMenu: !this.state.showMenu})}/>
                {this.state.showMenu?<div className="users-menu">
                    <div className="item">
                        <div className="text">{this.props.user.fullName}</div>
                        <div className="sub-text">{this.props.user.phoneNumber}</div>
                    </div>
                    {this.props.user.role ==="admin"?<div className="item clickable" onClick={this.manageUsers}>
                        <div className="text">Manage users</div>
                    </div>:null}
                    <div className="item clickable" onClick={this.logout}>
                        <div className="text">Logout</div>
                    </div>
                </div>:null}
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //

    return {
        ...state.indexReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(UsersWidget));