import {getFormattedDate, HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../consts";
import axios from "axios";

export const getDateStr = (date)=>`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;


export class TimeZone {
    constructor(project) {
        this.project = project;
    }

    getLocalTime = (date = Date.now(), dateOnly, dateWithYear, hourOnly, includeSeconds)=>{
        date = new Date(date);
        date.setSeconds(date.getSeconds() + this.project.timezone.rawOffset +  this.project.timezone.dstOffset + new Date().getTimezoneOffset()*60);
        // debugger;
        return getFormattedDate({date, dateOnly, dateWithYear, hourOnly, includeSeconds})
    }

}


export const uploadAttachment = ({file, projectId, errorId, taskId}) => {
    if (!file)
        return;

    let url = HOST_URL + '/api/attachments';

    let authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);

    if (errorId)
        formData.append('errorId', errorId);
    if (taskId)
        formData.append('taskId', taskId);

    return axios.post(url, formData, {
        headers: {
            Authorization: authToken,
            'content-type': 'multipart/form-data'
        }
    }).then((response) => {
        if (response.data.success) {
            return response.data.attachment;
        } else {
            // dispatch(addAttachmentError(new Error(response.data.message)));
            throw new Error(response.data.message);
        }
    });
}

export const isEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

