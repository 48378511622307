import checkIcon from "../errors/images/check-icon.svg";
import React, {useEffect, useState} from "react";
import {HOST_URL, LOCAL_STORAGE_TOKEN_KEY} from "../../consts";
import axios from "axios";
import "./errors-table.scss"
import {withNavigation} from '../withNavigate';
import EditMessage from "../editMessage/editMessage";
import "./messages.scss";
import orangeSpinner from "../loader/orange_spinner.svg";
import addIcon from "../messages/add-icon.svg";
import {TimeZone} from "../utils";

const MessageTable = ({project, robot, vehicle, user, className}) => {
    const [showEditMessage, setShowEditMessage] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [messages, setMessages] = useState([]);
    const goToMessages = () => {
        this.props.navigate("/messages");
    }

    const showEditMessageClicked = (message) => {
        setShowEditMessage(true);
        setSelectedMessage(message);
    }

    const createNewMessage = () => {
        setSelectedMessage({
            projectId: project._id,
            vehicleId: vehicle._id,
            created: Date.now(),
            expiryDate: Date.now(),
            description: '',
            level: 1
        });

        setShowEditMessage(true);
    }

    const getMessages = async () => {
        console.log(",,,,",project, vehicle, robot);
        let url = `${HOST_URL}/api/messages?projectId=${project._id}&expiryDate=${Date.now()}`;
        if (vehicle)
            url += `&vehicleId=${vehicle._id}`;

        if (robot)
            url += `&robotId=${robot._id}`;

        const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        const response = await axios.get(url, {headers: {Authorization: authToken}});
        setMessages(response.data.messages);
    }

    useEffect(() => {

        getMessages();
    },[project, vehicle, robot]);

    return (
        <div className={`messages ${className || ''}`}>
            {showEditMessage &&
                <EditMessage
                    closeEditMessage={() => setShowEditMessage(false)}
                    message={selectedMessage}
                />}


            <div className="header">
                <span className="title" onClick={goToMessages}>MESSAGES</span>
                {user.role !=="projectOwner" ?
                    <div className="new-message-button" onClick={createNewMessage}><img src={addIcon}/>NEW MESSAGE
                    </div> : null}
            </div>


            <div className="body ">
                {messages.map((message, index) => {
                    return <div className={'message level' + message.level} key={index}
                                onClick={() => showEditMessageClicked(message)}>
                                    <span className="circle">{message.level ===0 ?
                                        <img src={checkIcon}/> : '!'}</span>
                        <div className="right-side">
                            <div className="error-title">{message.description}</div>
                            <div
                                className="error-description">{new TimeZone(project).getLocalTime(message.created, true)}</div>
                        </div>
                    </div>
                })}
                {!messages.length && <div className="no-messages">No messages</div>}
            </div>
        </div>)
}

export default withNavigation(MessageTable);