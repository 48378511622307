import React, { Component, PropTypes } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./robot.scss";
import PlantHeader from "../plantHeader/plantHeader";
import Menu from "../menu/menu";
import checkIcon from "../errors/images/check-icon.svg";
import leftArrowIcon from "./left-arrow.png";
import rightArrowIcon from "./right-arrow.png";
import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY, SOCKET_URL } from "../../consts";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../loader/loader";
import { setRobotSettings } from "./actions/setRobotSettingsAction";
import { getErrors } from "../errors/actions/getErrorsAction";
import CleaningControlRobot from "../cleaningControl/cleaningControlRobot";
import TableComponent from "../tableComponent/tableComponent";
import DateCustomInput from "../dateCustomInput/dateCustomInput";
import { getRobotDetails } from "./actions/getRobotDetails";
import spinner from "./color_spinner.svg";
import { refreshRobotBatteries } from "../dashboard/actions/refreshRobotBatteries";
import { getLiveReports } from "../dashboard/actions/getLiveReports";
import { viewError } from "../editError/actions/viewErrorAction";
import EditError from "../editError/editError";
import { robotSelected } from "../robotsAndVehicles/actions/robotSelectedAction";
import { withNavigation } from "../withNavigate";
import socketIOClient from "socket.io-client";
import { TimeZone } from "../utils";
import RobotLogs from "./logs";
import ErrorTable from "../dashboard/errors-table";
import Modal from "../modal/modal";
import RobotSettings from "./robot-settings";

class RobotComponent extends Component {
  constructor(props) {
    super(props);

    if (!this.props.project) {
      this.props.oldNavigate("/projects");
      return;
    }

    if (!this.props.robot) {
      this.props.oldNavigate("/robots");
      return;
    }

    this.connectToSocket(this.props.robot);

    const settings = {
      ...this.props.project.settings,
      ...(this.props.robot.settings || {}),
    };

    this.state = {
      settings,
      editSettings: false,
      errorLastUpdate: new Date(),
      robot: this.props.robot,
    };

    this.timeZone = new TimeZone(this.props.project);

    console.log("settings", settings);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.robot) {
      const settings = {
        ...this.props.project.settings,
        ...(nextProps.robot.settings || {}),
      };
      this.setState({ robot: nextProps.robot, settings });
    }
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  connectToSocket = (robot) => {
    this.socket = socketIOClient(SOCKET_URL);

    this.socket.on("connect", () => {
      const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
      this.socket.emit("SET_USER_CONNECTED_TO_ROBOT", {
        token: authToken,
        robotId: robot._id,
      });
    });

    this.socket.on("disconnect", () => {
      console.log("disconnect form socket");
    });

    this.socket.on("REFRESH", () => {
      console.log("Refreshing robot data");
      this.getRobotData(robot);
    });
  };

  getRobotData = (robot) => {
    this.props.actions.getRobotDetails(robot._id);

    this.props.actions.getErrors({
      projectId: this.props.project._id,
      robotId: robot._id,
    });
  };

  robotClicked = (robot) => {
    console.log("robotSelected", robot);
    this.socket.disconnect();
    this.connectToSocket(robot);
    this.props.actions.robotSelected({
      ...robot,
      robotId: robot._id,
      robotName: robot.robotNumber.toString(),
    });
    this.getRobotData(robot);
    this.setState({ editSettings: false, isLoadingVoltages: false });
  };

  componentDidMount() {
    this.getRobotData(this.state.robot);
  }

  refreshErrors = () => {
    this.setState({ errorLastUpdate: new Date() });
    this.props.actions.getErrors({
      projectId: this.props.project._id,
      robotId: this.state.robot.id,
    });
  };

  getErrorDescription = (error) => {
    return this.timeZone.getLocalTime(error.timestamp);
  };

  getErrorTitle = (error) => {
    let str = "";

    str += error.description;

    if (error.row) {
      if (error.locationOnRow && error.locationOnRow >= 0)
        str = `${str} (row ${error.row.number}, ${
          Math.round(error.locationOnRow) / 1000
        }m)`;
      else str = `${str} (row ${error.row.number})`;
    }

    return str;
  };

  getSeverityClass = (error) => {
    if (error.status === "fixed") return 0;

    if (error.severity === "yellow") return 2;

    if (error.severity === "red") return 3;

    // default
    return 1;
  };

  showEditError = (error) => {
    this.props.actions.viewError();
    this.setState({ showEditError: true, selectedError: error });
  };

  render() {
    return (
      <div className="page">
        {this.props.isLoading ? <Loader /> : null}
        <div className="menu-wrapper">
          <Menu />
        </div>
        {this.state.showEditError ? (
          <EditError
            closeEditError={() => {
              this.setState({ showEditError: false });
            }}
            errors={[this.state.selectedError]}
            // deleteError={()=>{this.setState({showDeleteError: true, showEditError:false})}}
          />
        ) : null}
        <div className="content">
          <PlantHeader
            title={
              `Robot ${this.state.robot.robotName}` +
              (this.state.robot.rpi ? ` (${this.state.robot.rpi})` : "")
            }
            secondaryText={
              this.props.project.arrayType === "fixed"
                ? "Robots"
                : "Robots & Vehicles"
            }
            secondaryLink={"/robots"}
            thirdText={this.props.project.projectName}
            thirdLink={"/dashboard"}
          />
          <div className="arrows">
            {this.state.robot.prev ? (
              <span
                className="item"
                onClick={() => this.robotClicked(this.state.robot.prev)}
              >
                <span className="robot-name">
                  {this.state.robot.prev.robotNumber}
                </span>
                <img src={leftArrowIcon} />
              </span>
            ) : null}
            <span className="dotdotdot">...</span>
            {this.state.robot.next ? (
              <span
                className="item"
                onClick={() => this.robotClicked(this.state.robot.next)}
              >
                <img src={rightArrowIcon} />
                <span className="robot-name">
                  {this.state.robot.next.robotNumber}
                </span>
              </span>
            ) : null}
          </div>
          <div className="robots">
            <div className="left">
              {this.props.project.arrayType !== "solar-tracking" ? (
                <div className="control-panel">
                  <div>
                    <div className="title">CONTROL PANEL</div>
                    <div className="status">
                      {this.props.project.connectionStatus === "offline" ? (
                        <span className="offline">
                          <span className="circle"></span>offline
                        </span>
                      ) : (
                        <span className="online">
                          <span className="circle"></span>online
                        </span>
                      )}
                    </div>
                  </div>
                  <CleaningControlRobot
                    autoMode={true}
                    offline={false}
                    // this.props.project.connectionStatus ==="offline"}
                    category="robot"
                    startCleaningSeconds={this.state.robot.startCleaningSeconds}
                    currentState={this.state.robot.currentStatus}
                    categoryValue={this.state.robot.robotName}
                    categoryId={this.state.robot.id}
                    onPendingCommand={(pendingCommand) =>
                      this.setState({
                        robot: { ...this.state.robot, pendingCommand },
                      })
                    }
                    robot={this.state.robot}
                    project={this.props.project}
                    user={this.props.user}
                    namespace="robot"
                  />
                  {/*<div className="separator"></div>*/}
                  {/*<div className="lower">*/}
                  {/*<div className="left">*/}
                  {/*<div className="area-cleaned-title">Area cleaned</div>*/}
                  {/*<AreaCleaned progress={100} maxProgress={300} units={'m²'}/>*/}
                  {/*</div>*/}

                  {/*</div>*/}
                </div>
              ) : null}
              <RobotSettings
                robot={this.state.robot}
                project={this.props.project}
                user={this.props.user}
              />
            </div>

            <div className="right-column">
              <ErrorTable
                project={this.props.project}
                user={this.props.user}
                robot={this.props.robot}
                showEditError={this.showEditError}
              />
            </div>

            <RobotLogs robot={this.props.robot} project={this.props.project} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  //
  // console.log("state", state);
  return {
    user: state.indexReducer.user,
    project: state.indexReducer.project,
    robot: state.indexReducer.robot,
    ...state.robotReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        viewError,
        setRobotSettings,
        getErrors,
        getRobotDetails,
        refreshRobotBatteries,
        robotSelected,
        getLiveReports,
      },
      dispatch
    ),
  };
}

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(RobotComponent)
);
