import {SET_ROBOT_SETTINGS_SUCCESS} from "../../../reducers/robotReducer";
import {LOCAL_STORAGE_ROBOT} from "../../../consts";


export const setRobotSettingsSuccess = (robot) =>{
    robot.robotId = robot._id;
    localStorage.setItem(LOCAL_STORAGE_ROBOT, JSON.stringify(robot));
    console.log("saving robot",robot);
    return {
        type: SET_ROBOT_SETTINGS_SUCCESS,
        payload: {robot}
    }
}