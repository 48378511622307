import React, { Component, useEffect, useRef, useState } from 'react';
import 'react-dropdown/style.css';
import './editErrorResolution.scss';
import Modal from "../modal/modal";
import Attachments from "../attachments/attachments";
import DatePicker from "react-datepicker";
import deleteUserIcon from "../editUser/delete-user-icon.svg";
import ApiHandler from '../api';
import ReactSelect from 'react-select';
import { camelCaseToText, getFormattedDate } from '../../consts';


const EditErrorResolution = ({
    project,
    user,
    robotNumbers = [],
    technicians = [],
    isNew,
    existingErrorResolution,
    closeEditErrorResolution,
    deleteError
}) => {

    const apiHandler = new ApiHandler();

    const [errorResolution, setErrorResolution] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const attachmentsRef = useRef();

    useEffect(() => {
        if (existingErrorResolution) {
            setErrorResolution(existingErrorResolution);
        }
    }, [existingErrorResolution]);

    useEffect(() => {
        if (isNew) {
            const lastSaved = getFromLocallocalStorage();

            setErrorResolution({
                projectId: project._id,
                robotNumber: '',
                technicianId: lastSaved?.technicianId || {},
                robotId: {},
                status: 'pending',
                findings: [],
                replacements: [],
                responsibility: 'airtouch',
                location: undefined,
                remarks: '',
                createdBy: user._id,
                createdAt: new Date(),
                resolvedAt: lastSaved?.resolvedAt || new Date(),
            });
        }
    }, [isNew]);

    const onUploading = (isUploading) => {
        setIsUploadingImage(isUploading);
    }


    const getRobotNumbersOptions = () => {
        if (!robotNumbers)
            return [];

        const list = robotNumbers?.map(r => Number(r));
        list.sort((a, b) => a - b);
        const options = list.map(item => item.toString()).map(item => ({ label: item.toString(), value: item.toString() }))
        return options;
    }


    const getTitle = () => {
        if (isNew)
            return 'New Error Resolution';

        return 'Edit Error Resolution';
    }

    const saveToLocallocalStorage = () => {
        localStorage.setItem('errorResolution', JSON.stringify(errorResolution));
    }

    const getFromLocallocalStorage = () => {
        const tmp = JSON.parse(localStorage.getItem('errorResolution'));
        if (tmp) {
            tmp.createdAt = new Date(tmp.createdAt);
            tmp.resolvedAt = new Date(tmp.resolvedAt);
        }
        return tmp;
    }



    const onSubmit = async () => {
        if (isUploadingImage) {
            alert("Please wait until all images are uploaded");
            return;
        }

        let params = { ...errorResolution };
        // params.replacements = params.replacements.join(",");
        params.projectId = project._id;
        params.technicianId = errorResolution.technicianId._id;
        params.robotNumber = errorResolution.robotId.robotNumber;



        const mandatory = {
            projectId: 1,
            robotNumber: 1,
            technicianId: 1,
            status: 1,
            responsibility: 1
        }

        try {
            setIsSaving(true);
            if (isNew) {
                for (let key in mandatory) {
                    if (!params[key])
                        return alert(`Please fill in the ${camelCaseToText(key)} field`);
                }

                for (let key in params) {
                    if (!params[key])
                        delete params[key];
                }


                const result = await apiHandler.createErrorResolution(params);

                saveToLocallocalStorage();
                setErrorResolution({ ...errorResolution, _id: result._id });

                if (isNew)
                    await attachmentsRef.current.updateAll({ errorResolutionId: result._id });

                closeEditErrorResolution(true);

            } else {

                for (let key in mandatory) {
                    if (!params[key])
                        return alert(`Please fill in the ${camelCaseToText(key)} field`);
                }


                await apiHandler.editErrorResolution(errorResolution._id, params);
                closeEditErrorResolution(true);


            }
            setIsSaving(false);
        } catch (err) {
            setIsSaving(false);
            console.log(err);
            alert('Error saving data');
        }
    }



    const onFindingSelected = (items) => {
        setErrorResolution({ ...errorResolution, findings: items.map(item => item.value) });
    }

    const removeFinding = (finding) => {

        const i = errorResolution.findings.findIndex(f => f === finding);
        errorResolution.findings.splice(i, 1);
        setErrorResolution({ ...errorResolution });
    }


    const onReplacementSelected = (items) => {
        setErrorResolution({ ...errorResolution, replacements: items.map(item => item.value) });
    }


    const onLocationChanged = (e) => {

        let digitsOnly = e.target.value.replace(/\D/g, '');

        setErrorResolution({ ...errorResolution, location: digitsOnly });
    }


    const onTechnicianSelected = (item) => {
        setErrorResolution({ ...errorResolution, technicianId: { fullName: item.label, _id: item.value } })
    }

    const onRobotSelected = (item) => {
        setErrorResolution({ ...errorResolution, robotId: { robotNumber: item.value } });
    }

    const dateChanged = (date) => {
        setErrorResolution({ ...errorResolution, resolvedAt: date });
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#e36510' : '#b9bcbe',
            '&:hover': {
                borderColor: state.isFocused ? '#e36510' : '#b9bcbe',
            },
            borderRadius: 0,
            padding: '0',
            boxShadow: 'none',
            minHeight: '30px', // Set the minimum height
            fontSize: '13px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px', // Set the height for the indicators container
            display: 'flex',
            alignItems: 'center', // Center the indicators vertically
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0px', // Adjust padding as needed
            display: 'flex',
            alignItems: 'center', // Ensure the triangle is centered vertically
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px', // Remove default margin
            padding: '0px', // Remove default padding
            alignItems: 'center', // Center the input vertically
            height: '26px', // Set the input height
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            // borderColor: state.isSelected ? 'red' : 'black',
            //   backgroundColor: state.isSelected ? 'red' : 'white',
            //   color: state.isSelected ? 'white' : 'black',
            //   '&:hover': {
            //     backgroundColor: 'red', // Color when hovered
            //     color: 'white',
            //   },
        }),
    };


    return (
        <Modal className="edit-error-resolution" title={getTitle()} onClose={() => closeEditErrorResolution()}>

            {!!errorResolution && <>

                <div className="new-error-lines">
                    <div className="input-line">
                        <div className='half first'>
                            <div className="input-description">Robot Number</div>

                            <ReactSelect
                                styles={customStyles}
                                options={getRobotNumbersOptions()}
                                placeholder="Select Robot"
                                onChange={onRobotSelected}
                                value={{ label: errorResolution.robotId?.robotNumber, value: errorResolution.robotId?.robotNumber }}
                            />
                        </div>
                        <div className='half'>
                            <div className="input-description">Technician name</div>
                            <ReactSelect
                                styles={customStyles}
                                options={technicians.map(t => ({ label: t.fullName, value: t.id }))}
                                placeholder="Select technician"
                                onChange={onTechnicianSelected}
                                value={{ label: errorResolution.technicianId?.fullName, value: errorResolution.technicianId?.id }}
                            />
                        </div>

                    </div>

                    <div className="input-line datetime">
                        <div className="input-description">Reolved time</div>
                        <div className='date'>
                            <DatePicker
                                showTimeSelect
                                selected={new Date(errorResolution.resolvedAt || new Date())}
                                onChange={dateChanged}
                                shouldCloseOnSelect={true}
                            />
                        </div>
                        <input
                            value={getFormattedDate({ date: errorResolution.resolvedAt, hourOnly: true })}
                            disabled={true}
                            type="string"
                            className='time'
                        />
                    </div>

                    <div className="input-line radio">
                        <div className='half first'>
                            <div className="input-description">Error status</div>
                            <span className="radio-item" onClick={() => setErrorResolution({ ...errorResolution, status: 'pending' })}>
                                <input checked={errorResolution.status === 'pending'} value="pending" type="radio"
                                    name="errorResolution.status"
                                    onChange={(e) => setErrorResolution({ ...errorResolution, status: e.target.checked ? 'pending' : 'resolved' })} />
                                <label>Pending</label>
                            </span>

                            <span className="radio-item" onClick={() => setErrorResolution({ ...errorResolution, status: 'resolved' })}>
                                <input checked={errorResolution.status === 'resolved'} value="resolved" type="radio"
                                    name="errorResolution.status"
                                    onChange={(e) => setErrorResolution({ ...errorResolution, status: e.target.checked ? 'resolved' : 'pending' })} />
                                <label>Resolved</label>
                            </span>
                        </div>
                        <div className='half'>
                            <div className="input-description">Who is responsible?</div>
                            <span className="radio-item" onClick={() => setErrorResolution({ ...errorResolution, responsibility: 'airtouch' })}>
                                <input checked={errorResolution.responsibility === 'airtouch'} value={'airtouch'} type="radio"
                                    name="errorResolution.responsibility"
                                    onChange={(e) => setErrorResolution({ ...errorResolution, responsibility: e.target.checked ? 'airtouch' : 'client' })} />
                                <label>Airtouch</label>
                            </span>
                            <span className="radio-item" onClick={() => setErrorResolution({ ...errorResolution, responsibility: 'client' })}>
                                <input checked={errorResolution.responsibility === 'client'} value={'client'} type="radio"
                                    name="errorResolution.responsibility"
                                    onChange={(e) => setErrorResolution({ ...errorResolution, responsibility: e.target.checked ? 'client' : 'airtouch' })} />
                                <label>Client</label>
                            </span>

                        </div>
                    </div>

                </div>



                <div className="input-line">
                    <div className="input-description">Findings</div>
                    <ReactSelect
                        styles={customStyles}
                        options={FINDINGS.filter(finding => !errorResolution.findings.includes(finding)).map(f => ({ label: f, value: f }))}
                        isMulti={true}
                        placeholder="Select findings"
                        onChange={onFindingSelected}
                        value={errorResolution.findings.map(f => ({ label: f, value: f }))}
                    />
                </div>


                <div className="input-line">
                    <div className="input-description">Location on table (m)</div>
                    <input value={errorResolution.location} placeholder="" type="string"
                        onChange={onLocationChanged} />
                </div>

                <div className="input-line">
                    <div className="input-description">Parts replaced</div>

                    <ReactSelect
                        styles={customStyles}
                        options={REPLACEMENTS.filter(replacement => !errorResolution.replacements.includes(replacement)).map(f => ({ label: f, value: f }))}
                        isMulti={true}
                        placeholder="Select replaced parts"
                        onChange={onReplacementSelected}
                        value={errorResolution.replacements.map(f => ({ label: f, value: f }))}
                    />
                </div>

                <div className="input-line">
                    <div className="input-description">Remarks</div>
                    <input value={errorResolution.remarks} placeholder="" type="text"
                        onChange={(e) => setErrorResolution({ ...errorResolution, remarks: e.target.value })} />
                </div>

                <div className="input-line">
                    <Attachments
                    
                        ref={attachmentsRef}
                        type="errorResolution"
                        onUploading={onUploading}
                        projectId={project._id}
                        errorResolutionId={errorResolution._id}
                        attachments={errorResolution.attachments}
                    />
                </div>


                {isNew ?
                    <div className="buttons-wrapper">
                        <div className="button" onClick={onSubmit}>{isSaving?'SAVING...':'SAVE'}</div>
                    </div>
                    :
                    <div className="buttons-wrapper">
                        {deleteError && !isNew ?
                            <div className="delete-row" onClick={deleteError}>
                                <img src={deleteUserIcon} />
                                <span>Delete error</span>
                            </div> : null}
                        <div className="button cancel" onClick={() => closeEditErrorResolution()}>CANCEL</div>
                        <div className="button" onClick={onSubmit}>{isSaving?'SAVING...':'SAVE'}</div>
                    </div>
                }
            </>}
        </Modal>
    )
}

export default EditErrorResolution;


const FINDINGS = [
    'Low battery',
    'Weak charging',
    'Battery error',
    'Stuck on table',
    'Communication settings',
    'Communication antenna',
    'Communication other',
    'Mechanical wheels',
    'Mechanical wheels',
    'Mechanical body parts',
    'Mechanical bearing',
    'Mechanical chain',
    'Motor actuator',
    'Motor drive',
    'Motor cleaning',
    'Proximity magnet',    
    'Axel failed',
    'No issue found',
    'Other',
    'Reset only',
]


const REPLACEMENTS = [
    "Battery",
    "Rotor motor holder",
    "Drive motor holder",
    "Rotor motor",
    "Drive motor",
    "Led light",
    "On / off switch",
    "Magnet sensor",
    "Actuator",
    "Actuator cable",
    "Japan bearing",
    "Mid rotor bering",
    "End wheels bearings",
    "Vertical Wheel",
    "Horizontal wheel",
    "Charging hook robot",
    "Charging hooks docking",
    "Solar panel",
    "PCB",
    "Antenna",
    "Chain",
    "Cog-wheel",
    "Coupling",
    "Rotor’s pin",
    "Towels",
    "Bridges",
    "Magnet",
    "Docking",
    "System reset",
    "Communication problem",
    "Chain sprocket",
    "End rotor bearing",
    "End wheel bearing", 
    "Mid rotor bearing", 
    "Japan bearing", 
    "Fuse"
]

const RESPONSIBILITES = {
    airtouch: 'Airtouch',
    client: 'Client',
}



