import React, {Component, PropTypes} from 'react';
import UsersWidget from '../usersWidget/usersWidget';
import {connect} from 'react-redux';
import './plantHeader.scss';
import {withNavigation} from '../withNavigate';
import Weather from "../weatherHeader/weather";

class PlantHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressWidth: 0
        };
    }

    componentWillReceiveProps(nextProps) {

    }

    onSecondaryTextClicked = () => {
        if (this.props.secondaryLink)
            window.location.href = this.props.secondaryLink;
        else
            window.location.href = '/dashboard';
    }

    onThirdTextClicked = () => {
        if (this.props.thirdLink)
            window.location.href = this.props.thirdLink;
    }

    getAddress = () => {
        let str = this.props.project.streetAddress || '';
        if (str === '.')
            str = '';

        if (this.props.project.state && str.length) 
            str += ', ';
        str += this.props.project.state || '';

        return str;
    }

    render() {
        return (
            <div className="plant-header">
                <UsersWidget/>

                {this.props.title ?
                    <div className="first-text">{this.props.title}</div> :
                    <div className="project-title">
                        <div className="plant-name">{this.props.project.projectName} / {this.props.project.totalPower}MW</div>
                        <div className="address">{this.getAddress()}</div>
                    </div>
                }
                
                {!this.props.title?<Weather timezoneOffset={this.props.project.timezone.rawOffset +  this.props.project.timezone.dstOffset}/>:null}
                {this.props.secondaryText ?
                    <div>
                        <div onClick={this.onSecondaryTextClicked}>
                            <span className="slash">/</span>
                            <div className="second-text">{this.props.secondaryText}</div>
                        </div>
                        {this.props.thirdText ? <div onClick={this.onThirdTextClicked}>
                            <span className="slash">/</span>
                            <div className="second-text">{this.props.thirdText}</div>
                        </div> : null}
                    </div> :
                    <div className="right">
                        {this.props.projectData ?
                            <div style={{display: 'inline'}}>
                                <div className="item orange">
                                    <div className="name">Cycles completed</div>
                                    <div className="value ">{this.props.project.cyclesCompleted}</div>
                                </div>

                                <div className="item orange">
                                    <div className="name">Total area cleaned</div>
                                    <div className="value ">{this.props.project.areaCleaned}m²</div>
                                </div>
                            </div> : null}



                        {this.props.project?.arrayType !=='fixed' ? <div className="item default">
                            <div className="name">Number of rows</div>
                            <div className="value">{this.props.project.numberOfRows}</div>
                        </div> : null}

                        {/*<div className="item default">*/}
                        {/*    <div className="name">Number of robots</div>*/}
                        {/*    <div*/}
                        {/*        className="value">{this.props.project.numberOfTypeOneRobots + this.props.project.numberOfTypeTwoRobots}</div>*/}
                        {/*</div>*/}

                        {this.props.project.numberOfVehicles ?
                            <div className="item default">
                                <div className="name">Number of vehicles</div>
                                <div className="value">{this.props.project.numberOfVehicles}</div>
                            </div> : null}
                    </div>
                    
                }

                {this.props.children}
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //

    return {
        ...state.indexReducer
    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default  withNavigation(connect(mapStateToProps, mapDispatchToProps)(PlantHeader));