import {CREATE_MESSAGE_SUCCESS, EDIT_MESSAGE_SUCCESS} from "./editMessageReducer";

export const GET_MESSAGES_STARTED = 'GET_MESSAGES_STARTED';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';

const INITIAL_STATE = {
    messages: [],
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    const message = action.payload?action.payload.message:null;
    const {messages} = state;

    switch (action.type) {
        case GET_MESSAGES_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_MESSAGES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };

        case GET_MESSAGES_ERROR:
            return {
                ...state,
                message: action.payload.error,
                isLoading: false
            };
        case EDIT_MESSAGE_SUCCESS:

            const item = messages.find(e=>e._id ===message._id);
            if (item) {
                const index = messages.indexOf(item);
                messages[index] = message;
            }
            else {
                messages.splice(0, 0, message);
            }
            return {
                ...state,
                messages: [...messages]
            }
        case CREATE_MESSAGE_SUCCESS:

            return {
                ...state,
                messages: [action.payload.message,...messages]
            }
        case DELETE_MESSAGE_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case DELETE_MESSAGE_SUCCESS:
            messages.splice(messages.findIndex(e=>e._id ===message._id), 1);
            return {
                ...state,
                messages: [...messages]
            }

        default:
            return state;
    }
}