import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import './checkbox.scss';
import checked from "./checked.svg";
import unchecked from "./unchecked.svg";

class CheckboxComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.initialState
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialState !==undefined)
            this.setState({ isChecked: nextProps.initialState})
    }


    onClick = (event)=>{
        event.stopPropagation();
        this.setState({isChecked: !this.state.isChecked});
        this.props.onCheckChange(!this.state.isChecked);
    }

    render() {
        return (
            <div className="checkbox">
                <img src={this.state.isChecked?checked:unchecked} onClick={this.onClick}/>
            </div>
        )
    }


}


function mapStateToProps(state, ownProps) {
    //
    // //console.log("state.indexReducer", state.indexReducer)
    return {

    }

}

function mapDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(CheckboxComponent);