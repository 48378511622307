import orangeSpinner from "../loader/orange_spinner.svg";
import checkIcon from "../errors/images/check-icon.svg";
import React, { useEffect, useState } from "react";
import { TimeZone } from "../utils";
import { HOST_URL, LOCAL_STORAGE_TOKEN_KEY } from "../../consts";
import axios from "axios";
import "./errors-table.scss"
import EditError from "../editError/editError";

const ErrorTable = ({ project, robot, vehicle, user, className, repeaters = false }) => {
    const [timeZone, setTimeZone] = useState();
    const [isLoadingErrors, setIsLoadingErrors] = useState(false);
    const [errorLastUpdate, setErrorLastUpdate] = useState(new Date());
    const [errors, setErrors] = useState([]);
    const [showEditError, setShowEditError] = useState(false);
    const [selectedError, setSelectedError] = useState(null);

    const refreshErrors = async () => {
        setErrorLastUpdate(new Date());
        setIsLoadingErrors(true);
        const from = new Date();
        from.setDate(from.getDate() - 7);
        const to = new Date();

        const url = `${HOST_URL}/api/errors?projectId=${project._id}&from=${from.getTime()}&to=${to.getTime()}&robotId=${robot ? robot._id : ''}&vehicleId=${vehicle ? vehicle._id : ''}&repeaters=${repeaters}`;

        const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        try {
            const response = await axios.get(url, { headers: { Authorization: authToken } });
            if (response.data.success) {
                setErrors(response.data.errors);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingErrors(false);
        }
    }

    useEffect(() => {
        setTimeZone(new TimeZone(project));
        refreshErrors();
    }, [project, robot]);

    const getSeverityClass = (error) => {
        if (error.status.startsWith('fixed'))
            return 0;

        if (error.severity === 'yellow')
            return 2;

        if (error.severity === 'red')
            return 3;

        // default
        return 2;
    }

    const getErrorTitle = error => {
        let str = '';

        if (error.vehicleId) {
            str = `Vehicle ${error.vehicleId.vehicleNumber}`;
            if (error.robot)
                str += ' | '
        }

        if (error.robot && !robot)
            str += `Robot ${error.robot.name}`;

        if (error.repeaterId)
            str += `Repeater ${error.repeaterId.repeaterNumber}`;

        if (error.rpi)
            str += `RPi ${error.rpi}`;

        if (str) {
            str += ': ';
        }

        if (error.description)
            str += `${error.locationOnRow ? `(${Math.round(error.locationOnRow / 1000)}m) ` : ''}${error.errorCode}`;

        if (error.row && error.row.number && error.row.number != error.robot.name)
            str += ' (row' + error.row.number + ')';


        return str;
    }

    const getErrorDescription = error => {
        return timeZone.getLocalTime(error.timestamp || error.date);
    }

    const getMainClass = () => {
        let c = user.role !== "projectOwner" ? 'error-table' : 'error-table hide';
        if (className)
            c += ' ' + className;
        return c;
    }

    const onShowEditError = (error) => {
        setShowEditError(true);
        setSelectedError(error);
    }

    return (
        <div className={getMainClass()}>
            {showEditError &&
                <EditError closeEditError={() => setShowEditError(false)}
                    errors={[selectedError]} />}

            <div className="header">
                <span className="title">ERRORS</span>
                {timeZone ? <span
                    className="last-update">Last update {timeZone.getLocalTime(errorLastUpdate, false, false, true)}</span> : null}
                {isLoadingErrors ?
                    <span className="refresh-spinner"><img src={orangeSpinner} /></span> :
                    <span className="refresh" onClick={refreshErrors}>Refresh</span>
                }
            </div>
            <div className="body">
                {errors.map((error, index) => {
                    return <div className={'error level' + getSeverityClass(error)} key={index}
                        onClick={() => onShowEditError(error)}>
                        <span className="circle">{getSeverityClass(error) === 0 ?
                            <img src={checkIcon} /> : '!'}</span>
                        <div className="right-side">
                            <div className="error-title">{getErrorTitle(error)}</div>
                            <div className="error-description">{getErrorDescription(error)}</div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ErrorTable;