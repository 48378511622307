import React from 'react';
import './attachment.scss'
import Modal from "../modal/modal";


const Attachment = ({ closeAttachment, src }) => {

    return (
        <Modal title={"Attachment"} onClose={closeAttachment}>
            <img className="main-attachment" src={src} />
        </Modal>
    )
}


export default Attachment