import React, { Component, PropTypes } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import "./dashboard.scss";

import cleanedIcon from "./cleaned-icon.svg";
import clockIcon from "./clock-icon.svg";
import RobotStatus from "./robotStatus";
import PlantHeader from "../plantHeader/plantHeader";
import Menu from "../menu/menu";
import CleaningControlRobot from "../cleaningControl/cleaningControlRobot";
import ManualCleaningControl from "../manualCleaningControl/manualCleaningControl";
import { SOCKET_URL, LOCAL_STORAGE_TOKEN_KEY } from "../../consts";
import Loader from "../loader/loader";
import { getProjectDetails } from "./actions/getProjectDetails";
import Dropdown from "react-dropdown";
import { withNavigation } from "../withNavigate";
import EditMessage from "../editMessage/editMessage";
import { viewMessage } from "../editMessage/viewMessageAction";
import socketIOClient from "socket.io-client";
import { TimeZone } from "../utils";
import ErrorTable from "./errors-table";
import MessageTable from "./message-table";
import RobotBatteries from "./robotBatteries";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import AvgVoltage from "./avg-voltage";
import DailyVoltage from "./daily-voltage";
import DailyCleaning from "./daily-cleaning";
import ApiHandler from "../api";
import SpinningRefresh from "./spinningRefresh/spinningRefresh";
import { udpateProjectData } from "../settings/actions/udpateProjectDataAction";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    console.log("DashboardComponent constructor");

    let now = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    this.state = {
      graphWidth: this.getGraphWidth(),
      nonCleaningRobots: [],
      selectedRpis: {},
    };

    this.timeZone = new TimeZone(this.props.project);
  }

  componentWillUnmount() {
    this.socket?.disconnect();
    window.clearInterval(this.liveInterval);
  }

  connectToSocket = (project) => {
    this.socket = socketIOClient(SOCKET_URL);

    this.socket.on("connect", () => {
      const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
      this.socket.emit("SET_USER_CONNECTED_TO_PROJECT", {
        token: authToken,
        projectId: project._id,
      });
    });

    this.socket.on("disconnect", () => {
      console.log("disconnect form socket");
    });

    this.socket.on("REFRESH", () => {
      console.log("Refreshing project data");
      this.props.actions.getProjectDetails(project._id);
    });
  };

  componentDidMount() {
    console.log("DashboardComponent componentDidMount");
    if (!this.props.actions || !this.props.project) {
      this.props.oldNavigate("/projects");
      return;
    }

    this.connectToSocket(this.props.project);

    this.props.actions.getProjectDetails(this.props.project._id);
  }

  getGraphWidth = () => {
    if (!this.historyGraph) return 0;

    console.log("this.historyGraph.offsetWidth", this.historyGraph.offsetWidth);
    return this.historyGraph.offsetWidth - 44;
  };

  onSubnetSelected(option, group) {
    const { selectedRpis } = this.state;
    if (option.value) selectedRpis[group.groupNumber] = option.value;
    else delete selectedRpis[group.groupNumber];

    this.setState({ selectedRpis });
  }

  showEditMessage = (message) => {
    this.props.actions.viewMessage();
    this.setState({ showEditMessage: true, selectedMessage: message });
  };

  disconnectRpi = async () => {
    this.setState({ isDisconnectingRpi: true }, async () => {
      await new ApiHandler().disconnectRpi(this.props.project._id);
      setTimeout(() => {
        this.setState({ isDisconnectingRpi: false });
      }, 1000);
    });
  };

  render() {
    if (!this.state || !this.props.project) return <div></div>;

    return (
      <div className="page" ref={(ref) => (this.page = ref)}>
        {this.props.isLoading ? <Loader /> : null}
        <div className="menu-wrapper">
          <Menu />
        </div>

        {this.state.showEditMessage ? (
          <EditMessage
            closeEditMessage={() => {
              this.setState({ showEditMessage: false });
            }}
            message={this.state.selectedMessage}
            // deleteError={()=>{this.setState({showDeleteError: true, showEditError:false})}}
          />
        ) : null}
        <div className="content">
          <PlantHeader project={this.props.project} />
          <div className="dashboard">
            <div className="lines">
              <div className="line">
                <AvgVoltage timeZone={this.timeZone} />

                <RobotBatteries />
              </div>

              <div className="line bars">
                <DailyVoltage
                  width={this.state.graphWidth}
                  timeZone={this.timeZone}
                />

                <div
                  className="history-graph"
                  ref={(ref) => (this.historyGraph = ref)}
                >
                  <DailyCleaning
                    width={this.state.graphWidth}
                    timeZone={this.timeZone}
                  />
                </div>
              </div>
            </div>

            {this.props.user.role !== "projectOwner" && (
              <div className="right">
                <ErrorTable
                  project={this.props.project}
                  user={this.props.user}
                />

                <MessageTable
                  project={this.props.project}
                  user={this.props.user}
                  className="history-graph first"
                />
              </div>
            )}

            <div className="line line1">
              <div className="control-panel">
                <div>
                  <div className="title">CONTROL PANEL</div>
                  <div className="status">
                    <SpinningRefresh
                      color={
                        this.props.project.connectionStatus === "offline"
                          ? "#f56565"
                          : "#05b2aa"
                      }
                      onClick={this.disconnectRpi}
                      isSpinning={this.state.isDisconnectingRpi}
                      tooltipText="Refresh connection"
                    />
                    {this.props.project.connectionStatus === "offline" ? (
                      <span className="offline">offline</span>
                    ) : !this.props.project.connectedRpis ||
                      this.props.project.connectedRpis.length === 0 ? (
                      <span className="online">Online</span>
                    ) : this.props.project.connectedRpis.length === 1 ? (
                      <span className="online">
                        Subnet {this.props.project.connectedRpis[0]} is online
                      </span>
                    ) : this.props.project.connectedRpis &&
                      this.props.project.connectedRpis.length > 1 ? (
                      <span className="online">
                        Subnets {this.props.project.connectedRpis.join(", ")}{" "}
                        are online
                      </span>
                    ) : null}
                  </div>
                </div>
                {this.props.project.groups
                  .filter((g) => g.hasRobots)
                  .map((group, index) => (
                    <div className="cleaning-control-wrapper" key={index}>
                      <CleaningControlRobot
                        autoMode={true}
                        offline={
                          this.props.project.connectionStatus === "offline"
                        }
                        category="group"
                        currentState={group.currentStatus}
                        rpi={this.state.selectedRpis[group.groupNumber]}
                        categoryValue={group.groupNumber}
                        pendingCommand={group.pendingCommand}
                        onPendingCommand={(pendingCommand) =>
                          this.props.actions.udpateProjectData({
                            ...this.props.project,
                            pendingCommand,
                          })
                        }
                        cleaningTime={group.cleaningTime}
                        timezone={this.props.project.timezone}
                        startCleaningSeconds={group.startCleaningSeconds}
                        namespace={"group" + (index + 1)}
                        project={this.props.project}
                        user={this.props.user}
                      />
                      <div className="separator"></div>
                      {this.props.cleaningData[group.groupNumber] ? (
                        <div>
                          {this.props.cleaningData[group.groupNumber]
                            .cleaningPreventedReason ? (
                            <div className="yesterday">
                              <div className="item">
                                <img src={clockIcon} />
                                <div className="texts">
                                  <div className="name">Yesterday cleaning</div>
                                  <div className="value red">
                                    Cancelled due to{" "}
                                    {this.props.cleaningData[
                                      group.groupNumber
                                    ].cleaningPreventedReason.toLowerCase()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="yesterday">
                              <div className="item">
                                <img src={clockIcon} />
                                <div className="texts">
                                  <div className="name">Yesterday cleaning</div>
                                  <div className="value">
                                    {this.timeZone.getLocalTime(
                                      this.props.cleaningData[group.groupNumber]
                                        .startCleaningTimestamp
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <img src={cleanedIcon} />
                                <div className="texts">
                                  <div className="name">Cleaned yesterday</div>
                                  <div className="value">
                                    {Math.round(
                                      this.props.cleaningData[group.groupNumber]
                                        .cleaningDistance
                                    )}
                                    m
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.props.project.connectedRpis.length ? (
                            <div className="subnet-selection">
                              <Dropdown
                                // options={[{label: 'All subnets', value: ''}].concat([{label:'Subnet 1', value:1},{label:'Subnet 2', value:2}])}
                                options={[
                                  {
                                    label: "All subnets",
                                    value: "",
                                  },
                                ].concat(
                                  this.props.project.connectedRpis.map(
                                    (rpi) => ({
                                      label: "Subnet" + rpi,
                                      value: rpi,
                                    })
                                  )
                                )}
                                placeholder="All subnets"
                                placeholderClassName="placeholder"
                                // value={item.settings && item.groupNumber ? item.groupNumber + "" : "1"}
                                value={group.subnet}
                                onChange={(option) =>
                                  this.onSubnetSelected(option, group)
                                }
                                className="dropdown"
                                controlClassName="control"
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ))}
                <div className="lower">
                  {this.props.project.robots ? (
                    <div>
                      <div className="robot-status-title">
                        Robots status:{" "}
                        <span className="turquoise">
                          {
                            this.props.project.robots.filter((r) =>
                              [
                                "cleaning",
                                "drive-forward",
                                "drive-backwards",
                                "start-cleaning",
                              ].includes(r.currentStatus)
                            ).length
                          }{" "}
                          of {this.props.project.robots.length} are active
                        </span>
                        <span className="separator">|</span>
                        <span>
                          {
                            this.props.project.robots.filter(
                              (r) => r.currentStatus === "error"
                            ).length
                          }{" "}
                          errors
                        </span>
                      </div>
                      <RobotStatus
                        totalNumber={this.props.project.robots.length}
                        defaultColor={"#05b2aa"}
                        robots={this.props.project.robots}
                        specialCases={this.state.nonCleaningRobots}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {this.props.user.role !== "projectOwner" ? (
              <div className="line line1">
                <ManualCleaningControl
                  project={this.props.project}
                  offline={this.props.project.connectionStatus === "offline"}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  //

  return {
    project: state.indexReducer.project,
    user: state.indexReducer.user,
    ...state.dashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        udpateProjectData,
        viewMessage,
        getProjectDetails,
      },
      dispatch
    ),
  };
}

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
);
