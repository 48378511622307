import {EDIT_PROJECT_SUCCESS} from "../../../reducers/settingsReducer";
import {LOCAL_STORAGE_PROJECT} from "../../../consts";


export const editProjectSuccess = (project) =>{
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project));
    return {
        type: EDIT_PROJECT_SUCCESS,
        payload: {project}
    }
}