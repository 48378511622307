export const GET_CURRENT_WEATHER_STARTED = 'GET_CURRENT_WEATHER_STARTED';
export const GET_CURRENT_WEATHER_ERROR = 'GET_CURRENT_WEATHER_ERROR';
export const GET_CURRENT_WEATHER_SUCCESS = 'GET_CURRENT_WEATHER_SUCCESS';
export const GET_WEATHER_HISTORY_SUCCESS = 'GET_WEATHER_HISTORY_SUCCESS';

const INITIAL_STATE = {
    currentWeather: {},
    weatherHistory: [],
    isLoading: true
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    switch (action.type) {
        case GET_CURRENT_WEATHER_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_CURRENT_WEATHER_SUCCESS:
            return {
                ...state,
                // isLoading: false,
                currentWeather: action.payload.currentWeather || {}
            };
        case GET_WEATHER_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                weatherHistory: action.payload.weatherHistory
            };
        case GET_CURRENT_WEATHER_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        default:
            return state;
    }
}