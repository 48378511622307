import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { getFormattedDate } from "../../consts";

const ChargingBalanceComponent = ({ project, date }) => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const apiHandler = new ApiHandler();
    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            try {
                const reports = await apiHandler.getRobotDailyReports({ projectId: project._id, date, reportType: 'chargingBalance' });

                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        getReports();

    }, [project, date]);

    return <>
        <table className="chraging-balance">
            <tbody>
                <tr>
                    <th className="robot-number">Robot</th>
                    {reports.length? <>
                        <th>{getFormattedDate({date: reports[0].chargingBalance.dayByDayPercentage[0].date, dateOnly: true})} pre-cleaning</th>
                        <th>{getFormattedDate({date: reports[0].chargingBalance.dayByDayPercentage[1].date, dateOnly: true})} pre-cleaning</th>
                        <th>{getFormattedDate({date: reports[0].chargingBalance.dayByDayPercentage[2].date, dateOnly: true})} pre-cleaning</th>
                    </>:<>
                        <th>Day 1 pre-cleaning</th>
                        <th>Day 2 pre-cleaning</th>
                        <th>Day 3 pre-cleaning</th>
                    </>}
                </tr>




                {reports.map((report) => {
                    return <tr key={report.robotId.robotNumber}>
                        <td className="robot-number">{report.robotId.robotNumber}</td>
                        <td>{report.chargingBalance.dayByDayPercentage[0].batteryPercentage}%</td>
                        <td>{report.chargingBalance.dayByDayPercentage[1].batteryPercentage}%</td>
                        <td>{report.chargingBalance.dayByDayPercentage[2].batteryPercentage}%</td>
                    </tr>
                })}
            </tbody>
        </table >
    </>
}

export default ChargingBalanceComponent;