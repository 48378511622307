import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import 'react-dropdown/style.css';
import closeIcon from "../modal/close-icon.svg";
import placeholder from "./images/pdf-placeholder.png";
import { LOCAL_STORAGE_PROJECT } from '../../consts';
import { useDropzone } from 'react-dropzone'
import './attachments.scss';
import ApiHandler from '../api';
import spinner from '../loader/white_spinner.svg';

const AttachmentsComponent = React.forwardRef(({
    attachments = [],
    taskId,
    errorId,
    errorResolutionId,
    projectId,
    type,
    onUploading,
}, ref) => {

    const apiHandler = new ApiHandler();

    const [localAttachments, setLocalAttachments] = useState([...attachments]);

    useEffect(() => {
        console.log("localAttachments attachments", attachments, "localAttachments", localAttachments);

        if (localAttachments.length && localAttachments[localAttachments.length - 1].file)
            saveAttachment(localAttachments[localAttachments.length - 1].file);

    }, [localAttachments]);

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        console.log("attachments attachments", attachments, "localAttachments", localAttachments);

        if (attachments.length > localAttachments.length)
            setLocalAttachments([...attachments]);
    }, [attachments]);

    const onDrop = useCallback(function (acceptedFiles) {

        const file = acceptedFiles[0];

        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = (event) => {

            // const binaryStr = reader.result            
            setLocalAttachments(prevAttachments => prevAttachments.concat([{ file, data: file.type.includes('/pdf') ? placeholder : event.target.result }]));
        }
        reader.readAsDataURL(file)

    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const saveAttachment = async (file) => {
        if (!file)
            return;

        try {
            if (onUploading)
                onUploading(true);
            const attachment = await apiHandler.createAttachment({
                projectId,
                file,
                type,
                errorId,
                errorResolutionId,
                taskId
            });
            localAttachments.pop();
            localAttachments.push(attachment);
            setLocalAttachments([...localAttachments]);
        } catch (err) {
            console.log(err.stack);
            localAttachments.pop();
            setLocalAttachments([...localAttachments]);
            alert("Can't upload attachment");
        } finally {
            if (onUploading)
                onUploading(false);
        }
    }

    React.useImperativeHandle(ref, () => ({
        updateAll
    }));

    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const waitForAttachmentId = async (index, max = 30000) => {
        for (let i = 0; i < max; i += 1000) {
            console.log(i, "waiting for attachment id: ", localAttachments[index]);
            if (localAttachments[index]._id) {
                console.log("attachment id: ", localAttachments[index]._id);
                return;
            }
            await wait(1000);
        }
    }

    const updateAll = async ({
        taskId,
        errorId,
        errorResolutionId,
        projectId
    }) => {
        for (let i = 0; i < localAttachments.length; i++) {
            await waitForAttachmentId(i);

            await apiHandler.updateAttachment(localAttachments[i]._id, {
                taskId,
                errorId,
                errorResolutionId,
                projectId
            });
        }
    };


    const replaceAttachment = async (index, attachment) => {
        const tmpAttachments = [...localAttachments];
        tmpAttachments[index] = attachment;

        setLocalAttachments(tmpAttachments);
    }

    const removeAttachment = async (e, index) => {
        e.stopPropagation();
        e.preventDefault();


        const attachment = localAttachments.splice(index, 1)[0];

        setLocalAttachments([...localAttachments]);

        if (attachment._id) {
            try {
                await apiHandler.deleteAttachment(attachment._id);
            } catch (err) {
                replaceAttachment(index, attachment);
                alert("Can't delete attachment");
            }
        }
    }

    const getAttachmentPath = (index) => {
        return localAttachments[index]?.thumbUrl || localAttachments[index]?.data;
    }

    return (<div {...getRootProps()}>
        <div className={isDragActive ? "item attachments drag-active" : "item attachments"} >
            {localAttachments.length === 0 && !isDragActive && <div className="place-holder-empty">Drag and drop or click to select files</div>}

            {localAttachments.length <= 1 && isDragActive && <div className="place-holder-empty">Drop files here</div>}


            {localAttachments.map((attachment, index) =>
                <div className={index < 2 ? "attachment" : "attachment last"} key={index}>

                    {localAttachments[index]?.thumbUrl ?
                        <div className="image-wrapper">
                            <img className="x" src={closeIcon}
                                onClick={(e) => removeAttachment(e, index)} />
                            <img className="image" src={localAttachments[index].thumbUrl} />
                        </div> : localAttachments[index]?.data ?
                            <div className="image-wrapper">
                                <img className="image" src={localAttachments[index]?.data} />
                                <div className="spinner">
                                    <img src={spinner} />
                                </div>
                            </div> :
                            <div className="place-holder"></div>
                    }
                </div>
            )}


            {/* <div className="attachment">
                <div className="image-wrapper">
                    <img className="image" src={"https://res.cloudinary.com/dsdikyk5f/image/upload/v1722835504/projects/Hartuv%20test/i2.jpg"} />
                    <div className="spinner">
                        <img src={spinner} />
                    </div>
                </div>
            </div> */}

        </div>

        <input {...getInputProps()} />

    </div >)
});




export default AttachmentsComponent;