import {CREATE_TASK_SUCCESS, EDIT_TASK_SUCCESS} from "./editTaskReducer";

export const GET_TASKS_STARTED = 'GET_TASKS_STARTED';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';

const INITIAL_STATE = {
    tasks: [],
    isLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    // //console.log("action.type",action.type);
    const task = action.payload?action.payload.task:null;
    const {tasks} = state;

    switch (action.type) {
        case GET_TASKS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case GET_TASKS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };

        case GET_TASKS_ERROR:
            return {
                ...state,
                task: action.payload.error,
                isLoading: false
            };
        case EDIT_TASK_SUCCESS:

            const item = tasks.find(e=>e._id ===task._id);
            if (item) {
                const index = tasks.indexOf(item);
                tasks[index] = task;
            }
            else {
                tasks.splice(0, 0, task);
            }
            return {
                ...state,
                tasks: [...tasks]
            }
        case CREATE_TASK_SUCCESS:

            return {
                ...state,
                tasks: [action.payload.task,...tasks]
            }
        case DELETE_TASK_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case DELETE_TASK_SUCCESS:
            tasks.splice(tasks.findIndex(e=>e._id ===task._id), 1);
            return {
                ...state,
                tasks: [...tasks]
            }

        default:
            return state;
    }
}