import { useEffect, useState } from "react";
import ApiHandler from "../api";
import { getFormattedDate } from "../../consts";

const TableComponent = ({ project, date }) => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const apiHandler = new ApiHandler();
    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            try {
                const reports = await apiHandler.getRobotDailyReports({ projectId: project._id, date, reportType: 'table' });

                setReports(reports);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert('Error fetching reports');
                console.log(e);
            }
        }

        getReports();

    }, [project, date]);

    return <>
        <table className="table-issues">
            <tbody>
                <tr>
                    <th className="robot-number">Robot</th>
                    <th>HALT</th>
                    <th>OBSTACLE</th>
                    <th>GO HOME</th>
                </tr>

                {reports.map((report) => {
                    return <tr key={report.robotId.robotNumber}>
                        <td className="robot-number">{report.robotId.robotNumber}</td>
                        <td className='additional-data'>{report.tableErrors.find(te => te.normalizedErrorCode === 'HALT')?.errorData.map(errorItem =>
                            <div className="line">
                                <span className='date'>{getFormattedDate({ date: errorItem.date, dateOnly: true })}</span>
                                <span className='location'>Location: <span className='value'>{Math.round(errorItem.locationOnRow / 1000)}m</span></span>
                                <span className="error-code-item">Error code: <span className='value'>{errorItem.errorCode}</span></span>
                            </div>)}
                        </td>
                        <td className='additional-data'>{report.tableErrors.find(te => te.normalizedErrorCode === 'OBSTACLE')?.errorData.map(errorItem =>
                            <div className="line">
                                <span className='date'>{getFormattedDate({ date: errorItem.date, dateOnly: true })}</span>                
                                <span className='location'>Location: <span className='value'>{Math.round(errorItem.locationOnRow / 1000)}m</span></span>
                                <span className="error-code-item">Error code: <span className='value'>{errorItem.errorCode}</span></span>
                            </div>)}
                        </td>
                        <td className='additional-data'>{report.tableErrors.find(te => te.normalizedErrorCode === 'GO_HOME')?.errorData.map(errorItem =>
                            <div className="line">
                                <span className='date'>{getFormattedDate({ date: errorItem.date, dateOnly: true })}</span>
                                <span className='location'>Location: <span className='value'>{Math.round(errorItem.locationOnRow / 1000)}m</span></span>
                                <span className="error-code-item">Error code: <span className='value'>{errorItem.errorCode}</span></span>
                            </div>)}
                        </td>

                    </tr>
                })}
            </tbody>
        </table>
    </>
}

export default TableComponent;