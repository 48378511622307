// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper .modal {
  width: 35%; }
  .modal-wrapper .modal .input-line.checkbox .input-description {
    width: auto; }
  .modal-wrapper .modal .input-line.checkbox input {
    width: auto;
    height: auto;
    margin-right: 10px; }
  .modal-wrapper .modal .delete-robotSettings {
    width: 100%;
    text-align: left;
    color: var(--cherry-red);
    margin-bottom: 60px;
    cursor: pointer; }
    .modal-wrapper .modal .delete-robotSettings span {
      height: 25px;
      margin: 0 0 0 7px;
      font-family: Roboto;
      font-size: 17px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.27px;
      color: var(--cherry-red);
      vertical-align: top; }
  .modal-wrapper .modal .buttons-wrapper {
    text-align: center; }
    .modal-wrapper .modal .buttons-wrapper .button {
      display: inline-block !important; }
  .modal-wrapper .modal .button.cancel {
    margin-right: 25px;
    color: var(--brown-grey);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--ice-blue);
    position: relative;
    top: -2px; }
`, "",{"version":3,"sources":["webpack://./src/components/editRobotSettings/editRobotSettings.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;EADZ;IAKM,WAAW,EAAA;EALjB;IASM,WAAW;IACX,YAAY;IACZ,kBAAkB,EAAA;EAXxB;IAgBI,WAAW;IACX,gBAAgB;IAChB,wBAAwB;IACxB,mBAAmB;IACnB,eAAe,EAAA;IApBnB;MA0BM,YAAY;MACZ,iBAAiB;MACjB,mBAAmB;MACnB,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,uBAAuB;MACvB,wBAAwB;MACxB,mBAAmB,EAAA;EAlCzB;IAsCI,kBAAkB,EAAA;IAtCtB;MAyCM,gCAAgC,EAAA;EAzCtC;IA+CI,kBAAkB;IAClB,wBAAwB;IACxB,0CAA0C;IAC1C,iCAAiC;IACjC,kBAAkB;IAClB,SAAS,EAAA","sourcesContent":[".modal-wrapper .modal {\n  width: 35%;\n\n  .input-line.checkbox {\n    .input-description {\n      width: auto;\n    }\n\n    input {\n      width: auto;\n      height: auto;\n      margin-right: 10px;\n    }\n  }\n\n  .delete-robotSettings {\n    width: 100%;\n    text-align: left;\n    color: var(--cherry-red);\n    margin-bottom: 60px;\n    cursor: pointer;\n    img {\n\n    }\n\n    span {\n      height: 25px;\n      margin: 0 0 0 7px;\n      font-family: Roboto;\n      font-size: 17px;\n      font-weight: 500;\n      line-height: 25px;\n      letter-spacing: -0.27px;\n      color: var(--cherry-red);\n      vertical-align: top;\n    }\n  }\n  .buttons-wrapper {\n    text-align: center;\n\n    .button {\n      display: inline-block !important;\n    }\n  }\n\n\n  .button.cancel {\n    margin-right: 25px;\n    color: var(--brown-grey);\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n    background-color: var(--ice-blue);\n    position: relative;\n    top: -2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
